import { makeAutoObservable } from "mobx";
import { DEFAULT_APARTMENT_INFO } from "./IApartmentInfo";
export class DefaultApartmentInfo {
    constructor(data) {
        this.type = "Default";
        this.data = data;
        makeAutoObservable(this);
    }
    static empty() {
        return new DefaultApartmentInfo({
            floor: DEFAULT_APARTMENT_INFO.floor,
            aptNumber: DEFAULT_APARTMENT_INFO.aptNumber,
        });
    }
    static fromStorageData(storageData) {
        return new DefaultApartmentInfo({
            floor: storageData.floor,
            aptNumber: storageData.aptNumber,
        });
    }
    clone() {
        return new DefaultApartmentInfo({ ...this.data });
    }
    get isEmpty() {
        return this.data.floor.length === 0 && this.data.aptNumber.length === 0;
    }
    setData(updated) {
        this.data = { ...this.data, ...updated };
    }
    get storageData() {
        return {
            type: "Default",
            ...this.data,
        };
    }
}
