import { makeAutoObservable } from "mobx";
import { toPairs } from "../collections";
export class ErrorBox {
    constructor(params) {
        this.errorMessages = params.errorMessages;
        this.availabilityObjects = params.domainObjects || null;
        makeAutoObservable(this);
    }
    get errors() {
        if (!this.availabilityObjects)
            return [];
        const availabilityObjects = this.availabilityObjects;
        return toPairs(this.errorMessages)
            .filtered(([key, value]) => value !== undefined &&
            availabilityObjects.some((availabilityObject) => availabilityObject.availability.unavailabilityReasons.includes(key)))
            .map(([_, value]) => value).raw;
    }
    get isEmpty() {
        return this.errors.length === 0;
    }
}
