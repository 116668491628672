import { RArray } from "../collections";
import { makeImmutable } from "../core";
import { PreorderSettingsInstance, } from "./PreorderSettingsInstance";
export class MenuPreorderSettingsProvider {
    constructor(preorderSettings) {
        this.preorderSettings = preorderSettings;
        makeImmutable(this);
    }
    static create(params) {
        const menuPreorderSettings = params.productTypeGroups.values.flatMap((groupsForType) => groupsForType.objects.mapOptional((productTypeGroup) => productTypeGroup.preorderSettings));
        let sameDay = null;
        let relativeDate = null;
        const absoluteDates = [];
        menuPreorderSettings.forEach((setting) => {
            switch (setting.type) {
                case "SameDay":
                    sameDay = setting;
                    break;
                case "RelativeDate":
                    relativeDate =
                        relativeDate === null ? setting : relativeDate.max(setting);
                    break;
                case "AbsoluteDate":
                    if (!absoluteDates.some((existing) => existing.eq(setting))) {
                        absoluteDates.push(setting);
                    }
                    break;
            }
        });
        const result = absoluteDates;
        if (sameDay !== null) {
            result.push(sameDay);
        }
        if (relativeDate !== null) {
            result.push(relativeDate);
        }
        const instances = new RArray(result).map((preorderSettingsType) => params.preorderSettingsInstanceFactory.create(preorderSettingsType));
        return new MenuPreorderSettingsProvider(PreorderSettingsInstance.union(instances));
    }
}
