import { makeImmutable } from "../../core";
import { CrossSellingItemInstance } from "./CrossSellingItemInstance";
import { runInAction } from "mobx";
export class CrossSellingItemInstanceFactory {
    constructor(params) {
        this.listProvider = params.listProvider;
        this.createBehaviour = params.createBehaviour;
        this.productInstanceCreatorFactory = params.productInstanceCreatorFactory;
        makeImmutable(this);
    }
    create(params) {
        const instances = this.listProvider
            .items(params.inputProductTypeIds, params.sequenceNumber)
            .map((crossSellingItemType) => {
            const itemCreator = this.productInstanceCreatorFactory.create(crossSellingItemType.productInstance);
            return new CrossSellingItemInstance({
                typeId: crossSellingItemType.id,
                behaviour: this.createBehaviour(crossSellingItemType.id, itemCreator),
                kind: crossSellingItemType.kind,
                itemCreator,
            });
        });
        runInAction(() => {
            instances.forEach((crossSellingItemInstance) => crossSellingItemInstance.setScope(params.parentScope));
        });
        return instances;
    }
}
