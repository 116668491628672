var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { perRestaurant } from "../../di";
import { FulfillmentInstructions, FulfillmentInstructionsToken, } from "../FulfillmentInstructions";
import { PackagingContainerTypes, Packing, PackingAlgorithm, } from "../Packing";
import { PurchaseOrderPayloadPackingService } from "./PurchaseOrderPayloadPackingService";
import { IdMap } from "../../collections";
let PurchaseOrderPackingService = class PurchaseOrderPackingService {
    constructor(fulfillmentInstructions, purchaseOrderPayloadPackingService, packagingContainerTypes) {
        this.fulfillmentInstructions = fulfillmentInstructions;
        this.purchaseOrderPayloadPackingService = purchaseOrderPayloadPackingService;
        this.packingAlgorithm = packagingContainerTypes.isEmpty
            ? null
            : new PackingAlgorithm({ packagingContainerTypes });
        makeAutoObservable(this);
    }
    get packaging() {
        if (this.packingAlgorithm === null ||
            this.fulfillmentInstructions.fulfillmentMethodType === "DineIn") {
            return Packing.empty();
        }
        return this.packingAlgorithm.pack(this.purchaseOrderPayloadPackingService.packingItems);
    }
};
PurchaseOrderPackingService = __decorate([
    perRestaurant(),
    __param(0, inject(FulfillmentInstructionsToken)),
    __param(2, inject(PackagingContainerTypes)),
    __metadata("design:paramtypes", [typeof (_a = typeof FulfillmentInstructions !== "undefined" && FulfillmentInstructions) === "function" ? _a : Object, typeof (_b = typeof PurchaseOrderPayloadPackingService !== "undefined" && PurchaseOrderPayloadPackingService) === "function" ? _b : Object, typeof (_c = typeof IdMap !== "undefined" && IdMap) === "function" ? _c : Object])
], PurchaseOrderPackingService);
export { PurchaseOrderPackingService };
