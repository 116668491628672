var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject, singleton } from "tsyringe";
import { GlobalConfig } from "../config";
import { Money } from "../core";
import { localizePath } from "../locale";
import { RestaumaticRPCService } from "../services";
/**
 * Service for checking goegraphical information about the order, such as if the restaurant deliver to an address.
 */
let GeocodingService = class GeocodingService {
    constructor(rpcService, globalConfig) {
        this.rpcService = rpcService;
        this.siteId = globalConfig.siteId;
    }
    async geocode(restaurantId, country, address) {
        var _a;
        const result = await this.rpcService.rpc("GeocodeRequest", {
            streetAddress: address,
            country,
            accountId: null,
            restaurantId: (_a = restaurantId === null || restaurantId === void 0 ? void 0 : restaurantId.value) !== null && _a !== void 0 ? _a : null,
            fromPS: true,
        });
        if (result === null) {
            return null;
        }
        return result.coordinates;
    }
    async getDeliveryAddressStatus(restaurantId, streetAddress) {
        var _a;
        const coordinates = await this.geocode(restaurantId, streetAddress.country, streetAddress.data);
        if (coordinates === null) {
            return { type: "AddressDoesNotExistOrInaccurate" };
        }
        else if ("error" in coordinates) {
            return { type: "NetworkError" };
        }
        const restaurants = (_a = (await this.rpcService.rpc("GetRestaurants", {
            siteId: this.siteId,
            location: coordinates,
        }))) !== null && _a !== void 0 ? _a : []; // TODO Handle failed request with different message maybe?
        if ("error" in restaurants) {
            return { type: "NetworkError" };
        }
        const deliveringRestaurants = restaurants.filter((r) => r.deliveryInfo !== null);
        if (deliveringRestaurants.length === 0) {
            return {
                type: "RestaurantDoesntDeliverTo",
                streetAddress,
                coordinates,
            };
        }
        if (restaurantId === null) {
            return {
                type: "ChooseRestaurant",
                restaurants,
            };
        }
        const currentRestaurant = deliveringRestaurants.find((r) => r.id === restaurantId.value);
        if (currentRestaurant === undefined) {
            const restaurant = deliveringRestaurants[0];
            return {
                type: "ChangeRestaurant",
                restaurantInfo: {
                    id: restaurant.id,
                    name: restaurant.name,
                    path: localizePath(`/restauracja/${restaurant.slug}`),
                },
                coordinates,
            };
        }
        const { deliveryCost, minOrderValue, freeDeliveryThreshold } = currentRestaurant.deliveryInfo;
        return {
            type: "RestaurantDelivers",
            deliveryCost: Money.fromBackend(deliveryCost),
            minOrderValue: Money.fromBackend(minOrderValue),
            freeDeliveryThreshold: freeDeliveryThreshold === null
                ? null
                : Money.fromBackend(freeDeliveryThreshold),
            coordinates,
            restaurants,
        };
    }
    async reverseGeocode(restaurantId, coordinates) {
        var _a;
        const response = await this.rpcService.rpc("ReverseGeocodeRequest", {
            coordinates,
            restaurantId: (_a = restaurantId === null || restaurantId === void 0 ? void 0 : restaurantId.value) !== null && _a !== void 0 ? _a : null,
        });
        if (response === null || response.streetAddress === null) {
            throw new Error(`Failed to fetch address from coordinates: ${JSON.stringify(coordinates)}`);
        }
        return response.streetAddress;
    }
};
GeocodingService = __decorate([
    singleton(),
    __param(1, inject(GlobalConfig)),
    __metadata("design:paramtypes", [typeof (_a = typeof RestaumaticRPCService !== "undefined" && RestaumaticRPCService) === "function" ? _a : Object, typeof (_b = typeof GlobalConfig !== "undefined" && GlobalConfig) === "function" ? _b : Object])
], GeocodingService);
export { GeocodingService };
