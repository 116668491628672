import { t, translate } from "../I18n";
export const PProductError = {
    unavailable(parameters) {
        return new Unavailable(parameters);
    },
    temporarilyUnavailable() {
        return new TemporarilyUnavailable();
    },
    maxItemsExceeded(maxItemsNumber) {
        return new MaxItemsExceeded(maxItemsNumber.value);
    },
    notEnoughItems(minItemsNumber) {
        return new NotEnoughItems(minItemsNumber.value);
    },
    notExpectedItems(expectedItemsNumber) {
        return new NotExpectedItems(expectedItemsNumber.value);
    },
    notDivisible(details) {
        return new NotDivisible(details);
    },
    required() {
        return new Required();
    },
    outOfOrderingHours(nextOrderAvailableAt, canPreorder) {
        return new OutOfOrderingHours(nextOrderAvailableAt, canPreorder);
    },
    productCartOfOrderingHours(nextOrderAvailableAt, canPreorder) {
        return new ProductCartOutOfOrderingHours(nextOrderAvailableAt, canPreorder);
    },
};
class Unavailable {
    constructor(parameters) {
        this.type = "Unavailable";
        this.level = "warning";
        if (parameters.length === 0) {
            this.message = translate(t._core_types_sites.menu.creator.errors.productUnavailableInThisVariant);
        }
        else if (parameters.length === 1) {
            this.message = `${translate(t._core_types_sites.menu.creator.errors
                .productUnavailableInThisVariantOneParameter)}: ${parameters.join(", ")}.`;
        }
        else {
            this.message = `${translate(t._core_types_sites.menu.creator.errors.productUnavailableInThisVariant)}: ${parameters.join(", ")}.`;
        }
    }
}
class TemporarilyUnavailable {
    constructor() {
        this.type = "TemporarilyUnavailable";
        this.level = "danger";
        this.message = translate(t._core_types_sites.menu.creator.errors.productTemporarilyUnavailable);
    }
}
class MaxItemsExceeded {
    constructor(maxItems) {
        this.type = "MaxItemsExceeded";
        this.level = "danger";
        this.maxItems = maxItems;
        this.message = translate(t._core_types_sites.menu.creator.errors.chooseAtMostNModifierItems, { maxItems: maxItems.toString() });
    }
}
class NotEnoughItems {
    constructor(minItems) {
        this.type = "NotEnoughItems";
        this.level = "danger";
        this.minItems = minItems;
        this.message = translate(t._core_types_sites.menu.creator.errors.chooseAtLeastNModifierItems, { minItems: minItems.toString() });
    }
}
class NotExpectedItems {
    constructor(expectedItems) {
        this.type = "NotExpectedItems";
        this.level = "danger";
        this.expectedItems = expectedItems;
        this.message = translate(t._core_types_sites.menu.creator.errors.chooseExactlyNModifierItems, { expectedItems: expectedItems.toString() });
    }
}
class NotDivisible {
    constructor(details) {
        this.type = "NotDivisible";
        this.level = "danger";
        this.message = `${translate(t._core_types_sites.menu.creator.errors.productNotDivisibleInThisVariant)}: ${details.join(", ")}.`;
    }
}
class Required {
    constructor() {
        this.type = "Required";
        this.level = "danger";
        this.message = translate(t._common.form.errors.required);
    }
}
class OutOfOrderingHours {
    constructor(nextOrderAvailableAt, canPreorder) {
        this.type = "OutOfOrderingHours";
        this.level = "warning";
        if (nextOrderAvailableAt === null) {
            this.message = translate(t._core_types_sites.menu.alerts.restaurantIsNotTakingOrdersNow);
        }
        else if (nextOrderAvailableAt.type === "today") {
            this.message = translate(t._core_types_sites.menu.alerts.ordersWillBeProcessed.today, { hour: nextOrderAvailableAt.hour });
        }
        else if (nextOrderAvailableAt.type === "tomorrow") {
            this.message = translate(t._core_types_sites.menu.alerts.ordersWillBeProcessed.tomorrow, { hour: nextOrderAvailableAt.hour });
        }
        else {
            this.message = translate(t._core_types_sites.menu.alerts.ordersWillBeProcessed.later, { date: nextOrderAvailableAt.date });
        }
        if (canPreorder) {
            this.message = `${this.message}, ${translate(t._core_types_sites.menu.alerts.ordersWillBeProcessed.forAFutureDate)}.`;
            this.level = "info";
        }
    }
}
class ProductCartOutOfOrderingHours {
    constructor(nextOrderAvailableAt, canPreorder) {
        this.type = "OutOfOrderingHours";
        this.level = "warning";
        if (nextOrderAvailableAt === null) {
            this.message = translate(t._core_types_sites.menu.alerts.restaurantIsNotTakingOrdersNow);
        }
        else if (nextOrderAvailableAt.type === "today") {
            this.message = translate(t._core_types_sites.menu.alerts.ordersWillBeProcessed.today, { hour: nextOrderAvailableAt.hour });
        }
        else if (nextOrderAvailableAt.type === "tomorrow") {
            this.message = translate(t._core_types_sites.menu.alerts.ordersWillBeProcessed.tomorrow, { hour: nextOrderAvailableAt.hour });
        }
        else {
            this.message = translate(t._core_types_sites.menu.alerts.ordersWillBeProcessed.later, { date: nextOrderAvailableAt.date });
        }
        if (canPreorder) {
            this.message = `${this.message}, ${translate(t._core_types_sites.menu.alerts.ordersWillBeProcessed.forAFutureDate)}.`;
            this.level = "info";
        }
        else {
            this.message = `${this.message}. ${translate(t._core_types_sites.menu.alerts.ordersWillBeProcessed.onlyInRestaurant)}.`;
        }
    }
}
