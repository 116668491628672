import { Intl, Temporal } from "@js-temporal/polyfill";
import { DaySecond, FULL_DAY } from "./DaySecond";
import { makeImmutable } from "./makeImmutable";
export const WEEKDAY_NAMES = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
export const ISO_WEEKDAY_NAMES = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];
const MONTH_NAMES = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
/*
 * Restaumatic date wrapper. Represents Date in the restaurant timezone
 */
export class RDate {
    constructor(raw) {
        this.raw = raw;
        makeImmutable(this);
    }
    static now(timezone) {
        return new RDate(Temporal.Now.zonedDateTimeISO(timezone));
    }
    static fromUtcTimeString(strInUtc, timezone) {
        return new RDate(Temporal.ZonedDateTime.from(strInUtc + `[${timezone}]`));
    }
    static fromLocalTimeString(strInLocalTime, timezone) {
        return new RDate(Temporal.PlainDateTime.from(strInLocalTime).toZonedDateTime(timezone, {
            disambiguation: "compatible",
        }));
    }
    static fromLocalStorage(strInLocalTime) {
        try {
            const date = Temporal.ZonedDateTime.from(strInLocalTime);
            return new RDate(date);
        }
        catch (e) {
            return null;
        }
    }
    static compare(a, b) {
        return Temporal.ZonedDateTime.compare(a.raw, b.raw);
    }
    toLocalStorage() {
        const strInLocalTime = this.raw.toString();
        return strInLocalTime;
    }
    toString() {
        return new Date(this.raw.toInstant().epochMilliseconds).toISOString();
    }
    get day() {
        return this.raw.dayOfWeek;
    }
    get weekday() {
        return ISO_WEEKDAY_NAMES[this.raw.dayOfWeek - 1];
    }
    get month() {
        return MONTH_NAMES[this.raw.month - 1];
    }
    get secondOfDay() {
        const seconds = this.differenceInSeconds(this.startOfDay());
        // NOTICE Hotfix for winter time change
        // TODO rethink the concept of time
        return DaySecond.fromNumber(Math.max(0, Math.min(FULL_DAY, seconds)));
    }
    /**
     * Format as "YYYY-MM-DD"
     */
    get fullDate() {
        return this.raw.toPlainDate().toString();
    }
    /**
     * Format with provided template using Intl.DateTimeFormat
     */
    format(template) {
        const formatter = new Intl.DateTimeFormat("pl-PL", {
            ...template,
            timeZone: this.raw.timeZoneId,
        });
        return formatter.format(this.raw.toInstant().epochMilliseconds);
    }
    // it was "D"
    formatD() {
        return this.format({ day: "2-digit" });
    }
    // it was "DD.MM, HH:mm";
    formatDDMMHHmm() {
        return this.format({
            day: "2-digit",
            month: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        });
    }
    // it was "DD.MM"
    formatDDMM() {
        return this.format({ day: "2-digit", month: "2-digit" });
    }
    // it was "DD.MM.YYYY"
    formatDDMMYYYY() {
        return this.format({ day: "2-digit", month: "2-digit", year: "numeric" });
    }
    // it was "DD.MM.YYYY HH:mm"
    formatDDMMYYYYHHmm() {
        return this.format({
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
    }
    // it was "HH:mm"
    formatHHmm() {
        return this.format({ hour: "2-digit", minute: "2-digit" });
    }
    /**
     * Check if this date is before the provided date
     */
    isBefore(other) {
        return this.raw.epochNanoseconds < other.raw.epochNanoseconds;
    }
    isSameOrBefore(other) {
        return this.raw.epochNanoseconds <= other.raw.epochNanoseconds;
    }
    /**
     * Check if this date is after the provided date
     */
    isAfter(other) {
        return this.raw.epochNanoseconds > other.raw.epochNanoseconds;
    }
    isSameOrAfter(other) {
        return this.raw.epochNanoseconds >= other.raw.epochNanoseconds;
    }
    eq(other) {
        return this.raw.epochNanoseconds === other.raw.epochNanoseconds;
    }
    min(other) {
        return this.isBefore(other) ? this : other;
    }
    max(other) {
        return this.isAfter(other) ? this : other;
    }
    /**
     * Check if this date is between the provided dates
     */
    isBetween(begin, end) {
        return (this.raw.epochNanoseconds >= begin.raw.epochNanoseconds &&
            this.raw.epochNanoseconds <= end.raw.epochNanoseconds);
    }
    isSameDate(other) {
        return this.raw.toPlainDate().equals(other.raw.toPlainDate());
    }
    get isToday() {
        return Temporal.Now.plainDateISO().equals(this.raw.toPlainDate());
    }
    get isTomorrow() {
        return Temporal.Now.plainDateISO()
            .add({ days: 1 })
            .equals(this.raw.toPlainDate());
    }
    differenceInSeconds(other) {
        return (Number(this.raw.epochNanoseconds - other.raw.epochNanoseconds) /
            1000000000);
    }
    differenceInDays(other) {
        return this.raw.since(other.raw, { largestUnit: "days" }).days;
    }
    add(number, unit) {
        return new RDate(this.raw.add({ [unit]: number }));
    }
    sub(number, unit) {
        return new RDate(this.raw.subtract({ [unit]: number }));
    }
    startOfDay() {
        return new RDate(this.raw.withPlainTime(Temporal.PlainTime.from("00:00")));
    }
    endOfDay() {
        return new RDate(this.raw.withPlainTime(Temporal.PlainTime.from("23:59:59.999")));
    }
    /*
     * Returns an hour indicated by the daySecond at current date
     */
    atDaySecond(daySecond) {
        const { hour, minute, second } = daySecond.hms;
        return new RDate(this.raw.withPlainTime({ hour, minute, second }));
    }
    get timeZone() {
        return this.raw.timeZoneId;
    }
    get timeZoneLocale() {
        var _a, _b;
        const formatter = new Intl.DateTimeFormat(Skubacz.configuration.locale, {
            timeZone: this.timeZone,
            timeZoneName: "long",
        });
        const parts = formatter.formatToParts(new Date());
        return (_b = (_a = parts.find((part) => part.type === "timeZoneName")) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : "";
    }
}
export const Now = "Now";
