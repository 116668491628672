var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { IdentifiableMap } from "../../collections";
import { makeImmutable, randomUUID } from "../../core";
import { perRestaurant } from "../../di";
import { CustomParameterInstanceFactory, Menu, ProductConfig, ProductInstance, ProductInstanceCreatorFactory, ProductInstanceHalves, ProductInstancePart, ProductInstanceTrackingId, PurchaseOrderScope, SingleProductInstancePart, } from "../../ordering";
import { MenuSectionItemScope } from "./MenuSectionItemScope";
let VariantCreatorFactory = class VariantCreatorFactory {
    constructor(menu, purchaseOrderScope, productInstanceCreatorFactory, customParameterInstanceFactoy) {
        this.menu = menu;
        this.purchaseOrderScope = purchaseOrderScope;
        this.productInstanceCreatorFactory = productInstanceCreatorFactory;
        this.customParameterInstanceFactoy = customParameterInstanceFactoy;
        makeImmutable(this);
    }
    createProductInstance(variant) {
        const customParameterInstances = this.customParameterInstanceFactoy.fromCustomParameterSet(variant.customParameterChoices);
        const menuSectionItemScope = new MenuSectionItemScope({
            parentScope: this.purchaseOrderScope,
            selfCustomParameterSet: variant.customParameterChoices,
        });
        const { commonConfig, partsConfig } = this.productConfigs(customParameterInstances);
        const parts = this.creatorParts(variant.productTypes, partsConfig, menuSectionItemScope);
        if (parts === null) {
            return null;
        }
        return new ProductInstance({
            commonConfig,
            parts,
            trackingId: new ProductInstanceTrackingId(randomUUID()),
        });
    }
    productConfigs(customParameterInstances) {
        const { true: divisibleParameterInstances, false: nonDivisibleParameterInstances, } = customParameterInstances.partition((customParameterInstance) => customParameterInstance.customParameterType.isDivisible);
        const commonConfig = new ProductConfig({
            modifierInstances: IdentifiableMap.empty(),
            customParameterInstances: nonDivisibleParameterInstances,
        });
        const partsConfig = new ProductConfig({
            modifierInstances: IdentifiableMap.empty(),
            customParameterInstances: divisibleParameterInstances,
        });
        return { commonConfig, partsConfig };
    }
    creatorParts(productTypes, partsConfig, menuSectionItemScope) {
        switch (productTypes.type) {
            case "Single":
                return new SingleProductInstancePart(new ProductInstancePart({
                    scope: menuSectionItemScope,
                    productType: productTypes.productType,
                    productConfig: partsConfig,
                }));
            case "Halves":
                return ProductInstanceHalves.create({
                    scope: menuSectionItemScope,
                    productCategory: this.menu.productCategories.get(productTypes.first.productCategoryId),
                    first: new ProductInstancePart({
                        scope: menuSectionItemScope,
                        productType: productTypes.first,
                        productConfig: partsConfig,
                    }),
                    second: new ProductInstancePart({
                        scope: menuSectionItemScope,
                        productType: productTypes.second,
                        productConfig: partsConfig,
                    }),
                });
        }
    }
    creator(productInstance) {
        const creator = this.productInstanceCreatorFactory.create(productInstance);
        creator.initWithScope(this.purchaseOrderScope);
        return creator;
    }
};
VariantCreatorFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof Menu !== "undefined" && Menu) === "function" ? _a : Object, typeof (_b = typeof PurchaseOrderScope !== "undefined" && PurchaseOrderScope) === "function" ? _b : Object, typeof (_c = typeof ProductInstanceCreatorFactory !== "undefined" && ProductInstanceCreatorFactory) === "function" ? _c : Object, typeof (_d = typeof CustomParameterInstanceFactory !== "undefined" && CustomParameterInstanceFactory) === "function" ? _d : Object])
], VariantCreatorFactory);
export { VariantCreatorFactory };
