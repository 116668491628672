import { makeAutoObservable, runInAction } from "mobx";
import { CustomParameterInstance } from "../ProductInstance";
export class CustomParameterInstanceCreator {
    constructor(params) {
        // Note: assigned in constructor via choose
        this._selectedChoice = null;
        this.customParameterType = params.customParameterType;
        if (params.selectedChoice) {
            this.choose(params.selectedChoice);
        }
        makeAutoObservable(this);
    }
    get typeId() {
        return this.customParameterType.id;
    }
    get customParameterInstance() {
        return new CustomParameterInstance({
            customParameterType: this.customParameterType,
            selectedChoice: this.selectedChoice,
        });
    }
    /**
     * Select one of the choices in this parameter
     */
    choose(choiceId) {
        if (!this.customParameterType.includesChoice(choiceId)) {
            throw new Error(`CustomParameterChoiceId ${choiceId.toString()} is not supported`);
        }
        runInAction(() => {
            this._selectedChoice = choiceId;
        });
    }
    get selectedChoice() {
        return this._selectedChoice;
    }
    clone() {
        return new CustomParameterInstanceCreator({
            customParameterType: this.customParameterType,
            selectedChoice: this._selectedChoice,
        });
    }
}
