import { makeAutoObservable } from "mobx";
import { getTimeRemaining } from "./PFormattedHour";
const CLOCK_INTERVAL = 1000;
export class POrderSummaryCompleted {
    constructor(params) {
        this.type = "Completed";
        this.isFinal = true;
        this.restaurantAddress = params.restaurantAddress;
        this.fulfillmentMethodType = params.fulfillmentMethodType;
        this.restaurantPhones = params.restaurantPhones;
        this._completedAt = params.completedAt;
        this.now = params.now;
        this.isPreorder = params.isPreorder;
        this.interval = null;
        this._remainingTime = null;
        makeAutoObservable(this);
    }
    setRemaining(remainingTime) {
        this._remainingTime = remainingTime;
    }
    startClock() {
        if (this.interval !== null) {
            throw new Error("Double initialization error");
        }
        this.updateClock();
        this.interval = setInterval(() => this.updateClock(), CLOCK_INTERVAL);
    }
    updateClock() {
        const now = this.now();
        this.setRemaining(getTimeRemaining(now, this._completedAt));
    }
    stopClock() {
        if (this.interval === null) {
            throw new Error("Double disposal error");
        }
        clearInterval(this.interval);
        this.interval = null;
    }
    get completedAtDate() {
        return this._completedAt.fullDate;
    }
    get completedAtHour() {
        const restaurantRDate = this._completedAt;
        return `${restaurantRDate.formatHHmm()}`;
    }
    get remainingTime() {
        return this._remainingTime;
    }
}
