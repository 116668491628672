import { makeImmutable } from "../../core";
export class ModifierItemInstance {
    constructor(params) {
        this.scope = params.scope;
        this.modifierType = params.modifierType;
        this.modifierItemType = params.modifierItemType;
        this.quantity = params.quantity;
        this.defaultQuantity = params.modifierSettings.defaultQuantity(params.modifierItemType.id);
        makeImmutable(this);
    }
    get isSelected() {
        return this.quantity.value > 0;
    }
    eq(other) {
        return this.typeId.eq(other.typeId) && this.quantity.eq(other.quantity);
    }
    get typeId() {
        return this.modifierItemType.id;
    }
}
