var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { IdentifiableMap } from "../../collections";
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { CustomParameterInstanceFactory, ModifierInstanceFactory, } from "../ProductInstance";
import { CustomParameterInstanceCreatorFactory } from "./CustomParameterInstanceFactory";
import { ModifierInstanceCreatorFactory } from "./ModifierInstanceCreatorFactory";
import { ProductConfigCreator } from "./ProductConfigCreator";
let ProductConfigCreatorFactory = class ProductConfigCreatorFactory {
    constructor(modifierInstanceCreatorFactory, modifierInstanceFactory, customParameterInstanceCreatorFactory, customParameterInstanceFactory) {
        this.modifierInstanceCreatorFactory = modifierInstanceCreatorFactory;
        this.modifierInstanceFactory = modifierInstanceFactory;
        this.customParameterInstanceCreatorFactory = customParameterInstanceCreatorFactory;
        this.customParameterInstanceFactory = customParameterInstanceFactory;
        makeImmutable(this);
    }
    create(params) {
        const modifierInstanceCreators = IdentifiableMap.fromIterable("typeId", params.modifierTypes.objects.map((modifierType) => {
            const initialInstance = params.initialModifierInstances.find(modifierType.id);
            const modifierSettings = params.modifierSettings(modifierType);
            return this.modifierInstanceCreatorFactory.create({
                modifierInstance: initialInstance !== null && initialInstance !== void 0 ? initialInstance : this.modifierInstanceFactory.createWithDefaultItems({
                    modifierType,
                    modifierSettings,
                }),
                modifierSettings,
            });
        }));
        const customParameterInstanceCreators = IdentifiableMap.fromIterable("typeId", params.customParameterTypes.objects.map((customParameterType) => {
            const initialInstance = params.initialCustomParameterInstances.find(customParameterType.id);
            return this.customParameterInstanceCreatorFactory.create(initialInstance !== null && initialInstance !== void 0 ? initialInstance : this.customParameterInstanceFactory.createWithDefaultChoice(customParameterType));
        }));
        return new ProductConfigCreator({
            modifierInstanceCreators,
            customParameterInstanceCreators,
        });
    }
};
ProductConfigCreatorFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ModifierInstanceCreatorFactory !== "undefined" && ModifierInstanceCreatorFactory) === "function" ? _a : Object, typeof (_b = typeof ModifierInstanceFactory !== "undefined" && ModifierInstanceFactory) === "function" ? _b : Object, typeof (_c = typeof CustomParameterInstanceCreatorFactory !== "undefined" && CustomParameterInstanceCreatorFactory) === "function" ? _c : Object, typeof (_d = typeof CustomParameterInstanceFactory !== "undefined" && CustomParameterInstanceFactory) === "function" ? _d : Object])
], ProductConfigCreatorFactory);
export { ProductConfigCreatorFactory };
