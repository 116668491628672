import { Tracking } from "./Tracking";
export const GeolocationTracking = { findAddressEvent };
function addressStateToResult(state, streetAddress) {
    switch (state.type) {
        case "RestaurantDoesntDeliverTo":
            return { tag: state.type, streetAddress, coordinates: state.coordinates };
        case "AddressDoesNotExistOrInaccurate":
            return { tag: state.type, streetAddress };
        case "NetworkError":
        case "ChooseRestaurant":
        case "ChangeRestaurant":
        case "RestaurantDelivers":
            return { tag: state.type };
        default:
            return null;
    }
}
let lastResult = null;
function findAddressEvent(origin, status, streetAddress) {
    const result = addressStateToResult(status, streetAddress);
    const isNewResult = result !== null &&
        !(status.type === (lastResult === null || lastResult === void 0 ? void 0 : lastResult.type) &&
            (lastResult === null || lastResult === void 0 ? void 0 : lastResult.streetAddress.eq(streetAddress)));
    if (isNewResult) {
        Tracking.trackEvent({
            tag: "FindAddress",
            contents: {
                origin,
                result,
            },
        });
        lastResult = { type: status.type, streetAddress: streetAddress.clone() };
    }
}
