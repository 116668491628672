import { makeImmutable, Money } from "../../core";
/**
 * A group of items that can be packed, packed into containers, and unpackable items.
 */
export class Packing {
    constructor(params) {
        this.containers = params.containers;
        this.unpackableItems = params.unpackableItems;
        makeImmutable(this);
    }
    static empty() {
        return new Packing({ containers: [], unpackableItems: [] });
    }
    get price() {
        return Money.total(this.containers.map((container) => container.price));
    }
    addContainer(container) {
        this.containers.push(container);
    }
    pack(item) {
        let lastRemainder = item;
        for (const container of this.containers) {
            const result = container.pack(lastRemainder);
            if (result.type === "remained") {
                lastRemainder = result.remainder;
            }
            else {
                return result;
            }
        }
        return { type: "remained", remainder: lastRemainder };
    }
    unpackable(item) {
        this.unpackableItems.push(item);
    }
}
