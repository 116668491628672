import { flow, makeAutoObservable, reaction } from "mobx";
import { Availability } from "./Availability";
import { result } from "../core";
/**
 * Phone number
 */
export class PhoneNumber {
    constructor(params) {
        this.value = params.value;
        this.country = params.country;
        this.validatePhoneService = params.validatePhoneService;
        this._status = { type: "NotRequested" };
        makeAutoObservable(this, { _validate: flow });
        reaction(() => this.value, (value) => this._validate(value), { delay: 1000, fireImmediately: true });
    }
    static empty(validatePhoneService) {
        return new PhoneNumber({
            value: "",
            country: null,
            validatePhoneService,
        });
    }
    get isEmpty() {
        return this.value.length === 0;
    }
    *_validate(value) {
        this._status = { type: "Loading" };
        if (value.trim() === "" || this.country === null) {
            this._status = { type: "NoPhoneProvided" };
            return;
        }
        try {
            const isNumberValid = yield* result(this.validatePhoneService.validatePhoneNumber(value, this.country));
            this._status =
                isNumberValid === true
                    ? { type: "PhoneValid" }
                    : { type: "PhoneInvalid" };
        }
        catch {
            this._status = { type: "NetworkError" };
        }
    }
    get availability() {
        return Availability.boolean({
            PhoneMissing: this.value.length === 0,
            InvalidPhoneNumber: this._status.type === "PhoneInvalid",
        });
    }
}
