var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject } from "tsyringe";
import { Now, RDate, makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { IOrderingHours, OnlineOrderingSettingsToken, OrderingHoursAvailability, } from "../ordering";
let OrderingHoursAvailabilityFactory = class OrderingHoursAvailabilityFactory {
    constructor(now, orderingHours, onlineOrderingSettings) {
        this.now = now;
        this.orderingHours = orderingHours;
        this.onlineOrderingSettings = onlineOrderingSettings;
        makeImmutable(this);
    }
    create(newFulfillmentTime) {
        return new OrderingHoursAvailability({
            now: this.now,
            orderingHours: this.orderingHours,
            onlineOrderingSettings: this.onlineOrderingSettings,
            fulfillmentTime: newFulfillmentTime,
        });
    }
};
OrderingHoursAvailabilityFactory = __decorate([
    perRestaurant(),
    __param(0, inject(Now)),
    __param(1, inject(IOrderingHours)),
    __param(2, inject(OnlineOrderingSettingsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof RDate !== "undefined" && RDate) === "function" ? _a : Object, typeof (_b = typeof IOrderingHours !== "undefined" && IOrderingHours) === "function" ? _b : Object, Object])
], OrderingHoursAvailabilityFactory);
export { OrderingHoursAvailabilityFactory };
