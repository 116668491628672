var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { naturalCompare, RArray } from "../collections";
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { ProductInstanceAvailabilityService, } from "../ordering";
import { PProductConfigCreatorFactory } from "./PProductConfigCreatorFactory";
import { PProductInstanceCreator } from "./PProductInstanceCreator";
import { PProductInstanceCreatorPartFactory } from "./PProductInstanceCreatorPartFactory";
import { PRestaurant } from "./PRestaurant";
let PProductInstanceCreatorFactory = class PProductInstanceCreatorFactory {
    constructor(pProductInstanceCreatorPartFactory, pProductConfigCreatorFactory, productInstanceAvailabilityService, restaurant) {
        this.pProductInstanceCreatorPartFactory = pProductInstanceCreatorPartFactory;
        this.pProductConfigCreatorFactory = pProductConfigCreatorFactory;
        this.productInstanceAvailabilityService = productInstanceAvailabilityService;
        this.restaurant = restaurant;
        makeImmutable(this);
    }
    create(domain) {
        const possibleProductTypes = new RArray(domain.possibleProductTypeIds)
            .map((possibleProductTypeId) => this.restaurant.productTypes.get(possibleProductTypeId))
            .sorted((a, b) => naturalCompare(a.name, b.name)).raw;
        return new PProductInstanceCreator({
            domain,
            pProductInstanceCreatorPartFactory: this.pProductInstanceCreatorPartFactory,
            pProductConfigCreatorFactory: this.pProductConfigCreatorFactory,
            restaurant: this.restaurant,
            possibleProductTypes,
            productInstanceAvailabilityService: this.productInstanceAvailabilityService,
        });
    }
};
PProductInstanceCreatorFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PProductInstanceCreatorPartFactory !== "undefined" && PProductInstanceCreatorPartFactory) === "function" ? _a : Object, typeof (_b = typeof PProductConfigCreatorFactory !== "undefined" && PProductConfigCreatorFactory) === "function" ? _b : Object, typeof (_c = typeof ProductInstanceAvailabilityService !== "undefined" && ProductInstanceAvailabilityService) === "function" ? _c : Object, typeof (_d = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _d : Object])
], PProductInstanceCreatorFactory);
export { PProductInstanceCreatorFactory };
