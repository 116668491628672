var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
import { makeAutoObservable } from "mobx";
import { perRestaurant } from "../di";
import { Now, RDate } from "../core";
import { PCartIntegration } from "./PCartIntegration";
import { inject } from "tsyringe";
import { PRestaurant, PCheckout } from "../presentation";
let PMobileApps = class PMobileApps {
    constructor(now, restaurant, cartIntegration, checkout) {
        this.now = now;
        this.restaurant = restaurant;
        this.cartIntegration = cartIntegration;
        this.checkout = checkout;
        makeAutoObservable(this);
    }
};
PMobileApps = __decorate([
    perRestaurant(),
    __param(0, inject(Now)),
    __metadata("design:paramtypes", [typeof (_a = typeof RDate !== "undefined" && RDate) === "function" ? _a : Object, typeof (_b = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _b : Object, typeof (_c = typeof PCartIntegration !== "undefined" && PCartIntegration) === "function" ? _c : Object, typeof (_d = typeof PCheckout !== "undefined" && PCheckout) === "function" ? _d : Object])
], PMobileApps);
export { PMobileApps };
