import Device from "./Device";
import isValidSelector from "./isValidSelector";
import isLocalURL from "./isLocalURL";
const activeClass = "is-active";
const spyNavSelector = ".js-scroll-spy-nav";
const dropdownSelector = ".dropdown";
const dropdownToggleSelector = ".dropdown-toggle";
const targetSelector = "a";
/**
 * Used to disable the scrollspy feature eg. when the page is being scrolled programmatically.
 */
let isDisabled = false;
/**
 * Format the link to be recognized by Observer method.
 *
 * Links must have resolvable id targets, e.g, `<a href="#section-menu">menu</a>` must correspond to something in the DOM like `<div id="section-menu"></div>`.
 */
function ensureRequiredLinkFormat(targets) {
    targets.forEach((el) => {
        const href = el.getAttribute("href");
        // When link points to the anchor on current page, change `<a href="/subpage/#section-menu">menu</a>` to `<a href="#section-menu">menu</a>`.
        if (href &&
            href.indexOf("#") !== -1 &&
            href.split("#")[0] === window.location.pathname) {
            el.setAttribute("href", "#" + href.split("#")[1]);
        }
    });
}
/**
 * Reset state of all spy nav links.
 */
function resetSpyLinks(spyNav) {
    const spyLinks = spyNav.querySelectorAll(`${targetSelector}[href]`);
    spyLinks.forEach((link) => {
        var _a;
        link.classList.remove(activeClass);
        // Remove active state of dropdown toggle item.
        let dropdown = link.closest(dropdownSelector);
        if (!dropdown)
            return;
        (_a = dropdown
            .querySelector(dropdownToggleSelector)) === null || _a === void 0 ? void 0 : _a.classList.remove(activeClass);
    });
}
/**
 * Observer callback for handle spy link active state.
 *
 * We support `href` attribute only
 */
const scrollObserverFn = (entries) => {
    if (isDisabled)
        return;
    entries.forEach((entry) => {
        const sectionID = entry.target.id;
        const currentSpyLinks = document.querySelectorAll(`${spyNavSelector} ${targetSelector}[href="#${sectionID}"]`);
        if (!entry.isIntersecting)
            return;
        currentSpyLinks.forEach((link) => {
            var _a;
            resetSpyLinks(link.closest(spyNavSelector));
            link.classList.add(activeClass);
            // Set dropdown toggle item as active.
            let dropdown = link.closest(dropdownSelector);
            if (!dropdown)
                return;
            (_a = dropdown
                .querySelector(dropdownToggleSelector)) === null || _a === void 0 ? void 0 : _a.classList.add(activeClass);
        });
    });
};
/**
 * Handle scrollspy links & observable sections for single navigation.
 */
function handleScrollSpy(navigation, scrollArea, areaOffset, threshold) {
    let observableSections = [];
    const navLinkWithHref = navigation.querySelectorAll(`${targetSelector}[href]`);
    const intersectionOptions = {
        root: scrollArea,
        rootMargin: areaOffset,
        threshold: threshold,
    };
    navLinkWithHref.forEach((item) => {
        if (!item.hash || !isLocalURL(item.href) || !isValidSelector(item.hash))
            return;
        let section = document.querySelector(item.hash);
        if (!section || observableSections.includes(section))
            return;
        observableSections.push(section);
    });
    const scrollObserver = new IntersectionObserver(scrollObserverFn, intersectionOptions);
    observableSections.forEach((section) => {
        scrollObserver.observe(section);
    });
}
/**
 * Init method of ScrollSpy.
 */
function initScrollSpy(scrollArea, areaOffset, threshold) {
    const spyNavigations = document.querySelectorAll(spyNavSelector);
    spyNavigations.forEach((nav) => {
        const navLinks = nav.querySelectorAll(`${targetSelector}:not([href="#"])`);
        ensureRequiredLinkFormat(navLinks);
        handleScrollSpy(nav, scrollArea, areaOffset, threshold);
    });
}
export default {
    /**
     * ScrollSpy custom implementation with Intersection Observer.
     *
     * @link https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
     */
    init: function (
    // Element that is used as the viewport for checking visibility of the target. Defaults to the browser viewport if null.
    scrollArea = null, 
    // This value serves to grow or shrink each side of the root element's box before computing intersections
    areaOffset, 
    // That means at what percentage of the target's visibility the observer's callback should be executed
    threshold = [0]) {
        if (Device.screen.isMobileSize()) {
            areaOffset = "-20% 0px -80% 0px";
        }
        else {
            areaOffset = "-10% 0px -90% 0px";
        }
        initScrollSpy(scrollArea, areaOffset, threshold);
    },
    disable: () => (isDisabled = true),
    enable: () => (isDisabled = false),
};
