import { makeImmutable } from "../../core";
/**
 * Container for a menu of a restaurant. (a restaurant can have multiple menus)
 */
export class Menu {
    constructor(params) {
        this.productCategories = params.productCategories;
        this.productTypes = params.productTypes;
        this.modifierTypes = params.modifierTypes;
        this.customParameterTypes = params.customParameterTypes;
        this.schedules = params.schedules;
        makeImmutable(this);
    }
    /**
     * @returns are the provided product types included in the menu?
     */
    includes(productTypeIds) {
        return productTypeIds.isSubsetOf(this.productTypes.ids);
    }
}
