import { makeAutoObservable } from "mobx";
import { IdMap } from "../collections";
export class InCartPromotionTypes {
    constructor(initialPromotionTypes) {
        this.coupon = null;
        this.initialPromotionTypes = initialPromotionTypes;
        makeAutoObservable(this);
    }
    static create(promotionTypes) {
        return new InCartPromotionTypes(IdMap.fromIterable(promotionTypes.objects.map((p) => p.domain)));
    }
    setCoupon(coupon) {
        this.coupon = coupon;
    }
    get promotionTypes() {
        if (this.coupon === null) {
            return this.initialPromotionTypes;
        }
        const couponPromotion = IdMap.fromIterable([this.coupon.promotion]);
        return this.initialPromotionTypes.union(couponPromotion);
    }
    get couponCode() {
        var _a, _b;
        return (_b = (_a = this.coupon) === null || _a === void 0 ? void 0 : _a.code.value) !== null && _b !== void 0 ? _b : null;
    }
    clear() {
        this.coupon = null;
    }
}
