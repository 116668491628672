import { getDuplicates, RArray, RSet } from "../../collections";
import { makeImmutable } from "../../core";
import { ModifierItemTypeId, ModifierTypeId, } from "../../ordering";
import { Sentry } from "../../Sentry";
import { PProductType } from "../PProductType";
export class PProductTypeParser {
    constructor(params) {
        this.domainProductTypes = params.domainProductTypes;
        this.domainSchedules = params.domainSchedules;
        this.translatedParser = params.translatedParser;
        this.modifierTypes = params.modifierTypes;
        makeImmutable(this);
    }
    parse(id, productCategoryName, product) {
        const domainProductType = this.domainProductTypes.rawFind(id);
        if (domainProductType === null) {
            return null;
        }
        const allergens = this.validateAndDeduplicateAllergens(id, product);
        return new PProductType({
            domain: domainProductType,
            name: this.translatedParser.parse(product.name),
            categoryName: productCategoryName,
            tags: product.tags,
            photo: product.photo,
            description: this.translatedParser.parse(product.description),
            allergens,
            freeModifierItemsCount: this.parseProductFreeModifierItemsCount(product),
            baseIngredients: this.parseProductBaseIngredientsString(product, domainProductType),
            availabilitySchedule: product.availabilitySchedule === null
                ? null
                : this.domainSchedules.rawGet(product.availabilitySchedule),
            availability: product.availability,
            divisibleModifiersOrder: product.divisibleModifiersOrder.map((modifierId) => new ModifierTypeId(modifierId)),
            nonDivisibleModifiersOrder: product.nonDivisibleModifiersOrder.map((modifierId) => new ModifierTypeId(modifierId)),
        });
    }
    parseProductFreeModifierItemsCount(product) {
        return Object.values(product.modifierSettings)
            .map((modifierSetting) => modifierSetting.freeItems)
            .reduce((a, b) => a + b, 0);
    }
    parseProductBaseIngredientsString(product, domainProduct) {
        const modifierIds = [...domainProduct.modifierTypes.ids];
        return modifierIds
            .map((modifierId) => this.modifierTypes.get(modifierId))
            .map((modifierType) => this.parseProductModifierDefaultItemsNames(product, modifierType))
            .filter((v) => v !== null)
            .join(", ");
    }
    parseProductModifierDefaultItemsNames(product, modifier) {
        const modifierSettings = product.modifierSettings[modifier.id.value];
        if (modifierSettings === undefined ||
            modifierSettings.displayDefaultItems === false ||
            modifierSettings.defaultItems === null) {
            return null;
        }
        const defaultItems = new RArray(modifierSettings.defaultItems.map(([itemId, quantity]) => [new ModifierItemTypeId(itemId), quantity]));
        return modifier.getItemsDescription(defaultItems);
    }
    validateAndDeduplicateAllergens(productId, product) {
        const allergens = product.allergens.map(([allergen]) => allergen);
        const duplicateAllergens = getDuplicates(allergens);
        if (duplicateAllergens.length > 0) {
            const msg = `Detected duplicated allergens(s) ${duplicateAllergens.toString()} in product ${productId}`;
            Sentry.logError(new Error(msg));
        }
        return RSet.fromIterable(allergens, { ignoreDuplicates: true });
    }
}
