var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { makeAutoObservable } from "mobx";
import { perRestaurant } from "../di";
import { Tracking } from "../tracking";
import { PPurchaseOrder } from "./PPurchaseOrder";
import { OrderItemListTracking } from "./OrderItemListTracking";
let CheckoutTracking = class CheckoutTracking {
    constructor(pPurchaseOrder, orderItemListTracking) {
        this.pPurchaseOrder = pPurchaseOrder;
        this.orderItemListTracking = orderItemListTracking;
        makeAutoObservable(this);
    }
    sendBeginCheckoutEvent() {
        const items = this.pPurchaseOrder.orderLines.map((orderLine) => {
            var _a, _b;
            return ({
                ids: orderLine.productTypeIds.rawValues().map((id) => id.toString()),
                name: orderLine.name,
                price: (_a = orderLine.unitPrice) !== null && _a !== void 0 ? _a : 0,
                quantity: orderLine.quantity,
                category: (_b = orderLine.parts[0].categoryName) !== null && _b !== void 0 ? _b : "",
                list: this.orderItemListTracking.getData(orderLine.trackingId),
            });
        });
        Tracking.trackEvent({ tag: "BeginCheckout", contents: { items } });
    }
    get checkoutData() {
        return {
            items: this.pPurchaseOrder.orderLines.map((pOrderLine) => ({
                trackingId: pOrderLine.trackingId.value,
                list: this.orderItemListTracking.getData(pOrderLine.trackingId),
            })),
        };
    }
};
CheckoutTracking = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PPurchaseOrder !== "undefined" && PPurchaseOrder) === "function" ? _a : Object, typeof (_b = typeof OrderItemListTracking !== "undefined" && OrderItemListTracking) === "function" ? _b : Object])
], CheckoutTracking);
export { CheckoutTracking };
