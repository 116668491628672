var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject } from "tsyringe";
import { DateRange, DysjointDateRanges, makeImmutable, Now, RDate, } from "../core";
import { perRestaurant } from "../di";
import { IOrderingHours } from "./OrderingHours";
import { PreorderSettingsInstance } from "./PreorderSettingsInstance";
import { memo } from "../utils";
let PreorderSettingsInstanceFactory = class PreorderSettingsInstanceFactory {
    constructor(now, orderingHours) {
        this.now = now;
        this.orderingHours = orderingHours;
        const endOfDay = this.orderingHours.endOfDay(this.now);
        this.todayRange =
            endOfDay === null
                ? null
                : DateRange.fromDates({
                    begin: this.now,
                    end: endOfDay,
                });
        this.sameDayPreorderInstance = this.createSameDay();
        makeImmutable(this);
    }
    create(preorderSettingsType) {
        switch (preorderSettingsType.type) {
            case "SameDay":
                return this.sameDayPreorderInstance;
            case "AbsoluteDate":
                return memo(["PreorderSettings", preorderSettingsType.toString()], () => this.createAbsoluteDate(preorderSettingsType));
            case "RelativeDate":
                return memo(["PreorderSettings", preorderSettingsType.toString()], () => this.createRelativeDate(preorderSettingsType));
        }
    }
    createSameDay() {
        const dateRanges = this.todayRange === null
            ? DysjointDateRanges.Empty
            : DysjointDateRanges.singleton(this.todayRange);
        return new PreorderSettingsInstance({
            dateRanges,
            isSameDayOnly: true,
            isInThePast: false,
            hasTodayDate: true,
            includesNow: true,
        });
    }
    firstDate(buffer) {
        var _a, _b;
        if (buffer.minDays === 0) {
            return this.now;
        }
        return ((_b = (_a = this.orderingHours.addWorkdays(this.now, buffer.minDays + 1)) === null || _a === void 0 ? void 0 : _a.begin) !== null && _b !== void 0 ? _b : null);
    }
    hasTodayDate(dateRanges) {
        if (this.todayRange === null) {
            return false;
        }
        return !dateRanges.intersection(DysjointDateRanges.singleton(this.todayRange)).isEmpty;
    }
    createAbsoluteDate(preorderSettingsType) {
        const start = this.firstDate(preorderSettingsType.buffer);
        const trimmed = start === null ? null : preorderSettingsType.range.trimBegin(start);
        const dateRanges = trimmed === null
            ? DysjointDateRanges.Empty
            : DysjointDateRanges.singleton(trimmed);
        return new PreorderSettingsInstance({
            dateRanges,
            isSameDayOnly: false,
            isInThePast: preorderSettingsType.range.end.isBefore(this.now),
            hasTodayDate: this.hasTodayDate(dateRanges),
            includesNow: trimmed !== null && trimmed.includes(this.now),
        });
    }
    createRelativeDate(preorderSettingsType) {
        const begin = this.firstDate(preorderSettingsType.buffer);
        const end = this.now.add(preorderSettingsType.maxDays, "days").endOfDay();
        const range = begin === null || begin.isAfter(end)
            ? null
            : DateRange.fromDates({
                begin,
                end,
            });
        const dateRanges = range === null
            ? DysjointDateRanges.Empty
            : DysjointDateRanges.singleton(range);
        return new PreorderSettingsInstance({
            dateRanges,
            isSameDayOnly: false,
            isInThePast: end.isBefore(this.now),
            hasTodayDate: this.hasTodayDate(dateRanges),
            includesNow: range !== null && range.includes(this.now),
        });
    }
};
PreorderSettingsInstanceFactory = __decorate([
    perRestaurant(),
    __param(0, inject(Now)),
    __param(1, inject(IOrderingHours)),
    __metadata("design:paramtypes", [typeof (_a = typeof RDate !== "undefined" && RDate) === "function" ? _a : Object, typeof (_b = typeof IOrderingHours !== "undefined" && IOrderingHours) === "function" ? _b : Object])
], PreorderSettingsInstanceFactory);
export { PreorderSettingsInstanceFactory };
