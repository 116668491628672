import { makeAutoObservable } from "mobx";
import { IdentifiableMap } from "../../collections";
import { ParameterScope } from "../ParameterScope";
import { ProductConfig } from "../ProductInstance";
import { ProductConfigCreatorScope } from "./ProductConfigCreatorScope";
/*
 * Utility class for building correct `ProductConfig` - it deals with observing modifierTypes and customParameterTypes and creates according instances
 */
export class ProductConfigCreator {
    constructor(params) {
        this._scope = ParameterScope.Uninitialized;
        this.modifierInstanceCreators = params.modifierInstanceCreators;
        this.customParameterInstanceCreators =
            params.customParameterInstanceCreators;
        makeAutoObservable(this);
    }
    clone() {
        return new ProductConfigCreator({
            modifierInstanceCreators: IdentifiableMap.fromIterable("typeId", this.modifierInstanceCreators.objects.map((modifierInstance) => modifierInstance.clone())),
            customParameterInstanceCreators: IdentifiableMap.fromIterable("typeId", this.customParameterInstanceCreators.objects.map((customParameterInstance) => customParameterInstance.clone())),
        });
    }
    get scope() {
        return this._scope;
    }
    setScope(scope) {
        this._scope = scope;
        this.modifierInstanceCreators.objects.forEach((modifierInstance) => modifierInstance.setScope(this._scope));
    }
    initWithScope(parentScope) {
        const scope = new ProductConfigCreatorScope({
            parentScope,
            customParameterInstanceCreators: this.customParameterInstanceCreators,
        });
        this.setScope(scope);
    }
    selectInferredDefaults() {
        this.modifierInstanceCreators.objects.forEach((modifierInstance) => modifierInstance.selectInferredDefaults());
    }
    get productConfig() {
        return new ProductConfig({
            modifierInstances: new IdentifiableMap(this.modifierInstanceCreators.raw.map((modifierInstanceCreator) => modifierInstanceCreator.modifierInstance)),
            customParameterInstances: new IdentifiableMap(this.customParameterInstanceCreators.raw.map((customParameterInstanceCreator) => customParameterInstanceCreator.customParameterInstance)),
        });
    }
}
