var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject } from "tsyringe";
import { RArray } from "../collections";
import { makeImmutable, RestaurantId } from "../core";
import { perRestaurant } from "../di";
import { RestaumaticRPCService } from "../services";
import { OrderCouponCode } from "./Identifiers";
import { OrderCoupon } from "./OrderCoupon";
export const IPPromotionParser = "IPPromotionParser";
let PromotionService = class PromotionService {
    constructor(rpcService, promotionParser, restaurantId) {
        this.rpcService = rpcService;
        this.promotionParser = promotionParser;
        this.restaurantId = restaurantId;
        makeImmutable(this);
    }
    async getCoupon(couponCode) {
        const res = await this.rpcService.rpc("GetActivePromotionsRequest", {
            couponCodes: [couponCode],
            restaurantId: this.restaurantId.value,
        });
        if (res === null || "Left" in res) {
            return null;
        }
        const promotions = this.promotionParser.parsePromotions(res.Right.promotions);
        const coupons = new RArray(res.Right.coupons).mapOptional((couponPromotion) => {
            // we have to "find", not "get" the promotion - promotions are filtered by the app origin
            // so if coupon promotion is missing it means that promotion has different origin
            const promotion = promotions.rawFind(couponPromotion.promotionId);
            if (!promotion) {
                return null;
            }
            const code = new OrderCouponCode(couponPromotion.code);
            const coupon = new OrderCoupon({
                code,
                source: couponPromotion.source,
                promotion: promotion.domain,
            });
            return { coupon, promotion };
        });
        if (coupons.size > 1) {
            throw new Error("Invalid coupon endpoint response");
        }
        return coupons.find(0);
    }
};
PromotionService = __decorate([
    perRestaurant(),
    __param(1, inject(IPPromotionParser)),
    __metadata("design:paramtypes", [typeof (_a = typeof RestaumaticRPCService !== "undefined" && RestaumaticRPCService) === "function" ? _a : Object, Object, typeof (_b = typeof RestaurantId !== "undefined" && RestaurantId) === "function" ? _b : Object])
], PromotionService);
export { PromotionService };
