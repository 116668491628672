export class StringStorageSerializer {
    serializeForStorage(data) {
        return data;
    }
    deserializeFromStorage(data) {
        try {
            this.assertValid(data);
            return data;
        }
        catch (error) {
            return null;
        }
    }
    assertValid(data) {
        if (typeof data !== "string" || data === "null") {
            throw new Error("Invalid string value");
        }
    }
}
