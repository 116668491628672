var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { inject, singleton } from "tsyringe";
import { GlobalConfig } from "../config";
import { makeImmutable } from "../core";
import { IQrWaiter } from "./IQrWaiter";
import { RestaumaticRPCService } from "./RestaumaticRPCService";
let OrderingAvailabilityService = class OrderingAvailabilityService {
    constructor(rpcService, qrWaiter, globalConfig) {
        this.rpcService = rpcService;
        this.qrWaiter = qrWaiter;
        this.isIosReviewMode = globalConfig.isIosReviewMode;
        makeImmutable(this);
    }
    async getOrderingAvailability(restaurantId) {
        const response = await this.rpcService.rpc("GetOrderingAvailabilityForClientRequest", {
            restaurantId: restaurantId.value,
            isQrWaiterOrder: this.qrWaiter.isQrWaiterOrder(),
            isIosReviewMode: this.isIosReviewMode,
        });
        if (response === null) {
            throw new Error("Unable to load ordering availability");
        }
        return response;
    }
};
OrderingAvailabilityService = __decorate([
    singleton(),
    __param(1, inject(IQrWaiter)),
    __param(2, inject(GlobalConfig)),
    __metadata("design:paramtypes", [typeof (_a = typeof RestaumaticRPCService !== "undefined" && RestaumaticRPCService) === "function" ? _a : Object, typeof (_b = typeof IQrWaiter !== "undefined" && IQrWaiter) === "function" ? _b : Object, typeof (_c = typeof GlobalConfig !== "undefined" && GlobalConfig) === "function" ? _c : Object])
], OrderingAvailabilityService);
export { OrderingAvailabilityService };
