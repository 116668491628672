import { makeAutoObservable } from "mobx";
import { RSet } from "../../collections";
import { ParameterScope } from "../ParameterScope";
import { ProductInstanceHalves, ProductInstancePartScope, } from "../ProductInstance";
export class HalvesCreatorState {
    constructor(params) {
        this.isSplit = true;
        this.scope = ParameterScope.Uninitialized;
        this.productCategory = params.productCategory;
        this._commonConfigCreator = params.commonConfigCreator;
        this._firstHalf = params.firstHalf;
        this._secondHalf = params.secondHalf;
        makeAutoObservable(this);
    }
    get commonConfigCreator() {
        return this._commonConfigCreator;
    }
    get firstHalf() {
        return this._firstHalf;
    }
    get secondHalf() {
        return this._secondHalf;
    }
    initWithScope(parentScope) {
        const isSplitScope = new ProductInstancePartScope({
            parentScope,
            isSplit: this.isSplit,
        });
        this.commonConfigCreator.initWithScope(isSplitScope);
        this.scope = this.commonConfigCreator.scope;
        this.firstHalf.initWithScope(this.scope);
        this.secondHalf.initWithScope(this.scope);
    }
    get productTypeIds() {
        return RSet.fromIterable([this.firstHalf.productTypeId, this.secondHalf.productTypeId], {
            ignoreDuplicates: true,
        });
    }
    get commonProductConfig() {
        return this.commonConfigCreator.productConfig;
    }
    get productInstanceParts() {
        return new ProductInstanceHalves({
            scope: this.scope,
            productCategory: this.productCategory,
            first: this.firstHalf.productInstancePart,
            second: this.secondHalf.productInstancePart,
        });
    }
    selectInferredDefaults() {
        this.commonConfigCreator.selectInferredDefaults();
        this.firstHalf.productConfigCreator.selectInferredDefaults();
        this.secondHalf.productConfigCreator.selectInferredDefaults();
    }
}
