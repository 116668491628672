var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { GlobalConfig } from "../../config";
import { perRestaurant } from "../../di";
import { Availability, OrderingHoursAvailability, } from "../Availability";
import { FulfillmentInstructions, FulfillmentInstructionsToken, } from "../FulfillmentInstructions";
import { IOrderingAvailability } from "../OrderingAvailability";
import { OrderReceiver } from "../OrderReceiver";
import { PurchaseOrderPriceService } from "../PriceService";
import { PurchaseOrder } from "../PurchaseOrder";
import { PurchaseOrderPayload } from "../PurchaseOrderPayload";
import { Restaurant } from "../Restaurant";
import { DeliveryAvailabilityService } from "./DeliveryAvailabilityService";
import { FulfillmentInstructionsAvailabilityService } from "./FulfillmentInstructionsAvailabilityService";
import { PaymentAvailabilityService } from "./PaymentAvailabilityService";
import { PurchaseOrderPayloadAvailabilityService } from "./PurchaseOrderPayloadAvailabilityService";
let PurchaseOrderAvailabilityService = class PurchaseOrderAvailabilityService {
    constructor(globalConfig, payload, purchaseOrder, purchaseOrderPriceService, fulfillmentInstructions, restaurant, // TODO Decouple OrderingPolicy
    payloadAvailabilityService, fulfillmentInstructionsAvailabilityService, deliveryAvailabilityService, 
    // FIXME Temporary dependencies - their respective availabilities should be refactored into separate services
    orderReceiver, orderingHoursAvailability, orderingAvailability, paymentAvailabilityService) {
        this.payload = payload;
        this.purchaseOrder = purchaseOrder;
        this.purchaseOrderPriceService = purchaseOrderPriceService;
        this.fulfillmentInstructions = fulfillmentInstructions;
        this.restaurant = restaurant;
        this.payloadAvailabilityService = payloadAvailabilityService;
        this.fulfillmentInstructionsAvailabilityService = fulfillmentInstructionsAvailabilityService;
        this.deliveryAvailabilityService = deliveryAvailabilityService;
        this.orderReceiver = orderReceiver;
        this.orderingHoursAvailability = orderingHoursAvailability;
        this.orderingAvailability = orderingAvailability;
        this.paymentAvailabilityService = paymentAvailabilityService;
        this.isIosReviewMode = globalConfig.isIosReviewMode;
        makeAutoObservable(this);
    }
    get orderValueAvailability() {
        if (this.payload.isEmpty) {
            return Availability.available();
        }
        const { validationPrice } = this.purchaseOrderPriceService;
        if (validationPrice === null) {
            return Availability.unavailable("MissingParameterValues");
        }
        if (!this.restaurant.orderingPolicy.validate(validationPrice)) {
            return Availability.unavailable("MaximumOrderValueExceeded");
        }
        if (validationPrice.value.lt(this.purchaseOrder.minOrderValue)) {
            return Availability.unavailable("InsufficientOrderValue");
        }
        return Availability.available();
    }
    get deliveryAvailability() {
        if (!this.fulfillmentInstructions.isDelivery) {
            return Availability.available();
        }
        const { validationPrice } = this.purchaseOrderPriceService;
        if (validationPrice === null) {
            return Availability.unavailable("MissingParameterValues");
        }
        return this.deliveryAvailabilityService.availability(validationPrice);
    }
    get availability() {
        return Availability.composite([
            this.cartAvailability,
            this.deliveryAvailability,
            this.orderReceiver.availability,
            this.fulfillmentInstructionsAvailabilityService.availability,
            this.paymentAvailabilityService.availability,
            this.orderingHoursAvailability,
        ]);
    }
    get canPlaceOrder() {
        return (this.availability.isAvailable.isDefinitelyTrue || this.isIosReviewMode);
    }
    get cartAvailability() {
        return Availability.composite([
            this.orderingAvailability.availability,
            Availability.dependent("PurchaseOrderPayload", "", this.payloadAvailabilityService.availability),
            this.orderValueAvailability,
            this.orderingHoursAvailability,
        ]);
    }
    get isReadyForCheckout() {
        return this.cartAvailability.isAvailable.isPossible || this.isIosReviewMode;
    }
    get checkoutAvailability() {
        return Availability.composite([
            this.orderReceiver.availability,
            this.fulfillmentInstructionsAvailabilityService.availability,
            this.paymentAvailabilityService.availability,
        ]);
    }
    get isCheckoutFormValid() {
        return this.checkoutAvailability.isAvailable.isDefinitelyTrue;
    }
};
PurchaseOrderAvailabilityService = __decorate([
    perRestaurant(),
    __param(0, inject(GlobalConfig)),
    __param(4, inject(FulfillmentInstructionsToken)),
    __param(11, inject(IOrderingAvailability)),
    __metadata("design:paramtypes", [typeof (_a = typeof GlobalConfig !== "undefined" && GlobalConfig) === "function" ? _a : Object, typeof (_b = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _b : Object, typeof (_c = typeof PurchaseOrder !== "undefined" && PurchaseOrder) === "function" ? _c : Object, typeof (_d = typeof PurchaseOrderPriceService !== "undefined" && PurchaseOrderPriceService) === "function" ? _d : Object, typeof (_e = typeof FulfillmentInstructions !== "undefined" && FulfillmentInstructions) === "function" ? _e : Object, typeof (_f = typeof Restaurant !== "undefined" && Restaurant) === "function" ? _f : Object, typeof (_g = typeof PurchaseOrderPayloadAvailabilityService !== "undefined" && PurchaseOrderPayloadAvailabilityService) === "function" ? _g : Object, typeof (_h = typeof FulfillmentInstructionsAvailabilityService !== "undefined" && FulfillmentInstructionsAvailabilityService) === "function" ? _h : Object, typeof (_j = typeof DeliveryAvailabilityService !== "undefined" && DeliveryAvailabilityService) === "function" ? _j : Object, typeof (_k = typeof OrderReceiver !== "undefined" && OrderReceiver) === "function" ? _k : Object, typeof (_l = typeof OrderingHoursAvailability !== "undefined" && OrderingHoursAvailability) === "function" ? _l : Object, typeof (_m = typeof IOrderingAvailability !== "undefined" && IOrderingAvailability) === "function" ? _m : Object, typeof (_o = typeof PaymentAvailabilityService !== "undefined" && PaymentAvailabilityService) === "function" ? _o : Object])
], PurchaseOrderAvailabilityService);
export { PurchaseOrderAvailabilityService };
