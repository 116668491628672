var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { makeAutoObservable } from "mobx";
import { toPairs } from "../../collections";
import { perRestaurant } from "../../di";
import { ErrorBox, TextFieldController } from "../../form";
import { t, translate } from "../../I18n";
import { UserNote, UserNoteAvailabilityService } from "../../ordering";
let UserNoteController = class UserNoteController {
    constructor(userNote, userNoteAvailabilityService) {
        var _a, _b;
        this.userNoteAvailabilityService = userNoteAvailabilityService;
        this.userNoteMaxLength = UserNote.MAX_LENGTH;
        this.domain = userNote;
        this.userNote = new TextFieldController({
            initialValue: (_b = (_a = this.domain) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : "",
            onUpdate: (value) => {
                this.domain.value = value;
            },
            errorBox: new ErrorBox({
                domainObjects: [userNoteAvailabilityService],
                errorMessages: {
                    MaxLengthExceeded: translate(t.orders.checkout.max_length, {
                        l: UserNote.MAX_LENGTH.toString(),
                    }),
                },
            }),
        });
        makeAutoObservable(this);
    }
    get fields() {
        return {
            userNote: this.userNote,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const fieldsInvalid = this.fieldsArray.some((field) => field.isInvalid);
        const modelAvailability = this.userNoteAvailabilityService.availability;
        const modelInvalid = modelAvailability.isAvailable.isDefinitelyFalse;
        return fieldsInvalid || modelInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
};
UserNoteController = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof UserNote !== "undefined" && UserNote) === "function" ? _a : Object, typeof (_b = typeof UserNoteAvailabilityService !== "undefined" && UserNoteAvailabilityService) === "function" ? _b : Object])
], UserNoteController);
export { UserNoteController };
