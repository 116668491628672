export const DEFAULT_APARTMENT_INFO = {
    sector: "",
    houseOrBlock: "House",
    block: "",
    staircase: "",
    floor: "",
    aptNumber: "",
    interphone: "",
    additionalInfo: "",
};
