export function getTimeRemaining(now, future) {
    const diff = Math.max(0, future.differenceInSeconds(now));
    const seconds = Math.max(0, Math.floor(diff) % 60);
    const minutes = Math.max(0, Math.floor(diff / 60) % 60);
    const hours = Math.max(0, Math.floor(diff / 3600) % 24);
    return {
        seconds: seconds.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        hours: hours.toString().padStart(2, "0"),
    };
}
