var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject } from "tsyringe";
import { DateRange, makeImmutable, RDate, Timezone } from "../core";
import { perRestaurant } from "../di";
import { AbsoluteDatePreorderSettingsType, PreorderSettingsBuffer, RelativeDatePreorderSettingsType, SectionVisibilityService, } from "../ordering";
export const PreorderSettingsConfig = "PreorderSettingsConfig";
let PreorderSettingsTypeParser = class PreorderSettingsTypeParser {
    constructor(preorderSettingsConfig, timezone, sectionVisibilityService) {
        this.timezone = timezone;
        this.sectionVisibilityService = sectionVisibilityService;
        this.isPreorderEnabled = preorderSettingsConfig.enable_preorder;
        this.defaultPreorderSettingsType = this.parseSettings(preorderSettingsConfig.defaultPreorderSettings);
        makeImmutable(this);
    }
    parseBuffer(minDays) {
        if (minDays > 0) {
            return new PreorderSettingsBuffer(minDays);
        }
        return PreorderSettingsBuffer.Null;
    }
    parseSettings(preorderSettings) {
        switch (preorderSettings.tag) {
            case "SameDay":
                return { type: "SameDay" };
            case "RelativeDate": {
                return new RelativeDatePreorderSettingsType({
                    maxDays: preorderSettings.maxDays,
                    buffer: this.parseBuffer(preorderSettings.minDays),
                });
            }
            case "AbsoluteDate": {
                const begin = RDate.fromLocalTimeString(preorderSettings.from, this.timezone);
                const end = RDate.fromLocalTimeString(preorderSettings.to, this.timezone).endOfDay();
                return new AbsoluteDatePreorderSettingsType({
                    buffer: this.parseBuffer(preorderSettings.minDays),
                    range: DateRange.fromDates({ begin, end }),
                });
            }
        }
    }
    parse(params) {
        if (!this.sectionVisibilityService.isSectionVisible(params.visibilityScheduleId)) {
            return null;
        }
        if (!this.isPreorderEnabled ||
            params.preorderSettings === null) {
            return this.defaultPreorderSettingsType;
        }
        return this.parseSettings(params.preorderSettings);
    }
};
PreorderSettingsTypeParser = __decorate([
    perRestaurant(),
    __param(0, inject(PreorderSettingsConfig)),
    __param(1, inject(Timezone)),
    __metadata("design:paramtypes", [Object, typeof (_a = typeof Timezone !== "undefined" && Timezone) === "function" ? _a : Object, typeof (_b = typeof SectionVisibilityService !== "undefined" && SectionVisibilityService) === "function" ? _b : Object])
], PreorderSettingsTypeParser);
export { PreorderSettingsTypeParser };
