var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { flow, makeAutoObservable } from "mobx";
import { singleton } from "tsyringe";
import { result } from "../core";
import { t, translate } from "../I18n";
import { GeocodingService } from "../ordering";
import { WebGeolocationService } from "../services";
let PGeolocation = class PGeolocation {
    constructor(geocodingService, geolocationService) {
        this.geocodingService = geocodingService;
        this.geolocationService = geolocationService;
        this._status = { type: "NotRequested" };
        makeAutoObservable(this, { locateMe: flow });
    }
    get status() {
        return this._status;
    }
    get isLoading() {
        return this._status.type === "Loading";
    }
    get error() {
        return this._status.type === "Error" ? this._status.error : null;
    }
    async locateStreetAddress(restaurantId) {
        const coordinates = await this.geolocationService.getCurrentPosition();
        return await this.geocodingService.reverseGeocode(restaurantId, {
            lat: coordinates.latitude,
            lon: coordinates.longitude,
        });
    }
    *locateMe(restaurantId) {
        this._status = { type: "Loading" };
        try {
            const streetAddress = yield* result(this.locateStreetAddress(restaurantId));
            this._status = { type: "Success" };
            return streetAddress;
        }
        catch (e) {
            if (e instanceof GeolocationPositionError) {
                const error = e.code === GeolocationPositionError.PERMISSION_DENIED
                    ? translate(t.geolocator.no_permission)
                    : translate(t.geolocator.failed_to_locate);
                this._status = { type: "Error", error };
            }
            else {
                this._status = {
                    type: "Error",
                    error: translate(t.delivery.geocoding_error),
                };
            }
            console.error(e);
        }
        return null;
    }
};
PGeolocation = __decorate([
    singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof GeocodingService !== "undefined" && GeocodingService) === "function" ? _a : Object, typeof (_b = typeof WebGeolocationService !== "undefined" && WebGeolocationService) === "function" ? _b : Object])
], PGeolocation);
export { PGeolocation };
