import { makeAutoObservable } from "mobx";
import { RSet } from "../../collections";
import { Tristate } from "../../core";
/*
 * Availability strategy based on Schedule and active schedules from scope
 */
export class ScheduleAvailability {
    constructor(params) {
        this.availabilitySchedule = params.availabilitySchedule;
        this.fulfillmentTimeScope = params.fulfillmentTimeScope;
        this.scheduleAvailabilityService = params.scheduleAvailabilityService;
        makeAutoObservable(this);
    }
    get isAvailable() {
        const { fulfillmentTime } = this.fulfillmentTimeScope;
        return fulfillmentTime === null
            ? Tristate.Maybe
            : Tristate.fromBoolean(this.scheduleAvailabilityService.isAvailable(this.availabilitySchedule, fulfillmentTime));
    }
    get unavailabilityReasons() {
        return this.isAvailable.isDefinitelyTrue
            ? RSet.empty()
            : RSet.singleton("Schedule");
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitSchedule(this);
    }
    get fulfillmentTimeString() {
        const { fulfillmentTime } = this.fulfillmentTimeScope;
        if (fulfillmentTime === null) {
            return "??:??";
        }
        switch (fulfillmentTime.type) {
            case "ASAP":
                return "ASAP";
            case "OnTime":
                return `${fulfillmentTime.date.weekday} ${fulfillmentTime.date.formatHHmm()}`;
        }
    }
    toString() {
        return `${this.fulfillmentTimeString} -> ${this.availabilitySchedule.toString()}`;
    }
}
