import { makeAutoObservable } from "mobx";
/**
 * How and where should the order be fulfilled for Online order.
 */
export class OnlineFulfillmentInstructions {
    constructor(params) {
        this.type = "Online";
        this.deliveryAddress = params.deliveryAddress;
        this.fulfillmentMethodType = params.fulfillmentMethodType;
        this.fulfillmentTime = params.fulfillmentTime;
        makeAutoObservable(this);
    }
    get isDelivery() {
        return (this.fulfillmentMethodType === "Delivery" ||
            this.fulfillmentMethodType === "NoContactDelivery");
    }
    get fulfillmentMethod() {
        if (this.fulfillmentMethodType === "NoContactDelivery") {
            return "Delivery";
        }
        return this.fulfillmentMethodType;
    }
}
