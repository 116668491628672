var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { perRestaurant } from "../../di";
import { Availability } from "../Availability";
import { FulfillmentInstructions, FulfillmentInstructionsToken, } from "../FulfillmentInstructions";
import { DeliveryAddressAvailabilityService } from "./DeliveryAddressAvailabilityService";
import { UserNoteAvailabilityService } from "./UserNoteAvailabilityService";
let FulfillmentInstructionsAvailabilityService = class FulfillmentInstructionsAvailabilityService {
    constructor(fulfillmentInstructions, deliveryAddressAvailabilityService, userNoteAvailabilityService) {
        this.fulfillmentInstructions = fulfillmentInstructions;
        this.deliveryAddressAvailabilityService = deliveryAddressAvailabilityService;
        this.userNoteAvailabilityService = userNoteAvailabilityService;
        makeAutoObservable(this);
    }
    get fulfillmentTimeAvailability() {
        const { fulfillmentTime } = this.fulfillmentInstructions;
        return fulfillmentTime === null
            ? Availability.unavailable("FulfillmentTimeMissing")
            : Availability.available();
    }
    get fulfillmentMethodTypeAvailability() {
        return Availability.boolean({
            FulfillmentMethodTypeMissing: this.fulfillmentInstructions.fulfillmentMethodType === null,
        });
    }
    get deliveryAvailability() {
        switch (this.fulfillmentInstructions.type) {
            case "Online": {
                const { isDelivery } = this.fulfillmentInstructions;
                return isDelivery
                    ? this.deliveryAddressAvailabilityService.availability
                    : Availability.available();
            }
            case "QrWaiter":
                return Availability.available();
        }
    }
    get availability() {
        switch (this.fulfillmentInstructions.type) {
            case "Online": {
                return Availability.composite([
                    this.fulfillmentTimeAvailability,
                    this.fulfillmentMethodTypeAvailability,
                    this.deliveryAvailability,
                    this.userNoteAvailabilityService.availability,
                ]);
            }
            case "QrWaiter":
                return Availability.composite([
                    this.userNoteAvailabilityService.availability,
                ]);
        }
    }
};
FulfillmentInstructionsAvailabilityService = __decorate([
    perRestaurant(),
    __param(0, inject(FulfillmentInstructionsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof FulfillmentInstructions !== "undefined" && FulfillmentInstructions) === "function" ? _a : Object, typeof (_b = typeof DeliveryAddressAvailabilityService !== "undefined" && DeliveryAddressAvailabilityService) === "function" ? _b : Object, typeof (_c = typeof UserNoteAvailabilityService !== "undefined" && UserNoteAvailabilityService) === "function" ? _c : Object])
], FulfillmentInstructionsAvailabilityService);
export { FulfillmentInstructionsAvailabilityService };
