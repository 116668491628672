import { makeAutoObservable } from "mobx";
import { Quantity } from "../core";
import { t, translate } from "../I18n";
import { PPrice } from "./PPrice";
import { PProductError } from "./PProductError";
export class PProductCardCreator {
    constructor(params) {
        this.isIosReviewMode = params.isIosReviewMode;
        this.domain = params.domain;
        this.productCardCreatorAvailabilityService =
            params.productCardCreatorAvailabilityService;
        this.halvingPolicyAvailabilityService =
            params.halvingPolicyAvailabilityService;
        this.productInstancePreorderSettingsService =
            params.productInstancePreorderSettingsService;
        this.labelsCreatorFactory = params.labelsCreatorFactory;
        this.productCardCreatorPriceService = params.productCardCreatorPriceService;
        this.productTypes = params.productTypes;
        this.restaurant = params.restaurant;
        this.localizationProvider = params.localizationProvider;
        this.productInstanceCreator = params.productInstanceCreator;
        this.crossSellingCreator = params.crossSellingCreator;
        this.fulfillmentTime = params.fulfillmentTime;
        this.orderItemListTracking = params.orderItemListTracking;
        this.nextOrderingTime = params.nextOrderingTime;
        makeAutoObservable(this);
    }
    get restaurantId() {
        return this.restaurant.id;
    }
    get availability() {
        return this.productCardCreatorAvailabilityService.availability(this.domain);
    }
    get state() {
        return this.productInstanceCreator.state;
    }
    get price() {
        return new PPrice(this.localizationProvider, this.productCardCreatorPriceService.price(this.domain).spectrum).priceValue;
    }
    get priceValue() {
        const price = this.productCardCreatorPriceService.price(this.domain).spectrum;
        switch (price.type) {
            case "value":
                return price.value.asNumber;
            case "range":
                return price.min.asNumber;
            case "null":
                return 0;
        }
    }
    get orderingHoursMessage() {
        if (this.domain.orderingHoursAvailability.isOutOfOrderingHours) {
            return PProductError.productCartOfOrderingHours(this.nextOrderingTime.nextOrderAvailableAt, this.domain.orderingHoursAvailability.canPreorder);
        }
        return null;
    }
    // TODO: this should be in the domain
    get hasAnyAvailableFulfillmentTime() {
        return this.fulfillmentTime.hasAnyAvailableTime;
    }
    // TODO: this should be in the domain
    get notAvailableFulfillmentTimeOrEmpty() {
        return (!this.fulfillmentTime.hasValue ||
            !this.fulfillmentTime.isAvailableAtSelectedTime);
    }
    get noFulfillmentTime() {
        return !this.fulfillmentTime.hasValue;
    }
    get shouldChangeFulfillmentTime() {
        return (this.hasAnyAvailableFulfillmentTime &&
            this.notAvailableFulfillmentTimeOrEmpty);
    }
    get error() {
        return this.productInstanceCreator.error;
    }
    get quantity() {
        return this.domain.quantity.value;
    }
    get canDecrement() {
        return this.domain.quantity.gt(Quantity.One);
    }
    get canIncrement() {
        return true;
    }
    increment() {
        if (this.canIncrement) {
            this.domain.increment();
        }
    }
    decrement() {
        if (this.canDecrement) {
            this.domain.decrement();
        }
    }
    get labelsCreator() {
        return this.labelsCreatorFactory.create({
            scope: this.productInstanceCreator.scope,
            productInstances: [this.productInstanceCreator.instance],
            view: "ProductCard",
        });
    }
    get product() {
        return this.productInstanceCreator.product;
    }
    get productTypeIdValues() {
        return this.domain.productTypeIds
            .rawValues()
            .map((productTypeId) => productTypeId.value);
    }
    get isSubmitVisible() {
        return (this.productInstanceCreator.state.isSubmitVisible &&
            (this.hasAnyAvailableFulfillmentTime || this.isIosReviewMode));
    }
    get isSubmitDisabled() {
        return !this.productCardCreatorAvailabilityService.hasResult(this.domain);
    }
    finish() {
        // Touch fields to display current errors even for fields that user not touched
        if (this.availability.isAvailable.isDefinitelyFalse) {
            this.productInstanceCreator.touch();
        }
        this.orderItemListTracking.collectCreatorData(this.domain);
        return this.productCardCreatorAvailabilityService.result(this.domain);
    }
    get isSplitPossible() {
        const state = this.domain.productInstanceCreator.state;
        if (state.isSplit) {
            return false;
        }
        else {
            return this.halvingPolicyAvailabilityService.availability(state.productCategory.halvingPolicy, state.scope).isAvailable.isPossible;
        }
    }
    get preorderSettings() {
        return this.productInstancePreorderSettingsService.preorderSettings(this.productInstanceCreator.instance);
    }
    get weeklySchedule() {
        return this.productInstanceCreator.instance.productWeeklySchedule;
    }
    split() {
        this.domain.split();
        this.productInstanceCreator.updateState();
    }
    removeFirstHalf() {
        this.domain.removeFirstHalf();
        this.productInstanceCreator.updateState();
    }
    removeSecondHalf() {
        this.domain.removeSecondHalf();
        this.productInstanceCreator.updateState();
    }
    setFirstHalfProductType(productTypeString) {
        const productType = this.productTypes.rawGet(productTypeString);
        this.domain.setFirstHalfProductType(productType);
        this.productInstanceCreator.updateState();
    }
    setSecondHalfProductType(productTypeString) {
        const productType = this.productTypes.rawGet(productTypeString);
        this.domain.setSecondHalfProductType(productType);
        this.productInstanceCreator.updateState();
    }
    get temporarilyUnavailableIngredients() {
        return this.productInstanceCreator.state.temporarilyUnavailableIngredients;
    }
    get submitLabel() {
        if (this.shouldChangeFulfillmentTime && this.noFulfillmentTime) {
            return translate(t.restaurants.widgets.menu.plan_date);
        }
        if (this.shouldChangeFulfillmentTime && !this.noFulfillmentTime) {
            return translate(t.restaurants.widgets.menu.change_date);
        }
        return translate(t.theme_defaults.creator.add_to_cart);
    }
}
