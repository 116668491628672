import { makeImmutable } from "../../core";
export class CustomParameterBasedProductSelector {
    constructor(params) {
        this.kind = "CustomParameter";
        this.customParameterTypeId = params.customParameterTypeId;
        this.allowedChoices = params.allowedChoices;
        makeImmutable(this);
    }
    test(productInstance) {
        return this.allowedChoices.some((customParameterChoiceId) => productInstance.matchesCustomParameterValue(this.customParameterTypeId, customParameterChoiceId));
    }
}
