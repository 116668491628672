import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/collapse";
import StorageService from "./services/StorageService";
import Slider from "./utils/Slider";
import Gallery from "./utils/Gallery";
import { initTooltip } from "./utils/Tooltip";
import Popover from "./utils/Popover";
import Modals from "./utils/Modals";
import { initScroll } from "./utils/Scroll";
import ScrollSpy from "./utils/ScrollSpy";
import Form from "./utils/Form";
import SmartAppBanner from "./utils/SmartAppBanner";
import UniversalToggle from "./utils/UniversalToggle";
import Animate from "./utils/Animate";
import CurrentRestaurant from "./utils/CurrentRestaurant";
import Device from "./utils/Device";
import Tabs from "./utils/Tabs";
import { I18n } from "restaumatic-client";
import StickyNav from "./components/StickyNav";
import StickyHeader from "./components/StickyHeader";
import { applyMenuVisibleIn } from "./services/applyMenuVisibleIn";
import ShowMore from "./utils/ShowMore";
import Skeleton from "./utils/Skeleton";
import FieldTests from "./utils/FieldTests";

// Polyfill hasOwn (it's used by purescript optimizing backend)
"hasOwn" in Object || (Object.hasOwn = Object.call.bind(Object.hasOwnProperty));

const app = {
  /**
   * Execute immediately without waiting for DOM.
   *
   * WARNING: Code running here (or in general, code which is run before DomContentLoaded will not be able to use
   * polyfills)
   */
  executeImmediately: function () {
    StorageService.migrate();
    CurrentRestaurant.init();
    applyMenuVisibleIn();
    FieldTests.init();
  },

  /**
   * Form styles.
   */
  initFormStyles: function () {
    Form.initRadio();
    Form.initCheckbox();
    Form.initSelect();
  },

  /**
   * Init animations.
   */
  initAnimations: function () {
    Animate.init();
    Animate.initSplitting();
  },

  initLanguageSelect: function () {
    document.querySelectorAll("[data-locale]").forEach((el) => {
      el.addEventListener("click", () => {
        const newLocale = el.dataset.locale;
        let p = window.location.pathname;
        if (I18n.defaultLocale != I18n.currentLocale()) {
          p = p.replace(I18n.currentLocale(), newLocale);
        } else {
          p = "/" + newLocale + p;
        }
        window.location = p;
      });
    });
  },

  /**
   * Legacy feature - used only in active_menu & solej themes.
   */
  initPromotionsToggle: () => {
    document.querySelectorAll('[data-toggle="promotions"]').forEach((el) => {
      el.addEventListener("click", (e) => {
        document.querySelector(".menu-promotions").classList.toggle("is-shown");
        e.preventDefault();
      });
    });
  },
};

window.Skubacz.Device = Device;

app.executeImmediately();

document.addEventListener("DOMContentLoaded", () => {
  Skeleton.init();
  Tabs.init();
  Slider.init();
  Popover.init();
  initTooltip();
  StickyNav.init();
  initScroll();
  ScrollSpy.init();
  app.initFormStyles();
  UniversalToggle.init();
  SmartAppBanner.init();
  app.initAnimations();
  Modals.init();
  app.initLanguageSelect();
  StickyHeader.init();
  app.initPromotionsToggle();
  Gallery.init();
  ShowMore.init();
});
