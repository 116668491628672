var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { singleton } from "tsyringe";
import { IdMap, RMap, toPairs } from "../collections";
import { makeImmutable, Money } from "../core";
import { ProductType, ProductTypeId, } from "../ordering";
import { AvailabilitySpecParser } from "./AvailabilitySpecParser";
import { ModifierSettingsParser } from "./ModifierSettingsParser";
import { ParametrizationParser } from "./ParametrizationParser";
import { VolumeParser } from "./VolumeParser";
let ProductTypeParser = class ProductTypeParser {
    constructor(parametrizationParser, modifierSettingsParser, availabilitySpecParser) {
        this.parametrizationParser = parametrizationParser;
        this.modifierSettingsParser = modifierSettingsParser;
        this.availabilitySpecParser = availabilitySpecParser;
        makeImmutable(this);
    }
    parse(schedules, modifierTypes, customParameterTypes, productCategories, productTypeGroups, id, productType) {
        var _a, _b;
        if (productType.availability === "Hidden") {
            return null;
        }
        const productVolume = (_a = productType.packagingContainersConfigOverride) === null || _a === void 0 ? void 0 : _a.volume;
        return new ProductType({
            id: new ProductTypeId(id),
            productCategory: productCategories.rawGet(productType.category),
            volume: productVolume ? VolumeParser.parse(productVolume) : null,
            pricing: this.parametrizationParser
                .parse(customParameterTypes, productType.price)
                .map((value) => Money.fromBackend(value)),
            modifierTypes: IdMap.fromIterable(productType.productModifiers.map((modifierTypeId) => modifierTypes.rawGet(modifierTypeId)), {
                // NOTICE Duplicated product modifiers were probably created during the migration to the menu V2
                // Please use menu analysis to diagnose if this line could be safely removed
                // (see "duplicated productModifiers" Feature in /workspace/restaumatic/apps/analyse-menu tool)
                ignoreDuplicates: true,
            }),
            groups: (_b = productTypeGroups.rawFind(id)) !== null && _b !== void 0 ? _b : IdMap.empty(),
            schedule: productType.availabilitySchedule === null
                ? null
                : schedules.rawGet(productType.availabilitySchedule),
            modifierSettings: this.parseModifierSettings(modifierTypes, productType),
            availabilitySpec: this.parseAvailabilitySpec(schedules, customParameterTypes, productType),
        });
    }
    parseModifierSettings(modifierTypes, productType) {
        return RMap.fromIterable(toPairs(productType.modifierSettings).mapOptional(([modifierId, settings]) => {
            const modifierType = modifierTypes.rawFind(modifierId);
            if (modifierType === null) {
                return null;
            }
            return [
                modifierType.id,
                this.modifierSettingsParser.parse(modifierType.itemTypeIds, modifierType.editabilityParametrization, settings),
            ];
        }));
    }
    parseAvailabilitySpec(schedules, customParameterTypes, productType) {
        return this.availabilitySpecParser.parse(customParameterTypes, productType.availability, productType.variants, productType.availabilitySchedule === null
            ? null
            : schedules.rawGet(productType.availabilitySchedule));
    }
};
ProductTypeParser = __decorate([
    singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof ParametrizationParser !== "undefined" && ParametrizationParser) === "function" ? _a : Object, typeof (_b = typeof ModifierSettingsParser !== "undefined" && ModifierSettingsParser) === "function" ? _b : Object, typeof (_c = typeof AvailabilitySpecParser !== "undefined" && AvailabilitySpecParser) === "function" ? _c : Object])
], ProductTypeParser);
export { ProductTypeParser };
