var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IdMap } from "../../collections";
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { fromModifierItems } from "../Packing";
import { PositiveQuantity } from "../PositiveQuantity";
let ProductInstancePackingService = class ProductInstancePackingService {
    constructor() {
        makeImmutable(this);
    }
    packingItems(productInstance) {
        return this.productConfigPackingItems(productInstance.commonConfig).unionWith(this.productInstancePartsPackingItems(productInstance.parts), (a, b) => a.merge(b));
    }
    productConfigPackingItems(productConfig) {
        return productConfig.modifierInstances.objects
            .map((modifierInstance) => this.modifierInstancePackingItems(modifierInstance))
            .reduce((prev, curr) => prev.unionWith(curr, (a, b) => a.merge(b)), IdMap.empty());
    }
    productInstancePartsPackingItems(parts) {
        switch (parts.type) {
            case "SinglePart":
                return this.productConfigPackingItems(parts.part.productConfig);
            case "Halves": {
                const first = this.productConfigPackingItems(parts.first.productConfig);
                const second = this.productConfigPackingItems(parts.second.productConfig);
                return first.unionWith(second, (a, b) => a.merge(b));
            }
        }
    }
    modifierInstancePackingItems(modifierInstance) {
        const quantities = modifierInstance.selectedItems.map((itemInstance) => [
            itemInstance.typeId,
            new PositiveQuantity(itemInstance.quantity.value),
        ]);
        return fromModifierItems(modifierInstance.modifierType, quantities);
    }
};
ProductInstancePackingService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [])
], ProductInstancePackingService);
export { ProductInstancePackingService };
