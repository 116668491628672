import { makeImmutable } from "../../../core";
import { PositiveQuantity } from "../../PositiveQuantity";
/**
 * Products or modifiers and its amount to be packed in a container.
 *
 * For example "kebab x2".
 */
export class PackingItem {
    constructor(params) {
        this.subject = params.subject;
        this.quantity = params.quantity;
        makeImmutable(this);
    }
    withQuantityValue(quantityValue) {
        return quantityValue > 0
            ? new PackingItem({
                subject: this.subject,
                quantity: new PositiveQuantity(quantityValue),
            })
            : null;
    }
    get productVolume() {
        return this.subject.volume;
    }
    get totalVolume() {
        return this.subject.volume.multiply(this.quantity);
    }
    splitToFit(volume) {
        if (this.productVolume.value.valueOf() === 0) {
            return {
                quotient: this.withQuantityValue(this.quantity.value),
                remainder: null,
            };
        }
        else {
            const maxPossibleQuantity = volume
                .div(this.productVolume)
                .floor()
                .valueOf();
            const quotientQuantity = Math.min(this.quantity.value, maxPossibleQuantity);
            const remainderQuantity = this.quantity.value - quotientQuantity;
            return {
                quotient: this.withQuantityValue(quotientQuantity),
                remainder: this.withQuantityValue(remainderQuantity),
            };
        }
    }
    merge(other) {
        if (this.subject.eq(other.subject)) {
            return new PackingItem({
                subject: this.subject,
                quantity: this.quantity.add(other.quantity),
            });
        }
        throw new Error("Unable to merge completely different PackingItems");
    }
    /**
     * Multiply by a Quantity
     */
    multiply(quantity) {
        return new PackingItem({
            subject: this.subject,
            quantity: this.quantity.mul(quantity),
        });
    }
    get id() {
        return this.subject.id;
    }
}
