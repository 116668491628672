var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { RArray } from "../collections";
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { Menu, OrderLineFactory, PurchaseOrderPayload, } from "../ordering";
let PurchaseOrderPayloadFactory = class PurchaseOrderPayloadFactory {
    constructor(menu, orderLineFactory) {
        this.menu = menu;
        this.orderLineFactory = orderLineFactory;
        makeImmutable(this);
    }
    create(cartStorageData) {
        if (cartStorageData === null) {
            return new PurchaseOrderPayload({
                menu: this.menu,
                orderLines: [],
            });
        }
        const orderLines = new RArray(cartStorageData.purchaseOrderPayload).mapOptional((orderLineStorageData) => this.orderLineFactory.fromStorageData(orderLineStorageData));
        return new PurchaseOrderPayload({
            menu: this.menu,
            orderLines: orderLines.raw,
        });
    }
};
PurchaseOrderPayloadFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof Menu !== "undefined" && Menu) === "function" ? _a : Object, typeof (_b = typeof OrderLineFactory !== "undefined" && OrderLineFactory) === "function" ? _b : Object])
], PurchaseOrderPayloadFactory);
export { PurchaseOrderPayloadFactory };
