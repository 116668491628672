var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { RSet } from "../../collections";
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
let ProductInstanceVolumeService = class ProductInstanceVolumeService {
    constructor() {
        makeImmutable(this);
    }
    volume(productInstance) {
        switch (productInstance.parts.type) {
            case "SinglePart":
                return this.singlePartVolume(productInstance.parts);
            case "Halves":
                return this.productInstanceHalvesVolume(productInstance.parts);
        }
    }
    singlePartVolume(parts) {
        const volume = this.productInstancePartVolume(parts.part);
        return volume ? { productTypeIds: parts.productTypeIds, volume } : null;
    }
    productInstanceHalvesVolume(parts) {
        const fst = this.productInstancePartVolume(parts.first);
        const snd = this.productInstancePartVolume(parts.second);
        if (fst) {
            if (snd) {
                return { productTypeIds: parts.productTypeIds, volume: fst.max(snd) };
            }
            return {
                productTypeIds: RSet.singleton(parts.first.productTypeId),
                volume: fst,
            };
        }
        if (snd) {
            return {
                productTypeIds: RSet.singleton(parts.second.productTypeId),
                volume: snd,
            };
        }
        return null;
    }
    productInstancePartVolume(productInstancePart) {
        return productInstancePart.productType.volume;
    }
};
ProductInstanceVolumeService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [])
], ProductInstanceVolumeService);
export { ProductInstanceVolumeService };
