import { associationKey } from "../core";
import { getDuplicates } from "./utils";
/**
 * Error raised when trying to add element that is already present in a collection
 */
export class AddingDuplicateElementError extends Error {
    constructor(duplicatedElements) {
        const msg = "Unable to add element(s) as it is present already: " +
            duplicatedElements.map((x) => associationKey(x)).toString();
        super(msg);
    }
    static fromArray(arrayWithDuplicates) {
        return new this(getDuplicates(arrayWithDuplicates));
    }
    static fromSingleton(duplicate) {
        return new this([duplicate]);
    }
}
