import { makeAutoObservable } from "mobx";
import { Quantity } from "../core";
/**
 * Line of an order. Tells what product and how many of that product the customer wants to buy.
 * Analogous to a line on a receipt, such as "Orange Juice, x2, $5.00"
 */
export class OrderLine {
    constructor(params) {
        this.productInstanceCreator = params.productInstanceCreator;
        this._quantity = params.quantity;
        makeAutoObservable(this);
    }
    get productInstance() {
        return this.productInstanceCreator.instance;
    }
    get quantity() {
        return this._quantity;
    }
    get isEmpty() {
        return this.quantity.isZero;
    }
    get productTypeIds() {
        return this.productInstance.productTypeIds;
    }
    get weeklySchedule() {
        return this.productInstance.parts.productWeeklySchedule;
    }
    remove() {
        this._quantity = Quantity.Zero;
    }
    increaseQuantity() {
        this._quantity = this.quantity.incremented();
    }
    addQuantity(quantity) {
        this._quantity = this.quantity.add(quantity);
    }
    decreaseQuantity() {
        if (this.quantity.value > 0) {
            this._quantity = this.quantity.decremented();
        }
    }
    get storageData() {
        return {
            productInstance: this.productInstance.storageData,
            quantity: this.quantity.value,
        };
    }
}
