import { isQrWaiterOrder } from "../../../services/QrWaiter";
import { siteIds as menuStickyTabsSiteIds } from "./MenuStickyTabs";
export const addingProductToTheCartReactionExperiment = {
    name: "AddingProductToTheCartReaction",
    variants: [
        ["ShowCart", 4],
        ["ShowMenu", 1], // Test, ~20%
    ],
    controlVariant: "ShowCart",
    apply: (assignVariant, clearVariant) => {
        // FIXME: this is a hack to allow to simply test field-test
        const isTesting = localStorage.getItem("fieldTestExperimentsTest");
        const isProduction = Skubacz.configuration.environment === "production";
        const validSiteId = !menuStickyTabsSiteIds.includes(Skubacz.configuration.site_id);
        const validTheme = ["fiesta", "vesspa", "new_vision", "pasja"].includes(Skubacz.configuration.theme_name);
        const validSource = !isQrWaiterOrder() && !Skubacz.Device.isMobileApp();
        const validCountry = Skubacz.configuration.country === "PL";
        // e2e & testing purposes - allow to set variant manually via localStorage
        if (!isProduction || isTesting) {
            return;
        }
        if (validSiteId && validTheme && validSource && validCountry) {
            assignVariant();
        }
        else {
            clearVariant();
        }
    },
};
