import { makeImmutable } from "../../../core";
import { CustomParameterMultiSet, InternalParameterMultiSet, ParameterMultiSet, } from "../ParameterMultiSet";
import { CustomParameterSet } from "./CustomParameterSet";
import { InternalParameterSet } from "./InternalParameterSet";
/**
 * A single combination of different parameters.
 *
 * For example: "size: 30cm, dough: thick, fulfillmentMethod: delivery"
 */
export class ParameterSet {
    constructor(params) {
        var _a, _b;
        this.internal = (_a = params.internal) !== null && _a !== void 0 ? _a : InternalParameterSet.empty();
        this.custom = (_b = params.custom) !== null && _b !== void 0 ? _b : CustomParameterSet.empty();
        makeImmutable(this);
    }
    get customParameterTypes() {
        return this.custom.parameterTypes;
    }
    get isEmpty() {
        return this.custom.isEmpty && this.internal.isEmpty;
    }
    static empty() {
        return new ParameterSet({});
    }
    unify(other) {
        const internal = this.internal.unify(other.internal);
        const custom = this.custom.unify(other.custom);
        const matchingParameters = new ParameterMultiSet({
            internal: internal.matchingParameters,
            custom: custom.matchingParameters,
        });
        if (internal.type === "success" && custom.type === "success") {
            return {
                type: "success",
                matchingParameters,
                value: new ParameterSet({
                    internal: internal.value,
                    custom: custom.value,
                }),
            };
        }
        return {
            type: "failure",
            matchingParameters,
            incompatibleParameters: new ParameterMultiSet({
                internal: internal.type === "failure"
                    ? internal.incompatibleParameters
                    : InternalParameterMultiSet.empty(),
                custom: custom.type === "failure"
                    ? custom.incompatibleParameters
                    : CustomParameterMultiSet.empty(),
            }),
        };
    }
    /**
     * Unites two ParameterSets
     *
     * Fails if predicates from one set conflict with predicates from another set
     */
    union(other) {
        const internal = this.internal.union(other.internal);
        if (internal === null) {
            return null;
        }
        const custom = this.custom.union(other.custom);
        if (custom === null) {
            return null;
        }
        return new ParameterSet({ internal, custom });
    }
    toDebugJSON() {
        const result = {};
        Object.assign(result, this.internal);
        for (const [parameter, choiceId] of this.custom.choices) {
            result["custom:" + parameter.id.value] = choiceId;
        }
        return result;
    }
}
