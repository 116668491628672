var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { PurchaseOrderPayload } from "../PurchaseOrderPayload";
import { ProductInstanceCheckoutDataService } from "./ProductInstanceCheckoutDataService";
import { OrderLineAvailabilityService } from "../AvailabilityService";
let PurchaseOrderPayloadCheckoutDataService = class PurchaseOrderPayloadCheckoutDataService {
    constructor(payload, productInstanceCheckoutDataService, orderLineAvailabilityService) {
        this.payload = payload;
        this.productInstanceCheckoutDataService = productInstanceCheckoutDataService;
        this.orderLineAvailabilityService = orderLineAvailabilityService;
        makeImmutable(this);
    }
    orderLineCheckoutData(orderLine) {
        const { quantity, productInstance } = orderLine;
        return {
            productKey: {
                tag: "MenuProduct",
                contents: this.productInstanceCheckoutDataService.checkoutData(productInstance),
            },
            trackingId: productInstance.trackingId.value,
            quantity: quantity.value,
            note: null,
        };
    }
    get checkoutData() {
        return this.payload.orderLines.map((orderLine) => this.orderLineCheckoutData(orderLine)).raw;
    }
    get checkoutLoggingData() {
        return this.payload.orderLines.map((orderLine) => ({
            ...this.orderLineCheckoutData(orderLine),
            isAvailable: this.orderLineAvailabilityService.availability(orderLine).isAvailable
                .isDefinitelyTrue,
        })).raw;
    }
};
PurchaseOrderPayloadCheckoutDataService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _a : Object, typeof (_b = typeof ProductInstanceCheckoutDataService !== "undefined" && ProductInstanceCheckoutDataService) === "function" ? _b : Object, typeof (_c = typeof OrderLineAvailabilityService !== "undefined" && OrderLineAvailabilityService) === "function" ? _c : Object])
], PurchaseOrderPayloadCheckoutDataService);
export { PurchaseOrderPayloadCheckoutDataService };
