import { RSet } from "../../collections";
import { makeImmutable } from "../../core";
/*
 * Availability strategy dependence. Allows to provide another unavailability reason when dependency is unavailable
 */
export class DependentAvailability {
    constructor(params) {
        this.reason = params.reason;
        this.description = params.description;
        this.dependency = params.dependency;
        makeImmutable(this);
    }
    get unavailabilityReasons() {
        return this.dependency.unavailabilityReasons.isEmpty
            ? RSet.empty()
            : RSet.singleton(this.reason);
    }
    get isAvailable() {
        return this.dependency.isAvailable;
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitDependent(this);
    }
}
