import { flowResult, makeAutoObservable } from "mobx";
import { Money } from "../core";
import { formatFulfillmentMethodType } from "./formatFulfillmentMethodType";
import { formatAddressAsSingleLine } from "./PFormattedAddress";
import { formatOpeningHours } from "./POpeningHours";
export class PRestaurantSelection {
    constructor(params) {
        this.localizationProvider = params.localizationProvider;
        this.streetAddressStorageManager = params.streetAddressStorageManager;
        this.geolocation = params.geolocation;
        this.initialRestaurants = params.initialRestaurants;
        this.deliveryAddressController = params.deliveryAddressController;
        this.deliveryAddress = params.deliveryAddress;
        makeAutoObservable(this);
    }
    get isLoading() {
        return this.deliveryAddressState.type === "Loading";
    }
    get deliveryAddressState() {
        return this.deliveryAddress.status.state;
    }
    get restaurants() {
        switch (this.deliveryAddressState.type) {
            case "ChooseRestaurant":
            case "RestaurantDelivers":
                return this.deliveryAddressState.restaurants;
            default:
                return this.initialRestaurants;
        }
    }
    get restaurantListItems() {
        return this.restaurants.map((r) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            const deliveryCost = (_b = (_a = r.deliveryInfo) === null || _a === void 0 ? void 0 : _a.deliveryCost) !== null && _b !== void 0 ? _b : null;
            const minOrderValue = (_d = (_c = r.deliveryInfo) === null || _c === void 0 ? void 0 : _c.minOrderValue) !== null && _d !== void 0 ? _d : null;
            const freeThreshold = (_f = (_e = r.deliveryInfo) === null || _e === void 0 ? void 0 : _e.freeDeliveryThreshold) !== null && _f !== void 0 ? _f : null;
            return {
                id: r.id,
                name: r.name,
                slug: (_g = r.slug) !== null && _g !== void 0 ? _g : "",
                address: formatAddressAsSingleLine({
                    street: (_h = r.address.street) !== null && _h !== void 0 ? _h : "",
                    streetNumber: "",
                    city: r.address.city,
                    postCode: r.address.postCode,
                }, true),
                phones: r.contactPhoneNumber !== null
                    ? r.contactPhoneNumber.split(",").map((s) => s.trim())
                    : [],
                email: (_j = r.contactEmail) !== null && _j !== void 0 ? _j : "",
                deliveryTypes: r.fulfillmentMethods
                    .map(formatFulfillmentMethodType)
                    .join(", "),
                menuLink: `/restauracja/${r.slug}`,
                isWithinArea: !!r.deliveryInfo,
                openingHours: formatOpeningHours(r.restaurantHours),
                deliveryCost: deliveryCost !== null
                    ? this.localizationProvider.formatPrice(Money.fromBackend(deliveryCost))
                    : null,
                minOrderValue: minOrderValue !== null
                    ? this.localizationProvider.formatPrice(Money.fromBackend(minOrderValue))
                    : null,
                freeTreshold: freeThreshold !== null
                    ? this.localizationProvider.formatPrice(Money.fromBackend(freeThreshold))
                    : null,
            };
        });
    }
    get deliveringRestaurants() {
        return this.restaurantListItems.filter((item) => item.isWithinArea);
    }
    get notDeliveringRestaurants() {
        return this.restaurantListItems.filter((item) => !item.isWithinArea);
    }
    get geolocationIsLoading() {
        return this.geolocation.status.type === "Loading";
    }
    get geolocationError() {
        return this.geolocation.error;
    }
    get streetAddress() {
        return this.deliveryAddress.streetAddress;
    }
    async submitAddress() {
        this.streetAddressStorageManager.store(this.deliveryAddress.streetAddress);
        return await flowResult(this.deliveryAddress.validateAndSave(null));
    }
    locateMe() {
        return flowResult(this.deliveryAddressController.locateMe(null));
    }
}
