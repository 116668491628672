var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { makeAutoObservable } from "mobx";
import { singleton } from "tsyringe";
import { Availability } from "../Availability";
import { DeliveryAddress } from "../DeliveryAddress";
import { ApartmentInfoAvailabilityService } from "./ApartmentInfoAvailabilityService";
import { StreetAddressAvailabilityService } from "./StreetAddressAvailabilityService";
let DeliveryAddressAvailabilityService = class DeliveryAddressAvailabilityService {
    constructor(deliveryAddress, apartmentInfoAvailabilityService, streetAddressAvailabilityService) {
        this.deliveryAddress = deliveryAddress;
        this.apartmentInfoAvailabilityService = apartmentInfoAvailabilityService;
        this.streetAddressAvailabilityService = streetAddressAvailabilityService;
        makeAutoObservable(this);
    }
    get deliveryAddressStatusAvailability() {
        return this.deliveryAddress.doesTheRestaurantDelivers
            ? Availability.available()
            : Availability.unavailable("RestaurantDoesntDeliver");
    }
    get availability() {
        return Availability.composite([
            this.streetAddressAvailabilityService.availability(this.deliveryAddress.streetAddress),
            this.apartmentInfoAvailabilityService.availability(this.deliveryAddress.apartmentInfo),
            this.deliveryAddressStatusAvailability,
        ]);
    }
};
DeliveryAddressAvailabilityService = __decorate([
    singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof DeliveryAddress !== "undefined" && DeliveryAddress) === "function" ? _a : Object, typeof (_b = typeof ApartmentInfoAvailabilityService !== "undefined" && ApartmentInfoAvailabilityService) === "function" ? _b : Object, typeof (_c = typeof StreetAddressAvailabilityService !== "undefined" && StreetAddressAvailabilityService) === "function" ? _c : Object])
], DeliveryAddressAvailabilityService);
export { DeliveryAddressAvailabilityService };
