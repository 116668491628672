var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { Availability, ParametrizedAvailability, PreorderSettingsAvailability, ScheduleAvailability, } from "../Availability";
import { FulfillmentTimeScope } from "../FulfillmentTimeScope";
import { ScheduleAvailabilityService, SectionVisibilityService, } from "../Menu";
import { ProductTypePreorderSettingsService } from "../PreorderSettingsService";
let ProductTypeAvailabilityService = class ProductTypeAvailabilityService {
    constructor(productTypePreorderSettingsService, sectionVisibilityService, scheduleAvailabilityService, fulfillmentTimeScope) {
        this.productTypePreorderSettingsService = productTypePreorderSettingsService;
        this.sectionVisibilityService = sectionVisibilityService;
        this.scheduleAvailabilityService = scheduleAvailabilityService;
        this.fulfillmentTimeScope = fulfillmentTimeScope;
        makeImmutable(this);
    }
    productTypeAvailability(productType, scope) {
        return [
            Availability.dependent("ProductType", "", this.basicAvailability(productType, scope)),
            Availability.dependent("ProductTypeTimeBased", "", this.timeBasedAvailability(productType)),
        ];
    }
    visibilityAvailability(productType) {
        const isVisible = productType.groups.objects.some((productTypeGroup) => this.sectionVisibilityService.isSectionVisible(productTypeGroup.visibilityScheduleId));
        return isVisible
            ? Availability.available()
            : Availability.unavailable("Visibility");
    }
    basicAvailability(productType, scope) {
        const { availabilitySpec, pricing } = productType;
        if (availabilitySpec.type === "TemporarilyUnavailable") {
            return Availability.unavailable("TemporarilyUnavailable");
        }
        const parametric = new ParametrizedAvailability({
            unavailabilityReason: "ParametrizedAvailability",
            parametrization: availabilitySpec.availabilityParametrization,
            scope,
        });
        const price = new ParametrizedAvailability({
            unavailabilityReason: "PriceAvailability",
            parametrization: pricing.map(() => true),
            scope,
        });
        const visibility = this.visibilityAvailability(productType);
        return Availability.composite([parametric, price, visibility]);
    }
    timeBasedAvailability(productType) {
        const { availabilitySpec } = productType;
        if (availabilitySpec.type === "TemporarilyUnavailable") {
            return Availability.unavailable("TemporarilyUnavailable");
        }
        const schedule = availabilitySpec.availabilitySchedule === null
            ? Availability.available()
            : new ScheduleAvailability({
                availabilitySchedule: availabilitySpec.availabilitySchedule,
                fulfillmentTimeScope: this.fulfillmentTimeScope,
                scheduleAvailabilityService: this.scheduleAvailabilityService,
            });
        const preorder = new PreorderSettingsAvailability({
            fulfillmentTimeScope: this.fulfillmentTimeScope,
            preorderSettings: this.productTypePreorderSettingsService.preorderSettings(productType),
        });
        return Availability.composite([schedule, preorder]);
    }
};
ProductTypeAvailabilityService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ProductTypePreorderSettingsService !== "undefined" && ProductTypePreorderSettingsService) === "function" ? _a : Object, typeof (_b = typeof SectionVisibilityService !== "undefined" && SectionVisibilityService) === "function" ? _b : Object, typeof (_c = typeof ScheduleAvailabilityService !== "undefined" && ScheduleAvailabilityService) === "function" ? _c : Object, typeof (_d = typeof FulfillmentTimeScope !== "undefined" && FulfillmentTimeScope) === "function" ? _d : Object])
], ProductTypeAvailabilityService);
export { ProductTypeAvailabilityService };
