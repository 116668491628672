var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { reaction } from "mobx";
import { inject, singleton } from "tsyringe";
import { IQrWaiter } from "../services";
import { StorageManagerFactory } from "./StorageManagerFactory";
let PurchaseOrderStorageManager = class PurchaseOrderStorageManager {
    constructor(qrWaiter, storageManagerFactory) {
        this.cartStorageManager = null;
        this.previousCartStorageManager = null;
        this.fulfillmentTimeStorageManager =
            storageManagerFactory.forFulfillmentTime();
        this.streetAddressStorageManager = storageManagerFactory.forStreetAddress();
        this.apartmentInfoStorageManager = storageManagerFactory.forApartmentInfo();
        this.contactDetailsStorageManager = qrWaiter.isQrWaiterOrder()
            ? storageManagerFactory.forQrWaiterContactDetails()
            : storageManagerFactory.forOnlineContactDetails();
        this.invoiceDetailsStorageManager =
            storageManagerFactory.forInvoiceDetails();
        this.consentsStoreManager = storageManagerFactory.forConsents();
        this.fulfillmentMethodTypeStorageManager =
            storageManagerFactory.forFulfillmentMethodType();
        this.paymentStorageManager = storageManagerFactory.forPayment();
    }
    initialize(params) {
        this.cartStorageManager = params.cartStorageManager;
        this.previousCartStorageManager = params.previousCartStorageManager;
        const { storageData } = params;
        reaction(() => storageData.cartStorageData, (cartStorageData) => this.cartStorageManager.store(cartStorageData));
        reaction(() => storageData.fulfillmentTimeStorageData, (fulfillmentTimeStorageData) => {
            if (fulfillmentTimeStorageData !== null) {
                this.fulfillmentTimeStorageManager.store(fulfillmentTimeStorageData);
            }
        }, 
        // NOTICE: Firing immediately to ensure that the initial validated value is stored
        { fireImmediately: true });
        reaction(() => storageData.streetAddressStorageData, (streetAddressStorageData) => {
            if (streetAddressStorageData !== null) {
                this.streetAddressStorageManager.store(streetAddressStorageData);
            }
        });
        reaction(() => storageData.apartmentInfoStorageData, (apartmentInfoStorageData) => {
            if (apartmentInfoStorageData !== null) {
                this.apartmentInfoStorageManager.store(apartmentInfoStorageData);
            }
        });
        reaction(() => storageData.contactDetailsStorageData, (contactDetailsStorageData) => {
            this.contactDetailsStorageManager.store(contactDetailsStorageData);
        });
        reaction(() => storageData.invoiceDetailsStorageData, (invoiceDetailsStorageData) => {
            this.invoiceDetailsStorageManager.store(invoiceDetailsStorageData);
        });
        reaction(() => storageData.consentsStorageData, (consentsStorageData) => {
            this.consentsStoreManager.store(consentsStorageData);
        });
        reaction(() => storageData.paymentMediumStorageData, (paymentMediumStorageData) => {
            if (paymentMediumStorageData !== null) {
                this.paymentStorageManager.store(paymentMediumStorageData);
            }
        }, 
        // NOTICE: Firing immediately to ensure that the initial validated value is stored
        { fireImmediately: true });
        reaction(() => storageData.fulfillmentMethodType, (fulfillmentMethodType) => {
            if (fulfillmentMethodType !== null) {
                this.fulfillmentMethodTypeStorageManager.store(fulfillmentMethodType);
            }
        }, 
        // NOTICE: Firing immediately to ensure that the initial validated value is stored
        { fireImmediately: true });
    }
    erase() {
        var _a, _b;
        (_a = this.cartStorageManager) === null || _a === void 0 ? void 0 : _a.erase();
        (_b = this.previousCartStorageManager) === null || _b === void 0 ? void 0 : _b.erase();
        this.fulfillmentTimeStorageManager.erase();
        this.streetAddressStorageManager.erase();
        this.apartmentInfoStorageManager.erase();
        this.contactDetailsStorageManager.erase();
        this.invoiceDetailsStorageManager.erase();
        this.consentsStoreManager.erase();
        this.fulfillmentMethodTypeStorageManager.erase();
        this.paymentStorageManager.erase();
    }
};
PurchaseOrderStorageManager = __decorate([
    singleton(),
    __param(0, inject(IQrWaiter)),
    __metadata("design:paramtypes", [typeof (_a = typeof IQrWaiter !== "undefined" && IQrWaiter) === "function" ? _a : Object, typeof (_b = typeof StorageManagerFactory !== "undefined" && StorageManagerFactory) === "function" ? _b : Object])
], PurchaseOrderStorageManager);
export { PurchaseOrderStorageManager };
