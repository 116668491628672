import { makeImmutable, Money, Tristate } from "../../core";
export class ScopedPriceImpl {
    constructor(price) {
        this.type = "scoped";
        this.price = price;
        makeImmutable(this);
    }
    static fromPricing(pricing, parameterSet) {
        const price = pricing.unify(parameterSet);
        return new ScopedPriceImpl(price);
    }
    compare(other) {
        switch (other.type) {
            case "unscoped": {
                return this.compareMoneySet(this.price.map((thisValue) => thisValue.sub(other.value)).values);
            }
            case "scoped": {
                return this.compareMoneySet(this.price.combine(other.price, (a, b) => a.sub(b)).values);
            }
        }
    }
    compareMoneySet(xs) {
        return xs.every((m) => m.gt(Money.zero()))
            ? 1
            : xs.every((m) => m.lt(Money.zero()))
                ? -1
                : Math.sign(xs
                    .rawValues()
                    .map((money) => money.cents)
                    .reduce((acc, v) => acc + v) / xs.size);
    }
    combine(other, combination) {
        switch (other.type) {
            case "unscoped":
                return this.map((thisValue) => combination(thisValue, other.value));
            case "scoped": {
                const combinedPrice = this.price.combine(other.price, combination);
                return new ScopedPriceImpl(combinedPrice);
            }
        }
    }
    map(mapping) {
        return new ScopedPriceImpl(this.price.map(mapping));
    }
    get spectrum() {
        const values = this.price.values;
        const value = values.sample;
        if (value === null) {
            return { type: "null" };
        }
        if (values.size === 1) {
            return { type: "value", value };
        }
        const min = values.reduce((a, b) => a.min(b), value);
        const max = values.reduce((a, b) => a.max(b), value);
        return { type: "range", min, max };
    }
    get finalValue() {
        if (this.spectrum.type === "value") {
            return this.spectrum.value;
        }
        return null;
    }
    get hasPossibleValues() {
        return this.price.hasPossibleValues;
    }
    test(predicate) {
        const { values } = this.price;
        if (values.every(predicate)) {
            return Tristate.True;
        }
        if (values.some(predicate)) {
            return Tristate.Maybe;
        }
        return Tristate.False;
    }
}
