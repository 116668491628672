var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { makeAutoObservable } from "mobx";
import { RSet } from "../collections";
import { LocalizationProvider } from "../locale";
import { PurchaseOrder, PurchaseOrderAvailabilityService, PurchaseOrderPayload, PurchaseOrderPriceService, } from "../ordering";
import { PCartError } from "./PCartError";
import { PRestaurant } from "./PRestaurant";
import { perRestaurant } from "../di";
class PurchaseOrderAvailabilityVisitor {
    constructor() {
        this.reasons = RSet.empty();
    }
    visitAvailable() {
        // NOTICE Intentionally empty
    }
    visitUnavailable(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.reasons = this.reasons.union(availability.unavailabilityReasons);
        }
    }
    visitMaybe() {
        // NOTICE Intentionally empty
    }
    visitBoolean(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.reasons = this.reasons.union(availability.unavailabilityReasons);
        }
    }
    visitComposite(availability) {
        availability.availabilities.forEach((childAvailability) => {
            return childAvailability.accept(this);
        });
    }
    visitDependent(availability) {
        availability.dependency.accept(this);
    }
    // TODO Ask Mikołaj why separate visitor
    visitParametrized() {
        // NOTICE Intentionally empty
    }
    visitSchedule() {
        // NOTICE Intentionally empty
    }
    visitPreorder(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.reasons = this.reasons.union(availability.unavailabilityReasons);
        }
    }
    visitOrderingHours(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.reasons = this.reasons.union(availability.unavailabilityReasons);
        }
    }
}
let PPurchaseOrderUnavailabilityDetails = class PPurchaseOrderUnavailabilityDetails {
    constructor(purchaseOrder, payload, purchaseOrderAvailabilityService, purchaseOrderPriceService, restaurant, localizationProvider) {
        this.purchaseOrder = purchaseOrder;
        this.payload = payload;
        this.purchaseOrderAvailabilityService = purchaseOrderAvailabilityService;
        this.purchaseOrderPriceService = purchaseOrderPriceService;
        this.restaurant = restaurant;
        this.localizationProvider = localizationProvider;
        makeAutoObservable(this);
    }
    get reasons() {
        const visitor = new PurchaseOrderAvailabilityVisitor();
        this.purchaseOrderAvailabilityService.availability.accept(visitor);
        return visitor.reasons;
    }
    get insufficientOrderValueDetails() {
        const validationPrice = this.purchaseOrderPriceService.validationPrice;
        const minOrderValue = this.purchaseOrder.minOrderValue;
        const missingValue = minOrderValue.sub(validationPrice.value);
        return {
            minValue: minOrderValue.add(validationPrice.deliveryCost),
            missingValue,
        };
    }
    get error() {
        if (this.reasons.includes("OrderingAvailability")) {
            return PCartError.orderingNotPossible();
        }
        if (this.reasons.includes("InsufficientOrderValue")) {
            const { minValue, missingValue } = this.insufficientOrderValueDetails;
            return PCartError.minOrderValueWarning(this.localizationProvider.formatPrice(minValue), this.localizationProvider.formatPrice(missingValue));
        }
        if (this.reasons.includes("MaximumOrderValueExceeded")) {
            const maxValue = this.localizationProvider.formatPrice(this.restaurant.maxOrderValue);
            return PCartError.maxOrderValueWarning(maxValue);
        }
        if (!this.reasons.includes("OrderingHours") &&
            this.payload.orderLines.size > 1 &&
            (this.reasons.includes("Preorder") ||
                this.reasons.includes("PreorderDatesEmpty") ||
                this.reasons.includes("FulfillmentTimeOptionsMissing"))) {
            return PCartError.notOverlappingDishes();
        }
        if (!this.reasons.includes("OrderingHours") &&
            this.payload.orderLines.size === 1 &&
            this.reasons.includes("FulfillmentTimeOptionsMissing")) {
            return PCartError.productNotAvailable();
        }
        return null;
    }
};
PPurchaseOrderUnavailabilityDetails = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PurchaseOrder !== "undefined" && PurchaseOrder) === "function" ? _a : Object, typeof (_b = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _b : Object, typeof (_c = typeof PurchaseOrderAvailabilityService !== "undefined" && PurchaseOrderAvailabilityService) === "function" ? _c : Object, typeof (_d = typeof PurchaseOrderPriceService !== "undefined" && PurchaseOrderPriceService) === "function" ? _d : Object, typeof (_e = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _e : Object, typeof (_f = typeof LocalizationProvider !== "undefined" && LocalizationProvider) === "function" ? _f : Object])
], PPurchaseOrderUnavailabilityDetails);
export { PPurchaseOrderUnavailabilityDetails };
