import { RSet } from "../../collections";
import { makeImmutable, Tristate } from "../../core";
/*
 * Availability strategy based on Ordering Hours
 */
export class OrderingHoursAvailability {
    constructor(params) {
        this.now = params.now;
        this.orderingHours = params.orderingHours;
        this.allowOrderingInClosedRestaurant =
            params.onlineOrderingSettings.allowOrderingInClosedRestaurant;
        this.fulfillmentTime = params.fulfillmentTime;
        makeImmutable(this);
    }
    get isAvailable() {
        if (this.orderingHours.canOrderAt(this.now)) {
            return Tristate.True;
        }
        else {
            if (this.canPreorder) {
                return Tristate.True;
            }
            else {
                return Tristate.False;
            }
        }
    }
    get isBeforeOrderingHours() {
        var _a, _b;
        const nextEvent = this.orderingHours.nextEventAtDate(this.now);
        return (nextEvent.type === "OrderingBegins" && ((_b = (_a = nextEvent.at) === null || _a === void 0 ? void 0 : _a.isToday) !== null && _b !== void 0 ? _b : false));
    }
    get isAfterOrderingHours() {
        const nextEvent = this.orderingHours.nextEventAtDate(this.now);
        return nextEvent.type === "OrderingBegins" && nextEvent.at === null;
    }
    get isOutOfOrderingHours() {
        return !this.orderingHours.canOrderAt(this.now);
    }
    get canPreorder() {
        if (this.isOutOfOrderingHours && !this.allowOrderingInClosedRestaurant) {
            return false;
        }
        return this.fulfillmentTime.hasAnyAvailableTime;
    }
    get unavailabilityReasons() {
        return this.isAvailable.isDefinitelyTrue
            ? RSet.empty()
            : RSet.singleton("OrderingHours");
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitOrderingHours(this);
    }
}
