var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { Now, RDate } from "../../core";
import { perRestaurant } from "../../di";
import { Availability, ParametrizedAvailability, } from "../Availability";
import { FulfillmentTimeScope } from "../FulfillmentTimeScope";
let PromotionTypeAvailabilityService = class PromotionTypeAvailabilityService {
    constructor(now, fulfillmentTimeScope) {
        this.now = now;
        this.fulfillmentTimeScope = fulfillmentTimeScope;
        makeAutoObservable(this);
    }
    scheduleAvailability(promotionType) {
        const { fulfillmentTime } = this.fulfillmentTimeScope;
        if (!promotionType.availabilitySchedule.includes(this.now)) {
            return Availability.unavailable("NowOutsideSchedule");
        }
        if (fulfillmentTime === null) {
            return Availability.maybe("DateNotSelected");
        }
        switch (fulfillmentTime.type) {
            case "ASAP":
                if (promotionType.availabilitySchedule.includes(this.now)) {
                    return Availability.available();
                }
                break;
            case "OnTime":
                if (promotionType.availabilitySchedule.includes(fulfillmentTime.date)) {
                    return Availability.available();
                }
                break;
        }
        return Availability.unavailable("DateOutsideSchedule");
    }
    periodAvailability(promotionType) {
        const { fulfillmentTime } = this.fulfillmentTimeScope;
        if (promotionType.period.end.isBefore(this.now)) {
            return Availability.unavailable("ExpiredDateRange");
        }
        if (!promotionType.period.includes(this.now)) {
            return Availability.unavailable("NowOutsideRange");
        }
        if (fulfillmentTime === null) {
            return Availability.maybe("DateNotSelected");
        }
        switch (fulfillmentTime.type) {
            case "ASAP":
                if (promotionType.period.includes(this.now)) {
                    return Availability.available();
                }
                break;
            case "OnTime":
                if (promotionType.period.includes(fulfillmentTime.date)) {
                    return Availability.available();
                }
                break;
        }
        return Availability.unavailable("DateOutsideRange");
    }
    availability(promotionType, scope) {
        const parametrized = new ParametrizedAvailability({
            unavailabilityReason: "ParametrizedAvailability",
            parametrization: promotionType.availabilityParametrization,
            scope,
        });
        const debugId = `PromotionType#${promotionType.id.value}`;
        const dateRange = this.periodAvailability(promotionType);
        const schedule = this.scheduleAvailability(promotionType);
        return Availability.composite([
            Availability.dependent("DateRange", debugId, dateRange),
            Availability.dependent("Schedule", debugId, schedule),
            parametrized,
        ]);
    }
};
PromotionTypeAvailabilityService = __decorate([
    perRestaurant(),
    __param(0, inject(Now)),
    __metadata("design:paramtypes", [typeof (_a = typeof RDate !== "undefined" && RDate) === "function" ? _a : Object, typeof (_b = typeof FulfillmentTimeScope !== "undefined" && FulfillmentTimeScope) === "function" ? _b : Object])
], PromotionTypeAvailabilityService);
export { PromotionTypeAvailabilityService };
