var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
import { inject } from "tsyringe";
import { makeImmutable, Now, RDate } from "../core";
import { perRestaurant } from "../di";
import { Asap } from "./Asap";
import { ASAPFulfillmentTime } from "./FulfillmentTime";
import { OnlineOrderingSettingsToken, } from "./OnlineOrderingSettings";
import { IOrderingHours } from "./OrderingHours";
import { MenuPreorderSettingsProvider } from "./MenuPreorderSettingsProvider";
let FulfillmentTimeValidator = class FulfillmentTimeValidator {
    constructor(orderingHours, now, preorderSettingsProvider, onlineOrderingSettings, asap) {
        this.orderingHours = orderingHours;
        this.now = now;
        this.onlineOrderingSettings = onlineOrderingSettings;
        this.asap = asap;
        this.preorderSettings = preorderSettingsProvider.preorderSettings;
        makeImmutable(this);
    }
    get asapOnlyRestaurant() {
        return !this.onlineOrderingSettings.enableDelayedDelivery;
    }
    validate(input) {
        let fulfillmentTime;
        if (input === null) {
            fulfillmentTime = new ASAPFulfillmentTime();
        }
        else {
            // NOTICE Today date chosen, but asapOnly means that ASAP is only option for today dates
            const todayEndOfDay = this.orderingHours.endOfDay(this.now);
            if (this.asapOnlyRestaurant &&
                input.type === "OnTime" &&
                (todayEndOfDay !== null
                    ? input.date.isBefore(todayEndOfDay)
                    : input.date.isToday)) {
                fulfillmentTime = new ASAPFulfillmentTime();
            }
            else {
                // NOTICE Just validate user input without any alterations
                fulfillmentTime = input;
            }
        }
        switch (fulfillmentTime.type) {
            case "ASAP": {
                if (this.asap.asapOptionAvailable(this.preorderSettings)) {
                    return fulfillmentTime;
                }
                else {
                    return null;
                }
            }
            case "OnTime": {
                const date = fulfillmentTime.date;
                if (!this.orderingHours.canOrderAt(date)) {
                    return null;
                }
                if (!this.preorderSettings.canOrderAt(fulfillmentTime)) {
                    return null;
                }
                return fulfillmentTime;
            }
        }
    }
};
FulfillmentTimeValidator = __decorate([
    perRestaurant(),
    __param(0, inject(IOrderingHours)),
    __param(1, inject(Now)),
    __param(3, inject(OnlineOrderingSettingsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof IOrderingHours !== "undefined" && IOrderingHours) === "function" ? _a : Object, typeof (_b = typeof RDate !== "undefined" && RDate) === "function" ? _b : Object, typeof (_c = typeof MenuPreorderSettingsProvider !== "undefined" && MenuPreorderSettingsProvider) === "function" ? _c : Object, Object, typeof (_d = typeof Asap !== "undefined" && Asap) === "function" ? _d : Object])
], FulfillmentTimeValidator);
export { FulfillmentTimeValidator };
