import { makeAutoObservable } from "mobx";
import { toPairs } from "../../../collections";
import { ErrorBox, TextFieldController } from "../../../form";
import { t, translate } from "../../../I18n";
import { Email } from "../../../ordering";
export class QrWaiterContactFormController {
    constructor(params) {
        var _a;
        this.type = "QRWaiter";
        this.contactDetails = params.contactDetails;
        this.email = new TextFieldController({
            initialValue: ((_a = this.contactDetails.email) === null || _a === void 0 ? void 0 : _a.value) || "",
            onUpdate: (str) => (this.contactDetails.email = Email.create(str)),
            errorBox: new ErrorBox({
                domainObjects: [this.contactDetails],
                errorMessages: {
                    EmailMissing: translate(t._common.form.errors.required),
                    InvalidEmail: translate(t.validations.invalid_email),
                },
            }),
        });
        makeAutoObservable(this);
    }
    setData(params) {
        this.email.change(params.email.value);
    }
    get fields() {
        return {
            email: this.email,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const fieldsInvalid = this.fieldsArray.some((field) => field.isInvalid);
        const modelInvalid = this.contactDetails.availability.isAvailable.isDefinitelyFalse;
        return fieldsInvalid || modelInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.contactDetails;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
}
