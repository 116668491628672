import { RMap } from "../../collections";
import { Quantity, makeImmutable } from "../../core";
export class ModifierSettings {
    constructor(params) {
        this.editable = params.editable;
        this.freeItems = params.freeItems;
        this._defaultItems = params.defaultItems;
        this.displayDefaultItems = params.displayDefaultItems;
        makeImmutable(this);
    }
    defaultQuantity(modifierItemTypeId) {
        var _a;
        return (_a = this.defaultItems.find(modifierItemTypeId)) !== null && _a !== void 0 ? _a : Quantity.Zero;
    }
    get customParameterTypes() {
        return this.editable.customParameterTypes;
    }
    get defaultItems() {
        return this._defaultItems || RMap.empty();
    }
    merge(productSettings) {
        return new ModifierSettings({
            editable: this.editable.combine(productSettings.editable, (a, b) => a && b),
            freeItems: productSettings.freeItems,
            defaultItems: productSettings._defaultItems || this._defaultItems,
            displayDefaultItems: productSettings.displayDefaultItems,
        });
    }
}
