import { makeImmutable, } from "../../core";
export class Schedule {
    constructor(params) {
        this.id = params.id;
        this.schedule = params.schedule;
        makeImmutable(this);
    }
    includes(date) {
        return this.schedule.includes(date);
    }
    get associationKey() {
        return this.id.associationKey;
    }
    toString() {
        return this.schedule.toString();
    }
}
export const SchedulesAvailableNow = "SchedulesAvailableNow";
export const Schedules = "Schedule";
