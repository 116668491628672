var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
import { inject } from "tsyringe";
import { WeeklySchedule } from "../collections";
import { makeImmutable, Now, RDate } from "../core";
import { perRestaurant } from "../di";
import { FulfillmentInstructions, FulfillmentInstructionsToken, MenuPreorderSettingsProvider, NewFulfillmentTime, OrderingHoursWidget, ProductInstancePreorderSettingsService, } from "../ordering";
let NewFulfillmentTimeFactory = class NewFulfillmentTimeFactory {
    constructor(now, fulfillmentInstructions, orderingHoursWidget, menuPreorderSettingsProvider, productInstancePreorderSettingsService) {
        this.now = now;
        this.fulfillmentInstructions = fulfillmentInstructions;
        this.orderingHoursWidget = orderingHoursWidget;
        this.menuPreorderSettingsProvider = menuPreorderSettingsProvider;
        this.productInstancePreorderSettingsService = productInstancePreorderSettingsService;
        makeImmutable(this);
    }
    forProductCard(pProductInstanceCreator) {
        const { productInstancePreorderSettingsService } = this;
        return new NewFulfillmentTime({
            now: this.now,
            orderingHoursWidget: this.orderingHoursWidget,
            fulfillmentInstructions: this.fulfillmentInstructions,
            weeklyScheduleProvider: {
                get weeklySchedule() {
                    return (pProductInstanceCreator.instance.productWeeklySchedule ||
                        WeeklySchedule.Full);
                },
            },
            preorderSettingsProvider: {
                get preorderSettings() {
                    return productInstancePreorderSettingsService.preorderSettings(pProductInstanceCreator.instance);
                },
            },
        });
    }
    forWholeMenu() {
        return new NewFulfillmentTime({
            now: this.now,
            orderingHoursWidget: this.orderingHoursWidget,
            fulfillmentInstructions: this.fulfillmentInstructions,
            weeklyScheduleProvider: {
                get weeklySchedule() {
                    return WeeklySchedule.Full;
                },
            },
            preorderSettingsProvider: this.menuPreorderSettingsProvider,
        });
    }
};
NewFulfillmentTimeFactory = __decorate([
    perRestaurant(),
    __param(0, inject(Now)),
    __param(1, inject(FulfillmentInstructionsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof RDate !== "undefined" && RDate) === "function" ? _a : Object, typeof (_b = typeof FulfillmentInstructions !== "undefined" && FulfillmentInstructions) === "function" ? _b : Object, typeof (_c = typeof OrderingHoursWidget !== "undefined" && OrderingHoursWidget) === "function" ? _c : Object, typeof (_d = typeof MenuPreorderSettingsProvider !== "undefined" && MenuPreorderSettingsProvider) === "function" ? _d : Object, typeof (_e = typeof ProductInstancePreorderSettingsService !== "undefined" && ProductInstancePreorderSettingsService) === "function" ? _e : Object])
], NewFulfillmentTimeFactory);
export { NewFulfillmentTimeFactory };
