var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { RArray } from "../../collections";
import { makeImmutable, Quantity } from "../../core";
import { perRestaurant } from "../../di";
import { Availability } from "../Availability";
import { ModifierItemInstanceAvailabilityService } from "./ModifierItemInstanceAvailabilityService";
import { ModifierTypeAvailabilityService } from "./ModifierTypeAvailabilityService";
let ModifierInstanceAvailabilityService = class ModifierInstanceAvailabilityService {
    constructor(modifierTypeAvailabilityService, modifierItemInstanceAvailabilityService) {
        this.modifierTypeAvailabilityService = modifierTypeAvailabilityService;
        this.modifierItemInstanceAvailabilityService = modifierItemInstanceAvailabilityService;
        makeImmutable(this);
    }
    availability(modifierInstance) {
        const modifierInstanceItems = modifierInstance.selectedItems;
        const selectedItemCount = modifierInstanceItems.reduce((acc, itemInstance) => acc.add(itemInstance.quantity), Quantity.Zero);
        const availabilities = new RArray(modifierInstanceItems.map((itemInstance) => this.modifierItemInstanceAvailabilityService.unitAvailability(itemInstance)));
        const itemAvailabilities = Availability.dependent("ModifierItem", "", Availability.composite(availabilities.raw));
        const modifierTypeAvailability = this.modifierTypeAvailabilityService.availability(modifierInstance.modifierType, modifierInstance.scope);
        const quantityAvailabilities = selectedItemCount.isZero &&
            modifierTypeAvailability.isAvailable.isDefinitelyFalse
            ? Availability.available()
            : Availability.boolean({
                NotExpectedItems: modifierInstance.modifierType.maxItems !== null &&
                    modifierInstance.modifierType.minItems.eq(modifierInstance.modifierType.maxItems) &&
                    !selectedItemCount.eq(modifierInstance.modifierType.minItems),
                MaxItemsExceeded: modifierInstance.modifierType.maxItems !== null &&
                    selectedItemCount.gt(modifierInstance.modifierType.maxItems),
                NotEnoughItems: selectedItemCount.lt(modifierInstance.modifierType.minItems),
            });
        return Availability.composite([itemAvailabilities, quantityAvailabilities]);
    }
};
ModifierInstanceAvailabilityService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ModifierTypeAvailabilityService !== "undefined" && ModifierTypeAvailabilityService) === "function" ? _a : Object, typeof (_b = typeof ModifierItemInstanceAvailabilityService !== "undefined" && ModifierItemInstanceAvailabilityService) === "function" ? _b : Object])
], ModifierInstanceAvailabilityService);
export { ModifierInstanceAvailabilityService };
