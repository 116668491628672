var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { IdentifiableMap, RMutableMultiSet } from "../../collections";
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { ModifierItemInstanceAvailabilityService } from "../AvailabilityService";
import { ModifierInstanceCreator } from "./ModifierInstanceCreator";
import { ModifierInstanceCreatorEditability } from "./ModifierInstanceCreatorEditability";
import { ModifierItemInstanceCreator } from "./ModifierItemInstanceCreator";
let ModifierInstanceCreatorFactory = class ModifierInstanceCreatorFactory {
    constructor(modifierItemInstanceAvailabilityService) {
        this.modifierItemInstanceAvailabilityService = modifierItemInstanceAvailabilityService;
        makeImmutable(this);
    }
    create(params) {
        const { modifierInstance: { modifierType, items }, modifierSettings, } = params;
        const isMultipleChoice = modifierType.behaviour === "MultipleChoice";
        const editability = new ModifierInstanceCreatorEditability(modifierSettings.editable);
        const itemQuantities = RMutableMultiSet.fromIterable(items.objects.map((modifierItemInstance) => [
            modifierItemInstance.typeId,
            modifierItemInstance.quantity,
        ]));
        const itemCreators = IdentifiableMap.fromIterable("typeId", modifierType.itemTypes.objects.map((modifierItemType) => new ModifierItemInstanceCreator({
            modifierItemInstanceAvailabilityService: this.modifierItemInstanceAvailabilityService,
            itemQuantities,
            modifierType,
            modifierItemType,
            modifierSettings,
            maxDuplicatesPerItem: modifierType.maxDuplicatesPerItem,
            isMultipleChoice,
            editability,
        })));
        return new ModifierInstanceCreator({
            behaviour: modifierType.behaviour,
            modifierType,
            modifierSettings,
            itemQuantities,
            itemCreators,
            editability,
        });
    }
};
ModifierInstanceCreatorFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ModifierItemInstanceAvailabilityService !== "undefined" && ModifierItemInstanceAvailabilityService) === "function" ? _a : Object])
], ModifierInstanceCreatorFactory);
export { ModifierInstanceCreatorFactory };
