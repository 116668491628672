import { makeAutoObservable } from "mobx";
import { PModifierItemInstanceId } from "./PModifierItemInstanceId";
import { PPrice } from "./PPrice";
export class PModifierItemInstanceCreator {
    constructor(params) {
        this.instanceId = new PModifierItemInstanceId();
        this.domain = params.domain;
        this.behaviour = params.behaviour;
        this.modifierItemType = params.modifierItemType;
        this.localizationProvider = params.localizationProvider;
        this.modifierItemInstancePriceService =
            params.modifierItemInstancePriceService;
        this.modifierItemInstanceAvailabilityService =
            params.modifierItemInstanceAvailabilityService;
        this._touched = false;
        makeAutoObservable(this);
    }
    /**
     * The idea of hiding unavailable modifiers makes lot of troubles in code.
     * It fiters out modifiers in Cart and should work only in creator.
     * Eg. Default value in fulfillment method is DineIn so during refreshing page we use ProductInstanceCreator
     * which filters out modifiers which are not available in DineIn fulfilment method.
     **/
    get isVisible() {
        return this.domain.isPossiblyAvailable;
    }
    get typeId() {
        return this.modifierItemType.id;
    }
    touch() {
        this._touched = true;
    }
    get touched() {
        return this._touched;
    }
    get isChecked() {
        return this.domain.isSelected;
    }
    select() {
        this.touch();
        this.domain.select();
    }
    get isSelected() {
        return this.domain.isSelected;
    }
    setSelected(selected) {
        this.touch();
        this.domain.setSelected(selected);
    }
    get itemQuantity() {
        return this.domain.quantity.value;
    }
    get unitPrice() {
        const itemPrice = this.modifierItemInstancePriceService.price(this.domain.modifierItemInstance);
        const p = new PPrice(this.localizationProvider, itemPrice.spectrum)
            .priceValue;
        return p === null ? "" : ` (${p})`;
    }
    get canDecrement() {
        return this.domain.canDecrement;
    }
    get canIncrement() {
        return this.domain.canIncrement;
    }
    decrement() {
        this.touch();
        this.domain.decrement();
    }
    increment() {
        this.touch();
        this.domain.increment();
    }
    get itemAvailability() {
        return this.modifierItemInstanceAvailabilityService.unitAvailability(this.domain.modifierItemInstance);
    }
    get name() {
        return this.modifierItemType.name;
    }
    get photo() {
        return this.modifierItemType.photo;
    }
}
