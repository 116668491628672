var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
import { inject } from "tsyringe";
import { perRestaurant } from "../di";
import { IQrWaiter, ValidatePhoneNumberService } from "../services";
import { PurchaseOrderStorageManager } from "../storage";
import { ContactDetails, ContactDetailsLock } from "../ordering";
import { makeImmutable } from "../core";
let ContactDetailsFactory = class ContactDetailsFactory {
    constructor(qrWaiter, validatePhoneService, purchaseOrderStorageManager, contactDetailsLock) {
        this.validatePhoneService = validatePhoneService;
        this.purchaseOrderStorageManager = purchaseOrderStorageManager;
        this.contactDetailsLock = contactDetailsLock;
        this.isQrWaiterOrder = qrWaiter.isQrWaiterOrder();
        makeImmutable(this);
    }
    create() {
        const contactDetailsStorageData = this.purchaseOrderStorageManager.contactDetailsStorageManager.restore();
        return contactDetailsStorageData === null
            ? ContactDetails.empty({
                isQrWaiterOrder: this.isQrWaiterOrder,
                validatePhoneService: this.validatePhoneService,
                contactDetailsLock: this.contactDetailsLock,
            })
            : ContactDetails.fromStorageData({
                validatePhoneService: this.validatePhoneService,
                storageData: contactDetailsStorageData,
                contactDetailsLock: this.contactDetailsLock,
            });
    }
};
ContactDetailsFactory = __decorate([
    perRestaurant(),
    __param(0, inject(IQrWaiter)),
    __metadata("design:paramtypes", [typeof (_a = typeof IQrWaiter !== "undefined" && IQrWaiter) === "function" ? _a : Object, typeof (_b = typeof ValidatePhoneNumberService !== "undefined" && ValidatePhoneNumberService) === "function" ? _b : Object, typeof (_c = typeof PurchaseOrderStorageManager !== "undefined" && PurchaseOrderStorageManager) === "function" ? _c : Object, typeof (_d = typeof ContactDetailsLock !== "undefined" && ContactDetailsLock) === "function" ? _d : Object])
], ContactDetailsFactory);
export { ContactDetailsFactory };
