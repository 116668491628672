var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { makeAutoObservable } from "mobx";
import { DeliveryPolicy } from "../DeliveryPolicy";
import { StreetAddress } from "../StreetAddress";
import { ApartmentInfo, ApartmentInfoToken } from "./ApartmentInfo";
import { inject, singleton } from "tsyringe";
let DeliveryAddress = class DeliveryAddress {
    constructor(streetAddress, apartmentInfo) {
        this._streetAddress = streetAddress;
        this._apartmentInfo = apartmentInfo;
        this._status = { type: "NotRequested" };
        makeAutoObservable(this);
    }
    get streetAddress() {
        return this._streetAddress;
    }
    get apartmentInfo() {
        return this._apartmentInfo;
    }
    get status() {
        return this._status;
    }
    set(params) {
        this._streetAddress = params.streetAddress;
        this._apartmentInfo = params.apartmentInfo;
        this._status = params.status;
    }
    setApartmentInfoOnly(apartmentInfo) {
        this._apartmentInfo = apartmentInfo;
    }
    get deliveryPolicy() {
        switch (this.status.type) {
            case "RestaurantDelivers":
                return DeliveryPolicy.paid({
                    cost: this.status.deliveryCost,
                    freeDeliveryThreshold: this.status.freeDeliveryThreshold,
                    minOrderValue: this.status.minOrderValue,
                });
            case "RestaurantDoesntDeliverTo":
            case "InvalidAddress":
            case "AddressDoesNotExistOrInaccurate":
            case "ChangeRestaurant":
            case "ChooseRestaurant":
            case "NetworkError":
                return DeliveryPolicy.Impossible;
            case "NotRequested":
            case "ChangedAndNotValidatedYet":
            case "Loading":
                return null;
        }
    }
    get coordinates() {
        return "coordinates" in this._status ? this._status.coordinates : null;
    }
    get doesTheRestaurantDelivers() {
        return this._status.type === "RestaurantDelivers";
    }
};
DeliveryAddress = __decorate([
    singleton(),
    __param(1, inject(ApartmentInfoToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof StreetAddress !== "undefined" && StreetAddress) === "function" ? _a : Object, typeof (_b = typeof ApartmentInfo !== "undefined" && ApartmentInfo) === "function" ? _b : Object])
], DeliveryAddress);
export { DeliveryAddress };
