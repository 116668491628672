var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f;
import { inject, 
// NOTICE This class is allowed to use global container
// eslint-disable-next-line no-restricted-imports
container as rootContainer, singleton, } from "tsyringe";
import { makeImmutable, Now, RDate, RestaurantId, Timezone } from "../core";
import { perRestaurantFactory } from "../di";
import { CartCrossSellingCreator, ContactDetailsToken, Coupon, CouponToken, FirstOrderDiscount, FulfillmentInstructionsToken, ICrossSellingListProvider, InCartPromotionTypes, IOrderingAvailability, IOrderingHours, IPProductInstanceName, IPPromotionParser, IPromotionSchemeAvailabilityService, Menu, MenuPreorderSettingsProvider, OnlineOrderingSettingsToken, OrderingAvailability, OrderingHoursAvailability, OrderLinePriceService, OrderReceiver, PackagingContainerTypes, Payment, PreorderSettingsInstanceFactory, PromotionScheme, PromotionSchemeAvailabilityService, PromotionSchemeFactory, PromotionService, PurchaseOrderPayload, PurchaseOrderStorageData, Restaurant, Schedules, SchedulesAvailableNow, TipInstance, TipInstanceToken, } from "../ordering";
import { ActiveSectionsParser, CrossSellingListProviderParser, MenuSectionParser, MenuV2Parser, PackagingContainerParser, PPromotionParser, PreorderSettingsConfig, ProductTypeGroupParser, RestaurantParser, ScheduleParser, } from "../parsing";
import { ApartmentInfoController, ApartmentInfoControllerFactory, ConsentsFormController, ContactFormController, ContactFormControllerToken, FulfillmentFormController, FulfillmentFormControllerFactory, InvoiceFormController, OrderItemListTracking, PCrossSellingCreator, PCrossSellingCreatorFactory, PFulfillmentTime, PFulfillmentTimeFactory, POrderingAvailability, PProductInstanceName, PRestaurant, PRestaurantParser, } from "../presentation";
import { IQrWaiter, MenuResponse } from "../services";
import { PurchaseOrderStorageManager, StorageManagerFactory } from "../storage";
import { ContactDetailsFactory } from "./ContactDetailsFactory";
import { FirstOrderDiscountFactory } from "./FirstOrderDiscountFactory";
import { FulfillmentInstructionsFactory } from "./FulfillmentInstructionsFactory";
import { NewFulfillmentTimeFactory } from "./NewFulfillmentTimeFactory";
import { OrderReceiverFactory } from "./OrderReceiverFactory";
import { PaymentFactory } from "./PaymentFactory";
import { PurchaseOrderPayloadFactory } from "./PurchaseOrderPayloadFactory";
import { OrderingHoursAvailabilityFactory } from "./OrderingHoursAvailabilityFactory";
let PerRestaurantContainerFactory = class PerRestaurantContainerFactory {
    constructor(qrWaiter, schedulesParser, menuParser, activeSectionsParser, domainRestaurantParser, storageManagerFactory) {
        this.qrWaiter = qrWaiter;
        this.schedulesParser = schedulesParser;
        this.menuParser = menuParser;
        this.activeSectionsParser = activeSectionsParser;
        this.domainRestaurantParser = domainRestaurantParser;
        this.storageManagerFactory = storageManagerFactory;
        makeImmutable(this);
    }
    async create(params) {
        const container = rootContainer.createChildContainer();
        this.registerProblematic(container);
        await this.registerCommonDependencies(container, params);
        this.registerCartDependencies(container, params);
        this.registerCheckoutDependencies(container);
        return container;
    }
    // NOTICE all interfaces bound here exist only due to circular dependencies
    registerProblematic(container) {
        container.register(IPPromotionParser, { useClass: PPromotionParser });
        container.register(IPromotionSchemeAvailabilityService, {
            useClass: PromotionSchemeAvailabilityService,
        });
        container.register(IPProductInstanceName, {
            useClass: PProductInstanceName,
        });
    }
    async registerCommonDependencies(container, params) {
        var _a, _b, _c;
        container.register(RestaurantId, { useValue: params.restaurantId });
        const timezone = params.menuResponse.time_zone;
        container.register(Timezone, { useValue: timezone });
        const now = RDate.now(container.resolve(Timezone));
        container.register(Now, { useValue: now });
        if (params.dineInLocation !== null) {
            this.qrWaiter.setDineInLocation(params.dineInLocation);
        }
        const { catalogue } = params.menuResponse;
        const schedules = this.schedulesParser.parse(catalogue.availabilitySchedules);
        container.register(Schedules, { useValue: schedules });
        container.register(SchedulesAvailableNow, {
            useValue: schedules.filter((schedule) => schedule.includes(now)).ids,
        });
        container.register(IOrderingHours, { useValue: params.orderingHours });
        container.register(OnlineOrderingSettingsToken, {
            useValue: params.menuResponse.online_ordering_settings,
        });
        container.register(PreorderSettingsConfig, {
            useValue: {
                defaultPreorderSettings: params.menuResponse.online_ordering_settings.defaultPreorderSettings,
                enable_preorder: params.menuResponse.enable_preorder,
            },
        });
        container.register(PackagingContainerTypes, {
            useValue: new PackagingContainerParser({ catalogue }).parse(),
        });
        const activeSections = this.activeSectionsParser.parse(catalogue);
        const productTypeGroups = container.resolve(ProductTypeGroupParser).parse({
            domainScheduleIds: schedules.ids,
            activeSections,
        });
        container.register(Menu, {
            useValue: this.menuParser.parseMenu({
                catalogue: params.menuResponse.catalogue,
                schedules,
                productTypeGroups,
            }),
        });
        container.register(MenuPreorderSettingsProvider, {
            useValue: MenuPreorderSettingsProvider.create({
                productTypeGroups,
                preorderSettingsInstanceFactory: container.resolve(PreorderSettingsInstanceFactory),
            }),
        });
        container.register(Restaurant, {
            useValue: this.domainRestaurantParser.parse({
                restaurantId: params.restaurantId,
                input: params.menuResponse,
                menu: container.resolve(Menu),
                automaticCrossSellingWeights: params.automaticCrossSellingWeights,
                checkoutInfo: params.checkoutInfo,
                paymentChannels: params.paymentChannels,
            }),
        });
        container.register(ICrossSellingListProvider, {
            useValue: container.resolve(CrossSellingListProviderParser).parse({
                catalogue: params.menuResponse.catalogue,
                automaticCrossSellingWeights: params.automaticCrossSellingWeights,
            }),
        });
        const promotionTypes = container
            .resolve(PPromotionParser)
            .parsePromotions(params.menuResponse.promotions);
        container.register(InCartPromotionTypes, {
            useValue: InCartPromotionTypes.create(promotionTypes),
        });
        const restaurantSlug = params.menuResponse.slug;
        const cartStorageManager = this.storageManagerFactory.forCart(restaurantSlug);
        const cartStorageData = cartStorageManager.restore();
        if (cartStorageData !== null &&
            cartStorageData.restaurantId !== container.resolve(RestaurantId).value) {
            console.warn("Cart restaurant id deserialization mismatch");
        }
        // NOTICE It is crucial to restore and await cupon before purchaseOrder, because restored promotional items may reference coupon promotion
        container.register(CouponToken, {
            useValue: await Coupon.create({
                enableCoupons: container.resolve(Restaurant).orderingPolicy.enableCoupons,
                isQrWaiterOrder: this.qrWaiter.isQrWaiterOrder(),
                promotionService: container.resolve(PromotionService),
                inCartPromotionTypes: container.resolve(InCartPromotionTypes),
                couponCode: (_a = cartStorageData === null || cartStorageData === void 0 ? void 0 : cartStorageData.couponCode) !== null && _a !== void 0 ? _a : null,
            }),
        });
        container.register(IOrderingAvailability, {
            useValue: OrderingAvailability.create({
                isQrWaiterOrder: this.qrWaiter.isQrWaiterOrder(),
                orderingHours: container.resolve(IOrderingHours),
                backendOrderingAvailability: params.backendOrderingAvailability,
                timezone: container.resolve(Timezone),
            }),
        });
        const purchaseOrderStorageManager = container.resolve(PurchaseOrderStorageManager);
        container.register(FulfillmentInstructionsToken, {
            useValue: container
                .resolve(FulfillmentInstructionsFactory)
                .create(params.dineInLocation),
        });
        container.register(ApartmentInfoController, {
            useValue: container.resolve(ApartmentInfoControllerFactory).create(),
        });
        container.register(FulfillmentFormController, {
            useValue: container.resolve(FulfillmentFormControllerFactory).create(),
        });
        container.register(PurchaseOrderPayload, {
            useValue: container
                .resolve(PurchaseOrderPayloadFactory)
                .create(cartStorageData),
        });
        container.register(PRestaurant, {
            useValue: container.resolve(PRestaurantParser).parse({
                input: params.menuResponse,
                menuSections: container
                    .resolve(MenuSectionParser)
                    .parse(activeSections),
                promotionTypes,
                franchiseName: params.checkoutInfo.franchiseName,
            }),
        });
        const newFulfillmentTime = container
            .resolve(NewFulfillmentTimeFactory)
            .forWholeMenu();
        container.register(OrderingHoursAvailability, {
            useValue: container
                .resolve(OrderingHoursAvailabilityFactory)
                .create(newFulfillmentTime),
        });
        container.register(PromotionScheme, {
            useValue: container
                .resolve(PromotionSchemeFactory)
                .fromStorageData((_b = cartStorageData === null || cartStorageData === void 0 ? void 0 : cartStorageData.promotionScheme) !== null && _b !== void 0 ? _b : null),
        });
        container.register(Payment, {
            useValue: container.resolve(PaymentFactory).create(),
        });
        container.register(ContactDetailsToken, {
            useValue: container.resolve(ContactDetailsFactory).create(),
        });
        container.register(OrderReceiver, {
            useValue: container.resolve(OrderReceiverFactory).create(),
        });
        container.register(TipInstanceToken, {
            useValue: TipInstance.fromStorageData({
                areTipsEnabled: container.resolve(OnlineOrderingSettingsToken).tips
                    .enabled,
                value: (_c = cartStorageData === null || cartStorageData === void 0 ? void 0 : cartStorageData.tip) !== null && _c !== void 0 ? _c : null,
            }),
        });
        purchaseOrderStorageManager.initialize({
            cartStorageManager,
            previousCartStorageManager: this.storageManagerFactory.forPreviousCart(restaurantSlug),
            storageData: container.resolve(PurchaseOrderStorageData),
        });
        container.register(PFulfillmentTime, {
            useValue: container.resolve(PFulfillmentTimeFactory).create({
                newFulfillmentTime,
                restoreValueOnInit: true,
            }),
        });
        container.register(OrderItemListTracking, {
            useValue: OrderItemListTracking.create({
                orderLinePriceService: container.resolve(OrderLinePriceService),
                purchaseOrderPayload: container.resolve(PurchaseOrderPayload),
                productTypes: container.resolve(PRestaurant).productTypes,
                orderItemListStorageManager: this.storageManagerFactory.forOrderItemList(),
            }),
        });
    }
    registerCartDependencies(container, params) {
        container.register(FirstOrderDiscount, {
            useFactory: perRestaurantFactory((c) => c.resolve(FirstOrderDiscountFactory).create()),
        });
        container.register(PCrossSellingCreator, {
            useValue: container
                .resolve(PCrossSellingCreatorFactory)
                .create(container.resolve(CartCrossSellingCreator)),
        });
        container.register(POrderingAvailability, {
            useFactory: perRestaurantFactory((c) => new POrderingAvailability({
                domain: c.resolve(IOrderingAvailability),
                backendOrderingAvailability: params.backendOrderingAvailability,
            })),
        });
        // TODO Remove with Skubacz.CartHooks
        container.register(MenuResponse, {
            useValue: params.menuResponse,
        });
    }
    registerCheckoutDependencies(container) {
        container.register(ConsentsFormController, {
            useFactory: perRestaurantFactory((c) => new ConsentsFormController({
                consents: c.resolve(OrderReceiver).consents,
            })),
        });
        container.register(InvoiceFormController, {
            useFactory: perRestaurantFactory((c) => new InvoiceFormController({
                orderReceiver: c.resolve(OrderReceiver),
            })),
        });
        container.register(ContactFormControllerToken, {
            useFactory: perRestaurantFactory((c) => ContactFormController.fromContactDetails(c.resolve(ContactDetailsToken))),
        });
    }
};
PerRestaurantContainerFactory = __decorate([
    singleton(),
    __param(0, inject(IQrWaiter)),
    __metadata("design:paramtypes", [typeof (_a = typeof IQrWaiter !== "undefined" && IQrWaiter) === "function" ? _a : Object, typeof (_b = typeof ScheduleParser !== "undefined" && ScheduleParser) === "function" ? _b : Object, typeof (_c = typeof MenuV2Parser !== "undefined" && MenuV2Parser) === "function" ? _c : Object, typeof (_d = typeof ActiveSectionsParser !== "undefined" && ActiveSectionsParser) === "function" ? _d : Object, typeof (_e = typeof RestaurantParser !== "undefined" && RestaurantParser) === "function" ? _e : Object, typeof (_f = typeof StorageManagerFactory !== "undefined" && StorageManagerFactory) === "function" ? _f : Object])
], PerRestaurantContainerFactory);
export { PerRestaurantContainerFactory };
