var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { inject } from "tsyringe";
import { RArray, RSet } from "../collections";
import { GlobalConfig } from "../config";
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { Availability, OrderingHoursAvailability } from "./Availability";
import { ProductInstanceAvailabilityService } from "./AvailabilityService";
import { ProductCardCreatorResult } from "./ProductCardCreatorResult";
let ProductCardCreatorAvailabilityService = class ProductCardCreatorAvailabilityService {
    constructor(globalConfig, orderingHoursAvailability, productInstanceAvailabilityService) {
        this.orderingHoursAvailability = orderingHoursAvailability;
        this.productInstanceAvailabilityService = productInstanceAvailabilityService;
        this.isIosReviewMode = globalConfig.isIosReviewMode;
        makeImmutable(this);
    }
    availability(productCardCreator) {
        // NOTICE Cross selling creator contains only available elements so it cannot influence availability negatively
        return Availability.composite([
            this.productInstanceAvailabilityService.availability(productCardCreator.productInstanceCreator.instance),
            this.orderingHoursAvailability,
        ]);
    }
    hasResult(productCardCreator) {
        const availability = this.availability(productCardCreator);
        // NOTICE: This is a hack to allow to add a time-based unavailable Product to the Cart
        // TODO: add creator.fulfillmentTime.isEditable logic (has product any available fulfillment time to order to)
        if ((availability.isAvailable.isDefinitelyFalse &&
            availability.unavailabilityReasons.eq(RSet.singleton("ProductTypeTimeBased"))) ||
            this.isIosReviewMode) {
            return true;
        }
        if (availability.isAvailable.isDefinitelyFalse) {
            return false;
        }
        return true;
    }
    result(productCardCreator) {
        if (!this.hasResult(productCardCreator)) {
            return null;
        }
        return new ProductCardCreatorResult(RArray.singleton([
            productCardCreator.productInstanceCreator,
            productCardCreator.quantity,
        ]).concatenated(productCardCreator.crossSellingCreator.selectedItems.map(([creator, quantity, _]) => [creator, quantity])));
    }
};
ProductCardCreatorAvailabilityService = __decorate([
    perRestaurant(),
    __param(0, inject(GlobalConfig)),
    __metadata("design:paramtypes", [typeof (_a = typeof GlobalConfig !== "undefined" && GlobalConfig) === "function" ? _a : Object, typeof (_b = typeof OrderingHoursAvailability !== "undefined" && OrderingHoursAvailability) === "function" ? _b : Object, typeof (_c = typeof ProductInstanceAvailabilityService !== "undefined" && ProductInstanceAvailabilityService) === "function" ? _c : Object])
], ProductCardCreatorAvailabilityService);
export { ProductCardCreatorAvailabilityService };
