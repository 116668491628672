import { makeImmutable } from "../core";
import { Availability } from "./Availability";
const VAT_ID_REGEX = /^[a-zA-Z0-9 -]+$/;
export class VatId {
    constructor(value) {
        this.value = value;
        makeImmutable(this);
    }
    get invalid() {
        return !VAT_ID_REGEX.test(this.value);
    }
    get availability() {
        return Availability.boolean({
            VatIdMissing: this.value.length === 0,
            VatIdInvalid: this.invalid,
        });
    }
    toString() {
        return this.value;
    }
}
