import { RArray } from "./RArray";
export function fromPairs(pairs) {
    const result = {};
    for (const [k, v] of pairs) {
        result[k] = v;
    }
    return result;
}
export function toPairs(r) {
    return new RArray(Object.entries(r));
}
export const cartesianProduct = (parameters) => new RArray(parameters.reduce((acc, [param, options]) => options.reduce((res, option) => [
    ...res,
    ...acc.map((item) => new Map([...item, [param, option]])),
], []), [new Map()]));
export function getDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) !== index);
}
export function groupBy(sameGroup, xs) {
    let group = [];
    const result = [];
    for (const x of xs) {
        if (group.length > 0 && !sameGroup(group[0], x)) {
            result.push(group);
            group = [];
        }
        group.push(x);
    }
    if (group.length > 0) {
        result.push(group);
    }
    return result;
}
