import { makeAutoObservable } from "mobx";
import { Price, } from "../ordering";
import { PPrice } from "./PPrice";
import { Percentage, Money } from "../core";
export class PTip {
    constructor(params) {
        this.localizationProvider = params.localizationProvider;
        this.domain = params.domain;
        this.purchaseOrderPriceService = params.purchaseOrderPriceService;
        makeAutoObservable(this);
    }
    static create(params) {
        switch (params.domain.kind) {
            case "Enabled":
                return new PTip({
                    localizationProvider: params.localizationProvider,
                    domain: params.domain,
                    purchaseOrderPriceService: params.purchaseOrderPriceService,
                });
            case "Disabled":
                return null;
        }
    }
    get priceWithoutTip() {
        return this.purchaseOrderPriceService.priceWithoutTip;
    }
    get options() {
        return [5, 10, 15].map((bracket) => {
            var _a, _b;
            const percentage = Percentage.fromNumber(bracket);
            const value = this.priceWithoutTip.percentage(percentage);
            return {
                label: new PPrice(this.localizationProvider, value.spectrum).priceValue,
                numericValue: (_b = (_a = value.finalValue) === null || _a === void 0 ? void 0 : _a.asNumber) !== null && _b !== void 0 ? _b : null,
                description: percentage.toString(),
            };
        });
    }
    get numericValue() {
        return this.domain.numericValue;
    }
    get priceValue() {
        return this.localizationProvider.formatPrice(this.domain.value);
    }
    getOrderPriceWithTip(tipValue) {
        const tipPrice = Price.unscoped(Money.fromNumber(tipValue !== null && tipValue !== void 0 ? tipValue : 0, "monetary"));
        const orderPriceWithTip = tipPrice.finalValue === null || tipPrice.finalValue.leq(Money.zero())
            ? this.priceWithoutTip
            : this.priceWithoutTip.add(tipPrice);
        return new PPrice(this.localizationProvider, orderPriceWithTip.spectrum)
            .priceValue;
    }
    get currencySymbol() {
        return this.localizationProvider.currencySymbol;
    }
    get currencySeparator() {
        return this.localizationProvider.currencySeparator;
    }
    get currencyPrecision() {
        return this.localizationProvider.currencyPrecision;
    }
    onChange(value) {
        this.domain.setValue(Money.fromNumber(value !== null && value !== void 0 ? value : 0, "monetary"));
    }
    onRemove() {
        this.domain.resetValue();
    }
}
