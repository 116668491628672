import { makeImmutable } from "../../core";
import { Availability, ParametrizedAvailability } from "../Availability";
export class ModifierItemTypeAvailabilityService {
    constructor() {
        makeImmutable(this);
    }
    availability(modifierItemType, scope) {
        const { availabilitySpec, pricing } = modifierItemType;
        switch (availabilitySpec.type) {
            case "TemporarilyUnavailable":
                return Availability.unavailable("TemporarilyUnavailable");
            case "HiddenModifierItem":
                return Availability.unavailable("Hidden");
        }
        const parametric = new ParametrizedAvailability({
            unavailabilityReason: "ParametrizedAvailability",
            parametrization: availabilitySpec.availabilityParametrization,
            scope,
        });
        const price = new ParametrizedAvailability({
            unavailabilityReason: "PriceAvailability",
            parametrization: pricing.map(() => true),
            scope,
        });
        return Availability.composite([parametric, price]);
    }
}
