import { makeAutoObservable, runInAction } from "mobx";
import { fromResponseState } from "./POrderSummaryState";
import { fromResponseLoyalty, } from "./POrderSummaryLoyalty";
import { POrderInfo } from "./POrderInfo";
import { PurchaseOrderTracking } from "../../tracking";
const POLLING_INTERVAL = 15000;
export class POrderSummary {
    constructor(params) {
        this.localizationProvider = params.localizationProvider;
        this.orderSummaryService = params.orderSummaryService;
        this.cartStorageManager = params.cartStorageManager;
        this.previousCartStorageManager = params.previousCartStorageManager;
        this.orderItemListStorageManager = params.orderItemListStorageManager;
        this.fulfillmentTimeStorageManager = params.fulfillmentTimeStorageManager;
        this.redirectToRestaurant = params.redirectToRestaurant;
        this.orderUuid = params.orderUuid;
        this.isPollingActive = false;
        this.timeout = null;
        this._state = { type: "Loading", isFinal: false };
        this._userLoyalty = { type: "Hidden", showModal: false };
        this._orderInfo = null;
        makeAutoObservable(this);
    }
    setState(orderSummaryResponse) {
        // Assert we are not leaving final state
        if (this._state.isFinal) {
            throw new Error(`${this._state.type} is final state, refusing to transition`);
        }
        if (orderSummaryResponse === null) {
            this._state = { type: "Error", isFinal: true };
        }
        else {
            this._state = fromResponseState({
                orderSummaryResponse,
                retry: () => {
                    const previousCart = this.previousCartStorageManager.restore();
                    if (previousCart !== null) {
                        this.cartStorageManager.store(previousCart);
                    }
                    this.redirectToRestaurant();
                },
            });
        }
        if (this._state.isFinal) {
            this.stopPolling();
        }
    }
    get state() {
        return this._state;
    }
    setOrderInfo(orderInfo) {
        const localizationProvider = this.localizationProvider;
        this._orderInfo = new POrderInfo({ localizationProvider, orderInfo });
    }
    get orderInfo() {
        return this._orderInfo;
    }
    setUserLoyalty(state, userLoyalty) {
        this._userLoyalty = fromResponseLoyalty(state, userLoyalty);
    }
    get userLoyalty() {
        return this._userLoyalty;
    }
    initialize() {
        // Move current cart to the previous cart for "retry" action
        const cart = this.cartStorageManager.restore();
        if (cart !== null) {
            this.previousCartStorageManager.store(cart);
        }
        // Clear order-specific data
        this.cartStorageManager.erase();
        this.orderItemListStorageManager.erase();
        this.fulfillmentTimeStorageManager.erase();
        // Set init state and start fetching data
        this._state = { type: "Loading", isFinal: false };
        this.startPolling();
    }
    dispose() {
        this.stopPolling();
    }
    startPolling() {
        if (this.isPollingActive === true) {
            throw new Error("Double polling initialization error");
        }
        this.isPollingActive = true;
        this.fetchOrderSummary();
    }
    fetchOrderSummary() {
        this.orderSummaryService
            .getOrderSummary(this.orderUuid)
            .then((response) => {
            runInAction(() => {
                this.setState(response);
            });
            if (response !== null) {
                runInAction(() => {
                    this.setOrderInfo(response.orderInfo);
                    this.setUserLoyalty(response.state, response.userLoyalty);
                });
                PurchaseOrderTracking.trackPurchase(this.orderUuid, response.orderInfo, response.siteName);
            }
        })
            .catch(() => {
            this.setState(null);
        })
            .finally(() => {
            if (this.isPollingActive)
                this.fetchOrderSummaryWithTimeout();
        });
    }
    fetchOrderSummaryWithTimeout() {
        this.timeout = setTimeout(() => this.fetchOrderSummary(), POLLING_INTERVAL);
    }
    stopPolling() {
        this.isPollingActive = false;
        this.clearTimeout();
    }
    clearTimeout() {
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }
}
