var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { makeAutoObservable } from "mobx";
import { perRestaurant } from "../../di";
import { Payment } from "../Payment";
import { FulfillmentInstructions, FulfillmentInstructionsToken, } from "../FulfillmentInstructions";
import { inject } from "tsyringe";
import { Availability } from "../Availability";
import { StreetAddressAvailabilityService } from "./StreetAddressAvailabilityService";
let PaymentAvailabilityService = class PaymentAvailabilityService {
    constructor(payment, fulfillmentInstructions, streetAddressAvailabilityService) {
        this.payment = payment;
        this.fulfillmentInstructions = fulfillmentInstructions;
        this.streetAddressAvailabilityService = streetAddressAvailabilityService;
        makeAutoObservable(this);
    }
    get paymentAddressAvailability() {
        const { isFullAddressRequired, postCode, streetAddress } = this.payment.address;
        return Availability.composite([
            isFullAddressRequired
                ? this.streetAddressAvailabilityService.availability(streetAddress)
                : Availability.available(),
            postCode.availability,
        ]);
    }
    get availability() {
        const { medium, isAddressRequired } = this.payment;
        const { fulfillmentMethodType } = this.fulfillmentInstructions;
        return Availability.composite([
            Availability.boolean({
                PaymentMediumMissing: medium === null,
                OnlinePaymentMethodRequired: fulfillmentMethodType !== null &&
                    fulfillmentMethodType === "NoContactDelivery" &&
                    medium !== null &&
                    medium.type === "method",
            }),
            isAddressRequired
                ? this.paymentAddressAvailability
                : Availability.available(),
        ]);
    }
};
PaymentAvailabilityService = __decorate([
    perRestaurant(),
    __param(1, inject(FulfillmentInstructionsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof Payment !== "undefined" && Payment) === "function" ? _a : Object, typeof (_b = typeof FulfillmentInstructions !== "undefined" && FulfillmentInstructions) === "function" ? _b : Object, typeof (_c = typeof StreetAddressAvailabilityService !== "undefined" && StreetAddressAvailabilityService) === "function" ? _c : Object])
], PaymentAvailabilityService);
export { PaymentAvailabilityService };
