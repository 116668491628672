var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { IdentifiableMap, RArray, toPairs } from "../../collections";
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { CustomParameterInstanceFactory } from "./CustomParameterInstanceFactory";
import { ModifierInstanceFactory } from "./ModifierInstanceFactory";
import { ProductConfig } from "./ProductConfig";
let ProductConfigFactory = class ProductConfigFactory {
    constructor(modifierInstanceFactory, customParameterInstanceFactory) {
        this.modifierInstanceFactory = modifierInstanceFactory;
        this.customParameterInstanceFactory = customParameterInstanceFactory;
        makeImmutable(this);
    }
    fromStorageData({ modifierSettingsProvider, storageData, }) {
        const modifierInstances = new RArray(toPairs(storageData.modifierInstances)).mapOptional(([modifierTypeId, modifierInstanceStorageData]) => this.modifierInstanceFactory.fromStorageData({
            modifierTypeId,
            modifierSettingsProvider,
            storageData: modifierInstanceStorageData,
        }));
        const customParameterInstances = new RArray(toPairs(storageData.customParameterInstances)).mapOptional(([customParameterTypeId, customParameterStorageData]) => this.customParameterInstanceFactory.fromStorageData({
            customParameterTypeId,
            storageData: customParameterStorageData,
        }));
        return new ProductConfig({
            modifierInstances: IdentifiableMap.fromIterable("typeId", modifierInstances),
            customParameterInstances: IdentifiableMap.fromIterable("typeId", customParameterInstances),
        });
    }
};
ProductConfigFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ModifierInstanceFactory !== "undefined" && ModifierInstanceFactory) === "function" ? _a : Object, typeof (_b = typeof CustomParameterInstanceFactory !== "undefined" && CustomParameterInstanceFactory) === "function" ? _b : Object])
], ProductConfigFactory);
export { ProductConfigFactory };
