var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { PModifierItemInstanceCreatorFactory } from "./PModifierItemInstanceCreatorFactory";
import { PModifierSectionCreator } from "./PModifierSectionCreator";
let PModifierSectionCreatorFactory = class PModifierSectionCreatorFactory {
    constructor(pModifierItemInstanceCreatorFactory) {
        this.pModifierItemInstanceCreatorFactory = pModifierItemInstanceCreatorFactory;
        makeImmutable(this);
    }
    create({ domain, modifierItemSection, }) {
        const modifierItemInstanceCreators = modifierItemSection.items.map((item) => this.pModifierItemInstanceCreatorFactory.create({
            modifierItemType: item,
            domain: domain.itemCreators.get(item.id),
            behaviour: domain.behaviour,
        }));
        return new PModifierSectionCreator({
            modifierItemSection,
            modifierItemInstanceCreators,
        });
    }
};
PModifierSectionCreatorFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PModifierItemInstanceCreatorFactory !== "undefined" && PModifierItemInstanceCreatorFactory) === "function" ? _a : Object])
], PModifierSectionCreatorFactory);
export { PModifierSectionCreatorFactory };
