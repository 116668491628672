var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { Payment } from "../Payment";
import { GlobalConfig } from "../../config";
import { inject } from "tsyringe";
import { ApartmentInfoCheckoutDataService } from "./ApartmentInfoCheckoutDataService";
let PaymentCheckoutDataService = class PaymentCheckoutDataService {
    constructor(globalConfig, apartmentInfoCheckoutDataService, payment) {
        this.apartmentInfoCheckoutDataService = apartmentInfoCheckoutDataService;
        this.payment = payment;
        this.isIosApp = globalConfig.mobileAppType === "IosApp";
        makeImmutable(this);
    }
    checkoutData(applePayToken) {
        const { medium, isAddressRequired } = this.payment;
        if (medium === null || medium.type !== "channel") {
            return null;
        }
        return {
            applePayToken: applePayToken === null ? null : applePayToken.token,
            channel: medium.channelId,
            address: isAddressRequired ? this.paymentAddressCheckoutData : null,
            blikCode: null,
            cardToken: null,
            isIosApp: this.isIosApp,
        };
    }
    get paymentAddressCheckoutData() {
        const { address } = this.payment;
        const postCode = address.postCode.value;
        // TODO Simplify maybe?
        return !address.isFullAddressRequired &&
            address.fulfillmentInstructions.isDelivery // Checking isDelivery again (it is in isFullAddressRequired already) only for linter
            ? {
                ...address.fulfillmentInstructions.deliveryAddress.streetAddress.data,
                ...this.apartmentInfoCheckoutDataService.checkoutData(address.fulfillmentInstructions.deliveryAddress.apartmentInfo),
                postCode,
            }
            : {
                ...address.streetAddress.data,
                ...this.apartmentInfoCheckoutDataService.checkoutData(address.apartmentInfo),
                postCode,
            };
    }
};
PaymentCheckoutDataService = __decorate([
    perRestaurant(),
    __param(0, inject(GlobalConfig)),
    __metadata("design:paramtypes", [typeof (_a = typeof GlobalConfig !== "undefined" && GlobalConfig) === "function" ? _a : Object, typeof (_b = typeof ApartmentInfoCheckoutDataService !== "undefined" && ApartmentInfoCheckoutDataService) === "function" ? _b : Object, typeof (_c = typeof Payment !== "undefined" && Payment) === "function" ? _c : Object])
], PaymentCheckoutDataService);
export { PaymentCheckoutDataService };
