import { makeImmutable } from "../core";
export class LocalizedString {
    constructor(localizationProvider, value) {
        this.localizationProvider = localizationProvider;
        this._value = value;
        makeImmutable(this);
    }
    get value() {
        return this.localizationProvider.localize(this._value);
    }
}
