import { RSet } from "../../../collections";
import { makeImmutable } from "../../../core";
export class InternalParameterMultiSet {
    constructor(params) {
        this.fulfillmentMethod = params.fulfillmentMethod;
        this.splitToHalves = params.splitToHalves;
        makeImmutable(this);
    }
    get isEmpty() {
        return this.fulfillmentMethod.isEmpty && this.splitToHalves.isEmpty;
    }
    static empty() {
        return new InternalParameterMultiSet({
            fulfillmentMethod: RSet.empty(),
            splitToHalves: RSet.empty(),
        });
    }
    union(other) {
        return new InternalParameterMultiSet({
            fulfillmentMethod: this.fulfillmentMethod.union(other.fulfillmentMethod),
            splitToHalves: this.splitToHalves.union(other.splitToHalves),
        });
    }
    intersection(other) {
        return new InternalParameterMultiSet({
            fulfillmentMethod: this.fulfillmentMethod.intersection(other.fulfillmentMethod),
            splitToHalves: this.splitToHalves.intersection(other.splitToHalves),
        });
    }
    toString() {
        return `[fulfillmentMethod: [${this.fulfillmentMethod.toString()}], splitToHalves: [${this.splitToHalves.toString()}]]`;
    }
}
