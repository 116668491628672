import { ASAPFulfillmentTime } from "./ASAPFulfillmentTime";
import { OnTimeFulfillmentTime } from "./OnTimeFulfillmentTime";
export const FulfillmentTime = {
    fromStorageData(params) {
        switch (params.storageData.type) {
            case "ASAP":
                return ASAPFulfillmentTime.fromStorageData(params.storageData);
            case "OnTime":
                return OnTimeFulfillmentTime.fromStorageData({
                    now: params.now,
                    storageData: params.storageData,
                });
        }
    },
};
