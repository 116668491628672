import { makeAutoObservable } from "mobx";
export class PaymentAddress {
    constructor(params) {
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.streetAddress = params.streetAddress;
        this.apartmentInfo = params.apartmentInfo;
        this.postCode = params.postCode;
        this.isDifferentThanDeliveryAddress = false;
        makeAutoObservable(this);
    }
    get canBeDifferentThanDeliveryAddress() {
        return this.fulfillmentInstructions.isDelivery;
    }
    get isFullAddressRequired() {
        return (!this.canBeDifferentThanDeliveryAddress ||
            this.isDifferentThanDeliveryAddress);
    }
}
