import { makeImmutable } from "../../core";
export class EveryOrderSelector {
    constructor() {
        this.type = "EveryOrder";
        makeImmutable(this);
    }
    test(target) {
        // NOTICE Dummy condition just to register correct MobX dependency
        return target.price !== undefined;
    }
}
