import { DisabledCoupon } from "./DisabledCoupon";
import { EnabledCoupon } from "./EnabledCoupon";
export const Coupon = {
    async create(params) {
        if (!params.enableCoupons || params.isQrWaiterOrder) {
            return new DisabledCoupon();
        }
        return await EnabledCoupon.fromStorageData({
            promotionService: params.promotionService,
            inCartPromotionTypes: params.inCartPromotionTypes,
            couponCode: params.couponCode,
        });
    },
};
export const CouponToken = "Coupon";
