export const PAY_PO_CHANNEL_ID = 227;
export const P24_CHANNEL_TAG = "P24Channel";
export var P24;
(function (P24) {
    P24[P24["BLIK"] = 154] = "BLIK";
    P24[P24["CARDS"] = 241] = "CARDS";
    P24[P24["PAYPO"] = PAY_PO_CHANNEL_ID] = "PAYPO";
    P24[P24["GOOGLE_PAY"] = 264] = "GOOGLE_PAY";
    P24[P24["APPLE_PAY_PL"] = 252] = "APPLE_PAY_PL";
    P24[P24["APPLE_PAY"] = 253] = "APPLE_PAY";
})(P24 || (P24 = {}));
export function isAppleChannelId(id) {
    switch (id.tag) {
        case "P24Channel": {
            const contents = id.contents;
            return contents === P24.APPLE_PAY_PL || contents === P24.APPLE_PAY;
        }
        case "StripeChannel":
            return false;
    }
}
