import Fraction from "fraction.js";
import { makeImmutable } from "../core";
/**
 * How much physical space an item takes up.
 */
export class Volume {
    constructor(value) {
        this.value = value;
        makeImmutable(this);
    }
    static empty() {
        return new Volume(new Fraction(0));
    }
    static total(volumes) {
        return [...volumes].reduce((a, b) => a.add(b), Volume.empty());
    }
    compare(other) {
        return this.value.compare(other.value);
    }
    eq(other) {
        return this.compare(other) === 0;
    }
    /**
     * Multiply by another volume
     */
    multiply(quantity) {
        return new Volume(this.value.mul(quantity.value));
    }
    /**
     * Add another volume
     */
    add(other) {
        return new Volume(this.value.add(other.value));
    }
    /**
     * Subtract another volume
     */
    sub(other) {
        return new Volume(this.value.sub(other.value));
    }
    /**
     * Divide by another volume
     */
    div(other) {
        return this.value.div(other.value);
    }
    /**
     * Get the largest of two volumes
     */
    max(other) {
        if (this.compare(other) >= 0) {
            return this;
        }
        return other;
    }
}
