export var Sentry;
(function (Sentry_1) {
    function captureException(error, options = {}) {
        const Sentry = window.Sentry;
        if (Sentry) {
            Sentry.captureException(error, {
                tags: {
                    ...options.tags,
                    "client.version": "new_restaumatic_client",
                },
                level: options.level,
                extra: options.extra,
            });
        }
    }
    Sentry_1.captureException = captureException;
    function logError(error, options = {}) {
        captureException(error, { ...options, level: "log" });
    }
    Sentry_1.logError = logError;
})(Sentry || (Sentry = {}));
