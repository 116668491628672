import { makeAutoObservable, reaction, runInAction } from "mobx";
import { fromPairs, IdMap } from "../collections";
import { ParameterScope } from "./ParameterScope";
import { PromotionInstance } from "./PromotionInstance";
import { PromotionSolver, } from "./PromotionSolver";
export class PromotionScheme {
    constructor(params) {
        this.promotionSolverVariableAssignmentFactory =
            params.promotionSolverVariableAssignmentFactory;
        this._promotionInstances = params.promotionInstances;
        this.scope = ParameterScope.Uninitialized;
        this.recomputeExclusions();
        makeAutoObservable(this);
        this.startSolver();
    }
    startSolver() {
        // NOTICE We want solver to be active during the whole session, that's why this reaction is not disposed anywhere
        reaction(() => this.promotionSolverVariableAssignmentFactory.assignment, (variableAssignment) => {
            console.log("Solver invoked because cart has changed");
            const solver = new PromotionSolver(variableAssignment);
            this.setPromotionInstances(solver.promotionInstances);
        }, {
            equals: (a, b) => a.eq(b),
            fireImmediately: this._promotionInstances.isEmpty,
        });
    }
    setPromotionInstances(promotionInstances) {
        runInAction(() => {
            this._promotionInstances = promotionInstances;
            this.recomputeExclusions();
            this._promotionInstances.objects.forEach((promotionInstance) => promotionInstance.setScope(this.scope));
        });
    }
    get promotionInstances() {
        return this._promotionInstances;
    }
    setScope(scope) {
        this.scope = scope;
        this._promotionInstances.objects.forEach((promotionInstance) => promotionInstance.setScope(this.scope));
    }
    clear() {
        this._promotionInstances = IdMap.empty();
    }
    recomputeExclusions() {
        this._promotionInstances.objects.forEach((promotionInstanceA) => {
            const excludedInstances = this._promotionInstances.filter((promotionInstanceB) => PromotionInstance.excludes(promotionInstanceA, promotionInstanceB));
            promotionInstanceA.setExcludedInstances(excludedInstances);
        });
    }
    disableMarketingPromotionInstances() {
        this._promotionInstances.objects.forEach((promotionInstance) => {
            if (promotionInstance.promotionType.requiresMarketing) {
                promotionInstance.setActive(false);
            }
        });
    }
    get storageData() {
        return fromPairs(this._promotionInstances.objects.map((promotionInstance) => [
            promotionInstance.id.value,
            promotionInstance.storageData,
        ]));
    }
}
