var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { makeAutoObservable } from "mobx";
import { PurchaseOrderPayload } from "../PurchaseOrderPayload";
import { perRestaurant } from "../../di";
import { ProductInstancePreorderSettingsService } from "./ProductInstancePreorderSettingsService";
import { MenuPreorderSettingsProvider } from "../MenuPreorderSettingsProvider";
import { PreorderSettingsInstance } from "../PreorderSettingsInstance";
let PurchaseOrderPayloadPreorderSettingsService = class PurchaseOrderPayloadPreorderSettingsService {
    constructor(menuPreorderSettingsProvider, payload, productInstancePreorderSettingsService) {
        this.payload = payload;
        this.productInstancePreorderSettingsService = productInstancePreorderSettingsService;
        this.preorderSettingsWhenEmpty =
            menuPreorderSettingsProvider.preorderSettings;
        makeAutoObservable(this);
    }
    get preorderSettings() {
        if (this.payload.isEmpty) {
            return this.preorderSettingsWhenEmpty;
        }
        const settings = this.payload.orderLines.map((orderLine) => this.productInstancePreorderSettingsService.preorderSettings(orderLine.productInstance));
        return PreorderSettingsInstance.union(settings);
    }
};
PurchaseOrderPayloadPreorderSettingsService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof MenuPreorderSettingsProvider !== "undefined" && MenuPreorderSettingsProvider) === "function" ? _a : Object, typeof (_b = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _b : Object, typeof (_c = typeof ProductInstancePreorderSettingsService !== "undefined" && ProductInstancePreorderSettingsService) === "function" ? _c : Object])
], PurchaseOrderPayloadPreorderSettingsService);
export { PurchaseOrderPayloadPreorderSettingsService };
