var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { inject } from "tsyringe";
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { FulfillmentInstructions, FulfillmentInstructionsToken, } from "../../ordering";
import { PPromotionScheme } from "../PPromotionScheme";
import { PRestaurant } from "../PRestaurant";
import { POrderDetails } from "./POrderDetails";
let POrderDetailsProvider = class POrderDetailsProvider {
    constructor(restaurant, fulfillmentInstructions, promotionScheme) {
        this.orderDetails = POrderDetails.create({
            restaurant,
            fulfillmentInstructions,
            promotionScheme,
        });
        makeImmutable(this);
    }
};
POrderDetailsProvider = __decorate([
    perRestaurant(),
    __param(1, inject(FulfillmentInstructionsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _a : Object, typeof (_b = typeof FulfillmentInstructions !== "undefined" && FulfillmentInstructions) === "function" ? _b : Object, typeof (_c = typeof PPromotionScheme !== "undefined" && PPromotionScheme) === "function" ? _c : Object])
], POrderDetailsProvider);
export { POrderDetailsProvider };
