import { AutomaticCrossSellingListProvider, } from "./AutomaticCrossSellingListProvider";
import { ManualCrossSellingListProvider } from "./ManualCrossSellingListProvider";
import { NullCrossSellingListProvider } from "./NullCrossSellingListProvider";
import { SequentialCrossSellingListProvider } from "./SequentialCrossSellingListProvider";
export const CrossSellingListProvider = {
    create(params) {
        const { manualCrossSelling, automaticCrossSelling } = params.onlineOrderingSettings;
        const manual = manualCrossSelling.enabled &&
            manualCrossSelling.visibleIn.includes(params.crossSellingConsumer) &&
            params.crossSellingListRepository.isAnyCrossSellingListUsed
            ? new ManualCrossSellingListProvider(params.crossSellingListRepository)
            : null;
        const automatic = automaticCrossSelling.enabled &&
            automaticCrossSelling.visibleIn.includes(params.crossSellingConsumer)
            ? AutomaticCrossSellingListProvider.create({
                allowedProductCategories: params.allowedAutomaticProductCategories,
                menuProductTypes: params.menuProductTypes,
                weights: params.automaticWeights,
            })
            : null;
        if (manual !== null && automatic !== null) {
            console.log("Using sequential cross-selling list provider");
            return new SequentialCrossSellingListProvider([manual, automatic]);
        }
        if (manual !== null) {
            console.log("Using manual cross-selling list provider");
            return manual;
        }
        if (automatic !== null) {
            console.log("Using automatic cross-selling list provider");
            return automatic;
        }
        console.log("Cross-selling is disabled");
        return new NullCrossSellingListProvider();
    },
};
