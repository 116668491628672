import { makeImmutable } from "../core";
export class PCustomParameterChoice {
    constructor(params) {
        this.domain = params.domain;
        this._name = params.name;
        makeImmutable(this);
    }
    get id() {
        return this.domain.id;
    }
    get name() {
        return this._name.value;
    }
}
