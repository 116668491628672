import { makeAutoObservable } from "mobx";
import { ASAPFulfillmentTime } from "../FulfillmentTime";
/**
 * How and where should the order be fulfilled for QrWaiter order.
 */
export class QrWaiterFulfillmentInstructions {
    constructor(params) {
        this.type = "QrWaiter";
        this.isDelivery = false;
        /**
         * A way in which the order will be physically fulfilled - DineIn
         */
        this.fulfillmentMethodType = "DineIn";
        /**
         * When the order will be fulfilled - ASAP
         */
        this.fulfillmentTime = ASAPFulfillmentTime.empty();
        this.dineInLocation = params.dineInLocation;
        makeAutoObservable(this);
    }
    get fulfillmentMethod() {
        return this.fulfillmentMethodType;
    }
}
