var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { RestaurantId } from "../core";
import { perRestaurant } from "../di";
import { ContactDetails, ContactDetailsToken, } from "./ContactDetails";
import { FulfillmentInstructions, FulfillmentInstructionsToken, } from "./FulfillmentInstructions";
import { InCartPromotionTypes } from "./InCartPromotionTypes";
import { OrderReceiver } from "./OrderReceiver";
import { Payment } from "./Payment";
import { PromotionScheme } from "./PromotionScheme";
import { PurchaseOrderPayload } from "./PurchaseOrderPayload";
import { TipInstance, TipInstanceToken } from "./TipInstance";
let PurchaseOrderStorageData = class PurchaseOrderStorageData {
    constructor(restaurantId, inCartPromotionTypes, purchaseOrderPayload, promotionScheme, fulfillmentInstructions, payment, orderReceiver, tip, contactDetails) {
        this.restaurantId = restaurantId;
        this.inCartPromotionTypes = inCartPromotionTypes;
        this.purchaseOrderPayload = purchaseOrderPayload;
        this.promotionScheme = promotionScheme;
        this.fulfillmentInstructions = fulfillmentInstructions;
        this.payment = payment;
        this.orderReceiver = orderReceiver;
        this.tip = tip;
        this.contactDetails = contactDetails;
        makeAutoObservable(this);
    }
    get cartStorageData() {
        return {
            restaurantId: this.restaurantId.value,
            couponCode: this.inCartPromotionTypes.couponCode,
            purchaseOrderPayload: this.purchaseOrderPayload.storageData,
            promotionScheme: this.promotionScheme.storageData,
            tip: this.tip.storageData,
        };
    }
    get fulfillmentTimeStorageData() {
        const { fulfillmentTime } = this.fulfillmentInstructions;
        if (fulfillmentTime === null) {
            return null;
        }
        return fulfillmentTime.storageData;
    }
    get streetAddressStorageData() {
        if (!this.fulfillmentInstructions.isDelivery) {
            return null;
        }
        return this.fulfillmentInstructions.deliveryAddress.streetAddress
            .storageData;
    }
    get apartmentInfoStorageData() {
        if (!this.fulfillmentInstructions.isDelivery) {
            return null;
        }
        return this.fulfillmentInstructions.deliveryAddress.apartmentInfo
            .storageData;
    }
    get contactDetailsStorageData() {
        return this.contactDetails.storageData;
    }
    get invoiceDetailsStorageData() {
        return this.orderReceiver.invoiceRequestStorageData;
    }
    get consentsStorageData() {
        return this.orderReceiver.consents.storageData;
    }
    get paymentMediumStorageData() {
        return this.payment.storageData;
    }
    get fulfillmentMethodType() {
        return this.fulfillmentInstructions.fulfillmentMethodType;
    }
};
PurchaseOrderStorageData = __decorate([
    perRestaurant(),
    __param(4, inject(FulfillmentInstructionsToken)),
    __param(7, inject(TipInstanceToken)),
    __param(8, inject(ContactDetailsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof RestaurantId !== "undefined" && RestaurantId) === "function" ? _a : Object, typeof (_b = typeof InCartPromotionTypes !== "undefined" && InCartPromotionTypes) === "function" ? _b : Object, typeof (_c = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _c : Object, typeof (_d = typeof PromotionScheme !== "undefined" && PromotionScheme) === "function" ? _d : Object, typeof (_e = typeof FulfillmentInstructions !== "undefined" && FulfillmentInstructions) === "function" ? _e : Object, typeof (_f = typeof Payment !== "undefined" && Payment) === "function" ? _f : Object, typeof (_g = typeof OrderReceiver !== "undefined" && OrderReceiver) === "function" ? _g : Object, typeof (_h = typeof TipInstance !== "undefined" && TipInstance) === "function" ? _h : Object, typeof (_j = typeof ContactDetails !== "undefined" && ContactDetails) === "function" ? _j : Object])
], PurchaseOrderStorageData);
export { PurchaseOrderStorageData };
