var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { IdMap, RArray } from "../collections";
import { CustomParameterChoice, CustomParameterChoiceId, CustomParameterType, CustomParameterTypeId, } from "../ordering";
import { singleton } from "tsyringe";
let CustomParameterTypeParser = class CustomParameterTypeParser {
    parse(id, customParameter) {
        const choices = IdMap.fromIterable(new RArray(customParameter.choices).mapOptional(([customParameterChoiceId, parameterChoice]) => this.parseCustomParameterChoice(customParameterChoiceId, parameterChoice)));
        return new CustomParameterType({
            id: new CustomParameterTypeId(id),
            isDivisible: customParameter.divisible,
            choices,
            defaultChoice: this.defaultChoice(customParameter.defaultChoice, choices),
        });
    }
    defaultChoice(defaultChoice, choices) {
        const availableChoices = choices.filter((choice) => choice.availability === "Available").ids;
        if (defaultChoice !== null) {
            const choice = availableChoices.rawFind(defaultChoice);
            if (choice !== null) {
                return choice;
            }
        }
        if (availableChoices.size === 1) {
            return availableChoices.sample;
        }
        return null;
    }
    parseCustomParameterChoice(customParameterChoiceId, parameterChoice) {
        if (parameterChoice.availability === "Hidden") {
            return null;
        }
        return new CustomParameterChoice({
            id: new CustomParameterChoiceId(customParameterChoiceId),
            availability: parameterChoice.availability,
        });
    }
};
CustomParameterTypeParser = __decorate([
    singleton()
], CustomParameterTypeParser);
export { CustomParameterTypeParser };
