import { makeAutoObservable } from "mobx";
import { Quantity } from "../../core";
import { ParameterScope } from "../ParameterScope";
import { ModifierItemInstance } from "../ProductInstance";
export class ModifierItemInstanceCreator {
    constructor(params) {
        this.modifierItemInstanceAvailabilityService =
            params.modifierItemInstanceAvailabilityService;
        this._scope = ParameterScope.Uninitialized;
        this.itemQuantities = params.itemQuantities;
        this.modifierType = params.modifierType;
        this.modifierSettings = params.modifierSettings;
        this.modifierItemType = params.modifierItemType;
        this.maxDuplicatesPerItem = params.maxDuplicatesPerItem;
        this.defaultQuantity = params.modifierSettings.defaultQuantity(this.modifierItemType.id);
        this.isMultipleChoice = params.isMultipleChoice;
        this.editability = params.editability;
        makeAutoObservable(this);
    }
    get scope() {
        return this._scope;
    }
    setScope(scope) {
        this._scope = scope;
    }
    get typeId() {
        return this.modifierItemType.id;
    }
    get isIrremovableIngredient() {
        return this.modifierItemType.irremovable && this.isDefault;
    }
    get isPossiblyAvailable() {
        const availability = this.modifierItemInstanceAvailabilityService.unitAvailability(this);
        return availability.isAvailable.isPossible;
    }
    get quantity() {
        return this.isPossiblyAvailable
            ? this.itemQuantities.quantity(this.typeId)
            : Quantity.Zero;
    }
    get isEditable() {
        return this.editability.isPossiblyEditable && this.isPossiblyAvailable;
    }
    get isDefault() {
        return this.defaultQuantity.value > 0;
    }
    get canIncrement() {
        if (!this.isEditable) {
            return false;
        }
        if (this.maxDuplicatesPerItem !== null &&
            this.quantity.geq(this.maxDuplicatesPerItem)) {
            return false;
        }
        return true;
    }
    increment() {
        if (this.canIncrement) {
            this.itemQuantities.increment(this.typeId);
        }
    }
    get canDecrement() {
        if (!this.isEditable) {
            return false;
        }
        if (this.modifierItemType.irremovable &&
            this.quantity.leq(this.defaultQuantity)) {
            return false;
        }
        return this.quantity.value > 0;
    }
    decrement() {
        if (this.canDecrement) {
            this.itemQuantities.decrement(this.typeId);
        }
    }
    get isSelected() {
        return this.quantity.value > 0;
    }
    setSelected(selected) {
        if (!this.isEditable) {
            throw new Error(`ModifierItemInstance not editable`);
        }
        if (this.isIrremovableIngredient && !selected) {
            return;
        }
        this.itemQuantities.setQuantity(this.typeId, selected ? Quantity.One : Quantity.Zero);
    }
    select() {
        if (!this.isEditable) {
            throw new Error(`ModifierItemInstance not editable`);
        }
        this.itemQuantities.clear();
        this.itemQuantities.setQuantity(this.typeId, Quantity.One);
    }
    get modifierItemInstance() {
        return new ModifierItemInstance({
            scope: this.scope,
            modifierType: this.modifierType,
            modifierSettings: this.modifierSettings,
            modifierItemType: this.modifierItemType,
            quantity: this.quantity,
        });
    }
    clone(params) {
        return new ModifierItemInstanceCreator({
            modifierItemInstanceAvailabilityService: this.modifierItemInstanceAvailabilityService,
            itemQuantities: params.itemQuantities,
            modifierType: this.modifierType,
            modifierItemType: this.modifierItemType,
            maxDuplicatesPerItem: this.maxDuplicatesPerItem,
            modifierSettings: this.modifierSettings,
            isMultipleChoice: this.isMultipleChoice,
            editability: params.editability,
        });
    }
}
