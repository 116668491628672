var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
import { inject } from "tsyringe";
import { IdMap, RArray } from "../../collections";
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { RDay, Schedules, SectionVisibilityService, } from "../../ordering";
import { TranslatedParser } from "../../parsing";
import { PMenuSection } from "../PMenuSection";
import { PreorderInfo } from "../PreorderInfo";
import { PMenuSectionItemParser } from "./PMenuSectionItemParser";
let PMenuSectionParser = class PMenuSectionParser {
    constructor(domainSchedules, translatedParser, pMenuSectionItemParser, sectionVisibilityService) {
        this.translatedParser = translatedParser;
        this.pMenuSectionItemParser = pMenuSectionItemParser;
        this.sectionVisibilityService = sectionVisibilityService;
        this.domainScheduleIds = domainSchedules.ids;
        makeImmutable(this);
    }
    parse(params) {
        var _a;
        const { id, section, domainMenuSections } = params;
        const domainSection = domainMenuSections.rawFind(id);
        if (!domainSection) {
            return null;
        }
        return new PMenuSection({
            domain: domainSection,
            name: this.translatedParser.parse(section.name),
            description: this.translatedParser.parse(section.description),
            // TODO: do we want to process it here (resize etc.)?
            photoUrn: section.photo,
            items: this.parseSectionItems({
                productTypes: params.productTypes,
                customParameterTypes: params.customParameterTypes,
                domainSection,
                section,
            }),
            visibilityScheduleId: section.visibilitySchedule === null
                ? null
                : this.domainScheduleIds.rawFind(section.visibilitySchedule),
            sectionVisibilityService: this.sectionVisibilityService,
            preorderInfo: ((_a = section.preorderSettings) === null || _a === void 0 ? void 0 : _a.tag) === "AbsoluteDate"
                ? new PreorderInfo({
                    from: RDay.fromUTCString(section.preorderSettings.from),
                    to: RDay.fromUTCString(section.preorderSettings.to),
                })
                : null,
        });
    }
    parseSectionItems(params) {
        const { domainSection, section } = params;
        if (section.content.tag !== "Products") {
            return IdMap.empty();
        }
        const sectionItems = new RArray(section.content.items).mapOptional(([itemId, item]) => this.pMenuSectionItemParser.parse({
            productTypes: params.productTypes,
            customParameterTypes: params.customParameterTypes,
            domainSection,
            section,
            id: itemId,
            item,
        }));
        return IdMap.fromIterable(sectionItems);
    }
};
PMenuSectionParser = __decorate([
    perRestaurant(),
    __param(0, inject(Schedules)),
    __metadata("design:paramtypes", [typeof (_a = typeof IdMap !== "undefined" && IdMap) === "function" ? _a : Object, typeof (_b = typeof TranslatedParser !== "undefined" && TranslatedParser) === "function" ? _b : Object, typeof (_c = typeof PMenuSectionItemParser !== "undefined" && PMenuSectionItemParser) === "function" ? _c : Object, typeof (_d = typeof SectionVisibilityService !== "undefined" && SectionVisibilityService) === "function" ? _d : Object])
], PMenuSectionParser);
export { PMenuSectionParser };
