var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { PromotionSchemeAvailabilityService } from "../AvailabilityService";
import { PromotionInstancePriceService } from "../PriceService";
let PromotionSchemeCheckoutDataService = class PromotionSchemeCheckoutDataService {
    constructor(promotionSchemeAvailabilityService, promotionInstancePriceService) {
        this.promotionSchemeAvailabilityService = promotionSchemeAvailabilityService;
        this.promotionInstancePriceService = promotionInstancePriceService;
        makeImmutable(this);
    }
    get checkoutData() {
        return this.promotionSchemeAvailabilityService.activePossiblePromotionInstances.objects.map((promotionInstance) => {
            switch (promotionInstance.kind) {
                case "WholeOrder":
                    return this.wholeOrderPromotionInstanceCheckoutData(promotionInstance);
                case "Product":
                    return this.productPromotionInstanceCheckoutData(promotionInstance);
            }
        }).raw;
    }
    wholeOrderPromotionInstanceCheckoutData(promotionInstance) {
        const value = this.promotionInstancePriceService.value(promotionInstance).finalValue;
        return {
            promotionId: promotionInstance.promotionType.id.value,
            promotionalItemId: promotionInstance.id.value,
            source: [],
            input: promotionInstance.freebie === null
                ? { tag: "NoInput" }
                : {
                    tag: "FreeProductInput",
                    selection: promotionInstance.freebie.name,
                },
            active: promotionInstance.active,
            assignment: null,
            value: value ? value.asNumber : null,
        };
    }
    productPromotionInstanceCheckoutData(promotionInstance) {
        const value = this.promotionInstancePriceService.value(promotionInstance).finalValue;
        return {
            promotionId: promotionInstance.promotionType.id.value,
            promotionalItemId: promotionInstance.id.value,
            source: promotionInstance.discountSource.map((orderLineReference) => this.orderLineReferenceCheckoutData(orderLineReference)).raw,
            input: promotionInstance.freebie === null
                ? { tag: "NoInput" }
                : {
                    tag: "FreeProductInput",
                    selection: promotionInstance.freebie.name,
                },
            active: promotionInstance.active,
            assignment: this.orderLineReferenceCheckoutData(promotionInstance.discountTarget),
            value: value ? value.asNumber : null,
        };
    }
    orderLineReferenceCheckoutData(orderLineReference) {
        return {
            productGroupIndex: orderLineReference.orderLineIndex,
            // NOTICE productSequenceNum is 1-indexed on backend
            productSequenceNum: orderLineReference.productInstanceIndex + 1,
        };
    }
};
PromotionSchemeCheckoutDataService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PromotionSchemeAvailabilityService !== "undefined" && PromotionSchemeAvailabilityService) === "function" ? _a : Object, typeof (_b = typeof PromotionInstancePriceService !== "undefined" && PromotionInstancePriceService) === "function" ? _b : Object])
], PromotionSchemeCheckoutDataService);
export { PromotionSchemeCheckoutDataService };
