var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { perRestaurant } from "../../di";
import { ProductInstanceCreatorFactory } from "../ProductInstanceCreator";
import { PurchaseOrderPayload } from "../PurchaseOrderPayload";
import { PurchaseOrderScope } from "../PurchaseOrderScope";
import { CrossSellingItemInstanceCartBehaviour } from "./CrossSellingItemInstanceBehaviour";
import { CrossSellingItemInstanceFactory } from "./CrossSellingItemInstanceFactory";
import { CrossSellingItemInstanceListService } from "./CrossSellingItemInstanceListService";
import { ICrossSellingListProvider } from "./CrossSellingListProvider";
import { MAX_CROSS_SELLING_ITEM_COUNT, } from "./ICrossSellingCreator";
let CartCrossSellingCreator = class CartCrossSellingCreator {
    constructor(purchaseOrderPayload, purchaseOrderScope, listProvider, crossSellingItemInstanceListService, productInstanceCreatorFactory) {
        this.purchaseOrderPayload = purchaseOrderPayload;
        this.purchaseOrderScope = purchaseOrderScope;
        this.crossSellingItemInstanceListService = crossSellingItemInstanceListService;
        this.crossSellingItemInstanceFactory = new CrossSellingItemInstanceFactory({
            productInstanceCreatorFactory,
            listProvider,
            createBehaviour: (_, productInstanceCreator) => new CrossSellingItemInstanceCartBehaviour({
                purchaseOrderPayload,
                productInstanceCreator,
            }),
        });
        this.sequenceNumber = Math.random();
        makeAutoObservable(this);
    }
    get items() {
        const inputProductTypeIds = this.purchaseOrderPayload.productTypeIds;
        const excludedProductTypeIds = inputProductTypeIds;
        return this.crossSellingItemInstanceFactory
            .create({
            inputProductTypeIds,
            sequenceNumber: this.sequenceNumber,
            parentScope: this.purchaseOrderScope,
        })
            .filtered((crossSellingItemInstance) => this.crossSellingItemInstanceListService.shouldAppearInCrossSellingList(crossSellingItemInstance, excludedProductTypeIds))
            .take(MAX_CROSS_SELLING_ITEM_COUNT);
    }
    shuffle() {
        this.sequenceNumber = Math.random();
    }
};
CartCrossSellingCreator = __decorate([
    perRestaurant(),
    __param(2, inject(ICrossSellingListProvider)),
    __metadata("design:paramtypes", [typeof (_a = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _a : Object, typeof (_b = typeof PurchaseOrderScope !== "undefined" && PurchaseOrderScope) === "function" ? _b : Object, typeof (_c = typeof ICrossSellingListProvider !== "undefined" && ICrossSellingListProvider) === "function" ? _c : Object, typeof (_d = typeof CrossSellingItemInstanceListService !== "undefined" && CrossSellingItemInstanceListService) === "function" ? _d : Object, typeof (_e = typeof ProductInstanceCreatorFactory !== "undefined" && ProductInstanceCreatorFactory) === "function" ? _e : Object])
], CartCrossSellingCreator);
export { CartCrossSellingCreator };
