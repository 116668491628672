var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { RMap } from "../../collections";
import { makeImmutable } from "../../core";
import { LocalizationProvider } from "../../locale";
import { MenuSectionItemId, PurchaseOrderScope, } from "../../ordering";
import { perRestaurant } from "../../di";
import { LabelsCreatorFactory } from "../../labels";
import { TranslatedParser } from "../../parsing";
import { PMenuSectionItem, PMenuSectionItemCreatorContent, PMenuSectionItemSingleProductContent, } from "../PMenuSectionItem";
import { PProductInstanceVariantFactory } from "../PProductInstanceVariantFactory";
import { VariantCreatorFactory } from "./VariantCreatorFactory";
let PMenuSectionItemParser = class PMenuSectionItemParser {
    constructor(localizationProvider, translatedParser, variantCreatorFactory, purchaseOrderScope, labelsCreatorFactory, pProductInstanceVariantFactory) {
        this.localizationProvider = localizationProvider;
        this.translatedParser = translatedParser;
        this.variantCreatorFactory = variantCreatorFactory;
        this.purchaseOrderScope = purchaseOrderScope;
        this.labelsCreatorFactory = labelsCreatorFactory;
        this.pProductInstanceVariantFactory = pProductInstanceVariantFactory;
        makeImmutable(this);
    }
    variantParameterChoiceName(customParameterTypes, variant) {
        const parameterChoices = variant.customParameterChoices.choices.entries.map(([customParameterType, customParameterChoice]) => customParameterTypes
            .get(customParameterType.id)
            .choices.get(customParameterChoice).name);
        return parameterChoices.raw.join(" / ");
    }
    variant(customParameterTypes, domainVariant) {
        const instance = this.variantCreatorFactory.createProductInstance(domainVariant);
        if (instance === null) {
            return null;
        }
        return this.pProductInstanceVariantFactory.create({
            instance,
            creator: () => this.variantCreatorFactory.creator(instance),
            variantParameterChoiceName: this.variantParameterChoiceName(customParameterTypes, domainVariant),
        });
    }
    parseSingleProductContent(params) {
        const { domainSection, productType } = params;
        const productVariants = domainSection.variants.get(productType.id);
        const productColumns = domainSection.columns.find(productType.id);
        return new PMenuSectionItemSingleProductContent({
            id: productType.id,
            name: productType._name,
            description: productType.description,
            variants: productVariants.mapOptional((domainVariant) => this.variant(params.customParameterTypes, domainVariant)).raw,
            columns: productColumns === null
                ? RMap.empty()
                : productColumns.mapOptional((domainVariant) => this.variant(params.customParameterTypes, domainVariant)),
            availabilitySchedule: productType.availabilitySchedule,
            localizationProvider: this.localizationProvider,
            photoUrn: productType.photo,
            tags: productType.tags,
            allergens: productType.allergens,
            freeModifierItemsCount: productType.freeModifierItemsCount,
            baseIngredients: productType.baseIngredients,
            purchaseOrderScope: this.purchaseOrderScope,
            labelsCreatorFactory: this.labelsCreatorFactory,
        });
    }
    parseCreatorContent(customParameterTypes, creatorVariant, creatorData) {
        const variant = this.variant(customParameterTypes, creatorVariant);
        if (variant === null) {
            return null;
        }
        return new PMenuSectionItemCreatorContent({
            variant,
            name: this.translatedParser.parse(creatorData.creatorName),
            description: this.translatedParser.parse(creatorData.creatorDescription),
            photoUrn: creatorData.creatorPhoto,
        });
    }
    parseContent(params) {
        switch (params.item.tag) {
            case "SingleProduct": {
                const productType = params.productTypes.rawFind(params.item.itemProduct);
                if (productType === null) {
                    return null;
                }
                return this.parseSingleProductContent({
                    customParameterTypes: params.customParameterTypes,
                    domainSection: params.domainSection,
                    productType,
                });
            }
            case "ProductCreator": {
                const creatorVariant = params.domainSection.creators.rawFind(params.id);
                if (creatorVariant === null) {
                    return null;
                }
                return this.parseCreatorContent(params.customParameterTypes, creatorVariant, params.item);
            }
            default:
                return null;
        }
    }
    parse(params) {
        const content = this.parseContent(params);
        if (content === null) {
            return null;
        }
        return new PMenuSectionItem({
            id: new MenuSectionItemId(params.id),
            content,
        });
    }
};
PMenuSectionItemParser = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof LocalizationProvider !== "undefined" && LocalizationProvider) === "function" ? _a : Object, typeof (_b = typeof TranslatedParser !== "undefined" && TranslatedParser) === "function" ? _b : Object, typeof (_c = typeof VariantCreatorFactory !== "undefined" && VariantCreatorFactory) === "function" ? _c : Object, typeof (_d = typeof PurchaseOrderScope !== "undefined" && PurchaseOrderScope) === "function" ? _d : Object, typeof (_e = typeof LabelsCreatorFactory !== "undefined" && LabelsCreatorFactory) === "function" ? _e : Object, typeof (_f = typeof PProductInstanceVariantFactory !== "undefined" && PProductInstanceVariantFactory) === "function" ? _f : Object])
], PMenuSectionItemParser);
export { PMenuSectionItemParser };
