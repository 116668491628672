import { RSet } from "../../collections";
import { makeImmutable, Tristate } from "../../core";
/*
 * Always unavailable availability strategy
 */
export class Unavailable {
    constructor(reason) {
        this.reason = reason;
        makeImmutable(this);
    }
    get isAvailable() {
        return Tristate.False;
    }
    get unavailabilityReasons() {
        return RSet.singleton(this.reason);
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitUnavailable(this);
    }
}
