import { makeImmutable } from "../../core";
export class TypesBasedProductSelector {
    constructor(productTypeIds) {
        this.kind = "ProductTypes";
        this.productTypeIds = productTypeIds;
        makeImmutable(this);
    }
    test(productInstance) {
        return productInstance.productTypeIds.isSubsetOf(this.productTypeIds);
    }
}
