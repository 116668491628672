var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { singleton } from "tsyringe";
import { makeImmutable, Money, Quantity } from "../core";
import { HiddenModifierItemAvailabilitySpec, ModifierItemType, ModifierItemTypeId, } from "../ordering";
import { AvailabilitySpecParser } from "./AvailabilitySpecParser";
import { ParametrizationParser } from "./ParametrizationParser";
import { VolumeParser } from "./VolumeParser";
let ModifierItemTypeParser = class ModifierItemTypeParser {
    constructor(parametrizationParser, availabilitySpecParser) {
        this.parametrizationParser = parametrizationParser;
        this.availabilitySpecParser = availabilitySpecParser;
        makeImmutable(this);
    }
    parse(customParameterTypes, id, item) {
        var _a;
        const modifierItemVolume = (_a = item.packagingContainersConfig) === null || _a === void 0 ? void 0 : _a.volume;
        return new ModifierItemType({
            id: new ModifierItemTypeId(id),
            pricing: this.parametrizationParser
                .parse(customParameterTypes, item.price)
                .map((value) => Money.fromBackend(value)),
            volume: modifierItemVolume
                ? VolumeParser.parse(modifierItemVolume)
                : null,
            availabilitySpec: item.availability === "Hidden"
                ? new HiddenModifierItemAvailabilitySpec()
                : this.availabilitySpecParser.parse(customParameterTypes, item.availability, item.availableWhen),
            defaultSelection: Quantity.fromNumber(item.defaultSelection),
            irremovable: item.irremovable,
        });
    }
};
ModifierItemTypeParser = __decorate([
    singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof ParametrizationParser !== "undefined" && ParametrizationParser) === "function" ? _a : Object, typeof (_b = typeof AvailabilitySpecParser !== "undefined" && AvailabilitySpecParser) === "function" ? _b : Object])
], ModifierItemTypeParser);
export { ModifierItemTypeParser };
