var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { inject } from "tsyringe";
import { makeImmutable } from "../../../core";
import { perRestaurant } from "../../../di";
import { FulfillmentInstructions, FulfillmentInstructionsToken, } from "../../../ordering";
import { UserNoteController } from "../UserNoteController";
import { OnlineFulfillmentFormControllerFactory } from "./OnlineFulfillmentFormControllerFactory";
import { QrWaiterFulfillmentFormController } from "./QrWaiterFulfillmentFormController";
let FulfillmentFormControllerFactory = class FulfillmentFormControllerFactory {
    constructor(fulfillmentInstructions, onlineFulfillmentFormControllerFactory, userNoteController) {
        this.fulfillmentInstructions = fulfillmentInstructions;
        this.onlineFulfillmentFormControllerFactory = onlineFulfillmentFormControllerFactory;
        this.userNoteController = userNoteController;
        makeImmutable(this);
    }
    create() {
        switch (this.fulfillmentInstructions.type) {
            case "Online":
                return this.onlineFulfillmentFormControllerFactory.create(this.fulfillmentInstructions);
            case "QrWaiter":
                return new QrWaiterFulfillmentFormController({
                    fulfillmentInstructions: this.fulfillmentInstructions,
                    userNoteController: this.userNoteController,
                });
        }
    }
};
FulfillmentFormControllerFactory = __decorate([
    perRestaurant(),
    __param(0, inject(FulfillmentInstructionsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof FulfillmentInstructions !== "undefined" && FulfillmentInstructions) === "function" ? _a : Object, typeof (_b = typeof OnlineFulfillmentFormControllerFactory !== "undefined" && OnlineFulfillmentFormControllerFactory) === "function" ? _b : Object, typeof (_c = typeof UserNoteController !== "undefined" && UserNoteController) === "function" ? _c : Object])
], FulfillmentFormControllerFactory);
export { FulfillmentFormControllerFactory };
