var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { Menu } from "../Menu";
import { ParameterScope } from "../ParameterScope";
import { ProductConfigFactory } from "./ProductConfigFactory";
import { ProductInstancePart, } from "./ProductInstancePart";
let ProductInstancePartFactory = class ProductInstancePartFactory {
    constructor(menu, productConfigFactory) {
        this.menu = menu;
        this.productConfigFactory = productConfigFactory;
        makeImmutable(this);
    }
    fromStorageData(storageData) {
        const productType = this.menu.productTypes.rawFind(storageData.productTypeId);
        if (productType === null) {
            return null;
        }
        const productConfig = this.productConfigFactory.fromStorageData({
            modifierSettingsProvider: (modifierType) => productType.modifierSettings(modifierType),
            storageData: storageData.productConfig,
        });
        if (productConfig === null) {
            return null;
        }
        return new ProductInstancePart({
            scope: ParameterScope.Uninitialized,
            productType,
            productConfig,
        });
    }
};
ProductInstancePartFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof Menu !== "undefined" && Menu) === "function" ? _a : Object, typeof (_b = typeof ProductConfigFactory !== "undefined" && ProductConfigFactory) === "function" ? _b : Object])
], ProductInstancePartFactory);
export { ProductInstancePartFactory };
