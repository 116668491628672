var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { makeAutoObservable } from "mobx";
import { toPairs } from "../../collections";
import { perRestaurant } from "../../di";
import { CheckboxFieldController, ErrorBox, SelectableFieldController, TextFieldController, } from "../../form";
import { t, translate } from "../../I18n";
import { ApartmentInfoAvailabilityService, Payment, PaymentAvailabilityService, PostCode, StreetAddressAvailabilityService, } from "../../ordering";
import { PRestaurant } from "../PRestaurant";
import { DefaultApartmentInfoController } from "./ApartmentInfoController";
import { StreetAddressFormController } from "./StreetAddressFormController";
let PaymentFormController = class PaymentFormController {
    constructor(apartmentInfoAvailabilityService, streetAddressAvailabilityService, paymentAvailabilityService, restaurant, payment) {
        var _a, _b, _c, _d;
        this.paymentAvailabilityService = paymentAvailabilityService;
        this.payment = payment;
        const initialValue = this.getInitialValue(restaurant.paymentMediums, payment.medium);
        this.mediumController = new SelectableFieldController({
            initialValue,
            onUpdate: (value) => {
                if (value.kind !== "OnlineGroup") {
                    payment.medium = value.domainValue;
                }
            },
            onClear: () => {
                payment.medium = null;
            },
            errorBox: new ErrorBox({
                domainObjects: [paymentAvailabilityService],
                errorMessages: {
                    PaymentMediumMissing: translate(t.orders.checkout.payment_method.required),
                    OnlinePaymentMethodRequired: translate(t.orders.checkout.payment_method.online_payment_method_required),
                },
            }),
        });
        this.differentThanDeliveryAddress = new CheckboxFieldController({
            initialValue: (_b = (_a = this.payment.address) === null || _a === void 0 ? void 0 : _a.isDifferentThanDeliveryAddress) !== null && _b !== void 0 ? _b : false,
            onUpdate: (value) => {
                payment.address.isDifferentThanDeliveryAddress = value;
            },
        });
        this.postCodeController = new TextFieldController({
            initialValue: (_d = (_c = this.payment.address) === null || _c === void 0 ? void 0 : _c.postCode) === null || _d === void 0 ? void 0 : _d.toString(),
            onUpdate: (str) => {
                payment.address.postCode = new PostCode(str);
            },
            errorBox: new ErrorBox({
                domainObjects: [paymentAvailabilityService],
                errorMessages: {
                    PostCodeMissing: translate(t._common.form.errors.required),
                    InvalidPostCode: translate(t.validations.invalid_postcode),
                },
            }),
        });
        this.streetAddressController = new StreetAddressFormController({
            streetAddress: this.payment.address.streetAddress,
            streetAddressAvailabilityService,
        });
        this.apartmentInfoController = new DefaultApartmentInfoController({
            apartmentInfo: this.payment.address.apartmentInfo,
            apartmentInfoAvailabilityService,
        });
        // TODO: should we make a global rule for this (if restored from storage -> touch form to display errors)?
        if (initialValue !== null) {
            this.mediumController.blur();
        }
        makeAutoObservable(this);
    }
    get fields() {
        return {
            medium: this.mediumController,
            differentThanDeliveryAddress: this.differentThanDeliveryAddress,
            ...this.streetAddressController.fields,
            ...this.apartmentInfoController.fields,
            postCode: this.postCodeController, // overwrite postCode from streetAddress to use PostCodemodel in the payment address
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        return this.paymentAvailabilityService.availability.isAvailable
            .isDefinitelyFalse;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.payment;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
    get isPaymentAddressRequired() {
        return this.payment.isAddressRequired;
    }
    get canBeDifferentThanDeliveryAddress() {
        return this.payment.address.canBeDifferentThanDeliveryAddress;
    }
    get isFullAddressRequired() {
        return this.payment.address.isFullAddressRequired;
    }
    getInitialValue(paymentMediums, domain) {
        if (paymentMediums === null || domain === null) {
            return null;
        }
        const medium = paymentMediums.findWhere((medium) => medium.isSelected(domain)) || null;
        if (medium === null) {
            return null;
        }
        return medium.kind === "OnlineGroup" ? medium.getChannel(domain) : medium;
    }
};
PaymentFormController = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ApartmentInfoAvailabilityService !== "undefined" && ApartmentInfoAvailabilityService) === "function" ? _a : Object, typeof (_b = typeof StreetAddressAvailabilityService !== "undefined" && StreetAddressAvailabilityService) === "function" ? _b : Object, typeof (_c = typeof PaymentAvailabilityService !== "undefined" && PaymentAvailabilityService) === "function" ? _c : Object, typeof (_d = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _d : Object, typeof (_e = typeof Payment !== "undefined" && Payment) === "function" ? _e : Object])
], PaymentFormController);
export { PaymentFormController };
