var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
import { inject } from "tsyringe";
import { GlobalConfig } from "../../config";
import { makeImmutable, RestaurantId } from "../../core";
import { perRestaurant } from "../../di";
import { ContactDetails, ContactDetailsToken } from "../ContactDetails";
import { InCartPromotionTypes } from "../InCartPromotionTypes";
import { OrderReceiver } from "../OrderReceiver";
import { PurchaseOrderPackingService } from "../PackingService";
import { Payment } from "../Payment";
import { PurchaseOrder } from "../PurchaseOrder";
import { SalesChannel } from "../SalesChannel";
import { TipInstance, TipInstanceToken } from "../TipInstance";
import { FulfillmentInstructionsCheckoutDataService } from "./FulfillmentInstructionsCheckoutDataService";
import { PackingCheckoutDataService } from "./PackingCheckoutDataService";
import { PaymentCheckoutDataService } from "./PaymentCheckoutDataService";
import { PromotionSchemeCheckoutDataService } from "./PromotionSchemeCheckoutDataService";
import { PurchaseOrderPayloadCheckoutDataService } from "./PurchaseOrderPayloadCheckoutDataService";
let PurchaseOrderCheckoutDataService = class PurchaseOrderCheckoutDataService {
    constructor(globalConfig, restaurantId, purchaseOrderPayloadCheckoutDataService, packingCheckoutDataService, fulfillmentInstructionsCheckoutDataService, promotionSchemeCheckoutDataService, paymentCheckoutDataService, inCartPromotionTypes, payment, orderReceiver, salesChannel, purchaseOrder, tip, contactDetails, purchaseOrderPackingService) {
        this.restaurantId = restaurantId;
        this.purchaseOrderPayloadCheckoutDataService = purchaseOrderPayloadCheckoutDataService;
        this.packingCheckoutDataService = packingCheckoutDataService;
        this.fulfillmentInstructionsCheckoutDataService = fulfillmentInstructionsCheckoutDataService;
        this.promotionSchemeCheckoutDataService = promotionSchemeCheckoutDataService;
        this.paymentCheckoutDataService = paymentCheckoutDataService;
        this.inCartPromotionTypes = inCartPromotionTypes;
        this.payment = payment;
        this.orderReceiver = orderReceiver;
        this.salesChannel = salesChannel;
        this.purchaseOrder = purchaseOrder;
        this.tip = tip;
        this.contactDetails = contactDetails;
        this.purchaseOrderPackingService = purchaseOrderPackingService;
        this.locale = globalConfig.locale;
        this.isIosReviewMode = globalConfig.isIosReviewMode;
        makeImmutable(this);
    }
    /**
     * @returns data to be sent to the backend
     */
    checkoutData(params) {
        const { consents, invoiceRequestStorageData } = this.orderReceiver;
        return {
            checkout: {
                restaurantId: this.restaurantId.value,
                items: this.purchaseOrderPayloadCheckoutDataService.checkoutData,
                locale: this.locale,
                ...this.fulfillmentInstructionsCheckoutDataService.checkoutData,
                paymentMethod: this.payment.method,
                customer: {
                    ...this.contactDetailsCheckoutData,
                    subscribed: false,
                    subscribeEmail: consents.subscribeEmail,
                    subscribePhone: consents.subscribePhone,
                    subscribedRestaumatic: false,
                    subscribeRestaumaticEmail: consents.subscribeRestaumaticEmail,
                    subscribeRestaumaticPhone: consents.subscribeRestaumaticPhone,
                },
                origin: this.salesChannelCheckoutData,
                extraDiscount: null,
                draft: null,
                vatId: invoiceRequestStorageData.vatId,
                packagingContainers: this.packingCheckoutDataService.checkoutData(this.purchaseOrderPackingService.packaging),
                waiterPin: null,
                tip: this.tipCheckoutData,
                fieldTestExperiments: params.fieldTestExperiments,
                isIosReviewMode: this.isIosReviewMode,
                promotionalItems: this.promotionSchemeCheckoutDataService.checkoutData,
                couponCodes: this.inCartPromotionTypes.couponCode === null
                    ? []
                    : [this.inCartPromotionTypes.couponCode],
            },
            origin: params.appOriginPath,
            payment: this.paymentCheckoutDataService.checkoutData(params.applePayToken),
            tracking: params.tracking,
        };
    }
    get checkoutLoggingData() {
        var _a, _b;
        const fulfillmentInstructionsCheckoutData = this.fulfillmentInstructionsCheckoutDataService.checkoutData;
        return {
            paymentMethod: (_b = (_a = this.payment.medium) === null || _a === void 0 ? void 0 : _a.method) !== null && _b !== void 0 ? _b : null,
            fulfillmentTime: fulfillmentInstructionsCheckoutData.requestedFulfillmentTime,
            fulfillmentMethodType: fulfillmentInstructionsCheckoutData.fulfillmentMethod.tag,
            minOrderValue: this.purchaseOrder.minOrderValue.asNumber,
            couponCodes: this.inCartPromotionTypes.couponCode === null
                ? []
                : [this.inCartPromotionTypes.couponCode],
            items: this.purchaseOrderPayloadCheckoutDataService.checkoutLoggingData,
        };
    }
    get tipCheckoutData() {
        switch (this.tip.kind) {
            case "Enabled": {
                if (this.tip.numericValue === null) {
                    return null;
                }
                return { tag: "Tip", contents: this.tip.numericValue };
            }
            case "Disabled":
                return null;
        }
    }
    get salesChannelCheckoutData() {
        switch (this.salesChannel.value) {
            case "Sites":
                return { tag: "Online" };
            case "QRWaiter":
                return { tag: "Bar" };
            case "IosApp":
                return { tag: "IosApp" };
            case "AndroidApp":
                return { tag: "AndroidApp" };
        }
    }
    get contactDetailsCheckoutData() {
        var _a, _b, _c, _d;
        switch (this.contactDetails.type) {
            case "Online":
                return {
                    email: (_a = this.contactDetails.email.value) !== null && _a !== void 0 ? _a : null,
                    fullName: (_b = this.contactDetails.fullName.value) !== null && _b !== void 0 ? _b : "",
                    phone: (_c = this.contactDetails.phone.value) !== null && _c !== void 0 ? _c : null,
                };
            case "QRWaiter":
                return {
                    email: (_d = this.contactDetails.email.value) !== null && _d !== void 0 ? _d : null,
                    phone: "",
                    fullName: "",
                };
        }
    }
};
PurchaseOrderCheckoutDataService = __decorate([
    perRestaurant(),
    __param(0, inject(GlobalConfig)),
    __param(12, inject(TipInstanceToken)),
    __param(13, inject(ContactDetailsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof GlobalConfig !== "undefined" && GlobalConfig) === "function" ? _a : Object, typeof (_b = typeof RestaurantId !== "undefined" && RestaurantId) === "function" ? _b : Object, typeof (_c = typeof PurchaseOrderPayloadCheckoutDataService !== "undefined" && PurchaseOrderPayloadCheckoutDataService) === "function" ? _c : Object, typeof (_d = typeof PackingCheckoutDataService !== "undefined" && PackingCheckoutDataService) === "function" ? _d : Object, typeof (_e = typeof FulfillmentInstructionsCheckoutDataService !== "undefined" && FulfillmentInstructionsCheckoutDataService) === "function" ? _e : Object, typeof (_f = typeof PromotionSchemeCheckoutDataService !== "undefined" && PromotionSchemeCheckoutDataService) === "function" ? _f : Object, typeof (_g = typeof PaymentCheckoutDataService !== "undefined" && PaymentCheckoutDataService) === "function" ? _g : Object, typeof (_h = typeof InCartPromotionTypes !== "undefined" && InCartPromotionTypes) === "function" ? _h : Object, typeof (_j = typeof Payment !== "undefined" && Payment) === "function" ? _j : Object, typeof (_k = typeof OrderReceiver !== "undefined" && OrderReceiver) === "function" ? _k : Object, typeof (_l = typeof SalesChannel !== "undefined" && SalesChannel) === "function" ? _l : Object, typeof (_m = typeof PurchaseOrder !== "undefined" && PurchaseOrder) === "function" ? _m : Object, typeof (_o = typeof TipInstance !== "undefined" && TipInstance) === "function" ? _o : Object, typeof (_p = typeof ContactDetails !== "undefined" && ContactDetails) === "function" ? _p : Object, typeof (_q = typeof PurchaseOrderPackingService !== "undefined" && PurchaseOrderPackingService) === "function" ? _q : Object])
], PurchaseOrderCheckoutDataService);
export { PurchaseOrderCheckoutDataService };
