import throttle from "../utils/throttle";
/**
 * `stickyHeader` is a header that is immediately visible and may or may not be an DOM item that handle a sticky state.
 *
 * `stickyHeaderElement` is the actual DOM item that gets the sticky state and must have appropriate styles to support this (position & visibility).
 * `stickyHeaderElement` can be the same element as `stickyHeader` or element beyond `stickyHeader`.
 *
 * Both these elements are required to correctly handling sticky mechanism.
 */
const stickyHeader = document.querySelector("#sticky_header");
const stickyHeaderElement = document.querySelector(".js-sticky-header-element");
const bodyElement = document.querySelector("body");
const bodyAffixedStateClass = "l-navbar-affixed";
const headerAffixedStateClass = "is-affixed";
// Create events instance for global use
const affixedEvent = new Event("affixed.restaumatic.sticky-header", {
    bubbles: false,
    cancelable: true,
    composed: true,
});
const unaffixedEvent = new Event("unaffixed.restaumatic.sticky-header", {
    bubbles: false,
    cancelable: true,
    composed: true,
});
/**
 * Get element offset to top relative to page.
 */
function getElOffsetTop(elem) {
    let offset = 0;
    // Loop up the DOM
    if (elem && elem.offsetParent) {
        do {
            offset += elem.offsetTop;
            elem = elem.offsetParent;
        } while (elem);
    }
    return offset < 0 ? 0 : offset;
}
/**
 * Get height of sticky header.
 */
function getStickyHeaderHeight(element = stickyHeaderElement) {
    return element ? element.clientHeight : 0;
}
/**
 * Keep height of the affixed parent element in order to prevent "jumping page" effect.
 */
function keepHeaderHeight(elem) {
    const parent = elem.parentElement;
    const parentHeight = parent === null || parent === void 0 ? void 0 : parent.offsetHeight;
    if (parent && parentHeight > 0) {
        parent.style.minHeight = `${parentHeight}px`;
    }
}
/**
 * Check if sticky header is in affixed state.
 */
function isHeaderAffixed() {
    return stickyHeader === null || stickyHeader === void 0 ? void 0 : stickyHeader.classList.contains(headerAffixedStateClass);
}
/**
 * Check if Header has position required to be affixed.
 * Sticky of fixed position style must be defined in CSS styles. There is no required position style by default.
 */
function isHeaderSticky() {
    if (!stickyHeaderElement)
        return false;
    const stickyHeaderPosition = getComputedStyle(stickyHeaderElement).position;
    const requiredPositionStyle = ["sticky", "fixed"];
    const isHeaderHasRequiredPosition = requiredPositionStyle.includes(stickyHeaderPosition);
    let isHeaderVisible = true;
    if ("checkVisibility" in stickyHeaderElement) {
        // @ts-ignore - `checkVisibility()` is modern method that is not yet included in TypeScript’s default HTMLElement type definitions
        isHeaderVisible = stickyHeaderElement.checkVisibility();
    }
    return isHeaderHasRequiredPosition && isHeaderVisible;
}
/**
 * Handle toggle of affixed state.
 */
function toggleAffixedState(position) {
    if (window.scrollY > position) {
        if (isHeaderAffixed())
            return;
        stickyHeader.classList.add(headerAffixedStateClass);
        stickyHeaderElement.classList.add(headerAffixedStateClass);
        bodyElement.classList.add(bodyAffixedStateClass);
        // Trigger is affixed event
        document.dispatchEvent(affixedEvent);
    }
    else {
        if (!isHeaderAffixed())
            return;
        stickyHeader.classList.remove(headerAffixedStateClass);
        stickyHeaderElement.classList.remove(headerAffixedStateClass);
        bodyElement.classList.remove(bodyAffixedStateClass);
        // Trigger is not affixed event
        document.dispatchEvent(unaffixedEvent);
    }
}
/**
 * Observe scroll event for changing state of header.
 */
function observeScrollEvent(position) {
    window.addEventListener("scroll", throttle(() => {
        toggleAffixedState(position);
    }, 200));
}
/**
 * Initialize StickyHeader feature.
 */
function init() {
    if (!stickyHeader || !stickyHeaderElement)
        return;
    const keepHeaderHeightAttr = stickyHeader.dataset.keepHeight;
    const stickyPosition = getElOffsetTop(stickyHeader) + stickyHeader.clientHeight;
    if (keepHeaderHeightAttr != undefined && keepHeaderHeightAttr !== "false") {
        keepHeaderHeight(stickyHeader);
    }
    toggleAffixedState(stickyPosition);
    observeScrollEvent(stickyPosition);
}
/**
 * Sticky Header.
 */
export default {
    data: { stickyHeader, stickyHeaderElement, headerAffixedStateClass },
    utils: { getStickyHeaderHeight, isHeaderAffixed, isHeaderSticky },
    init,
};
