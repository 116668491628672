var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { Price } from "../Price";
import { promotionEffectPrice } from "../PromotionType";
import { OrderLineReferencePrice } from "./OrderLineReferencePrice";
import { PurchaseOrderPayloadPriceService } from "./PurchaseOrderPayloadPriceService";
let PromotionInstancePriceService = class PromotionInstancePriceService {
    constructor(purchaseOrderPayloadPriceService) {
        this.purchaseOrderPayloadPriceService = purchaseOrderPayloadPriceService;
        makeImmutable(this);
    }
    value(promotionInstance) {
        if (!promotionInstance.active) {
            return Price.free();
        }
        switch (promotionInstance.kind) {
            case "Product":
                return promotionEffectPrice(promotionInstance.promotionType.effect, OrderLineReferencePrice.price(promotionInstance.discountTarget), promotionInstance.freebie);
            case "WholeOrder":
                return promotionEffectPrice(promotionInstance.promotionType.effect, this.purchaseOrderPayloadPriceService.price, promotionInstance.freebie);
        }
    }
};
PromotionInstancePriceService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PurchaseOrderPayloadPriceService !== "undefined" && PurchaseOrderPayloadPriceService) === "function" ? _a : Object])
], PromotionInstancePriceService);
export { PromotionInstancePriceService };
