import { makeAutoObservable } from "mobx";
import { ParameterScope } from "../ParameterScope";
import { ProductInstancePart } from "../ProductInstance";
/*
 * Utility class for building correct `ProductInstancePart` - it deals with changing productTypes in halves
 */
export class ProductInstanceCreatorPart {
    constructor(params) {
        this._scope = ParameterScope.Uninitialized;
        this.productType = params.productType;
        this.productConfigCreator = params.productConfigCreator;
        makeAutoObservable(this);
    }
    get productTypeId() {
        return this.productType.id;
    }
    get scope() {
        return this._scope;
    }
    initWithScope(parentScope) {
        this.productConfigCreator.initWithScope(parentScope);
        this._scope = this.productConfigCreator.scope;
    }
    get productInstancePart() {
        return new ProductInstancePart({
            scope: this.scope,
            productType: this.productType,
            productConfig: this.productConfigCreator.productConfig,
        });
    }
    clone() {
        return new ProductInstanceCreatorPart({
            productType: this.productType,
            productConfigCreator: this.productConfigCreator.clone(),
        });
    }
    selectInferredDefaults() {
        this.productConfigCreator.selectInferredDefaults();
    }
}
