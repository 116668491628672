var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { ApartmentInfoCheckoutDataService } from "./ApartmentInfoCheckoutDataService";
let DeliveryAddressCheckoutDataService = class DeliveryAddressCheckoutDataService {
    constructor(apartmentInfoCheckoutDataService) {
        this.apartmentInfoCheckoutDataService = apartmentInfoCheckoutDataService;
        makeImmutable(this);
    }
    checkoutData(deliveryAddress) {
        const apartmentInfoCheckoutData = this.apartmentInfoCheckoutDataService.checkoutData(deliveryAddress.apartmentInfo);
        return {
            ...deliveryAddress.streetAddress.data,
            apartmentNumber: apartmentInfoCheckoutData.aptNumber,
            floor: apartmentInfoCheckoutData.floor,
            country: deliveryAddress.streetAddress.country,
            // Additional country-specific information
            extra: apartmentInfoCheckoutData,
            // Used in RO country to show custom delivery address in PR
            formattedAddress: countrySpecificFormattedAddress(deliveryAddress.streetAddress.country, deliveryAddress.streetAddress.data, apartmentInfoCheckoutData),
            coordinates: deliveryAddress.coordinates,
        };
    }
};
DeliveryAddressCheckoutDataService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ApartmentInfoCheckoutDataService !== "undefined" && ApartmentInfoCheckoutDataService) === "function" ? _a : Object])
], DeliveryAddressCheckoutDataService);
export { DeliveryAddressCheckoutDataService };
function countrySpecificFormattedAddress(country, streetAddress, apartmentInfo) {
    switch (country) {
        case "RO":
            return formatRomanianAddress(streetAddress, apartmentInfo);
        default:
            return null;
    }
}
function formatRomanianAddress(streetAddress, apartmentInfo) {
    const result = {
        value: "",
        concat(value, label) {
            if (value.length > 0) {
                this.value = `${this.value}, ${label} ${value}`;
            }
            return this;
        },
    };
    // Street, city
    result.value = defaultFormatBuildingAddress(streetAddress);
    // Sector
    result.concat(apartmentInfo.sector, "Sec.");
    if (apartmentInfo.houseOrBlock === "Block") {
        // Bloc
        result.concat(apartmentInfo.block, "Bl.");
        // Scara
        result.concat(apartmentInfo.staircase, "Sc.");
        // Etaj
        result.concat(apartmentInfo.floor, "Et.");
        // Apt number
        result.concat(apartmentInfo.aptNumber, "Ap.");
        // Interfon
        result.concat(apartmentInfo.interphone, "Intf.");
    }
    // Alte detali/reper (additional info)
    result.concat(apartmentInfo.additionalInfo, "Reper:");
    return result.value;
}
function defaultFormatBuildingAddress(streetAddress) {
    const { street, streetNumber, city, postCode } = streetAddress;
    const postCodeAndCity = postCode.length > 0 && city.length > 0
        ? `${postCode} ${city}`
        : city || postCode;
    return `${street} ${streetNumber}, ${postCodeAndCity}`;
}
