import { sessionGetJSONItem, sessionSetJSONItem, } from "../services/SessionStorage";
import { RestaumaticRPCService } from "restaumatic-client/src/services/RestaumaticRPCService";
import { Sentry } from "restaumatic-client/src";
const initialDineInLocationKey = "initialDineInLocation";
function getQrWaiterParameters() {
    const searchParams = new URLSearchParams(window.location.search);
    const tableParam = searchParams.get("table");
    const areaIdParam = searchParams.get("areaId");
    const pointName = searchParams.get("pointName");
    if (tableParam != null) {
        const number = parseInt(tableParam);
        if (!isNaN(number)) {
            return { table: number };
        }
    }
    if (areaIdParam !== null && pointName != null) {
        const areaId = parseInt(areaIdParam);
        if (!isNaN(areaId)) {
            return { areaId, pointName };
        }
    }
    return null;
}
export function searchInitialDineInLocation(restaurantId) {
    const params = getQrWaiterParameters();
    if (!params) {
        return Promise.resolve(null);
    }
    if ("table" in params) {
        return Promise.resolve({ tag: "Table", number: params.table });
    }
    if ("areaId" in params) {
        const rpcService = new RestaumaticRPCService("");
        return rpcService
            .rpc2("GetServingAreaRequest", {
            restaurantId: restaurantId.value,
            servingAreaId: params.areaId,
        })
            .then((result) => {
            var _a;
            if (!result) {
                Sentry.logError(new Error(`QrWaiter Serving area not found, restaurantId=${restaurantId.value}, areaId=${params.areaId}`));
            }
            return {
                tag: "ServingLocation",
                areaId: params.areaId,
                areaName: (_a = result === null || result === void 0 ? void 0 : result.name) !== null && _a !== void 0 ? _a : "?",
                pointName: params.pointName,
            };
        });
    }
    return Promise.resolve(null);
}
export function isQrWaiterOrder() {
    return (getQrWaiterParameters() !== null || getSessionDineInLocation() !== null);
}
export function getDineInLocation(restaurantId) {
    const sessionDineInLocation = getSessionDineInLocation();
    return sessionDineInLocation != null
        ? Promise.resolve(sessionDineInLocation)
        : searchInitialDineInLocation(restaurantId);
}
function getSessionDineInLocation() {
    return sessionGetJSONItem(initialDineInLocationKey);
}
export function setDineInLocation(location) {
    sessionSetJSONItem(initialDineInLocationKey, location);
}
