import { t, translate } from "../I18n";
import { TemporarilyUnavailableLabel } from "./TemporarilyUnavailableLabel";
export const OrderForLabel = {
    createLabel() {
        return (labelParams) => {
            if (labelParams.fulfillmentTime === null) {
                return null;
            }
            if (TemporarilyUnavailableLabel.createLabel()(labelParams) !== null) {
                return null;
            }
            if (labelParams.reasons.includes("Preorder") &&
                labelParams.productInstances.length > 0) {
                const preorderSettings = labelParams.productInstancePreorderSettingsService.preorderSettings(labelParams.productInstances[0]);
                const { onlyRange } = preorderSettings;
                if (onlyRange !== null) {
                    const begin = onlyRange.begin.formatDDMM();
                    const end = onlyRange.end.formatDDMM();
                    if (preorderSettings.isSameDayOnly) {
                        return null;
                    }
                    const message = begin === end
                        ? translate(t.client.labels.not_available_now.order_for_exact_date, { begin })
                        : translate(t.client.labels.not_available_now.order_for, {
                            begin,
                            end,
                        });
                    return {
                        message,
                        enabledOnClick: true,
                        type: "info",
                    };
                }
            }
            return null;
        };
    },
};
