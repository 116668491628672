var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a;
import { inject, singleton } from "tsyringe";
import { makeImmutable } from "../core";
import { RArray, toPairs } from "../collections";
import { MenuConsumer } from "../config";
let ActiveSectionsParser = class ActiveSectionsParser {
    constructor(appOrigin) {
        this.appOrigin = appOrigin;
        makeImmutable(this);
    }
    parse(catalogue) {
        var _a, _b;
        // NOTICE this is legacy multiple menu feature, consider removing
        const firstMenuSections = (_b = (_a = toPairs(catalogue.menus)
            .map(([_, menu]) => menu)
            .find(0)) === null || _a === void 0 ? void 0 : _a.sections) !== null && _b !== void 0 ? _b : [];
        return new RArray(toPairs(catalogue.sections)).filtered(([sectionId, section]) => section.visible &&
            section.visibleIn.some((visbleIn) => visbleIn === this.appOrigin) &&
            firstMenuSections.includes(sectionId));
    }
};
ActiveSectionsParser = __decorate([
    singleton(),
    __param(0, inject(MenuConsumer)),
    __metadata("design:paramtypes", [typeof (_a = typeof MenuConsumer !== "undefined" && MenuConsumer) === "function" ? _a : Object])
], ActiveSectionsParser);
export { ActiveSectionsParser };
