var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { Menu } from "../Menu";
import { ProductInstanceCreator } from "./ProductInstanceCreator";
import { ProductInstanceCreatorStateFactory } from "./ProductInstanceCreatorStateFactory";
let ProductInstanceCreatorFactory = class ProductInstanceCreatorFactory {
    constructor(menu, productInstanceCreatorStateFactory) {
        this.menu = menu;
        this.productInstanceCreatorStateFactory = productInstanceCreatorStateFactory;
        makeImmutable(this);
    }
    create(productInstance) {
        const productCategory = this.menu.productCategories.get(productInstance.productCategoryId);
        const possibleProductTypeIds = this.menu.productTypes.filter((productType) => productType.productCategoryId.eq(productCategory.id)).ids;
        return new ProductInstanceCreator({
            productInstanceCreatorStateFactory: this.productInstanceCreatorStateFactory,
            possibleProductTypeIds,
            state: this.productInstanceCreatorStateFactory.fromProductInstanceParts({
                productCategory,
                commonConfig: productInstance.commonConfig,
                parts: productInstance.parts,
            }),
            trackingId: productInstance.trackingId,
        });
    }
};
ProductInstanceCreatorFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof Menu !== "undefined" && Menu) === "function" ? _a : Object, typeof (_b = typeof ProductInstanceCreatorStateFactory !== "undefined" && ProductInstanceCreatorStateFactory) === "function" ? _b : Object])
], ProductInstanceCreatorFactory);
export { ProductInstanceCreatorFactory };
