var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a;
import { inject, singleton } from "tsyringe";
import { MenuConsumer } from "../config";
import { makeImmutable, Money } from "../core";
import { OrderingPolicy, Restaurant } from "../ordering";
let RestaurantParser = class RestaurantParser {
    constructor(menuConsumer) {
        this.menuConsumer = menuConsumer;
        makeImmutable(this);
    }
    maxOrderValue(input) {
        const { max_order_value } = input;
        return max_order_value === null ? null : Money.fromBackend(max_order_value);
    }
    parse(params) {
        const { input } = params;
        if (params.checkoutInfo.fulfillmentMethods.isEmpty) {
            throw new Error("Fulfillment methods are empty");
        }
        const minOrderValue = {
            takeaway: Money.fromString(input.min_order_value_with_pickup),
            default: Money.fromString(input.min_order_value),
        };
        const orderingPolicy = new OrderingPolicy({
            maxOrderValue: this.maxOrderValue(input),
            deliveryInTotal: input.delivery_in_total,
            enableCoupons: input.enable_coupons,
            enableFod: input.enable_fod,
            enableMarketingConsents: input.enable_marketing_consents,
        });
        return new Restaurant({
            id: params.restaurantId,
            menu: params.menu,
            timezone: input.time_zone,
            menuConsumer: this.menuConsumer,
            orderingPolicy,
            minOrderValue,
            fulfillmentMethods: params.checkoutInfo.fulfillmentMethods,
            paymentMethods: params.checkoutInfo.paymentMethods,
            paymentChannels: params.paymentChannels,
        });
    }
};
RestaurantParser = __decorate([
    singleton(),
    __param(0, inject(MenuConsumer)),
    __metadata("design:paramtypes", [typeof (_a = typeof MenuConsumer !== "undefined" && MenuConsumer) === "function" ? _a : Object])
], RestaurantParser);
export { RestaurantParser };
