var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject } from "tsyringe";
import { makeImmutable, Now, RDate } from "../core";
import { perRestaurant } from "../di";
import { OnlineOrderingSettingsToken, } from "./OnlineOrderingSettings";
import { IOrderingHours } from "./OrderingHours";
let Asap = class Asap {
    constructor(orderingHours, now, onlineOrderingSettings) {
        this.orderingHours = orderingHours;
        this.now = now;
        this.allowOrderingInClosedRestaurant =
            onlineOrderingSettings.allowOrderingInClosedRestaurant;
        this.enableDelayedDelivery = onlineOrderingSettings.enableDelayedDelivery;
        makeImmutable(this);
    }
    isPlacingOrderPossibleAsap() {
        var _a, _b;
        const event = this.orderingHours.nextEventAtDate(this.now);
        switch (event.type) {
            // NOTICE Ordering is closed, but will begin at this date and restaurant allows placing orders outside ordering hours
            case "OrderingBegins":
                return (this.allowOrderingInClosedRestaurant &&
                    !this.enableDelayedDelivery &&
                    ((_b = (_a = event.at) === null || _a === void 0 ? void 0 : _a.isToday) !== null && _b !== void 0 ? _b : false));
            // NOTICE Ordering is open and will end in the future
            case "OrderingEnds":
                return true;
        }
    }
    asapOptionAvailable(preorderSettings) {
        return this.isPlacingOrderPossibleAsap() && preorderSettings.hasTodayDate;
    }
};
Asap = __decorate([
    perRestaurant(),
    __param(0, inject(IOrderingHours)),
    __param(1, inject(Now)),
    __param(2, inject(OnlineOrderingSettingsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof IOrderingHours !== "undefined" && IOrderingHours) === "function" ? _a : Object, typeof (_b = typeof RDate !== "undefined" && RDate) === "function" ? _b : Object, Object])
], Asap);
export { Asap };
