import { RArray } from "../../../collections";
import { Identifier, makeImmutable, } from "../../../core";
export class ProductInstancePackingSubjectId extends Identifier {
    constructor(productTypeIds) {
        const id = new RArray(productTypeIds)
            .sorted((a, b) => a.value.localeCompare(b.value))
            .raw.join(":");
        super("ProductInstancePackingSubjectId", id);
    }
}
export class ProductInstancePackingSubject {
    constructor(params) {
        this.type = "ProductInstance";
        this.id = new ProductInstancePackingSubjectId(params.productTypeIds);
        this.productTypeIds = params.productTypeIds;
        this.volume = params.volume;
        makeImmutable(this);
    }
    eq(other) {
        return (other.type === "ProductInstance" &&
            this.productTypeIds.eq(other.productTypeIds) &&
            this.volume.eq(other.volume));
    }
}
export class ModifierItemPackingSubject {
    constructor(params) {
        this.type = "ModifierItem";
        this.modifierTypeId = params.modifierTypeId;
        this.modifierItemTypeId = params.modifierItemTypeId;
        this.volume = params.volume;
        makeImmutable(this);
    }
    get id() {
        return this.modifierItemTypeId;
    }
    eq(other) {
        return (other.type === "ModifierItem" &&
            this.modifierTypeId.eq(other.modifierTypeId) &&
            this.modifierItemTypeId.eq(other.modifierItemTypeId) &&
            this.volume.eq(other.volume));
    }
}
