var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import { inject } from "tsyringe";
import { GlobalConfig } from "../config";
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { LabelsCreatorFactory } from "../labels";
import { LocalizationProvider } from "../locale";
import { HalvingPolicyAvailabilityService, Menu, NextOrderingTime, ProductCardCreatorAvailabilityService, ProductCardCreatorPriceService, ProductInstancePreorderSettingsService, } from "../ordering";
import { OrderItemListTracking } from "./OrderItemListTracking";
import { PProductCardCreator } from "./PProductCardCreator";
import { PRestaurant } from "./PRestaurant";
let PProductCardCreatorFactory = class PProductCardCreatorFactory {
    constructor(globalConfig, localizationProvider, productCardCreatorAvailabilityService, halvingPolicyAvailabilityService, productInstancePreorderSettingsService, labelsCreatorFactory, productCardCreatorPriceService, restaurant, menu, orderItemListTracking, nextOrderingTime) {
        this.localizationProvider = localizationProvider;
        this.productCardCreatorAvailabilityService = productCardCreatorAvailabilityService;
        this.halvingPolicyAvailabilityService = halvingPolicyAvailabilityService;
        this.productInstancePreorderSettingsService = productInstancePreorderSettingsService;
        this.labelsCreatorFactory = labelsCreatorFactory;
        this.productCardCreatorPriceService = productCardCreatorPriceService;
        this.restaurant = restaurant;
        this.menu = menu;
        this.orderItemListTracking = orderItemListTracking;
        this.nextOrderingTime = nextOrderingTime;
        this.isIosReviewMode = globalConfig.isIosReviewMode;
        makeImmutable(this);
    }
    create(params) {
        return new PProductCardCreator({
            isIosReviewMode: this.isIosReviewMode,
            localizationProvider: this.localizationProvider,
            productCardCreatorAvailabilityService: this.productCardCreatorAvailabilityService,
            halvingPolicyAvailabilityService: this.halvingPolicyAvailabilityService,
            labelsCreatorFactory: this.labelsCreatorFactory,
            productCardCreatorPriceService: this.productCardCreatorPriceService,
            productInstancePreorderSettingsService: this.productInstancePreorderSettingsService,
            restaurant: this.restaurant,
            productTypes: this.menu.productTypes,
            domain: params.domain,
            productInstanceCreator: params.productInstanceCreator,
            crossSellingCreator: params.crossSellingCreator,
            fulfillmentTime: params.fulfillmentTime,
            orderItemListTracking: this.orderItemListTracking,
            nextOrderingTime: this.nextOrderingTime,
        });
    }
};
PProductCardCreatorFactory = __decorate([
    perRestaurant(),
    __param(0, inject(GlobalConfig)),
    __metadata("design:paramtypes", [typeof (_a = typeof GlobalConfig !== "undefined" && GlobalConfig) === "function" ? _a : Object, typeof (_b = typeof LocalizationProvider !== "undefined" && LocalizationProvider) === "function" ? _b : Object, typeof (_c = typeof ProductCardCreatorAvailabilityService !== "undefined" && ProductCardCreatorAvailabilityService) === "function" ? _c : Object, typeof (_d = typeof HalvingPolicyAvailabilityService !== "undefined" && HalvingPolicyAvailabilityService) === "function" ? _d : Object, typeof (_e = typeof ProductInstancePreorderSettingsService !== "undefined" && ProductInstancePreorderSettingsService) === "function" ? _e : Object, typeof (_f = typeof LabelsCreatorFactory !== "undefined" && LabelsCreatorFactory) === "function" ? _f : Object, typeof (_g = typeof ProductCardCreatorPriceService !== "undefined" && ProductCardCreatorPriceService) === "function" ? _g : Object, typeof (_h = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _h : Object, typeof (_j = typeof Menu !== "undefined" && Menu) === "function" ? _j : Object, typeof (_k = typeof OrderItemListTracking !== "undefined" && OrderItemListTracking) === "function" ? _k : Object, typeof (_l = typeof NextOrderingTime !== "undefined" && NextOrderingTime) === "function" ? _l : Object])
], PProductCardCreatorFactory);
export { PProductCardCreatorFactory };
