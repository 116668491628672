var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { fromPairs } from "../../collections";
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
let ProductInstanceCheckoutDataService = class ProductInstanceCheckoutDataService {
    constructor() {
        makeImmutable(this);
    }
    checkoutData(productInstance) {
        return {
            ...this.productConfigCheckoutData(productInstance.commonConfig),
            parts: this.productInstancePartsCheckoutData(productInstance.parts),
        };
    }
    productConfigCheckoutData(productConfig) {
        const serializedCustomParameters = productConfig.customParameterInstances.objects.mapOptional((customParameterInstance) => {
            const selectedChoice = customParameterInstance.selectedChoice;
            if (!selectedChoice) {
                return null;
            }
            return [customParameterInstance.typeId.value, selectedChoice.value];
        });
        const serializedModifiers = productConfig.modifierInstances.objects.map((modifierInstance) => [
            modifierInstance.typeId.value,
            modifierInstance.selectedItemIds.map((x) => x.value).raw,
        ]);
        return {
            customParameters: fromPairs(serializedCustomParameters),
            modifiers: fromPairs(serializedModifiers),
        };
    }
    productInstancePartsCheckoutData(productInstanceParts) {
        switch (productInstanceParts.type) {
            case "SinglePart":
                return [
                    this.productInstancePartCheckoutData(productInstanceParts.part),
                ];
            case "Halves":
                return [
                    this.productInstancePartCheckoutData(productInstanceParts.first),
                    this.productInstancePartCheckoutData(productInstanceParts.second),
                ];
        }
    }
    productInstancePartCheckoutData(productInstancePart) {
        return {
            productId: productInstancePart.productTypeId.value,
            ...this.productConfigCheckoutData(productInstancePart.productConfig),
        };
    }
};
ProductInstanceCheckoutDataService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [])
], ProductInstanceCheckoutDataService);
export { ProductInstanceCheckoutDataService };
