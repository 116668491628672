import { makeAutoObservable } from "mobx";
import { ParameterSet } from "../Menu";
import { CustomParameterInstances } from "../ProductInstance";
export class ProductConfigCreatorScope {
    constructor(params) {
        this.parent = params.parentScope;
        this.customParameterInstanceCreators =
            params.customParameterInstanceCreators;
        makeAutoObservable(this);
    }
    get parameterSet() {
        const selfParameterSet = new ParameterSet({
            custom: CustomParameterInstances.toCustomParameterSet(this.customParameterInstanceCreators),
        });
        const result = this.parent.parameterSet.union(selfParameterSet);
        if (result === null) {
            throw new Error("Incompatible parameter scopes");
        }
        return result;
    }
}
