import { makeImmutable } from "../core";
import { UninitializedParameterScope } from "./UninitializedParameterScope";
export class ParameterScope {
    constructor(params) {
        this.parameterSet = params.parameterSet;
        this.fulfillmentTime = params.fulfillmentTime;
        makeImmutable(this);
    }
    static fromParameterSet(parameterSet) {
        return new ParameterScope({
            parameterSet,
            fulfillmentTime: null,
        });
    }
}
ParameterScope.Uninitialized = new UninitializedParameterScope();
