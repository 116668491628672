import { makeImmutable } from "../core";
export class UninitializedParameterScope {
    constructor() {
        makeImmutable(this);
    }
    get now() {
        throw new Error("Uninitialized parameter scope");
    }
    get parameterSet() {
        throw new Error("Uninitialized parameter scope");
    }
    get fulfillmentTime() {
        throw new Error("Uninitialized parameter scope");
    }
}
