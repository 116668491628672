import { fromPairs, IdentifiableMap, RArray } from "../../collections";
import { makeImmutable } from "../../core";
/**
 * A modifier configuration which can contain multiple ModifierItems from a ModifierType.
 *
 * Can contain duplicates, but they can be constrained by a max value.
 *
 * For example: "ketchup x3, salsa x1, garlic x2"
 */
export class ModifierInstance {
    constructor(params) {
        this.scope = params.scope;
        this.modifierType = params.modifierType;
        this.items = params.items;
        this.freeItems = params.modifierSettings.freeItems;
        makeImmutable(this);
    }
    get selectedItems() {
        return this.items.filter((itemInstance) => itemInstance.isSelected).objects;
    }
    eq(other) {
        return (this.typeId.eq(other.typeId) &&
            IdentifiableMap.eq("typeId", this.items, other.items));
    }
    get typeId() {
        return this.modifierType.id;
    }
    get selectedItemIds() {
        return this.items.objects.flatMap(({ typeId, quantity }) => RArray.repeat(typeId, quantity));
    }
    get storageData() {
        return {
            items: fromPairs(this.items.objects.map((modifierItemInstance) => [
                modifierItemInstance.typeId.value,
                {
                    quantity: modifierItemInstance.quantity.value,
                },
            ])),
        };
    }
}
