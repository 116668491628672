import { makeAutoObservable } from "mobx";
import { InfiniteScrollStrategy, PaginationStrategy, } from "./NavigationStrategy";
import { getItemsFirstDate as getItemsFirstDay, getItemsLastDate as getItemsLastDay, } from "./utils";
export class PFulfillmentTimeNavigation {
    constructor(params) {
        this.strategy = params.strategy;
        this.minDay = params.minDay;
        this.maxDay = params.maxDay;
        this.getDates = params.getDates;
        makeAutoObservable(this);
    }
    static createPaginated(params) {
        return new PFulfillmentTimeNavigation({
            strategy: new PaginationStrategy({ pageSize: params.pageSize }),
            minDay: params.minDay,
            maxDay: params.maxDay,
            getDates: params.getDates,
        });
    }
    static createInfinityScroll(params) {
        return new PFulfillmentTimeNavigation({
            strategy: new InfiniteScrollStrategy({ pageSize: params.pageSize }),
            minDay: params.minDay,
            maxDay: params.maxDay,
            getDates: params.getDates,
        });
    }
    get type() {
        return this.strategy.type;
    }
    getInit(from) {
        return this._getNextItems(from);
    }
    getNext(currentItems) {
        const lastDate = getItemsLastDay(currentItems);
        if (!lastDate) {
            return [];
        }
        const newItems = this._getNextItems(lastDate.addDays(1));
        return this.strategy.getNext(currentItems, newItems);
    }
    getPrev(currentItems) {
        const firstDate = getItemsFirstDay(currentItems);
        if (!firstDate) {
            return [];
        }
        const newItems = this._getPrevItems(firstDate.addDays(-1));
        return this.strategy.getPrev(currentItems, newItems);
    }
    _getPrevItems(from) {
        const to = from.addDays(-this.strategy.pageSize + 1);
        const dateItems = this.getDatesWithinBoundary(to, from);
        let groupped = groupDisabledItems(dateItems);
        while (groupped.length < this.strategy.pageSize) {
            const firstDay = getItemsFirstDay(groupped);
            if (!firstDay ||
                firstDay.isSame(this.minDay) ||
                firstDay.isBefore(this.minDay)) {
                break;
            }
            const missingItemsNumber = this.strategy.pageSize - groupped.length;
            const newItems = this.getDatesWithinBoundary(firstDay.addDays(-missingItemsNumber), firstDay.addDays(-1));
            if (newItems.length > 0) {
                groupped = groupDisabledItems([...newItems, ...groupped]);
            }
            else {
                break;
            }
        }
        const grouppedAndTrimmedByPreorder = groupped.filter((dateItem) => dateItem.type === "Disabled" && dateItem.to.isBefore(this.minDay)
            ? false
            : true);
        return grouppedAndTrimmedByPreorder;
    }
    _getNextItems(from) {
        const to = from.addDays(this.strategy.pageSize - 1);
        const dateItems = this.getDatesWithinBoundary(from, to);
        let groupped = groupDisabledItems(dateItems);
        while (groupped.length < this.strategy.pageSize) {
            const lastDay = getItemsLastDay(groupped);
            if (!lastDay ||
                lastDay.isSame(this.maxDay) ||
                lastDay.isAfter(this.maxDay)) {
                break;
            }
            const missingItemsNumber = this.strategy.pageSize - groupped.length;
            const newItems = this.getDatesWithinBoundary(lastDay.addDays(1), lastDay.addDays(missingItemsNumber));
            if (newItems.length > 0) {
                groupped = groupDisabledItems([...groupped, ...newItems]);
            }
            else {
                break;
            }
        }
        const grouppedAndTrimmedByPreorder = groupped.filter((dateItem) => dateItem.type === "Disabled" && dateItem.from.isAfter(this.maxDay)
            ? false
            : true);
        return grouppedAndTrimmedByPreorder;
    }
    getDatesWithinBoundary(from, to) {
        return this.getDates(from.max(this.minDay), to.min(this.maxDay));
    }
}
function groupDisabledItems(dateItems) {
    return dateItems.reduce((result, currentItem) => {
        const prevItem = result[result.length - 1];
        if (currentItem.type === "Disabled" &&
            prevItem !== undefined &&
            prevItem.type === "Disabled") {
            // Extend the date range of the last disabled item
            prevItem.to = currentItem.to;
        }
        else {
            result.push(currentItem);
        }
        return result;
    }, []);
}
