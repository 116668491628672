var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { makeAutoObservable } from "mobx";
import { perRestaurant } from "../../di";
import { IdMap } from "../../collections";
import { PurchaseOrderPayload } from "../PurchaseOrderPayload";
import { OrderLinePackingService } from "./OrderLinePackingService";
let PurchaseOrderPayloadPackingService = class PurchaseOrderPayloadPackingService {
    constructor(payload, orderLinePackingService) {
        this.payload = payload;
        this.orderLinePackingService = orderLinePackingService;
        makeAutoObservable(this);
    }
    get packingItems() {
        return this.payload.orderLines
            .map((orderLine) => this.orderLinePackingService.packingItems(orderLine))
            .reduce((prev, curr) => prev.unionWith(curr, (a, b) => a.merge(b)), IdMap.empty());
    }
};
PurchaseOrderPayloadPackingService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _a : Object, typeof (_b = typeof OrderLinePackingService !== "undefined" && OrderLinePackingService) === "function" ? _b : Object])
], PurchaseOrderPayloadPackingService);
export { PurchaseOrderPayloadPackingService };
