import { makeImmutable } from "../core";
export class OrderLineReference {
    constructor(params) {
        this.orderLineIndex = params.orderLineIndex;
        this.productInstanceIndex = params.productInstanceIndex;
        this.productInstance = params.productInstance;
        makeImmutable(this);
    }
    static fromStorageData({ purchaseOrderPayload, storageData, }) {
        const orderLine = purchaseOrderPayload.orderLines.find(storageData.orderLineIndex);
        if (orderLine === null ||
            storageData.productInstanceIndex >= orderLine.quantity.value) {
            return null;
        }
        return new OrderLineReference({
            orderLineIndex: storageData.orderLineIndex,
            productInstanceIndex: storageData.productInstanceIndex,
            productInstance: orderLine.productInstance,
        });
    }
    eq(other) {
        return (this.orderLineIndex === other.orderLineIndex &&
            this.productInstanceIndex === other.productInstanceIndex);
    }
    get storageData() {
        return {
            orderLineIndex: this.orderLineIndex,
            productInstanceIndex: this.productInstanceIndex,
        };
    }
}
