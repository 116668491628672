import { makeAutoObservable, reaction } from "mobx";
import { RArray } from "../collections";
import { OrderItemListData, Tracking } from "../tracking";
export class OrderItemListTracking {
    constructor(params) {
        this.orderLinePriceService = params.orderLinePriceService;
        this.data = params.data;
        this.orderItemListStorageManager = params.orderItemListStorageManager;
        this.purchaseOrderPayload = params.purchaseOrderPayload;
        this.productTypes = params.productTypes;
        makeAutoObservable(this);
        this.initializeStorageManager();
        this.initializeEvents();
    }
    static create(params) {
        const storageData = params.orderItemListStorageManager.restore();
        const itemListData = storageData !== null
            ? OrderItemListData.fromStorageData(storageData)
            : OrderItemListData.empty();
        return new OrderItemListTracking({
            orderLinePriceService: params.orderLinePriceService,
            data: itemListData,
            orderItemListStorageManager: params.orderItemListStorageManager,
            purchaseOrderPayload: params.purchaseOrderPayload,
            productTypes: params.productTypes,
        });
    }
    initializeStorageManager() {
        reaction(() => this.data.storageData, (storageData) => {
            this.orderItemListStorageManager.store(storageData);
        });
    }
    initializeEvents() {
        reaction(() => this.purchaseOrderPayload.orderLines, (orderLines, prevOrderLines) => {
            // Track "AddToCart" event
            getOrderLinesDiff(orderLines, prevOrderLines)
                .map((orderLine) => this.orderLineToAnalyticsOrderItem(orderLine))
                .forEach((contents) => {
                Tracking.trackEvent({ tag: "AddToCart", contents });
            });
            // Track "RemoveFromCart" event
            getOrderLinesDiff(prevOrderLines, orderLines)
                .map((orderLine) => this.orderLineToAnalyticsOrderItem(orderLine))
                .forEach((contents) => {
                Tracking.trackEvent({ tag: "RemoveFromCart", contents });
            });
        });
    }
    orderLineToAnalyticsOrderItem(orderLine) {
        var _a, _b;
        const productTypes = new RArray(orderLine.productTypeIds.rawValues()).mapOptional((productTypeId) => this.productTypes.find(productTypeId));
        return {
            name: productTypes.map((productType) => productType.name).raw.join("/"),
            price: (_b = (_a = this.orderLinePriceService.unitPrice(orderLine).finalValue) === null || _a === void 0 ? void 0 : _a.asNumber) !== null && _b !== void 0 ? _b : 0,
            // NOTICE: Domain decrements orderLine's quantity first and separately filters visible orderLines with "not empty" rule
            // So there is a case when orderLine's quantity is 0. But for analytics we need to track it as 1 to compute order line value (price * quantity)
            // To fix this we can create a subscribtion based orderLine list & observe it's changes to send events (before domain decrements quantity)
            quantity: orderLine.quantity.value === 0 ? 1 : orderLine.quantity.value,
            category: productTypes.get(0).categoryName,
            ids: productTypes.map((productType) => productType.id.toString()).raw,
            list: this.data.get(orderLine.productInstance.trackingId),
        };
    }
    collectCreatorData(productCardCreator) {
        this.collectProductData(productCardCreator.productInstanceCreator.instance.trackingId, { tag: "Menu" });
        productCardCreator.crossSellingCreator.selectedItems.forEach(([creator, _, kind]) => this.collectProductData(creator.instance.trackingId, {
            tag: "CrossSelling",
            place: "Creator",
            kind,
        }));
    }
    collectProductData(trackingId, list) {
        this.data.set(trackingId, list);
    }
    getData(id) {
        return this.data.get(id);
    }
}
function getOrderLinesDiff(a, b) {
    const bTrackingIds = b.map((bItem) => bItem.productInstance.trackingId);
    return a.filtered((aItem) => !bTrackingIds.includes(aItem.productInstance.trackingId));
}
