var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { LocalizationProvider } from "../locale";
import { CrossSellingItemInstancePriceService, } from "../ordering";
import { OrderItemListTracking } from "./OrderItemListTracking";
import { PCrossSellingCreator } from "./PCrossSellingCreator";
import { PProductInstanceCreatorFactory } from "./PProductInstanceCreatorFactory";
import { PRestaurant } from "./PRestaurant";
let PCrossSellingCreatorFactory = class PCrossSellingCreatorFactory {
    constructor(restaurant, localizationProvider, orderItemListTracking, pProductInstanceCreatorFactory, crossSellingItemInstancePriceService) {
        this.restaurant = restaurant;
        this.localizationProvider = localizationProvider;
        this.orderItemListTracking = orderItemListTracking;
        this.pProductInstanceCreatorFactory = pProductInstanceCreatorFactory;
        this.crossSellingItemInstancePriceService = crossSellingItemInstancePriceService;
        makeImmutable(this);
    }
    create(domain) {
        return new PCrossSellingCreator(domain, this.restaurant, this.localizationProvider, this.orderItemListTracking, this.pProductInstanceCreatorFactory, this.crossSellingItemInstancePriceService);
    }
};
PCrossSellingCreatorFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _a : Object, typeof (_b = typeof LocalizationProvider !== "undefined" && LocalizationProvider) === "function" ? _b : Object, typeof (_c = typeof OrderItemListTracking !== "undefined" && OrderItemListTracking) === "function" ? _c : Object, typeof (_d = typeof PProductInstanceCreatorFactory !== "undefined" && PProductInstanceCreatorFactory) === "function" ? _d : Object, typeof (_e = typeof CrossSellingItemInstancePriceService !== "undefined" && CrossSellingItemInstancePriceService) === "function" ? _e : Object])
], PCrossSellingCreatorFactory);
export { PCrossSellingCreatorFactory };
