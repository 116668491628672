var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { makeImmutable, Quantity } from "../core";
import { perRestaurant } from "../di";
import { OrderLine } from "./OrderLine";
import { ProductInstanceFactory } from "./ProductInstance";
import { ProductInstanceCreatorFactory } from "./ProductInstanceCreator";
import { PurchaseOrderScope } from "./PurchaseOrderScope";
let OrderLineFactory = class OrderLineFactory {
    constructor(productInstanceFactory, productInstanceCreatorFactory, purchaseOrderScope) {
        this.productInstanceFactory = productInstanceFactory;
        this.productInstanceCreatorFactory = productInstanceCreatorFactory;
        this.purchaseOrderScope = purchaseOrderScope;
        makeImmutable(this);
    }
    fromStorageData(storageData) {
        if (storageData.quantity < 1) {
            return null;
        }
        const productInstance = this.productInstanceFactory.fromStorageData(storageData.productInstance);
        if (productInstance === null) {
            return null;
        }
        const productInstanceCreator = this.productInstanceCreatorFactory.create(productInstance);
        productInstanceCreator.initWithScope(this.purchaseOrderScope);
        return new OrderLine({
            productInstanceCreator,
            quantity: Quantity.fromNumber(storageData.quantity),
        });
    }
};
OrderLineFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ProductInstanceFactory !== "undefined" && ProductInstanceFactory) === "function" ? _a : Object, typeof (_b = typeof ProductInstanceCreatorFactory !== "undefined" && ProductInstanceCreatorFactory) === "function" ? _b : Object, typeof (_c = typeof PurchaseOrderScope !== "undefined" && PurchaseOrderScope) === "function" ? _c : Object])
], OrderLineFactory);
export { OrderLineFactory };
