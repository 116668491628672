var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { RArray } from "../collections";
import { makeImmutable } from "../core";
import { ParameterCombination, Parametrization, SalesChannel, } from "../ordering";
import { ParameterSetBuilder } from "./ParameterSetBuilder";
import { singleton } from "tsyringe";
let ParametrizationParser = class ParametrizationParser {
    constructor(salesChannel) {
        this.menuOrderOrigin = salesChannel.menuOrderOrigin;
        makeImmutable(this);
    }
    parseCombination(customParameterTypes, parameterCombinationArray, value) {
        const parameterCombination = new ParameterSetBuilder();
        for (const [parameterId, parameterChoiceId] of parameterCombinationArray) {
            if (parameterId.tag === "CustomParameter" &&
                parameterChoiceId.tag === "CustomParameterChoice") {
                const customParameterType = customParameterTypes.rawGet(parameterId.contents);
                const choiceId = customParameterType.choices.rawFind(parameterChoiceId.contents);
                if (choiceId === null) {
                    return null;
                }
                parameterCombination.setCustomParameter(customParameterType, choiceId.id);
            }
            else if (parameterId.tag === "InternalParameter" &&
                parameterChoiceId.tag === "InternalParameterChoice") {
                const choice = parameterChoiceId.contents;
                if (choice.tag !== `${parameterId.contents.tag}Choice`) {
                    throw new Error("Impossible happened");
                }
                parameterCombination.setInternalParameter(choice);
            }
        }
        const premise = parameterCombination.build(this.menuOrderOrigin);
        if (premise === null) {
            return null;
        }
        return new ParameterCombination({ premise, value });
    }
    parse(customParameterTypes, parametric) {
        const parameterCombinations = new RArray(parametric.values).mapOptional(([parameterCombinationArray, value]) => this.parseCombination(customParameterTypes, parameterCombinationArray, value));
        return Parametrization.fromParameterCombinations(parameterCombinations);
    }
};
ParametrizationParser = __decorate([
    singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof SalesChannel !== "undefined" && SalesChannel) === "function" ? _a : Object])
], ParametrizationParser);
export { ParametrizationParser };
