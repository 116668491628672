import { translate, t } from "../I18n";
export function formatFulfillmentMethodType(type) {
    switch (type) {
        case "Delivery":
            return translate(t.delivery_method.delivery);
        case "NoContactDelivery":
            return translate(t.delivery_method.no_contact_delivery);
        case "Takeaway":
            return translate(t.delivery_method.takeaway);
        case "DineIn":
            return translate(t.delivery_method.dine_in);
    }
}
export function formatFulfillmentMethodTypeShort(type) {
    switch (type) {
        case "Delivery":
            return translate(t.delivery_method.delivery);
        case "NoContactDelivery":
            return translate(t.delivery_method.no_contact_delivery);
        case "Takeaway":
            return translate(t.delivery_method.takeaway_short);
        case "DineIn":
            return translate(t.delivery_method.dine_in_short);
    }
}
