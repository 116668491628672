var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { flow, makeAutoObservable } from "mobx";
import { singleton } from "tsyringe";
import { result } from "../../core";
import { VALID_ADDRESS_STATUS_TYPES, } from "../DeliveryAddress";
import { GeocodingService } from "../GeocodingService";
import { StreetAddressAvailabilityService } from "./StreetAddressAvailabilityService";
let DeliveryAddressStatus = class DeliveryAddressStatus {
    constructor(streetAddressAvailabilityService, geocodingService) {
        this.streetAddressAvailabilityService = streetAddressAvailabilityService;
        this.geocodingService = geocodingService;
        this._state = { type: "NotRequested" };
        makeAutoObservable(this, { validate: flow });
    }
    *validate(restaurantId, requestedStreetAddress) {
        const streetAddressAvailability = this.streetAddressAvailabilityService.availability(requestedStreetAddress);
        const addressInvalid = streetAddressAvailability.isAvailable.isDefinitelyFalse;
        if (addressInvalid) {
            this._state = { type: "InvalidAddress" };
            return false;
        }
        this._state = { type: "Loading" };
        try {
            this._state = yield* result(this.geocodingService.getDeliveryAddressStatus(restaurantId, requestedStreetAddress));
            return VALID_ADDRESS_STATUS_TYPES.includes(this._state.type);
        }
        catch {
            this._state = { type: "NetworkError" };
        }
        return false;
    }
    get state() {
        return this._state;
    }
};
DeliveryAddressStatus = __decorate([
    singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof StreetAddressAvailabilityService !== "undefined" && StreetAddressAvailabilityService) === "function" ? _a : Object, typeof (_b = typeof GeocodingService !== "undefined" && GeocodingService) === "function" ? _b : Object])
], DeliveryAddressStatus);
export { DeliveryAddressStatus };
