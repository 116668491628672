import { makeAutoObservable } from "mobx";
import { PProductError } from "./PProductError";
import { PHalvesProductInstanceCreator, PSinglePartProductInstanceCreator, } from "./PProductInstanceCreatorState";
import { PProductUnavailabilityDetails } from "./PProductInstanceUnavailabilityDetails";
export class PProductInstanceCreator {
    constructor(params) {
        this.domain = params.domain;
        this.pProductInstanceCreatorPartFactory =
            params.pProductInstanceCreatorPartFactory;
        this.pProductConfigCreatorFactory = params.pProductConfigCreatorFactory;
        this.productInstanceAvailabilityService =
            params.productInstanceAvailabilityService;
        this.restaurant = params.restaurant;
        this.possibleProductTypes = params.possibleProductTypes;
        this._state = this.createState();
        makeAutoObservable(this);
    }
    get scope() {
        return this.domain.scope;
    }
    get instance() {
        return this.domain.instance;
    }
    commonModifierOrder(productTypeIds) {
        const restaurantProductTypes = this.restaurant.productTypes;
        return productTypeIds
            .flatMap((productTypeId) => restaurantProductTypes.get(productTypeId).divisibleModifiersOrder)
            .filter((modifierTypeId, index, array) => array.indexOf(modifierTypeId) === index);
    }
    createState() {
        const parts = this.domain.state;
        const productTypeIds = parts.productTypeIds.rawValues();
        const commonConfigCreator = this.pProductConfigCreatorFactory.create({
            domain: parts.commonConfigCreator,
            modifierOrder: this.commonModifierOrder(productTypeIds),
        });
        if (parts.isSplit) {
            const firstHalf = this.partCreator(parts.firstHalf);
            const secondHalf = this.partCreator(parts.secondHalf);
            return new PHalvesProductInstanceCreator({
                commonConfigCreator,
                firstHalf,
                secondHalf,
            });
        }
        else {
            const part = this.partCreator(parts.partCreator);
            return new PSinglePartProductInstanceCreator({
                commonConfigCreator,
                part,
            });
        }
    }
    get state() {
        return this._state;
    }
    partCreator(part) {
        return this.pProductInstanceCreatorPartFactory.create({
            domain: part,
            productTypeId: part.productType.id,
            possibleProductTypes: this.possibleProductTypes,
        });
    }
    get product() {
        return this.state.product;
    }
    updateState() {
        this._state = this.createState();
    }
    get error() {
        const parts = this.domain.state.productInstanceParts;
        const partsAvailability = this.productInstanceAvailabilityService.productInstancePartsAvailability(parts);
        if (parts.type === "Halves" &&
            partsAvailability.unavailabilityReasons.includes("HalvingAvailability")) {
            const availability = this.productInstanceAvailabilityService.availability(this.domain.instance);
            const { parametersDetails } = new PProductUnavailabilityDetails({
                availability,
                restaurant: this.restaurant,
            });
            if (parametersDetails.length > 0) {
                return PProductError.notDivisible(parametersDetails);
            }
        }
        return null;
    }
    touch() {
        this.state.touch();
    }
}
