import { makeAutoObservable } from "mobx";
import { RArray } from "../collections";
import { PAY_PO_CHANNEL_ID } from "./P24";
/**
 * A parent container for all information about the restaurant (menu, timezone, etc...)
 */
export class Restaurant {
    constructor(params) {
        this.id = params.id;
        this.menu = params.menu;
        this.menuConsumer = params.menuConsumer;
        this.orderingPolicy = params.orderingPolicy;
        this.minOrderValue = params.minOrderValue;
        this.fulfillmentMethods = params.fulfillmentMethods;
        this.paymentMethods = params.paymentMethods;
        this._paymentChannels = params.paymentChannels;
        makeAutoObservable(this);
    }
    get paymentChannels() {
        if (!this.paymentMethods.includes("Online")) {
            return RArray.empty();
        }
        if (this.menuConsumer === "QrWaiter") {
            return this._paymentChannels.filtered((channel) => {
                return !(channel.id.tag === "P24Channel" &&
                    channel.id.contents === PAY_PO_CHANNEL_ID);
            });
        }
        return this._paymentChannels;
    }
}
