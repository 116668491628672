import { makeImmutable } from "../core";
import { Availability } from "./Availability";
// Regex only for PL
const POSTCODE_REGEX = /^[0-9]{2}-[0-9]{3}$/;
/**
 * Post Code
 */
export class PostCode {
    constructor(value) {
        this.value = value;
        makeImmutable(this);
    }
    static empty() {
        return new PostCode("");
    }
    get isEmpty() {
        return this.value.length === 0;
    }
    toString() {
        return this.value;
    }
    // TODO & FIXME: works only for PL country
    get valid() {
        return POSTCODE_REGEX.test(this.value);
    }
    get availability() {
        return Availability.boolean({
            PostCodeMissing: this.isEmpty,
            InvalidPostCode: !this.isEmpty && !this.valid,
        });
    }
}
