import { Available } from "./Available";
import { BooleanAvailability } from "./BooleanAvailability";
import { CompositeAvailability } from "./CompositeAvailability";
import { DependentAvailability } from "./DependentAvailability";
import { Maybe } from "./Maybe";
import { Unavailable } from "./Unavailable";
/*
 * Collection of various domain objects availability calculation strategies
 */
export const Availability = {
    available() {
        return new Available();
    },
    maybe(reason) {
        return new Maybe(reason);
    },
    unavailable(reason) {
        return new Unavailable(reason);
    },
    composite(childAvailabilities) {
        return new CompositeAvailability(childAvailabilities);
    },
    boolean(booleanMap) {
        return new BooleanAvailability(booleanMap);
    },
    dependent(reason, description, dependency) {
        return new DependentAvailability({ reason, description, dependency });
    },
};
