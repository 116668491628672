import { makeImmutable } from "../../core";
import { ProductInstancePrice } from "./ProductInstancePrice";
export class ProductInstancePriceService {
    constructor() {
        makeImmutable(this);
    }
    price(productInstance) {
        return ProductInstancePrice.price(ProductInstancePrice.priceSelector(productInstance));
    }
}
