import { makeAutoObservable } from "mobx";
export class PModifierSectionCreator {
    constructor(params) {
        this.modifierItemSection = params.modifierItemSection;
        this.allItemCreators = params.modifierItemInstanceCreators;
        makeAutoObservable(this);
    }
    get touched() {
        return this.itemCreators.some((item) => item.touched);
    }
    get isVisible() {
        return this.itemCreators.length > 0;
    }
    get hasName() {
        return this.modifierItemSection.hasName;
    }
    get name() {
        return this.modifierItemSection.name;
    }
    get itemCreators() {
        return this.allItemCreators.filter((item) => item.isVisible);
    }
}
