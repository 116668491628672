var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { makeImmutable } from "../../../core";
import { perRestaurant } from "../../../di";
import { ApartmentInfoAvailabilityService } from "../../../ordering";
import { PDeliveryAddress } from "../../PDeliveryAddress";
import { DefaultApartmentInfoController } from "./DefaultApartmentInfoController";
import { RomanianApartmentInfoController } from "./RomanianApartmentInfoController";
let ApartmentInfoControllerFactory = class ApartmentInfoControllerFactory {
    constructor(deliveryAddress, apartmentInfoAvailabilityService) {
        this.deliveryAddress = deliveryAddress;
        this.apartmentInfoAvailabilityService = apartmentInfoAvailabilityService;
        makeImmutable(this);
    }
    create() {
        const { apartmentInfo } = this.deliveryAddress;
        const onBlur = () => {
            this.deliveryAddress.saveApartmentInfo();
        };
        switch (apartmentInfo.type) {
            case "Default":
                return new DefaultApartmentInfoController({
                    apartmentInfo,
                    apartmentInfoAvailabilityService: this.apartmentInfoAvailabilityService,
                    onBlur,
                });
            case "Romanian":
                return new RomanianApartmentInfoController({
                    apartmentInfo: apartmentInfo,
                    apartmentInfoAvailabilityService: this.apartmentInfoAvailabilityService,
                    onBlur,
                });
        }
    }
};
ApartmentInfoControllerFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PDeliveryAddress !== "undefined" && PDeliveryAddress) === "function" ? _a : Object, typeof (_b = typeof ApartmentInfoAvailabilityService !== "undefined" && ApartmentInfoAvailabilityService) === "function" ? _b : Object])
], ApartmentInfoControllerFactory);
export { ApartmentInfoControllerFactory };
