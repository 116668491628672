var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a;
import { inject, singleton } from "tsyringe";
import { RArray, RSet, UnknownElementError, WeeklySchedule, } from "../collections";
import { MenuConsumer } from "../config";
import { DateRange, makeImmutable, Money, Percentage, RDate, } from "../core";
import { PromotionTypeId, } from "./Identifiers";
import { InternalParameterSet, ParameterCombination, ParameterSet, Parametrization, } from "./Menu";
import { PositiveQuantity } from "./PositiveQuantity";
import { ProductPromotionSelector, } from "./ProductPromotionSelector";
import { ProductPromotionScope, WholeOrderPromotionScope, } from "./PromotionScope";
import { PromotionFreebie, PromotionType, } from "./PromotionType";
import { UnsupportedWholeOrderPromotionSelector, WholeOrderPromotionSelector, } from "./WholeOrderPromotionSelector";
const AVAILABLE_AT_TO_FULFIMENT_METHOD = {
    Everywhere: "Everywhere",
    DeliveryOnly: RArray.singleton("Delivery"),
    RestaurantOnly: RArray.singleton("DineIn"),
    TakeawayOnly: RArray.singleton("Takeaway"),
    DeliveryAndTakeaway: new RArray(["Delivery", "Takeaway"]),
    RestaurantAndTakeaway: new RArray(["DineIn", "Takeaway"]),
};
let PromotionParser = class PromotionParser {
    constructor(menuConsumer) {
        this.menuConsumer = menuConsumer;
        makeImmutable(this);
    }
    parse(params) {
        if (!params.promotion.enabledMenuConsumers.some((menuConsumer) => menuConsumer === this.menuConsumer)) {
            return null;
        }
        if (params.promotion.promotionType === "InformationOnly") {
            // NOTICE InformationOnly promotions don't influence ordering in any way
            return null;
        }
        try {
            return new PromotionType({
                id: new PromotionTypeId(params.promotion.id),
                autoselect: params.promotion.enableAutoselect,
                availabilityParametrization: this.parseAvailableAt(params.promotion.availableAt),
                availabilitySchedule: WeeklySchedule.fromWeeklyAvailability(params.promotion.availability),
                effect: this.parseEffect(params.promotion.prize),
                scope: this.parseScope(params.productTypeIds, params.productCategoryIds, params.customParameterTypes, params.promotion.conditions),
                combinesWith: RSet.fromIterable(params.promotion.combineTo.contents.map((promotionTypeId) => new PromotionTypeId(promotionTypeId))),
                requiresMarketing: params.promotion.requiresMarketing,
                period: DateRange.fromDates({
                    begin: RDate.fromLocalTimeString(params.promotion.start, params.timezone).startOfDay(),
                    end: RDate.fromLocalTimeString(params.promotion.finish, params.timezone).endOfDay(),
                }),
            });
        }
        catch (error) {
            // FIXME Filter invalid promotions in higgs and remove this catch
            if (error instanceof UnknownElementError ||
                error instanceof UnsupportedWholeOrderPromotionSelector) {
                return null;
            }
            throw error;
        }
    }
    parseAvailableAt(availableAt) {
        const fulfillmentMethods = AVAILABLE_AT_TO_FULFIMENT_METHOD[availableAt];
        if (fulfillmentMethods === "Everywhere") {
            return Parametrization.empty(true);
        }
        return Parametrization.fromParameterCombinations(fulfillmentMethods.map((fulfillmentMethod) => new ParameterCombination({
            premise: new ParameterSet({
                internal: new InternalParameterSet({
                    fulfillmentMethod,
                    splitToHalves: null,
                }),
            }),
            value: true,
        })));
    }
    parseEffect(prize) {
        switch (prize.tag) {
            case "Freeby":
                return {
                    type: "Freebie",
                    freebies: new RArray(prize.contents).map((f) => new PromotionFreebie({
                        name: f.productName,
                        value: Money.fromBackend(f.productValue),
                    })),
                };
            case "PercentageDiscount":
                return prize.contents > 0
                    ? {
                        type: "PercentageDiscount",
                        discount: Percentage.fromNumber(prize.contents),
                    }
                    : {
                        type: "PercentageIncrease",
                        increase: Percentage.fromNumber(-prize.contents),
                    };
            case "PriceDiscount":
                return prize.contents > 0
                    ? {
                        type: "MonetaryDiscount",
                        discount: Money.fromBackend(prize.contents),
                    }
                    : {
                        type: "MonetaryIncrease",
                        increase: Money.fromBackend(-prize.contents),
                    };
            case "TextInformation":
                return { type: "TextInformation" };
            case "CouponValue":
                return { type: "CouponValue" };
        }
    }
    parseCartPromotionScope(cartScope) {
        switch (cartScope.tag) {
            case "CartValue": {
                const minimumValue = Money.fromBackend(cartScope.minCartValue);
                return WholeOrderPromotionSelector.minimumValue(minimumValue);
            }
            case "Always":
                return WholeOrderPromotionSelector.everyOrder();
            case "Never":
                return WholeOrderPromotionSelector.noOrder();
            case "HasCoupon":
            case "FirstOrder":
            case "Loyalty":
                throw new UnsupportedWholeOrderPromotionSelector(cartScope);
        }
    }
    parseScope(productTypeIds, productCategoryIds, customParameterTypes, conditions) {
        switch (conditions.tag) {
            case "CartPromotion": {
                const { contents } = conditions;
                return new WholeOrderPromotionScope({
                    selector: this.parseCartPromotionScope(contents),
                });
            }
            case "ProductPromotion": {
                const { contents } = conditions;
                return new ProductPromotionScope({
                    selector: ProductPromotionSelector.composite(contents.productFilter.map((f) => this.parseSelector(productTypeIds, productCategoryIds, customParameterTypes, f))),
                    repeatable: contents.repeatable,
                    requiredItems: new PositiveQuantity(contents.numRequiredItems),
                });
            }
        }
    }
    parseSelector(productTypeIds, productCategoryIds, customParameterTypes, filter) {
        switch (filter.tag) {
            case "ItemIdFilter": {
                const filterIds = new RArray(filter.contents).mapOptional((id) => {
                    switch (id.tag) {
                        case "ComboItem":
                            throw new Error("ComboItems are not supported");
                        case "ProductItem":
                            return productTypeIds.rawFind(id.contents);
                    }
                });
                return ProductPromotionSelector.ofProductTypes(RSet.fromIterable(filterIds));
            }
            case "ProductCategoryIdFilter": {
                const categories = RSet.fromIterable(filter.contents.map((c) => productCategoryIds.rawGet(c)));
                return ProductPromotionSelector.fromCategories(categories);
            }
            case "ParameterValueFilter": {
                const customParameterType = customParameterTypes.rawGet(filter.contents[0]);
                const allowedChoices = new RArray(filter.contents[1]).mapOptional((choiceId) => customParameterType.choices.rawFind(choiceId));
                return ProductPromotionSelector.withCustomParameters(customParameterType.id, RSet.fromIterable(allowedChoices.map((choice) => choice.id)));
            }
            case "AnyComboFilter": {
                throw new Error("AnyComboFilter is not supported");
            }
        }
    }
};
PromotionParser = __decorate([
    singleton(),
    __param(0, inject(MenuConsumer)),
    __metadata("design:paramtypes", [typeof (_a = typeof MenuConsumer !== "undefined" && MenuConsumer) === "function" ? _a : Object])
], PromotionParser);
export { PromotionParser };
