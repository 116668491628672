var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { FulfillmentInstructions, FulfillmentInstructionsToken, } from "../FulfillmentInstructions";
import { DeliveryAddressCheckoutDataService } from "./DeliveryAddressCheckoutDataService";
import { inject } from "tsyringe";
import { UserNote } from "../UserNote";
let FulfillmentInstructionsCheckoutDataService = class FulfillmentInstructionsCheckoutDataService {
    constructor(fulfillmentInstructions, deliveryAddressCheckoutDataService, userNote) {
        this.fulfillmentInstructions = fulfillmentInstructions;
        this.deliveryAddressCheckoutDataService = deliveryAddressCheckoutDataService;
        this.userNote = userNote;
        makeImmutable(this);
    }
    get checkoutData() {
        switch (this.fulfillmentInstructions.type) {
            case "Online":
                return {
                    fulfillmentMethod: this.fulfillmentMethodCheckoutData,
                    requestedFulfillmentTime: this.fulfillmentInstructions.fulfillmentTime !== null
                        ? this.fulfillmentTimeCheckoutData(this.fulfillmentInstructions.fulfillmentTime)
                        : null,
                    userNote: this.userNoteCheckoutData,
                };
            case "QrWaiter":
                return {
                    fulfillmentMethod: {
                        tag: this.fulfillmentInstructions.fulfillmentMethodType,
                        location: this.fulfillmentInstructions.dineInLocation,
                    },
                    requestedFulfillmentTime: this.fulfillmentTimeCheckoutData(this.fulfillmentInstructions.fulfillmentTime),
                    userNote: this.userNoteCheckoutData,
                };
        }
    }
    get userNoteCheckoutData() {
        var _a;
        return (_a = this.userNote.value) !== null && _a !== void 0 ? _a : null;
    }
    fulfillmentTimeCheckoutData(fulfillmentTime) {
        switch (fulfillmentTime.type) {
            case "ASAP":
                return null;
            case "OnTime":
                return fulfillmentTime.date.toString();
        }
    }
    get fulfillmentMethodCheckoutData() {
        // TODO: default fulfillmentMethodType?
        if (this.fulfillmentInstructions.fulfillmentMethodType === null) {
            return {
                tag: "DineIn",
                location: null,
            };
        }
        switch (this.fulfillmentInstructions.fulfillmentMethodType) {
            case "Delivery":
                return {
                    tag: this.fulfillmentInstructions.fulfillmentMethodType,
                    deliveryAddress: this.deliveryAddressCheckoutDataService.checkoutData(this.fulfillmentInstructions.deliveryAddress),
                    customDeliveryPrice: null,
                };
            case "NoContactDelivery":
                return {
                    tag: this.fulfillmentInstructions.fulfillmentMethodType,
                    deliveryAddress: this.deliveryAddressCheckoutDataService.checkoutData(this.fulfillmentInstructions.deliveryAddress),
                };
            case "DineIn":
                return {
                    tag: this.fulfillmentInstructions.fulfillmentMethodType,
                    location: null,
                };
            case "Takeaway":
                return {
                    tag: this.fulfillmentInstructions.fulfillmentMethodType,
                };
        }
    }
};
FulfillmentInstructionsCheckoutDataService = __decorate([
    perRestaurant(),
    __param(0, inject(FulfillmentInstructionsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof FulfillmentInstructions !== "undefined" && FulfillmentInstructions) === "function" ? _a : Object, typeof (_b = typeof DeliveryAddressCheckoutDataService !== "undefined" && DeliveryAddressCheckoutDataService) === "function" ? _b : Object, typeof (_c = typeof UserNote !== "undefined" && UserNote) === "function" ? _c : Object])
], FulfillmentInstructionsCheckoutDataService);
export { FulfillmentInstructionsCheckoutDataService };
