var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { LocalizationProvider } from "../locale";
import { CustomParameterInstanceAvailabilityService, ModifierInstanceAvailabilityService, ModifierInstancePriceService, ProductConfigAvailabilityService, } from "../ordering";
import { PCustomParameterInstanceCreator } from "./PCustomParameterInstanceCreator";
import { PModifierInstanceCreator } from "./PModifierInstanceCreator";
import { PModifierSectionCreatorFactory } from "./PModifierSectionCreatorFactory";
import { PProductConfigCreator } from "./PProductConfigCreator";
import { PRestaurant } from "./PRestaurant";
let PProductConfigCreatorFactory = class PProductConfigCreatorFactory {
    constructor(localizationProvider, productConfigAvailabilityService, customParameterInstanceAvailabilityService, modifierInstanceAvailabilityService, restaurant, pModifierSectionCreatorFactory, modifierInstancePriceService) {
        this.localizationProvider = localizationProvider;
        this.productConfigAvailabilityService = productConfigAvailabilityService;
        this.customParameterInstanceAvailabilityService = customParameterInstanceAvailabilityService;
        this.modifierInstanceAvailabilityService = modifierInstanceAvailabilityService;
        this.restaurant = restaurant;
        this.pModifierSectionCreatorFactory = pModifierSectionCreatorFactory;
        this.modifierInstancePriceService = modifierInstancePriceService;
        makeImmutable(this);
    }
    createModifierInstanceCreator(domain) {
        return new PModifierInstanceCreator({
            domain,
            modifierInstanceAvailabilityService: this.modifierInstanceAvailabilityService,
            modifierInstancePriceService: this.modifierInstancePriceService,
            modifierType: this.restaurant.modifierTypes.get(domain.typeId),
            localizationProvider: this.localizationProvider,
            pModifierSectionCreatorFactory: this.pModifierSectionCreatorFactory,
        });
    }
    createCustomParameterInstanceCreator(domain) {
        return new PCustomParameterInstanceCreator({
            domain,
            customParameterInstanceAvailabilityService: this.customParameterInstanceAvailabilityService,
            customParameterType: this.restaurant.customParameterTypes.get(domain.typeId),
        });
    }
    create(params) {
        const modifierInstanceCreators = params.domain.modifierInstanceCreators.objects
            .map((modifierInstanceCreator) => this.createModifierInstanceCreator(modifierInstanceCreator))
            .sorted((a, b) => params.modifierOrder.findIndex((idx) => idx.eq(a.typeId)) -
            params.modifierOrder.findIndex((idx) => idx.eq(b.typeId))).raw;
        const customParameterInstanceCreators = params.domain.customParameterInstanceCreators.objects
            .map((customParameterInstanceCreator) => this.createCustomParameterInstanceCreator(customParameterInstanceCreator))
            .sorted((a, b) => a.name.localeCompare(b.name)).raw;
        return new PProductConfigCreator({
            domain: params.domain,
            productConfigAvailabilityService: this.productConfigAvailabilityService,
            modifierInstanceCreators,
            customParameterInstanceCreators,
        });
    }
};
PProductConfigCreatorFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof LocalizationProvider !== "undefined" && LocalizationProvider) === "function" ? _a : Object, typeof (_b = typeof ProductConfigAvailabilityService !== "undefined" && ProductConfigAvailabilityService) === "function" ? _b : Object, typeof (_c = typeof CustomParameterInstanceAvailabilityService !== "undefined" && CustomParameterInstanceAvailabilityService) === "function" ? _c : Object, typeof (_d = typeof ModifierInstanceAvailabilityService !== "undefined" && ModifierInstanceAvailabilityService) === "function" ? _d : Object, typeof (_e = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _e : Object, typeof (_f = typeof PModifierSectionCreatorFactory !== "undefined" && PModifierSectionCreatorFactory) === "function" ? _f : Object, typeof (_g = typeof ModifierInstancePriceService !== "undefined" && ModifierInstancePriceService) === "function" ? _g : Object])
], PProductConfigCreatorFactory);
export { PProductConfigCreatorFactory };
