import { RArray } from "../../collections";
import { makeImmutable } from "../../core";
export class PromotionSolution {
    constructor(variables) {
        this.variables = variables;
        this.remainingVariables = new RArray(this.variables.disabled);
        makeImmutable(this);
    }
    get isTriviallyBest() {
        return this.enabledVariablesCount === this.variables.size;
    }
    [Symbol.iterator]() {
        return this.nextSolutions();
    }
    *nextSolutions() {
        for (const remainingVariable of this.remainingVariables) {
            const nextVariables = this.variables.enable(remainingVariable);
            if (nextVariables !== null) {
                yield new PromotionSolution(nextVariables);
            }
        }
    }
    get label() {
        return this.variables.label;
    }
    get enabledVariablesCount() {
        return this.variables.enabledVariablesCount;
    }
    get isValid() {
        return this.variables.areConstraintsMet;
    }
    couldBeBetterThan(other) {
        if (other === null) {
            return true;
        }
        if (other.isTriviallyBest) {
            return false;
        }
        return (this.enabledVariablesCount + this.remainingVariables.size >
            other.enabledVariablesCount);
    }
    static betterOf(a, b) {
        // NOTICE We do not have check if a is valid assuming that we can only transition from null into valid best solution
        if (a !== null) {
            if (b.isValid) {
                return a.enabledVariablesCount >= b.enabledVariablesCount ? a : b;
            }
            return a;
        }
        else {
            return b.isValid ? b : null;
        }
    }
}
