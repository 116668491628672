var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { Availability } from "../Availability";
import { HalvingPolicyAvailabilityService } from "./HalvingPolicyAvailabilityService";
import { ProductConfigAvailabilityService } from "./ProductConfigAvailabilityService";
import { ProductInstancePartAvailabilityService } from "./ProductInstancePartAvailabilityService";
let ProductInstanceAvailabilityService = class ProductInstanceAvailabilityService {
    constructor(productConfigAvailabilityService, productInstancePartAvailabilityService, halvingPolicyAvailabilityService) {
        this.productConfigAvailabilityService = productConfigAvailabilityService;
        this.productInstancePartAvailabilityService = productInstancePartAvailabilityService;
        this.halvingPolicyAvailabilityService = halvingPolicyAvailabilityService;
        makeImmutable(this);
    }
    availability(productInstance) {
        return Availability.composite([
            Availability.dependent("CommonConfig", "", this.productConfigAvailabilityService.availability(productInstance.commonConfig)),
            this.productInstancePartsAvailability(productInstance.parts),
        ]);
    }
    productInstancePartsAvailability(productInstanceParts) {
        switch (productInstanceParts.type) {
            case "SinglePart":
                return this.productInstancePartAvailabilityService.availability(productInstanceParts.part);
            case "Halves":
                return Availability.composite([
                    this.halvingPolicyAvailabilityService.availability(productInstanceParts.productCategory.halvingPolicy, productInstanceParts.scope),
                    this.productInstancePartAvailabilityService.availability(productInstanceParts.first),
                    this.productInstancePartAvailabilityService.availability(productInstanceParts.second),
                ]);
        }
    }
};
ProductInstanceAvailabilityService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ProductConfigAvailabilityService !== "undefined" && ProductConfigAvailabilityService) === "function" ? _a : Object, typeof (_b = typeof ProductInstancePartAvailabilityService !== "undefined" && ProductInstancePartAvailabilityService) === "function" ? _b : Object, typeof (_c = typeof HalvingPolicyAvailabilityService !== "undefined" && HalvingPolicyAvailabilityService) === "function" ? _c : Object])
], ProductInstanceAvailabilityService);
export { ProductInstanceAvailabilityService };
