var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var RestaumaticClient_1;
var _a;
import "reflect-metadata";
import { 
// NOTICE This class is allowed to use global container
// eslint-disable-next-line no-restricted-imports
container, singleton, } from "tsyringe";
import { GlobalConfig, MenuConsumer } from "../config";
import { makeImmutable } from "../core";
import { ApartmentInfoFactory, PCartIntegration, PerRestaurantContainerFactory, PMobileApps, POrderSummaryFactory, PRestaurantSelectionFactory, StreetAddressFactory, } from "../factory";
import { LocalizationProvider } from "../locale";
import { ApartmentInfoToken, BackendDataService, SalesChannel, StreetAddress, } from "../ordering";
import { PCheckout, PlaceOrderCallback } from "../presentation";
import { ApplePayServiceProvider, IQrWaiter, MenuService, RestaumaticRPCService, } from "../services";
import { IStorage, StorageManagerFactory } from "../storage";
let RestaumaticClient = RestaumaticClient_1 = class RestaumaticClient {
    constructor(storageManagerFactory) {
        this.storageManagerFactory = storageManagerFactory;
        makeImmutable(this);
    }
    static create(params) {
        container.register(GlobalConfig, { useValue: params.config });
        container.register(RestaumaticRPCService, {
            useValue: new RestaumaticRPCService(params.rpcUrl),
        });
        container.register(LocalizationProvider, {
            useValue: new LocalizationProvider(params.config),
        });
        container.register(IStorage, { useValue: params.storage });
        container.register(IQrWaiter, { useValue: params.qrWaiter });
        container.register(ApplePayServiceProvider, {
            useValue: ApplePayServiceProvider.create({
                isApplePaySupported: params.config.isApplePaySupported,
                mobileAppType: params.config.mobileAppType,
                currency: params.config.currency,
            }),
        });
        const isQrWaiterOrder = params.qrWaiter.isQrWaiterOrder();
        container.register(MenuConsumer, {
            useValue: isQrWaiterOrder ? "QrWaiter" : "Site",
        });
        container.register(SalesChannel, {
            useValue: SalesChannel.create(isQrWaiterOrder, params.config.mobileAppType),
        });
        container.register(StreetAddress, {
            useValue: container.resolve(StreetAddressFactory).create(),
        });
        container.register(ApartmentInfoToken, {
            useValue: container.resolve(ApartmentInfoFactory).create(),
        });
        return container.resolve(RestaumaticClient_1);
    }
    async createMobileApps(menuResponse, placeOrderCallback) {
        const perRestaurantContextFactory = container.resolve(PerRestaurantContainerFactory);
        const backendDataService = container.resolve(BackendDataService);
        const backendData = await backendDataService.getBackendData({
            restaurantId: menuResponse.id,
            menuResponse: Promise.resolve(menuResponse),
            fetchPaymentChannels: true,
        });
        const restaurantContainer = await perRestaurantContextFactory.create(backendData);
        restaurantContainer.register(PlaceOrderCallback, {
            useValue: placeOrderCallback,
        });
        return restaurantContainer.resolve(PMobileApps);
    }
    createOrderSummary(params) {
        const orderSummaryFactory = container.resolve(POrderSummaryFactory);
        return orderSummaryFactory.create(params);
    }
    async createCheckoutSites(params) {
        const menuService = container.resolve(MenuService);
        const backendDataService = container.resolve(BackendDataService);
        const backendCheckoutData = await backendDataService.getBackendData({
            restaurantId: params.restaurantId,
            menuResponse: menuService.getMenu(params.menuPath),
            fetchPaymentChannels: true,
        });
        const perRestaurantContextFactory = container.resolve(PerRestaurantContainerFactory);
        const restaurantContainer = await perRestaurantContextFactory.create(backendCheckoutData);
        restaurantContainer.register(PlaceOrderCallback, {
            useValue: params.placeOrderCallback,
        });
        return restaurantContainer.resolve(PCheckout);
    }
    async createCartSites(params) {
        const menuService = container.resolve(MenuService);
        const backendDataService = container.resolve(BackendDataService);
        const backendCartData = await backendDataService.getBackendData({
            restaurantId: params.restaurantId,
            menuResponse: menuService.getMenu(params.menuPath),
            fetchPaymentChannels: false,
        });
        const perRestaurantContextFactory = container.resolve(PerRestaurantContainerFactory);
        const restaurantContainer = await perRestaurantContextFactory.create(backendCartData);
        return restaurantContainer.resolve(PCartIntegration);
    }
    createRestaurantSelection(initialRestaurants) {
        const restaurantSelectionFactory = container.resolve(PRestaurantSelectionFactory);
        return restaurantSelectionFactory.create(initialRestaurants);
    }
};
RestaumaticClient = RestaumaticClient_1 = __decorate([
    singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof StorageManagerFactory !== "undefined" && StorageManagerFactory) === "function" ? _a : Object])
], RestaumaticClient);
export { RestaumaticClient };
