import { RDate, makeImmutable } from "../../core";
/**
 * Information that the orrder should be fulfilled on a specified hour.
 */
export class OnTimeFulfillmentTime {
    constructor(params) {
        this.type = "OnTime";
        this.date = params.date;
        makeImmutable(this);
    }
    static fromStorageData(params) {
        var _a;
        let date;
        if (((_a = params.storageData) === null || _a === void 0 ? void 0 : _a.version) === 2) {
            date = RDate.fromLocalStorage(params.storageData.date);
            if (date === null) {
                return null;
            }
        }
        else {
            date = RDate.fromUtcTimeString(params.storageData.date, Skubacz.configuration.time_zone);
        }
        if (date.isBefore(params.now)) {
            // NOTICE Past dates are invalid
            return null;
        }
        return new OnTimeFulfillmentTime({
            date,
        });
    }
    get storageData() {
        return {
            type: "OnTime",
            date: this.date.toLocalStorage(),
            version: 2,
        };
    }
}
