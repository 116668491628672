import { makeImmutable, Quantity } from "../../core";
import { ModifierSettings } from "./ModifierSettings";
export class ModifierType {
    constructor(params) {
        this.id = params.id;
        this.itemTypes = params.itemTypes;
        this.behaviour = params.behaviour;
        this.maxDuplicatesPerItem = params.maxDuplicatesPerItem;
        this.maxItems = params.maxItems;
        this.minItems = params.minItems;
        this.isDivisible = params.isDivisible;
        this.availabilityParametrization = params.availabilityParametrization;
        this.editabilityParametrization = params.editabilityParametrization;
        this.exchangePolicy = params.exchangePolicy;
        makeImmutable(this);
    }
    get itemTypeIds() {
        return this.itemTypes.ids;
    }
    includesItemType(modifierItemTypeId) {
        return this.itemTypes.includes(modifierItemTypeId);
    }
    itemType(modifierItemTypeId) {
        return this.itemTypes.get(modifierItemTypeId);
    }
    /**
     * @returns by which custom parameters is the modifier parametrized?
     */
    get customParameterTypes() {
        const itemsParameterTypes = this.itemTypes.objects.map((modifierItemType) => modifierItemType.customParameterTypes);
        return itemsParameterTypes
            .reduce((prev, curr) => prev.union(curr), this.availabilityParametrization.customParameterTypes)
            .union(this.editabilityParametrization.customParameterTypes);
    }
    get defaultSettings() {
        return new ModifierSettings({
            editable: this.editabilityParametrization,
            freeItems: Quantity.Zero,
            defaultItems: this.itemTypes.raw.map((itemType) => itemType.defaultSelection),
            displayDefaultItems: true,
        });
    }
}
