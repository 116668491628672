class ConsoleLogger {
    debug(message, messageContext, error) {
        console.debug(message, messageContext, error);
    }
    info(message, messageContext, error) {
        console.log(message, messageContext, error);
    }
    warn(message, messageContext, error) {
        console.warn(message, messageContext, error);
    }
    error(message, messageContext, error) {
        console.error(message, messageContext, error);
    }
}
class NullLogger {
    debug(_message, _messageContext, _error) {
        // NOTICE Intentionally empty
    }
    info(_message, _messageContext, _error) {
        // NOTICE Intentionally empty
    }
    warn(_message, _messageContext, _error) {
        // NOTICE Intentionally empty
    }
    error(_message, _messageContext, _error) {
        // NOTICE Intentionally empty
    }
}
const LOGGER_LOCAL_STORAGE_KEY = "client_logger";
function createHttpLogger() {
    var _a;
    const clientLoggerType = typeof localStorage !== "undefined"
        ? (_a = localStorage.getItem(LOGGER_LOCAL_STORAGE_KEY)) === null || _a === void 0 ? void 0 : _a.toLowerCase()
        : undefined;
    if (clientLoggerType === "null") {
        return new NullLogger();
    }
    else if (clientLoggerType === "console") {
        return new ConsoleLogger();
    }
    else {
        return new NullLogger();
    }
}
export const httpLogger = createHttpLogger();
