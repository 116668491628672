var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var StorageVersionManager_1;
var _a;
import { inject, singleton } from "tsyringe";
import { IStorage } from "./IStorage";
import { makeImmutable } from "../core";
let StorageVersionManager = StorageVersionManager_1 = class StorageVersionManager {
    constructor(storage) {
        this.storage = storage;
        this.versionChanged =
            this.storage.restore(StorageVersionManager_1.STORAGE_FORMAT_VERSION_KEY) !==
                StorageVersionManager_1.CURRENT_STORAGE_VERSION;
        if (this.versionChanged) {
            this.storage.store(StorageVersionManager_1.STORAGE_FORMAT_VERSION_KEY, StorageVersionManager_1.CURRENT_STORAGE_VERSION);
        }
        makeImmutable(this);
    }
    /**
     * Checks the storage version and if it's not valid erases provided storage managers data
     */
    checkStorageFormatVersion(storageManagerToErase) {
        if (this.versionChanged) {
            storageManagerToErase.erase();
        }
    }
};
StorageVersionManager.CURRENT_STORAGE_VERSION = "1";
StorageVersionManager.STORAGE_FORMAT_VERSION_KEY = "client_storageFormatVersion";
StorageVersionManager = StorageVersionManager_1 = __decorate([
    singleton(),
    __param(0, inject(IStorage)),
    __metadata("design:paramtypes", [typeof (_a = typeof IStorage !== "undefined" && IStorage) === "function" ? _a : Object])
], StorageVersionManager);
export { StorageVersionManager };
