var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { LocalizationProvider } from "../locale";
import { ProductInstancePriceService, PurchaseOrderPayload, } from "../ordering";
import { PProductInstanceVariant } from "./PProductInstanceVariant";
let PProductInstanceVariantFactory = class PProductInstanceVariantFactory {
    constructor(localizationProvider, purchaseOrderPayload, productInstancePriceService) {
        this.localizationProvider = localizationProvider;
        this.purchaseOrderPayload = purchaseOrderPayload;
        this.productInstancePriceService = productInstancePriceService;
        makeImmutable(this);
    }
    create(params) {
        return new PProductInstanceVariant({
            instance: params.instance,
            productInstancePriceService: this.productInstancePriceService,
            creator: params.creator,
            purchaseOrderPayload: this.purchaseOrderPayload,
            variantParameterChoiceName: params.variantParameterChoiceName,
            localizationProvider: this.localizationProvider,
        });
    }
};
PProductInstanceVariantFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof LocalizationProvider !== "undefined" && LocalizationProvider) === "function" ? _a : Object, typeof (_b = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _b : Object, typeof (_c = typeof ProductInstancePriceService !== "undefined" && ProductInstancePriceService) === "function" ? _c : Object])
], PProductInstanceVariantFactory);
export { PProductInstanceVariantFactory };
