import { Tracking } from "../tracking";
import { weightedRandom } from "./weightedRandom";
export class FieldTests {
    constructor(params) {
        this.fieldTestsStorageManager = params.fieldTestsStorageManager;
        this.sentToAnalytics = new Set();
    }
    applyExperiments(experiments) {
        experiments.forEach((experiment) => experiment.apply(() => this.assignVariant(experiment), () => this.clearVariant(experiment), () => this.getVariant(experiment)));
    }
    getVariant(experiment) {
        const variants = this.getAllVariants();
        return experiment.name in variants
            ? variants[experiment.name]
            : experiment.controlVariant;
    }
    assignVariant(experiment) {
        const variants = this.getAllVariants();
        if (!(experiment.name in variants)) {
            variants[experiment.name] = weightedRandom(experiment.variants);
            console.log(`[FieldTest] Experiment ${experiment.name}: selecting and saving variant ${variants[experiment.name]}`);
            this.setAllVariants(variants);
        }
        else {
            console.log(`[FieldTest] Experiment ${experiment.name}: already chose variant ${variants[experiment.name]}`);
        }
        const value = variants[experiment.name];
        if (!this.sentToAnalytics.has(experiment.name)) {
            Tracking.trackEvent({
                tag: "ExperienceImpression",
                contents: {
                    experiment: experiment.name,
                    variant: value,
                },
            });
            this.sentToAnalytics.add(experiment.name);
        }
        return value;
    }
    clearVariant(experiment) {
        const variants = this.getAllVariants();
        delete variants[experiment.name];
        this.setAllVariants(variants);
    }
    getAllVariants() {
        var _a;
        return (_a = this.fieldTestsStorageManager.restore()) !== null && _a !== void 0 ? _a : {};
    }
    setAllVariants(variants) {
        this.fieldTestsStorageManager.store(variants);
    }
}
