import { makeImmutable } from "../../core";
/**
 * A family of products such as "Pasta" or "Fish".
 * Groups ProductTypes.
 *
 * Corresponds to "ProductCategory" in MenuV2
 */
export class ProductCategory {
    constructor(params) {
        this.id = params.id;
        this.halvingPolicy = params.halvingPolicy;
        this.modifierTypes = params.modifierTypes;
        this.volume = params.volume;
        this.divisiblePricePolicy = params.divisiblePricePolicy;
        makeImmutable(this);
    }
    get customParameterTypes() {
        return this.halvingPolicy.customParameterTypes;
    }
}
