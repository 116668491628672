var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { IdMap } from "../../collections";
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { PackingItem, ProductInstancePackingSubject } from "../Packing";
import { PositiveQuantity } from "../PositiveQuantity";
import { ProductInstancePackingService } from "./ProductInstancePackingService";
import { ProductInstanceVolumeService } from "./ProductInstanceVolumeService";
let OrderLinePackingService = class OrderLinePackingService {
    constructor(productInstancePackingService, productInstanceVolumeService) {
        this.productInstancePackingService = productInstancePackingService;
        this.productInstanceVolumeService = productInstanceVolumeService;
        makeImmutable(this);
    }
    packingItems(orderLine) {
        const quantity = new PositiveQuantity(orderLine.quantity.value);
        const result = [
            ...this.productInstancePackingService
                .packingItems(orderLine.productInstance)
                .objects.map((packingItem) => packingItem.multiply(quantity)).raw,
        ];
        const productInstanceVolume = this.productInstanceVolumeService.volume(orderLine.productInstance);
        if (productInstanceVolume) {
            result.push(new PackingItem({
                subject: new ProductInstancePackingSubject(productInstanceVolume),
                quantity,
            }));
        }
        return IdMap.fromIterable(result);
    }
};
OrderLinePackingService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ProductInstancePackingService !== "undefined" && ProductInstancePackingService) === "function" ? _a : Object, typeof (_b = typeof ProductInstanceVolumeService !== "undefined" && ProductInstanceVolumeService) === "function" ? _b : Object])
], OrderLinePackingService);
export { OrderLinePackingService };
