var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var RestaumaticRPCService_1;
import { singleton } from "tsyringe";
import { makeImmutable } from "../core";
import { httpLogger } from "../logger";
import { Sentry } from "../Sentry";
import { retryPromise, toErrorWithMessage } from "../utils";
async function parseBodyAsText(response) {
    if (response === undefined) {
        return Promise.resolve("undefined");
    }
    try {
        return response.text();
    }
    catch (err) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return Promise.resolve(`error: ${err}`);
    }
}
async function loggingFetch(url, data) {
    const response = await fetch(url, data);
    if (!response.ok) {
        httpLogger.info(`Fetch - ${response.status}`, {
            url: url,
            method: data.method,
            status: response.status,
        });
    }
    return response;
}
// TODO: throw exceptions on network failure
/**
 * RPC Service for interacting with the backend.
 */
let RestaumaticRPCService = RestaumaticRPCService_1 = class RestaumaticRPCService {
    constructor(url) {
        this.url = url;
        makeImmutable(this);
    }
    static async fetchJson(url, data) {
        let response;
        try {
            response = await retryPromise(() => loggingFetch(url, data), 3, 100);
            if (!response.ok) {
                const body = await parseBodyAsText(response);
                Sentry.logError(new Error(`Fetch - ${response.status} ${response.statusText}`), {
                    tags: {
                        httpStatus: response.status,
                    },
                    extra: {
                        url: url,
                        method: data.method,
                        status: response.status,
                        /* Extra debugging info, to determine where did a given response come from */
                        headers: response.headers,
                        responseType: response.type,
                        responseBody: body,
                    },
                });
                return null;
            }
            return (await response.json());
        }
        catch (err) {
            const error = toErrorWithMessage(err);
            if (err instanceof SyntaxError) {
                // Unexpected token < in JSON
                const body = await parseBodyAsText(response);
                httpLogger.info(`Fetch - json parsing failed: ${error.message}}`, {
                    url: url,
                    method: data.method,
                    errorMsg: error.message,
                    body: body,
                });
                Sentry.logError(new Error(`Fetch - json parsing failed: ${error.message}`), {
                    extra: {
                        url: url,
                        method: data.method,
                        errorMsg: error.message,
                        error: err,
                        body: body,
                        encodedBody: btoa(body),
                    },
                });
            }
            else {
                httpLogger.info(`Fetch - network error: ${error.message}`, {
                    url: url,
                    method: data.method,
                    errorMsg: error.message,
                    error: err,
                });
                Sentry.logError(new Error(`Fetch - network error: ${error.message}`), {
                    extra: {
                        url: url,
                        method: data.method,
                        errorMsg: error.message,
                        error: err,
                    },
                });
            }
        }
        return null;
    }
    async post(path, data) {
        return RestaumaticRPCService_1.fetchJson(this.url + path, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(data),
        });
    }
    /**
     * Perform a RPC request to Restaumatic backend.
     * Throws RPCError when a RPC error response is received.
     */
    async rpc(method, params) {
        const response = await this.post(`/api/v1/sites/rpc?${method}`, {
            params,
            method,
            id: "1",
        });
        if (response === null) {
            return null;
        }
        if ("error" in response) {
            const { code, message, data } = response.error;
            const dataStr = data !== undefined ? `, data: ${data}` : "";
            httpLogger.info(`Fetch - 200 RPCError`, {
                code: code,
                message: message,
                data: data,
            });
            Sentry.logError(new Error(`RPCError[${code}]: ${message}${dataStr}`));
            return null;
        }
        return response.result;
    }
    async rpc2(method, params) {
        return await this.post(`/api/v1/sites/rpc/${method}`, params);
    }
};
RestaumaticRPCService = RestaumaticRPCService_1 = __decorate([
    singleton(),
    __metadata("design:paramtypes", [String])
], RestaumaticRPCService);
export { RestaumaticRPCService };
