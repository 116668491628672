var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { perRestaurant } from "../di";
import { FirstOrderDiscount, NextOrderingTime, OrderingHoursAvailability, PurchaseOrderAvailabilityService, SalesChannel, } from "../ordering";
import { FODFormControllerProvider, FulfillmentFormController, PDeliveryAddressCoordinator, } from "./formControllers";
import { OrderItemListTracking } from "./OrderItemListTracking";
import { PCrossSellingCreator } from "./PCrossSellingCreator";
import { PFulfillmentTime } from "./PFulfillmentTime";
import { POrderDetailsProvider } from "./POrderDetails";
import { PProductError } from "./PProductError";
import { PPurchaseOrder } from "./PPurchaseOrder";
import { PRestaurant } from "./PRestaurant";
let PCart = class PCart {
    constructor(purchaseOrder, restaurant, orderDetailsProvider, firstOrderDiscount, fodFormControllerProvider, fulfillmentFormController, fulfillmentTime, 
    // NOTICE OrderItemListTracking is injected just to register reaction
    orderItemListTracking, crossSellingCreator, nextOrderingTime, orderingHoursAvailability, purchaseOrderAvailabilityService, salesChannel, deliveryAddressCoordinator) {
        this.purchaseOrder = purchaseOrder;
        this.restaurant = restaurant;
        this.firstOrderDiscount = firstOrderDiscount;
        this.fodFormControllerProvider = fodFormControllerProvider;
        this.fulfillmentFormController = fulfillmentFormController;
        this.fulfillmentTime = fulfillmentTime;
        this.orderItemListTracking = orderItemListTracking;
        this.crossSellingCreator = crossSellingCreator;
        this.nextOrderingTime = nextOrderingTime;
        this.orderingHoursAvailability = orderingHoursAvailability;
        this.purchaseOrderAvailabilityService = purchaseOrderAvailabilityService;
        this.salesChannel = salesChannel;
        this.deliveryAddressCoordinator = deliveryAddressCoordinator;
        this.orderDetails = orderDetailsProvider.orderDetails;
        this.fodFormController = fodFormControllerProvider.fodFormController;
        this._isCartOpen = false;
        makeAutoObservable(this);
    }
    get isCartOpen() {
        return this._isCartOpen;
    }
    setCartOpen() {
        this._isCartOpen = true;
    }
    setCartClosed() {
        this._isCartOpen = false;
    }
    // NOTICE This is used only by e2e test tool to display debug availability
    get availability() {
        return this.purchaseOrderAvailabilityService.cartAvailability;
    }
    get restaurantId() {
        return this.restaurant.id;
    }
    get restaurantName() {
        return this.restaurant.name;
    }
    get fodState() {
        return this.firstOrderDiscount.state;
    }
    get price() {
        return this.purchaseOrder.price;
    }
    get priceValue() {
        var _a, _b;
        return (_b = (_a = this.purchaseOrder.finalPrice) === null || _a === void 0 ? void 0 : _a.asNumber) !== null && _b !== void 0 ? _b : 0;
    }
    get isReadyForCheckout() {
        return this.purchaseOrder.isReadyForCheckout;
    }
    get orderingHoursMessage() {
        if (this.orderingHoursAvailability.isOutOfOrderingHours) {
            return PProductError.outOfOrderingHours(this.nextOrderingTime.nextOrderAvailableAt, this.orderingHoursAvailability.canPreorder);
        }
        return null;
    }
    get error() {
        return this.purchaseOrder.error;
    }
    get fulfillmentMethodsOptions() {
        return this.purchaseOrder.fulfillmentMethodOptions;
    }
    get fulfillmentMethodOptionsShort() {
        return this.purchaseOrder.fulfillmentMethodOptionsShort;
    }
    get deliveryPrice() {
        return this.purchaseOrder.deliveryPrice;
    }
    get packagingPrice() {
        return this.purchaseOrder.packagingPrice;
    }
    get orderLines() {
        return this.purchaseOrder.orderLines;
    }
    get promotions() {
        return this.orderDetails.promotionInstances(true);
    }
    get menuOrderOrigin() {
        return this.salesChannel.menuOrderOrigin;
    }
    get numItems() {
        return this.purchaseOrder.numItems;
    }
    get isEmpty() {
        return this.numItems === 0;
    }
    addOrderLines(orderLines) {
        const results = orderLines.map((orderLine) => this.purchaseOrder.addOrderLine(orderLine));
        if (results.some((result) => result === "added_new_item")) {
            this.crossSellingCreator.domain.shuffle();
        }
    }
    clear() {
        this.purchaseOrder.clear();
        this.orderDetails.clearCoupon();
    }
    get couponState() {
        return this.orderDetails.couponState;
    }
    get grandTotal() {
        return this.purchaseOrder.grandTotal;
    }
    get instanceCounts() {
        return this.purchaseOrder.instanceCounts;
    }
    get cartMeetsCouponPromotionConditions() {
        if (this.couponState.type === "Applied") {
            return this.promotions.some((promotionInstance) => promotionInstance.isCoupon);
        }
        return true;
    }
    get checkoutLoggingData() {
        return this.purchaseOrder.checkoutLoggingData;
    }
};
PCart = __decorate([
    perRestaurant(),
    __param(3, inject(FirstOrderDiscount)),
    __param(5, inject(FulfillmentFormController)),
    __metadata("design:paramtypes", [typeof (_a = typeof PPurchaseOrder !== "undefined" && PPurchaseOrder) === "function" ? _a : Object, typeof (_b = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _b : Object, typeof (_c = typeof POrderDetailsProvider !== "undefined" && POrderDetailsProvider) === "function" ? _c : Object, typeof (_d = typeof FirstOrderDiscount !== "undefined" && FirstOrderDiscount) === "function" ? _d : Object, typeof (_e = typeof FODFormControllerProvider !== "undefined" && FODFormControllerProvider) === "function" ? _e : Object, typeof (_f = typeof FulfillmentFormController !== "undefined" && FulfillmentFormController) === "function" ? _f : Object, typeof (_g = typeof PFulfillmentTime !== "undefined" && PFulfillmentTime) === "function" ? _g : Object, typeof (_h = typeof OrderItemListTracking !== "undefined" && OrderItemListTracking) === "function" ? _h : Object, typeof (_j = typeof PCrossSellingCreator !== "undefined" && PCrossSellingCreator) === "function" ? _j : Object, typeof (_k = typeof NextOrderingTime !== "undefined" && NextOrderingTime) === "function" ? _k : Object, typeof (_l = typeof OrderingHoursAvailability !== "undefined" && OrderingHoursAvailability) === "function" ? _l : Object, typeof (_m = typeof PurchaseOrderAvailabilityService !== "undefined" && PurchaseOrderAvailabilityService) === "function" ? _m : Object, typeof (_o = typeof SalesChannel !== "undefined" && SalesChannel) === "function" ? _o : Object, typeof (_p = typeof PDeliveryAddressCoordinator !== "undefined" && PDeliveryAddressCoordinator) === "function" ? _p : Object])
], PCart);
export { PCart };
