import { makeImmutable, DaySchedule } from "../core";
import { RArray } from "./RArray";
import { Holiday } from "./Holiday";
export class Holidays {
    constructor(params) {
        this.overrides = params.overrides;
        this.appends = params.appends;
        makeImmutable(this);
    }
    static create(timezone, holidayInfos) {
        const holidays = new RArray(holidayInfos.map((holidayInfo) => Holiday.fromHolidayInfo(timezone, holidayInfo)));
        const overrides = holidays.map((h) => h.override);
        const appends = holidays.mapOptional((h) => h.append);
        return new Holidays({ overrides, appends });
    }
    /*
      `override` represents Holidays defined for concrete days and will replace default schedule from OpeningHours.
      The `append` values represent "the rest" of a holiday that has hours spanning the day boundary, ie. 14:00-03:00 gives
      an append of 0:00 - 3:00 on the "next" day. The "append" values are combined both with "overrides" and normal opening
      hours.
    */
    at(date) {
        var _a, _b;
        const override = (_b = (_a = this.overrides.findWhere((holiday) => holiday.dates.includes(date))) === null || _a === void 0 ? void 0 : _a.schedule) !== null && _b !== void 0 ? _b : null;
        const appends = this.appends
            .filtered((append) => append.dates.includes(date))
            .map((append) => append.range);
        const append = DaySchedule.fromRanges(appends.raw);
        return { override, append };
    }
}
Holidays.Empty = new Holidays({
    overrides: RArray.empty(),
    appends: RArray.empty(),
});
