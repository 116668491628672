import { makeAutoObservable } from "mobx";
import { RArray, RSet } from "../../collections";
import { ParameterScope } from "../ParameterScope";
import { MAX_CROSS_SELLING_ITEM_COUNT, } from "./ICrossSellingCreator";
export class ProductCardCrossSellingCreator {
    constructor(params) {
        this.parentScope = ParameterScope.Uninitialized;
        this.purchaseOrderPayload = params.purchaseOrderPayload;
        this.crossSellingItemInstanceListService =
            params.crossSellingItemInstanceListService;
        this.crossSellingItemInstanceFactory =
            params.crossSellingItemInstanceFactory;
        this._items = RArray.empty();
        this.inputProductTypeIds = RSet.empty();
        makeAutoObservable(this);
    }
    initWithScope(parentScope) {
        this.parentScope = parentScope;
        this._items.forEach((crossSellingItemInstance) => crossSellingItemInstance.setScope(this.parentScope));
    }
    setInputProductTypeIds(inputProductTypeIds) {
        this.inputProductTypeIds = inputProductTypeIds;
        this._items = this.crossSellingItemInstanceFactory.create({
            inputProductTypeIds,
            sequenceNumber: Math.random(),
            parentScope: this.parentScope,
        });
    }
    get excludedProductTypeIds() {
        return this.purchaseOrderPayload.productTypeIds.union(this.inputProductTypeIds);
    }
    get items() {
        return this._items
            .filtered((crossSellingItemInstance) => this.crossSellingItemInstanceListService.shouldAppearInCrossSellingList(crossSellingItemInstance, this.excludedProductTypeIds))
            .take(MAX_CROSS_SELLING_ITEM_COUNT);
    }
    get selectedItems() {
        return this.items.mapOptional(({ itemCreator, quantity, kind }) => {
            if (quantity.isZero) {
                return null;
            }
            return [itemCreator, quantity, kind];
        });
    }
    shuffle() {
        // NOTICE Intentionally empty
    }
}
