import { RMap } from "../collections";
import { CustomParameterSet, InternalParameterSet, ParameterSet, } from "../ordering";
/**
 * Utility class used for decoding MenuV2 parametrics into ParameterSet.
 */
export class ParameterSetBuilder {
    constructor() {
        this.customParameterChoices = [];
        this.fulfillmentMethod = null;
        this.splitToHalves = null;
        this.orderOrigin = null;
    }
    setCustomParameter(customParameterType, customParameterChoiceId) {
        this.customParameterChoices.push([
            customParameterType,
            customParameterChoiceId,
        ]);
    }
    setInternalParameter(internalParameterChoice) {
        switch (internalParameterChoice.tag) {
            case "FulfillmentMethodChoice":
                this.fulfillmentMethod = internalParameterChoice.contents;
                break;
            case "IsDividedChoice":
                this.splitToHalves = internalParameterChoice.contents;
                break;
            case "OriginChoice":
                this.orderOrigin = internalParameterChoice.contents;
                break;
            case "PaymentMethodChoice":
                throw new Error("Parametrization by payment method is not supported");
            case "ScheduleChoice":
                throw new Error("Parametrization by schedule is not supported");
        }
    }
    build(menuOrderOrigin) {
        if (this.orderOrigin !== null && this.orderOrigin !== menuOrderOrigin) {
            return null;
        }
        const customParameterValues = CustomParameterSet.fromChoices(RMap.fromIterable(this.customParameterChoices));
        return new ParameterSet({
            custom: customParameterValues,
            internal: new InternalParameterSet({
                fulfillmentMethod: this.fulfillmentMethod,
                splitToHalves: this.splitToHalves,
            }),
        });
    }
}
