import { Availability } from "../Availability";
export const OrderingAvailability = {
    create(params) {
        switch (params.backendOrderingAvailability.tag) {
            case "OrderingImpossible":
                return {
                    availability: Availability.unavailable("OrderingAvailability"),
                };
            case "OrderingPossible": {
                return {
                    availability: Availability.available(),
                };
            }
        }
    },
};
