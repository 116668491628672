var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { LocalizationProvider } from "../locale";
import { PromotionInstanceAvailabilityService, PromotionInstancePriceService, } from "../ordering";
import { PPromotionInstance } from "./PPromotionInstance";
let PPromotionInstanceFactory = class PPromotionInstanceFactory {
    constructor(localizationProvider, promotionInstanceAvailabilityService, promotionInstancePriceService) {
        this.localizationProvider = localizationProvider;
        this.promotionInstanceAvailabilityService = promotionInstanceAvailabilityService;
        this.promotionInstancePriceService = promotionInstancePriceService;
        makeImmutable(this);
    }
    create(params) {
        return new PPromotionInstance({
            localizationProvider: this.localizationProvider,
            promotionInstanceAvailabilityService: this.promotionInstanceAvailabilityService,
            promotionInstancePriceService: this.promotionInstancePriceService,
            domain: params.domain,
            promotionType: params.promotionType,
        });
    }
};
PPromotionInstanceFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof LocalizationProvider !== "undefined" && LocalizationProvider) === "function" ? _a : Object, typeof (_b = typeof PromotionInstanceAvailabilityService !== "undefined" && PromotionInstanceAvailabilityService) === "function" ? _b : Object, typeof (_c = typeof PromotionInstancePriceService !== "undefined" && PromotionInstancePriceService) === "function" ? _c : Object])
], PPromotionInstanceFactory);
export { PPromotionInstanceFactory };
