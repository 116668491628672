var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { singleton } from "tsyringe";
import { makeImmutable } from "../core";
import { LocalizationProvider } from "../locale";
import { DeliveryAddressFormController, PDeliveryAddress, PGeolocation, PRestaurantSelection, } from "../presentation";
import { StorageManagerFactory } from "../storage";
let PRestaurantSelectionFactory = class PRestaurantSelectionFactory {
    constructor(localizationProvider, storageManagerFactory, deliveryAddressController, deliveryAddress, geolocation) {
        this.localizationProvider = localizationProvider;
        this.storageManagerFactory = storageManagerFactory;
        this.deliveryAddressController = deliveryAddressController;
        this.deliveryAddress = deliveryAddress;
        this.geolocation = geolocation;
        makeImmutable(this);
    }
    create(initialRestaurants) {
        const streetAddressStorageManager = this.storageManagerFactory.forStreetAddress();
        return new PRestaurantSelection({
            localizationProvider: this.localizationProvider,
            streetAddressStorageManager,
            geolocation: this.geolocation,
            initialRestaurants,
            deliveryAddressController: this.deliveryAddressController,
            deliveryAddress: this.deliveryAddress,
        });
    }
};
PRestaurantSelectionFactory = __decorate([
    singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof LocalizationProvider !== "undefined" && LocalizationProvider) === "function" ? _a : Object, typeof (_b = typeof StorageManagerFactory !== "undefined" && StorageManagerFactory) === "function" ? _b : Object, typeof (_c = typeof DeliveryAddressFormController !== "undefined" && DeliveryAddressFormController) === "function" ? _c : Object, typeof (_d = typeof PDeliveryAddress !== "undefined" && PDeliveryAddress) === "function" ? _d : Object, typeof (_e = typeof PGeolocation !== "undefined" && PGeolocation) === "function" ? _e : Object])
], PRestaurantSelectionFactory);
export { PRestaurantSelectionFactory };
