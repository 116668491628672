import { makeAutoObservable } from "mobx";
import { ParameterSet, } from "../../ordering";
export class MenuSectionItemScope {
    constructor(params) {
        this.parent = params.parentScope;
        this.selfCustomParameterSet = params.selfCustomParameterSet;
        makeAutoObservable(this);
    }
    get parameterSet() {
        const selfParameterSet = new ParameterSet({
            custom: this.selfCustomParameterSet,
        });
        const result = this.parent.parameterSet.union(selfParameterSet);
        if (result === null) {
            throw new Error("Incompatible parameter scopes");
        }
        return result;
    }
}
