var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
import { inject } from "tsyringe";
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { ContactFormController, ContactFormControllerToken, } from "../presentation";
import { FODService, IQrWaiter, ValidatePhoneNumberService } from "../services";
import { Restaurant, DisabledFOD, EnabledFOD, } from "../ordering";
let FirstOrderDiscountFactory = class FirstOrderDiscountFactory {
    constructor(restaurant, validatePhoneService, firstOrderDiscountService, contactFormController, qrWaiter) {
        this.restaurant = restaurant;
        this.validatePhoneService = validatePhoneService;
        this.firstOrderDiscountService = firstOrderDiscountService;
        this.contactFormController = contactFormController;
        this.isQrWaiterOrder = qrWaiter.isQrWaiterOrder();
        makeImmutable(this);
    }
    create() {
        // TODO Refactor into storage manager
        const FODStorageKey = "claimedFod";
        const isClaimed = window.localStorage.getItem(FODStorageKey) === "true";
        const setIsClaimed = (value) => window.localStorage.setItem(FODStorageKey, JSON.stringify(value));
        const isFODDisabled = isClaimed ||
            !this.restaurant.orderingPolicy.enableFod ||
            this.isQrWaiterOrder;
        return isFODDisabled
            ? new DisabledFOD()
            : new EnabledFOD({
                restaurantId: this.restaurant.id,
                validatePhoneService: this.validatePhoneService,
                firstOrderDiscountService: this.firstOrderDiscountService,
                contactFormController: this.contactFormController,
                setIsClaimed,
            });
    }
};
FirstOrderDiscountFactory = __decorate([
    perRestaurant(),
    __param(3, inject(ContactFormControllerToken)),
    __param(4, inject(IQrWaiter)),
    __metadata("design:paramtypes", [typeof (_a = typeof Restaurant !== "undefined" && Restaurant) === "function" ? _a : Object, typeof (_b = typeof ValidatePhoneNumberService !== "undefined" && ValidatePhoneNumberService) === "function" ? _b : Object, typeof (_c = typeof FODService !== "undefined" && FODService) === "function" ? _c : Object, typeof (_d = typeof ContactFormController !== "undefined" && ContactFormController) === "function" ? _d : Object, typeof (_e = typeof IQrWaiter !== "undefined" && IQrWaiter) === "function" ? _e : Object])
], FirstOrderDiscountFactory);
export { FirstOrderDiscountFactory };
