var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { Availability } from "../Availability";
import { ModifierItemTypeAvailabilityService } from "./ModifierItemTypeAvailabilityService";
import { ModifierTypeAvailabilityService } from "./ModifierTypeAvailabilityService";
let ModifierItemInstanceAvailabilityService = class ModifierItemInstanceAvailabilityService {
    constructor(modifierTypeAvailabilityService, modifierItemTypeAvailabilityService) {
        this.modifierTypeAvailabilityService = modifierTypeAvailabilityService;
        this.modifierItemTypeAvailabilityService = modifierItemTypeAvailabilityService;
        makeImmutable(this);
    }
    modifierTypeAvailability(modifierItemInstance) {
        const modifierTypeAvailability = this.modifierTypeAvailabilityService.availability(modifierItemInstance.modifierType, modifierItemInstance.scope);
        return Availability.dependent("ModifierType", modifierItemInstance.modifierType.id.value, modifierTypeAvailability);
    }
    unitAvailability(modifierItemInstance) {
        return Availability.composite([
            this.modifierTypeAvailability(modifierItemInstance),
            this.modifierItemTypeAvailabilityService.availability(modifierItemInstance.modifierItemType, modifierItemInstance.scope),
        ]);
    }
};
ModifierItemInstanceAvailabilityService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ModifierTypeAvailabilityService !== "undefined" && ModifierTypeAvailabilityService) === "function" ? _a : Object, typeof (_b = typeof ModifierItemTypeAvailabilityService !== "undefined" && ModifierItemTypeAvailabilityService) === "function" ? _b : Object])
], ModifierItemInstanceAvailabilityService);
export { ModifierItemInstanceAvailabilityService };
