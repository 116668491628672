import { makeImmutable } from "../core";
import { UnknownElementError } from "./UnknownElementError";
/*
 * Restaumatic Array wrapper including helper array operations. Minimal alternative to lodash/underscore libraries
 */
export class RArray {
    constructor(raw) {
        this.raw = [...raw];
        makeImmutable(this);
    }
    static empty() {
        return new RArray([]);
    }
    static singleton(element) {
        return new RArray([element]);
    }
    static range(size) {
        return new RArray([...Array(size).keys()]);
    }
    static repeat(element, count) {
        return new RArray(new Array(count.value).fill(element));
    }
    static eq(arrayA, arrayB) {
        if (arrayA.size !== arrayB.size) {
            return false;
        }
        for (let i = 0; i < arrayA.size; ++i) {
            if (!arrayA.get(i).eq(arrayB.get(i))) {
                return false;
            }
        }
        return true;
    }
    static unique(array) {
        return array.filtered((element, index) => {
            var _a;
            return ((_a = array.firstIndexWhere((other) => element.eq(other))) !== null && _a !== void 0 ? _a : index) ===
                index;
        });
    }
    [Symbol.iterator]() {
        return this.raw.values();
    }
    get size() {
        return this.raw.length;
    }
    get isEmpty() {
        return this.size === 0;
    }
    map(mapping) {
        return new RArray(this.raw.map(mapping));
    }
    mapOptional(mapping) {
        const res = this.raw.map(mapping).filter((el) => el !== null);
        return new RArray(res);
    }
    forEach(action) {
        this.raw.forEach(action);
    }
    flatMap(mapping) {
        return new RArray(this.raw.flatMap((element) => mapping(element).raw));
    }
    reduce(reducer, initialValue) {
        return this.raw.reduce(reducer, initialValue);
    }
    filtered(predicate) {
        return new RArray(this.raw.filter(predicate));
    }
    every(predicate) {
        return this.raw.every(predicate);
    }
    some(predicate) {
        return this.raw.some(predicate);
    }
    count(predicate) {
        return this.raw.reduce((count, element) => count + (predicate(element) ? 1 : 0), 0);
    }
    includes(element) {
        return this.raw.includes(element);
    }
    drop(n) {
        return new RArray(this.raw.slice(n));
    }
    sorted(comparator) {
        return new RArray([...this.raw].sort(comparator));
    }
    chunks(ofSize) {
        if (ofSize < 1) {
            throw new Error("Chunks size must be positive");
        }
        const res = [];
        for (let i = 0; i < this.size; i += ofSize) {
            const chunk = new RArray(this.raw.slice(i, i + ofSize));
            res.push(chunk);
        }
        return new RArray(res);
    }
    concatenated(other) {
        return new RArray([...this.raw, ...other]);
    }
    shallowClone() {
        return new RArray([...this.raw]);
    }
    firstIndexWhere(predicate) {
        const index = this.raw.findIndex(predicate);
        return index === -1 ? null : index;
    }
    find(index) {
        var _a;
        return (_a = this.raw[index]) !== null && _a !== void 0 ? _a : null;
    }
    findWhere(predicate) {
        var _a;
        return (_a = this.raw.find(predicate)) !== null && _a !== void 0 ? _a : null;
    }
    get(index) {
        const element = this.find(index);
        if (element === null) {
            throw new UnknownElementError();
        }
        return element;
    }
    get last() {
        if (this.isEmpty) {
            throw new Error("Unable to call last on empty array");
        }
        return this.raw[this.size - 1];
    }
    sliceAt(index) {
        const p1 = this.raw.slice(0, index);
        const p2 = this.raw.slice(index);
        return [new RArray(p1), new RArray(p2)];
    }
    take(count) {
        return new RArray(this.raw.slice(0, count));
    }
    toString() {
        return this.raw.toString();
    }
}
