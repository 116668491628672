import { makeAutoObservable } from "mobx";
export class PQrWaiterOrderDetails {
    constructor(params) {
        this.type = "QrWaiter";
        this.isFulfillmentEditable = false;
        this.promotionScheme = params.promotionScheme;
        makeAutoObservable(this);
    }
    promotionInstances(showInactivePromotionInstances) {
        return this.promotionScheme.promotionInstances(showInactivePromotionInstances);
    }
    clearCoupon() {
        this.promotionScheme.clearCoupon();
    }
    get couponState() {
        return this.promotionScheme.couponState;
    }
    get doesAnyPromotionRequireMarketingConsents() {
        return this.promotionScheme.doesAnyPromotionRequireMarketingConsents;
    }
    disableMarketingPromotionInstances() {
        this.promotionScheme.disableMarketingPromotionInstances();
    }
}
