var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { IdMap, RArray, RMap, toPairs } from "../collections";
import { makeImmutable } from "../core";
import { CrossSellingItemType, CrossSellingItemTypeId, CrossSellingList, CrossSellingListId, CrossSellingListRepository, Menu, ProductInstanceParser, } from "../ordering";
import { perRestaurant } from "../di";
let CrossSellingListParser = class CrossSellingListParser {
    constructor(menu, productInstanceParser) {
        this.productInstanceParser = productInstanceParser;
        this.productTypeIds = menu.productTypes.ids;
        makeImmutable(this);
    }
    parseCrossSellingLists(catalogue) {
        const crossSellingListIds = toPairs(catalogue.crossSellingListsV2).mapOptional(([rawCrossSellingListId, crossSellingList]) => {
            return new CrossSellingList({
                id: new CrossSellingListId(rawCrossSellingListId),
                items: new RArray(crossSellingList.items).mapOptional(([crossSellingItemTypeId, crossSellingListItem]) => {
                    const productInstance = this.productInstanceParser.parseCartProductKey(crossSellingListItem.content);
                    if (productInstance === null) {
                        return null;
                    }
                    return new CrossSellingItemType({
                        id: new CrossSellingItemTypeId(crossSellingItemTypeId),
                        kind: "Manual",
                        productInstance,
                    });
                }),
            });
        });
        return IdMap.fromIterable(crossSellingListIds);
    }
    parseCrossSellingListAssociations(catalogue, crossSellingListIds) {
        return RMap.fromIterable(new RArray(this.productTypeIds).mapOptional((productTypeId) => {
            const productType = catalogue.products[productTypeId.value];
            let crossSellingListId = null;
            if (productType.crossSellingListOverride !== null) {
                crossSellingListId = crossSellingListIds.rawFind(productType.crossSellingListOverride);
            }
            if (crossSellingListId === null) {
                const productCategory = catalogue.categories[productType.category];
                if (productCategory.defaultCrossSellingList !== null) {
                    crossSellingListId = crossSellingListIds.rawFind(productCategory.defaultCrossSellingList);
                }
            }
            if (crossSellingListId === null) {
                return null;
            }
            return [productTypeId, crossSellingListId];
        }));
    }
    parse(catalogue) {
        const crossSellingLists = this.parseCrossSellingLists(catalogue);
        const crossSellingListAssociations = this.parseCrossSellingListAssociations(catalogue, crossSellingLists.ids);
        return new CrossSellingListRepository({
            crossSellingLists,
            crossSellingListAssociations,
        });
    }
};
CrossSellingListParser = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof Menu !== "undefined" && Menu) === "function" ? _a : Object, typeof (_b = typeof ProductInstanceParser !== "undefined" && ProductInstanceParser) === "function" ? _b : Object])
], CrossSellingListParser);
export { CrossSellingListParser };
