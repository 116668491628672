import { makeImmutable } from "../core";
export class PMenu {
    constructor(params) {
        this.domain = params.domain;
        this.sections = params.sections;
        makeImmutable(this);
    }
    findSchedule(scheduleId) {
        return this.domain.schedules.rawFind(scheduleId);
    }
}
