var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { FulfillmentTimeScope, ProductInstanceAvailabilityService, ProductInstancePreorderSettingsService, } from "../ordering";
import { LabelsCreator } from "./LabelRegistry";
let LabelsCreatorFactory = class LabelsCreatorFactory {
    constructor(fulfillmentTimeScope, productInstanceAvailabilityService, productInstancePreorderSettingsService) {
        this.fulfillmentTimeScope = fulfillmentTimeScope;
        this.productInstanceAvailabilityService = productInstanceAvailabilityService;
        this.productInstancePreorderSettingsService = productInstancePreorderSettingsService;
        makeImmutable(this);
    }
    create(params) {
        return new LabelsCreator({
            fulfillmentTimeScope: this.fulfillmentTimeScope,
            productInstanceAvailabilityService: this.productInstanceAvailabilityService,
            scope: params.scope,
            productInstances: params.productInstances,
            view: params.view,
            productInstancePreorderSettingsService: this.productInstancePreorderSettingsService,
        });
    }
};
LabelsCreatorFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof FulfillmentTimeScope !== "undefined" && FulfillmentTimeScope) === "function" ? _a : Object, typeof (_b = typeof ProductInstanceAvailabilityService !== "undefined" && ProductInstanceAvailabilityService) === "function" ? _b : Object, typeof (_c = typeof ProductInstancePreorderSettingsService !== "undefined" && ProductInstancePreorderSettingsService) === "function" ? _c : Object])
], LabelsCreatorFactory);
export { LabelsCreatorFactory };
