var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { makeAutoObservable } from "mobx";
import { singleton } from "tsyringe";
import { Availability } from "../Availability";
let ApartmentInfoAvailabilityService = class ApartmentInfoAvailabilityService {
    constructor() {
        makeAutoObservable(this);
    }
    availability(apartmentInfo) {
        switch (apartmentInfo.type) {
            case "Default":
                return Availability.available();
            case "Romanian": {
                const { isBlock, data } = apartmentInfo;
                return Availability.boolean({
                    BlockMissing: isBlock ? data.block.length === 0 : false,
                    StaircaseMissing: isBlock ? data.staircase.length === 0 : false,
                    FloorMissing: isBlock ? data.floor.length === 0 : false,
                    AptNumberMissing: isBlock ? data.aptNumber.length === 0 : false,
                });
            }
        }
    }
};
ApartmentInfoAvailabilityService = __decorate([
    singleton(),
    __metadata("design:paramtypes", [])
], ApartmentInfoAvailabilityService);
export { ApartmentInfoAvailabilityService };
