import { RArray, RMap } from "../../collections";
import { CustomParameterSet } from "../Menu";
export const CustomParameterInstances = {
    toCustomParameterSet(customParameterInstances) {
        const parameterChoices = customParameterInstances.objects.flatMap((customParameterInstance) => customParameterInstance.selectedChoice === null
            ? RArray.empty()
            : new RArray([
                [
                    customParameterInstance.customParameterType,
                    customParameterInstance.selectedChoice,
                ],
            ]));
        return CustomParameterSet.fromChoices(RMap.fromIterable(parameterChoices));
    },
};
