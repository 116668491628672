import { makeImmutable, optionalEq } from "../../core";
/**
 * A concrete selection of a CustomParameterChoice from a CustomParameterType by the user.
 *
 * For example: the user chooses "20cm" for the "size" parameter.
 */
export class CustomParameterInstance {
    constructor(params) {
        this.selectedChoice = null;
        this.customParameterType = params.customParameterType;
        this.selectedChoice = params.selectedChoice;
        makeImmutable(this);
    }
    eq(other) {
        return (this.typeId.eq(other.typeId) &&
            optionalEq(this.selectedChoice, other.selectedChoice));
    }
    get typeId() {
        return this.customParameterType.id;
    }
    get storageData() {
        if (this.selectedChoice === null) {
            return null;
        }
        return {
            selectedChoice: this.selectedChoice.value,
        };
    }
}
