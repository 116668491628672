var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ModifierSettings, } from "../ordering";
import { RArray, RMap } from "../collections";
import { singleton } from "tsyringe";
import { Quantity } from "../core";
let ModifierSettingsParser = class ModifierSettingsParser {
    parse(modifierItemTypeIds, modifierTypeEditability, settings) {
        return new ModifierSettings({
            editable: modifierTypeEditability,
            freeItems: Quantity.fromNumber(settings.freeItems),
            defaultItems: settings.defaultItems
                ? RMap.fromIterable(new RArray(settings.defaultItems).mapOptional(([itemId, quantity]) => {
                    const itemTypeId = modifierItemTypeIds.rawFind(itemId);
                    if (itemTypeId === null) {
                        return null;
                    }
                    return [itemTypeId, Quantity.fromNumber(quantity)];
                }))
                : null,
            displayDefaultItems: settings.displayDefaultItems,
        });
    }
};
ModifierSettingsParser = __decorate([
    singleton()
], ModifierSettingsParser);
export { ModifierSettingsParser };
