var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { IdMap } from "../collections";
import { PRestaurant } from "./PRestaurant";
import { perRestaurant } from "../di";
import { Coupon, CouponToken, PromotionScheme, PromotionSchemeAvailabilityService, } from "../ordering";
import { PPromotionInstanceFactory } from "./PPromotionInstanceFactory";
let PPromotionScheme = class PPromotionScheme {
    constructor(domain, restaurant, coupon, pPromotionInstanceFactory, promotionSchemeAvailabilityService) {
        this.domain = domain;
        this.restaurant = restaurant;
        this.coupon = coupon;
        this.pPromotionInstanceFactory = pPromotionInstanceFactory;
        this.promotionSchemeAvailabilityService = promotionSchemeAvailabilityService;
        makeAutoObservable(this);
    }
    get couponState() {
        return this.coupon.state;
    }
    clearCoupon() {
        this.coupon.clear();
    }
    get doesAnyPromotionRequireMarketingConsents() {
        return this.promotionSchemeAvailabilityService
            .doesAnyActivePromotionRequireMarketingConsents;
    }
    get promotionTypes() {
        if (this.coupon.type === "Enabled" &&
            this.coupon.couponPromotion !== null) {
            const couponPromotion = IdMap.fromIterable([this.coupon.couponPromotion]);
            return this.restaurant.promotionTypes.union(couponPromotion);
        }
        return this.restaurant.promotionTypes;
    }
    disableMarketingPromotionInstances() {
        this.domain.disableMarketingPromotionInstances();
    }
    promotionInstances(showInactivePromotionInstances) {
        const promotionInstances = showInactivePromotionInstances
            ? this.promotionSchemeAvailabilityService.possiblePromotionInstances
            : this.promotionSchemeAvailabilityService
                .activePossiblePromotionInstances;
        return promotionInstances.objects.map((promotionInstance) => this.pPromotionInstanceFactory.create({
            domain: promotionInstance,
            promotionType: this.promotionTypes.get(promotionInstance.promotionType.id),
        })).raw;
    }
};
PPromotionScheme = __decorate([
    perRestaurant(),
    __param(2, inject(CouponToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof PromotionScheme !== "undefined" && PromotionScheme) === "function" ? _a : Object, typeof (_b = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _b : Object, typeof (_c = typeof Coupon !== "undefined" && Coupon) === "function" ? _c : Object, typeof (_d = typeof PPromotionInstanceFactory !== "undefined" && PPromotionInstanceFactory) === "function" ? _d : Object, typeof (_e = typeof PromotionSchemeAvailabilityService !== "undefined" && PromotionSchemeAvailabilityService) === "function" ? _e : Object])
], PPromotionScheme);
export { PPromotionScheme };
