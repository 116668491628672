import { makeImmutable } from "../core";
/**
 * Quantity
 */
export class PositiveQuantity {
    constructor(value = 1) {
        if (value <= 0) {
            throw new Error("PositiveQuantity cannot represent less than one");
        }
        this.value = value;
        makeImmutable(this);
    }
    eq(other) {
        return this.value === other.value;
    }
    /**
     * Add another Quantity
     */
    add(other) {
        return new PositiveQuantity(this.value + other.value);
    }
    /**
     * Multiply by another Quantity
     */
    mul(other) {
        return new PositiveQuantity(this.value * other.value);
    }
}
