var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { flowResult, makeAutoObservable } from "mobx";
import { RestaurantId } from "../../core";
import { perRestaurant } from "../../di";
import { DeliveryAddressFormController } from "./DeliveryAddressFormController";
import { PGeolocation } from "../PGeolocation";
import { PDeliveryAddress } from "../PDeliveryAddress";
let PDeliveryAddressCoordinator = class PDeliveryAddressCoordinator {
    constructor(restaurantId, geolocation, deliveryAddress, deliveryAddressController) {
        this.restaurantId = restaurantId;
        this.geolocation = geolocation;
        this.deliveryAddress = deliveryAddress;
        this.deliveryAddressController = deliveryAddressController;
        makeAutoObservable(this);
        // NOTICE Validate street address when entering cart/checkout
        void this.validateAndSave();
    }
    get isGeolocationLoading() {
        return this.geolocation.status.type === "Loading";
    }
    get geolocationError() {
        return this.geolocation.error;
    }
    async locateMe() {
        return flowResult(this.deliveryAddressController.locateMe(this.restaurantId));
    }
    // TODO: (B2C-256) remove it, see PDeliveryAddress comment
    async validateAndForceSave() {
        return await flowResult(this.deliveryAddress.validateAndForceSave(this.restaurantId));
    }
    async validateAndSave() {
        return await flowResult(this.deliveryAddress.validateAndSave(this.restaurantId));
    }
    saveUnvalidated() {
        this.deliveryAddress.saveUnvalidated();
    }
};
PDeliveryAddressCoordinator = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof RestaurantId !== "undefined" && RestaurantId) === "function" ? _a : Object, typeof (_b = typeof PGeolocation !== "undefined" && PGeolocation) === "function" ? _b : Object, typeof (_c = typeof PDeliveryAddress !== "undefined" && PDeliveryAddress) === "function" ? _c : Object, typeof (_d = typeof DeliveryAddressFormController !== "undefined" && DeliveryAddressFormController) === "function" ? _d : Object])
], PDeliveryAddressCoordinator);
export { PDeliveryAddressCoordinator };
