export function formatAddress(address, showPostCode) {
    return {
        firstLine: `${address.street} ${address.streetNumber}`.trim(),
        secondLine: `${showPostCode && address.postCode !== null ? address.postCode + " " : ""}${address.city}`.trim(),
    };
}
export function formatAddressAsSingleLine(address, showPostCode) {
    const result = formatAddress(address, showPostCode);
    return `${result.firstLine}, ${result.secondLine}`;
}
