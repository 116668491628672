var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { IdMap, toPairs } from "../collections";
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { InCartPromotionTypes } from "./InCartPromotionTypes";
import { PurchaseOrderScope } from "./PurchaseOrderScope";
import { PromotionInstance } from "./PromotionInstance";
import { PromotionScheme } from "./PromotionScheme";
import { PromotionSolverVariableAssignmentFactory } from "./PromotionSolver";
import { PurchaseOrderPayload } from "./PurchaseOrderPayload";
let PromotionSchemeFactory = class PromotionSchemeFactory {
    constructor(inCartPromotionTypes, purchaseOrderPayload, purchaseOrderScope, promotionSolverVariableAssignmentFactory) {
        this.inCartPromotionTypes = inCartPromotionTypes;
        this.purchaseOrderPayload = purchaseOrderPayload;
        this.purchaseOrderScope = purchaseOrderScope;
        this.promotionSolverVariableAssignmentFactory = promotionSolverVariableAssignmentFactory;
        makeImmutable(this);
    }
    fromStorageData(storageData) {
        const promotionInstances = storageData === null
            ? IdMap.empty()
            : IdMap.fromIterable(toPairs(storageData).mapOptional(([instanceId, instanceStorageData]) => PromotionInstance.fromStorageData({
                promotionTypes: this.inCartPromotionTypes.promotionTypes,
                purchaseOrderPayload: this.purchaseOrderPayload,
                id: parseInt(instanceId),
                storageData: instanceStorageData,
            })));
        const promotionScheme = new PromotionScheme({
            promotionSolverVariableAssignmentFactory: this.promotionSolverVariableAssignmentFactory,
            promotionInstances,
        });
        promotionScheme.setScope(this.purchaseOrderScope);
        return promotionScheme;
    }
};
PromotionSchemeFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof InCartPromotionTypes !== "undefined" && InCartPromotionTypes) === "function" ? _a : Object, typeof (_b = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _b : Object, typeof (_c = typeof PurchaseOrderScope !== "undefined" && PurchaseOrderScope) === "function" ? _c : Object, typeof (_d = typeof PromotionSolverVariableAssignmentFactory !== "undefined" && PromotionSolverVariableAssignmentFactory) === "function" ? _d : Object])
], PromotionSchemeFactory);
export { PromotionSchemeFactory };
