import { makeImmutable, Money } from "../core";
export class DeliveryPolicy {
    constructor(favor) {
        this.favor = favor;
        makeImmutable(this);
    }
    static paid(params) {
        return new DeliveryPolicy({ type: "possible", ...params });
    }
    get deliveryCost() {
        switch (this.favor.type) {
            case "possible":
                return this.favor.cost;
            case "impossible":
                return Money.zero();
        }
    }
    get minOrderValue() {
        switch (this.favor.type) {
            case "possible":
                return this.favor.minOrderValue;
            case "impossible":
                return null;
        }
    }
    validate({ value: orderValue, }) {
        if (this.favor.type === "impossible") {
            return "DeliveryImpossible";
        }
        if (this.favor.freeDeliveryThreshold &&
            orderValue.geq(this.favor.freeDeliveryThreshold)) {
            return "FreeDeliveryPossible";
        }
        return "PaidDeliveryPossible";
    }
}
DeliveryPolicy.Impossible = new DeliveryPolicy({ type: "impossible" });
