var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { singleton } from "tsyringe";
import { IdMap, RArray } from "../collections";
import { makeImmutable, Quantity } from "../core";
import { ModifierType, ModifierTypeId } from "../ordering";
import { ModifierItemTypeParser } from "./ModifierItemTypeParser";
import { ParametrizationParser } from "./ParametrizationParser";
let ModifierTypeParser = class ModifierTypeParser {
    constructor(parametrizationParser, modifierItemTypeParser) {
        this.parametrizationParser = parametrizationParser;
        this.modifierItemTypeParser = modifierItemTypeParser;
        makeImmutable(this);
    }
    parse(customParameterTypes, id, modifier) {
        const flattenedSections = modifier.sections.flatMap((section) => section.items);
        const itemTypes = IdMap.fromIterable(new RArray(flattenedSections).mapOptional(([id, item]) => this.modifierItemTypeParser.parse(customParameterTypes, id, item)));
        return new ModifierType({
            id: new ModifierTypeId(id),
            itemTypes,
            behaviour: modifier.displayMode === "ModifierPizzaGrid"
                ? "Wrapping"
                : modifier.displayMode === "ModifierRadioButtonList"
                    ? "SingleChoice"
                    : modifier.displayMode === "ModifierCheckboxList"
                        ? "MultipleChoice"
                        : "Numeric",
            maxDuplicatesPerItem: modifier.maxDuplicatesPerItem === null
                ? null
                : Quantity.fromNumber(modifier.maxDuplicatesPerItem),
            maxItems: modifier.maxItems === null
                ? null
                : Quantity.fromNumber(modifier.maxItems),
            minItems: Quantity.fromNumber(modifier.minItems),
            isDivisible: modifier.divisible,
            availabilityParametrization: this.parametrizationParser.parse(customParameterTypes, modifier.availableWhen),
            editabilityParametrization: this.parametrizationParser.parse(customParameterTypes, modifier.userEditableWhen),
            exchangePolicy: modifier.exchangePolicy,
        });
    }
};
ModifierTypeParser = __decorate([
    singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof ParametrizationParser !== "undefined" && ParametrizationParser) === "function" ? _a : Object, typeof (_b = typeof ModifierItemTypeParser !== "undefined" && ModifierItemTypeParser) === "function" ? _b : Object])
], ModifierTypeParser);
export { ModifierTypeParser };
