import { makeAutoObservable } from "mobx";
import { RSet } from "../../collections";
import { ParameterScope } from "../ParameterScope";
import { ProductInstancePartScope, SingleProductInstancePart, } from "../ProductInstance";
export class SinglePartCreatorState {
    constructor(params) {
        this.isSplit = false;
        this.scope = ParameterScope.Uninitialized;
        this.productCategory = params.productCategory;
        this.commonConfigCreator = params.commonConfigCreator;
        this.partCreator = params.partCreator;
        makeAutoObservable(this);
    }
    initWithScope(parentScope) {
        const isSplitScope = new ProductInstancePartScope({
            parentScope,
            isSplit: this.isSplit,
        });
        this.commonConfigCreator.initWithScope(isSplitScope);
        this.scope = this.commonConfigCreator.scope;
        this.partCreator.initWithScope(this.scope);
    }
    get productTypeIds() {
        return RSet.singleton(this.partCreator.productTypeId);
    }
    get commonProductConfig() {
        return this.commonConfigCreator.productConfig;
    }
    get partProductConfig() {
        return this.partCreator.productConfigCreator.productConfig;
    }
    get productInstanceParts() {
        return new SingleProductInstancePart(this.partCreator.productInstancePart);
    }
    selectInferredDefaults() {
        this.commonConfigCreator.selectInferredDefaults();
        this.partCreator.selectInferredDefaults();
    }
}
