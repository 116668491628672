import { makeAutoObservable } from "mobx";
import { InternalParameterSet, ParameterSet } from "../Menu";
export class ProductInstancePartScope {
    constructor(params) {
        this.parent = params.parentScope;
        this.isSplit = params.isSplit;
        makeAutoObservable(this);
    }
    get parameterSet() {
        const selfParameterSet = new ParameterSet({
            internal: new InternalParameterSet({
                fulfillmentMethod: null,
                splitToHalves: this.isSplit,
            }),
        });
        const result = this.parent.parameterSet.union(selfParameterSet);
        if (result === null) {
            throw new Error("Incompatible parameter scopes");
        }
        return result;
    }
}
