import { makeAutoObservable } from "mobx";
import { P24_CHANNEL_TAG, PAY_PO_CHANNEL_ID, isAppleChannelId } from "./P24";
const PAYMENT_CHANNELS_WITH_ADDRESS_REQUIRED = [PAY_PO_CHANNEL_ID];
export class Payment {
    constructor(params) {
        this.address = params.address;
        this.medium = params.medium;
        this.isRestored = params.isRestored;
        makeAutoObservable(this);
    }
    static fromStorageData(params) {
        const isStoredMediumAvailable = isMediumAvailable(params.storageData, params.restaurant.paymentMethods, params.restaurant.paymentChannels);
        return new Payment({
            address: params.paymentAddress,
            medium: isStoredMediumAvailable ? params.storageData : null,
            isRestored: isStoredMediumAvailable,
        });
    }
    get method() {
        // TODO: default payment method?
        if (!this.medium) {
            return "Cash";
        }
        return this.medium.method;
    }
    // TODO: different abstraction for channels with specific logic, like PayPo & Blik
    get isAddressRequired() {
        if (this.medium === null || this.medium.type !== "channel") {
            return false;
        }
        return (this.medium.channelId.tag === P24_CHANNEL_TAG &&
            PAYMENT_CHANNELS_WITH_ADDRESS_REQUIRED.includes(this.medium.channelId.contents));
    }
    get isApplePay() {
        var _a;
        return (((_a = this.medium) === null || _a === void 0 ? void 0 : _a.type) === "channel" && isAppleChannelId(this.medium.channelId));
    }
    get storageData() {
        return this.medium;
    }
}
export function equalPaymentChannelIds(a, b) {
    if (a.tag !== "StripeChannel" && b.tag !== "StripeChannel") {
        return a.tag === b.tag && a.contents === b.contents;
    }
    return a.tag === "StripeChannel" && b.tag === "StripeChannel";
}
function isMediumAvailable(storageData, paymentMethods, paymentChannels) {
    if (storageData === null) {
        return false;
    }
    switch (storageData.type) {
        case "method":
            return paymentMethods.some((method) => method === storageData.method);
        case "channel":
            return paymentChannels
                .filtered((channel) => channel.enabled === true)
                .some((channel) => equalPaymentChannelIds(channel.id, storageData.channelId));
    }
}
