import { makeImmutable } from "../../../core";
export class PreorderSettingsBuffer {
    constructor(minDays) {
        this.minDays = minDays;
        makeImmutable(this);
    }
    eq(other) {
        return this.minDays === other.minDays;
    }
    min(other) {
        return new PreorderSettingsBuffer(Math.min(other.minDays));
    }
}
PreorderSettingsBuffer.Null = new PreorderSettingsBuffer(0);
