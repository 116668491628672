var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
import { inject } from "tsyringe";
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { LocalizationProvider } from "../locale";
import { PurchaseOrderPriceService, TipInstance, TipInstanceToken, } from "../ordering";
import { PPurchaseOrder } from "./PPurchaseOrder";
import { PTip } from "./PTip";
let PTipProvider = class PTipProvider {
    constructor(localizationProvider, purchaseOrder, purchaseOrderPriceService, tip) {
        this.tip = PTip.create({
            domain: tip,
            localizationProvider,
            purchaseOrder,
            purchaseOrderPriceService,
        });
        makeImmutable(this);
    }
};
PTipProvider = __decorate([
    perRestaurant(),
    __param(3, inject(TipInstanceToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof LocalizationProvider !== "undefined" && LocalizationProvider) === "function" ? _a : Object, typeof (_b = typeof PPurchaseOrder !== "undefined" && PPurchaseOrder) === "function" ? _b : Object, typeof (_c = typeof PurchaseOrderPriceService !== "undefined" && PurchaseOrderPriceService) === "function" ? _c : Object, typeof (_d = typeof TipInstance !== "undefined" && TipInstance) === "function" ? _d : Object])
], PTipProvider);
export { PTipProvider };
