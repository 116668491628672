var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
import { inject, singleton } from "tsyringe";
import { RArray } from "../collections";
import { GlobalConfig } from "../config";
import { makeImmutable } from "../core";
import { LocalizationProvider } from "../locale";
import { IQrWaiter } from "./IQrWaiter";
import { RestaumaticRPCService } from "./RestaumaticRPCService";
/**
 * Service for getting information about the order checkout
 */
let CheckoutInfoService = class CheckoutInfoService {
    constructor(rpcService, localizationProvider, qrWaiter, globalConfig) {
        this.rpcService = rpcService;
        this.localizationProvider = localizationProvider;
        this.qrWaiter = qrWaiter;
        this.isIosReviewMode = globalConfig.isIosReviewMode;
        makeImmutable(this);
    }
    /**
     * @returns basic information about the checkout
     */
    async getCheckoutInfo(restaurantId) {
        const checkoutInfo = await this.rpcService.rpc("GetCheckoutInfoRequest", {
            restaurantId: restaurantId.value,
            isQrWaiterOrder: this.qrWaiter.isQrWaiterOrder(),
            isIosReviewMode: this.isIosReviewMode,
            locale: this.localizationProvider.locale,
        });
        if (checkoutInfo === null) {
            throw new Error("Unable to load checkout info");
        }
        return {
            paymentMethods: new RArray(checkoutInfo.paymentMethods),
            fulfillmentMethods: new RArray(checkoutInfo.fulfillmentMethods),
            franchiseName: checkoutInfo.franchiseName,
        };
    }
    async getPaymentChannels(restaurantId) {
        const result = await this.rpcService.rpc("GetPaymentChannelsRequest", {
            restaurantId: restaurantId.value,
        });
        if (result === null) {
            console.log("Payment channels retrieval failed");
            return RArray.empty();
        }
        return new RArray(result.channels);
    }
};
CheckoutInfoService = __decorate([
    singleton(),
    __param(2, inject(IQrWaiter)),
    __param(3, inject(GlobalConfig)),
    __metadata("design:paramtypes", [typeof (_a = typeof RestaumaticRPCService !== "undefined" && RestaumaticRPCService) === "function" ? _a : Object, typeof (_b = typeof LocalizationProvider !== "undefined" && LocalizationProvider) === "function" ? _b : Object, typeof (_c = typeof IQrWaiter !== "undefined" && IQrWaiter) === "function" ? _c : Object, typeof (_d = typeof GlobalConfig !== "undefined" && GlobalConfig) === "function" ? _d : Object])
], CheckoutInfoService);
export { CheckoutInfoService };
