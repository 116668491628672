var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { Money } from "../../core";
import { perRestaurant } from "../../di";
import { Delivery } from "../Delivery";
import { FulfillmentInstructions, FulfillmentInstructionsToken, } from "../FulfillmentInstructions";
import { PurchaseOrderPackingService } from "../PackingService";
import { Price } from "../Price";
import { PurchaseOrder } from "../PurchaseOrder";
import { PurchaseOrderValidationPrice } from "../PurchaseOrderValidationPrice";
import { Restaurant } from "../Restaurant";
import { TipInstance, TipInstanceToken } from "../TipInstance";
import { PromotionInstancePriceService } from "./PromotionInstancePriceService";
import { PurchaseOrderPayloadPriceService } from "./PurchaseOrderPayloadPriceService";
export const IPromotionSchemeAvailabilityService = "IPromotionSchemeAvailabilityService";
let PurchaseOrderPriceService = class PurchaseOrderPriceService {
    constructor(restaurant, purchaseOrder, tip, delivery, purchaseOrderPayloadPriceService, promotionSchemeAvailabilityService, fulfillmentInstructions, promotionInstancePriceService, purchaseOrderPackingService) {
        this.restaurant = restaurant;
        this.purchaseOrder = purchaseOrder;
        this.tip = tip;
        this.delivery = delivery;
        this.purchaseOrderPayloadPriceService = purchaseOrderPayloadPriceService;
        this.promotionSchemeAvailabilityService = promotionSchemeAvailabilityService;
        this.fulfillmentInstructions = fulfillmentInstructions;
        this.promotionInstancePriceService = promotionInstancePriceService;
        this.purchaseOrderPackingService = purchaseOrderPackingService;
        makeAutoObservable(this);
    }
    get validationPrice() {
        const priceWithoutDelivery = this.priceWithoutDelivery.finalValue;
        if (priceWithoutDelivery === null) {
            return null;
        }
        return PurchaseOrderValidationPrice.create({
            deliveryInTotal: this.restaurant.orderingPolicy.deliveryInTotal,
            priceWithoutDelivery,
            deliveryCost: this.purchaseOrder.deliveryCost,
        });
    }
    get deliveryPrice() {
        if (this.validationPrice === null) {
            return Money.zero();
        }
        if (this.fulfillmentInstructions.fulfillmentMethod === "Delivery") {
            return this.delivery.price(this.validationPrice);
        }
        return Money.zero();
    }
    get promotionSchemePrice() {
        return Price.total(this.promotionSchemeAvailabilityService.activePossiblePromotionInstances.objects.map((promotionInstance) => this.promotionInstancePriceService.value(promotionInstance)));
    }
    get priceWithoutDelivery() {
        const packagingPrice = Price.unscoped(this.purchaseOrderPackingService.packaging.price);
        return this.purchaseOrderPayloadPriceService.price
            .add(packagingPrice)
            .add(this.promotionSchemePrice);
    }
    get priceWithoutTip() {
        const calculatedDeliveryPrice = Price.unscoped(this.deliveryPrice);
        return this.priceWithoutDelivery.add(calculatedDeliveryPrice);
    }
    get price() {
        const tipPrice = Price.unscoped(this.tip.value);
        return this.priceWithoutTip.add(tipPrice);
    }
};
PurchaseOrderPriceService = __decorate([
    perRestaurant(),
    __param(2, inject(TipInstanceToken)),
    __param(5, inject(IPromotionSchemeAvailabilityService)),
    __param(6, inject(FulfillmentInstructionsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof Restaurant !== "undefined" && Restaurant) === "function" ? _a : Object, typeof (_b = typeof PurchaseOrder !== "undefined" && PurchaseOrder) === "function" ? _b : Object, typeof (_c = typeof TipInstance !== "undefined" && TipInstance) === "function" ? _c : Object, typeof (_d = typeof Delivery !== "undefined" && Delivery) === "function" ? _d : Object, typeof (_e = typeof PurchaseOrderPayloadPriceService !== "undefined" && PurchaseOrderPayloadPriceService) === "function" ? _e : Object, Object, typeof (_f = typeof FulfillmentInstructions !== "undefined" && FulfillmentInstructions) === "function" ? _f : Object, typeof (_g = typeof PromotionInstancePriceService !== "undefined" && PromotionInstancePriceService) === "function" ? _g : Object, typeof (_h = typeof PurchaseOrderPackingService !== "undefined" && PurchaseOrderPackingService) === "function" ? _h : Object])
], PurchaseOrderPriceService);
export { PurchaseOrderPriceService };
