var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { singleton } from "tsyringe";
import { IdMap, toPairs, WeeklySchedule } from "../collections";
import { makeImmutable } from "../core";
import { Schedule, ScheduleId } from "../ordering";
let ScheduleParser = class ScheduleParser {
    constructor() {
        makeImmutable(this);
    }
    parse(availabilitySchedules) {
        const schedules = toPairs(availabilitySchedules).map(([id, schedule]) => new Schedule({
            id: new ScheduleId(id),
            schedule: WeeklySchedule.fromWeeklyAvailability(schedule.availability),
        }));
        return IdMap.fromIterable(schedules);
    }
};
ScheduleParser = __decorate([
    singleton(),
    __metadata("design:paramtypes", [])
], ScheduleParser);
export { ScheduleParser };
