var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { Money } from "../core";
import { perRestaurant } from "../di";
import { FulfillmentInstructions, FulfillmentInstructionsToken, } from "./FulfillmentInstructions";
import { Restaurant } from "./Restaurant";
let Delivery = class Delivery {
    constructor(fulfillmentInstructions, restaurant) {
        this.fulfillmentInstructions = fulfillmentInstructions;
        this.defaultMinOrderValue = restaurant.minOrderValue.default;
        makeAutoObservable(this);
    }
    get lastDeliveryPolicy() {
        return this.fulfillmentInstructions.isDelivery
            ? this.fulfillmentInstructions.deliveryAddress.deliveryPolicy
            : null;
    }
    get cost() {
        if (this.lastDeliveryPolicy === null) {
            return Money.zero();
        }
        return this.lastDeliveryPolicy.deliveryCost;
    }
    get minOrderValue() {
        var _a, _b;
        return (_b = (_a = this.lastDeliveryPolicy) === null || _a === void 0 ? void 0 : _a.minOrderValue) !== null && _b !== void 0 ? _b : this.defaultMinOrderValue;
    }
    price(orderValidationPrice) {
        if (this.lastDeliveryPolicy === null) {
            return Money.zero();
        }
        const deliveryValidationResult = this.lastDeliveryPolicy.validate(orderValidationPrice);
        switch (deliveryValidationResult) {
            case "FreeDeliveryPossible":
            case "DeliveryImpossible":
                return Money.zero();
            case "PaidDeliveryPossible":
                return this.cost;
        }
    }
};
Delivery = __decorate([
    perRestaurant(),
    __param(0, inject(FulfillmentInstructionsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof FulfillmentInstructions !== "undefined" && FulfillmentInstructions) === "function" ? _a : Object, typeof (_b = typeof Restaurant !== "undefined" && Restaurant) === "function" ? _b : Object])
], Delivery);
export { Delivery };
