import { makeAutoObservable } from "mobx";
import { IdMap, RArray } from "../../collections";
import { OrderLineReference } from "../OrderLineReference";
import { ParameterScope } from "../ParameterScope";
export class ProductPromotionInstance {
    constructor(params) {
        this.kind = "Product";
        this.id = params.id;
        this.promotionType = params.promotionType;
        this._active = params.active;
        this.discountSource = params.discountSource;
        this.discountTarget = params.discountTarget;
        this._freebie = params.freebie;
        this._scope = ParameterScope.Uninitialized;
        this.excludedInstances = IdMap.empty();
        makeAutoObservable(this);
    }
    static fromStorageData(params) {
        const { promotionType, id, storageData, purchaseOrderPayload } = params;
        if (promotionType.scope.kind !== "Product") {
            return null;
        }
        const discountSource = storageData.discountSource.map((referenceStorageData) => OrderLineReference.fromStorageData({
            purchaseOrderPayload,
            storageData: referenceStorageData,
        }));
        if (!discountSource.every((el) => el !== null)) {
            return null;
        }
        const discountTarget = OrderLineReference.fromStorageData({
            purchaseOrderPayload,
            storageData: storageData.discountTarget,
        });
        if (discountTarget === null) {
            return null;
        }
        return new ProductPromotionInstance({
            id,
            promotionType,
            active: storageData.active,
            discountSource: new RArray(discountSource),
            discountTarget,
            freebie: params.freebie,
        });
    }
    get scope() {
        return this._scope;
    }
    setScope(scope) {
        this._scope = scope;
    }
    setExcludedInstances(excluded) {
        this.excludedInstances = excluded;
    }
    get active() {
        return this._active;
    }
    setActive(active) {
        this._active = active;
        if (active) {
            this.excludedInstances.objects.forEach((excludedInstance) => excludedInstance.setActive(false));
        }
    }
    get freebie() {
        return this._freebie;
    }
    setFreebie(freebie) {
        this._freebie = freebie;
    }
    get storageData() {
        return {
            kind: "ProductPromotionInstance",
            promotionTypeId: this.promotionType.id.value,
            active: this.active,
            discountSource: this.discountSource.raw.map((discountSource) => discountSource.storageData),
            discountTarget: this.discountTarget.storageData,
            freebie: this.freebie === null ? null : this.freebie.storageData,
        };
    }
}
