import { DysjointDateRanges, makeImmutable } from "../core";
import { RDay } from "./RDay";
export class PreorderSettingsInstance {
    constructor(params) {
        this.dateRanges = params.dateRanges;
        this.isSameDayOnly = params.isSameDayOnly;
        this.isInThePast = params.isInThePast;
        this.hasTodayDate = params.hasTodayDate;
        this.includesNow = params.includesNow;
        makeImmutable(this);
    }
    static union(preorderSettings) {
        if (preorderSettings.size === 1) {
            return preorderSettings.get(0);
        }
        const rangesUnion = preorderSettings
            .map((setting) => setting.dateRanges)
            .reduce((dateRange, preorderDateRange) => dateRange.union(preorderDateRange), DysjointDateRanges.Empty);
        return new PreorderSettingsInstance({
            dateRanges: rangesUnion,
            isSameDayOnly: preorderSettings.every((settings) => settings.isSameDayOnly),
            isInThePast: preorderSettings.every((settings) => settings.isInThePast),
            hasTodayDate: preorderSettings.some((settings) => settings.hasTodayDate),
            includesNow: preorderSettings.some((settings) => settings.includesNow),
        });
    }
    static intersection(preorderSettings) {
        if (preorderSettings.size === 1) {
            return preorderSettings.get(0);
        }
        const rangesIntersection = preorderSettings
            .map((setting) => setting.dateRanges)
            .reduce((dateRange, preorderDateRange) => 
        // NOTICE Order of arguments matters: X ∩ Empty = X, but Empty ∩ X = Empty
        preorderDateRange.intersection(dateRange), DysjointDateRanges.Empty);
        return new PreorderSettingsInstance({
            dateRanges: rangesIntersection,
            isSameDayOnly: preorderSettings.every((settings) => settings.isSameDayOnly),
            isInThePast: preorderSettings.some((settings) => settings.isInThePast),
            hasTodayDate: preorderSettings.every((settings) => settings.hasTodayDate),
            includesNow: preorderSettings.every((settings) => settings.includesNow),
        });
    }
    get maxDay() {
        const maxDay = this.dateRanges.end;
        if (maxDay === null) {
            return null;
        }
        return RDay.fromRDate(maxDay);
    }
    get minDay() {
        const minDay = this.dateRanges.begin;
        if (minDay === null) {
            return null;
        }
        return RDay.fromRDate(minDay);
    }
    canOrderAt(fulfillmentTime) {
        switch (fulfillmentTime.type) {
            case "ASAP":
                return this.includesNow;
            case "OnTime":
                return this.includes(fulfillmentTime.date);
        }
    }
    includes(date) {
        return this.dateRanges.includes(date);
    }
    get onlyRange() {
        const { ranges } = this.dateRanges;
        if (ranges.length === 1) {
            return ranges[0];
        }
        return null;
    }
    get hasMultipleRanges() {
        return this.dateRanges.ranges.length > 1;
    }
}
