var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { LabelsCreatorFactory } from "../labels";
import { LocalizationProvider } from "../locale";
import { ProductInstanceAvailabilityService, OrderLinePriceService, PurchaseOrderScope, } from "../ordering";
import { POrderLine } from "./POrderLine";
import { PRestaurant } from "./PRestaurant";
let POrderLineFactory = class POrderLineFactory {
    constructor(restaurant, localizationProvider, productInstanceAvailabilityService, labelsCreatorFactory, orderLinePriceService, purchaseOrderScope) {
        this.restaurant = restaurant;
        this.localizationProvider = localizationProvider;
        this.productInstanceAvailabilityService = productInstanceAvailabilityService;
        this.labelsCreatorFactory = labelsCreatorFactory;
        this.orderLinePriceService = orderLinePriceService;
        this.purchaseOrderScope = purchaseOrderScope;
        makeImmutable(this);
    }
    create(domain) {
        return new POrderLine({
            domain,
            restaurant: this.restaurant,
            localizationProvider: this.localizationProvider,
            productInstanceAvailabilityService: this.productInstanceAvailabilityService,
            labelsCreatorFactory: this.labelsCreatorFactory,
            orderLinePriceService: this.orderLinePriceService,
            scope: this.purchaseOrderScope,
        });
    }
};
POrderLineFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _a : Object, typeof (_b = typeof LocalizationProvider !== "undefined" && LocalizationProvider) === "function" ? _b : Object, typeof (_c = typeof ProductInstanceAvailabilityService !== "undefined" && ProductInstanceAvailabilityService) === "function" ? _c : Object, typeof (_d = typeof LabelsCreatorFactory !== "undefined" && LabelsCreatorFactory) === "function" ? _d : Object, typeof (_e = typeof OrderLinePriceService !== "undefined" && OrderLinePriceService) === "function" ? _e : Object, typeof (_f = typeof PurchaseOrderScope !== "undefined" && PurchaseOrderScope) === "function" ? _f : Object])
], POrderLineFactory);
export { POrderLineFactory };
