var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { translate, t } from "../../I18n";
import { flow, makeAutoObservable } from "mobx";
import { toPairs } from "../../collections";
import { ErrorBox, TextFieldController } from "../../form";
import { PDeliveryAddress } from "../PDeliveryAddress";
import { singleton } from "tsyringe";
import { DeliveryAddressAvailabilityService, StreetAddressAvailabilityService, } from "../../ordering";
import { PGeolocation } from "../PGeolocation";
let DeliveryAddressFormController = class DeliveryAddressFormController {
    constructor(geolocation, deliveryAddress, streetAddressAvailabilityService, deliveryAddressAvailabilityService) {
        this.geolocation = geolocation;
        this.deliveryAddressAvailabilityService = deliveryAddressAvailabilityService;
        this.deliveryAddress = deliveryAddress;
        const { streetAddress } = deliveryAddress;
        const streetAddressAvailability = {
            get availability() {
                return streetAddressAvailabilityService.availability(streetAddress);
            },
        };
        this.street = new TextFieldController({
            initialValue: streetAddress.street,
            onUpdate: (str) => {
                streetAddress.street = str;
            },
            errorBox: new ErrorBox({
                domainObjects: [streetAddressAvailability],
                errorMessages: {
                    StreetMissing: translate(t._common.form.errors.required),
                },
            }),
        });
        this.streetNumber = new TextFieldController({
            initialValue: streetAddress.streetNumber,
            onUpdate: (str) => {
                streetAddress.streetNumber = str;
            },
            errorBox: new ErrorBox({
                domainObjects: [streetAddressAvailability],
                errorMessages: {
                    StreetNumberMissing: translate(t._common.form.errors.required),
                },
            }),
        });
        this.city = new TextFieldController({
            initialValue: streetAddress.city,
            onUpdate: (str) => {
                streetAddress.city = str;
            },
            errorBox: new ErrorBox({
                domainObjects: [streetAddressAvailability],
                errorMessages: {
                    CityMissing: translate(t._common.form.errors.required),
                },
            }),
        });
        this.postCode = new TextFieldController({
            initialValue: streetAddress.postCode,
            onUpdate: (str) => {
                streetAddress.postCode = str;
            },
            errorBox: new ErrorBox({
                domainObjects: [streetAddressAvailability],
                errorMessages: {
                    PostCodeMissing: translate(t._common.form.errors.required),
                },
            }),
        });
        // TODO: (re)move this?
        if (!streetAddress.isEmpty) {
            this.submit();
        }
        makeAutoObservable(this, { locateMe: flow });
    }
    get fields() {
        return {
            street: this.street,
            streetNumber: this.streetNumber,
            city: this.city,
            postCode: this.postCode,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const fieldsInvalid = this.fieldsArray.some((field) => field.isInvalid);
        const modelAvailability = this.deliveryAddressAvailabilityService.availability;
        const modelInvalid = modelAvailability.isAvailable.isDefinitelyFalse;
        return fieldsInvalid || modelInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.deliveryAddress;
    }
    get isPostCodeRequired() {
        return this.deliveryAddress.streetAddress.isPostCodeRequired;
    }
    get validationStatus() {
        return this.deliveryAddress.status.state;
    }
    // NOTICE: for tracking purposes only
    get streetAddress() {
        return this.deliveryAddress.streetAddress;
    }
    // TODO: rename to blur?
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
    *locateMe(restaurantId) {
        // TODO Handle subgenerators typing better
        const streetAddress = (yield this.geolocation.locateMe(restaurantId));
        if (streetAddress !== null) {
            const { city, street, streetNumber } = this.fields;
            city.change(streetAddress.city);
            street.change(streetAddress.street);
            streetNumber.change(streetAddress.streetNumber);
            return true;
        }
        return false;
    }
};
DeliveryAddressFormController = __decorate([
    singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof PGeolocation !== "undefined" && PGeolocation) === "function" ? _a : Object, typeof (_b = typeof PDeliveryAddress !== "undefined" && PDeliveryAddress) === "function" ? _b : Object, typeof (_c = typeof StreetAddressAvailabilityService !== "undefined" && StreetAddressAvailabilityService) === "function" ? _c : Object, typeof (_d = typeof DeliveryAddressAvailabilityService !== "undefined" && DeliveryAddressAvailabilityService) === "function" ? _d : Object])
], DeliveryAddressFormController);
export { DeliveryAddressFormController };
