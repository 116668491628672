import { FieldTests, LocalStorage, StorageManagerFactory, StorageVersionManager, } from "restaumatic-client";
import { addingProductToTheCartReactionExperiment } from "./experiments/AddingProductToTheCartReaction";
import { menuStickyTabs } from "./experiments/MenuStickyTabs";
/**
 * List of experiments to apply.
 */
const experiments = [
    addingProductToTheCartReactionExperiment,
    menuStickyTabs,
];
/**
 * Global instance for initialising and getting experiments' data.
 * TODO: Move it to the DI global container if ready.
 * NOTICE Trouble is that this is used way before client is even initialized
 */
const _storage = LocalStorage.create();
const fieldTest = new FieldTests({
    fieldTestsStorageManager: new StorageManagerFactory(_storage, new StorageVersionManager(_storage)).forFieldTests(),
});
export default {
    init() {
        fieldTest.applyExperiments(experiments);
    },
    getVariant(experiment) {
        return fieldTest.getVariant(experiment);
    },
};
