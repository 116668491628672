var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { Money } from "../core";
import { perRestaurant } from "../di";
import { Delivery } from "./Delivery";
import { FulfillmentInstructions, FulfillmentInstructionsToken, } from "./FulfillmentInstructions";
import { Restaurant } from "./Restaurant";
/**
 * Represents an order from the perspective of a buyer.
 */
let PurchaseOrder = class PurchaseOrder {
    constructor(restaurant, delivery, fulfillmentInstructions) {
        this.restaurant = restaurant;
        this.delivery = delivery;
        this.fulfillmentInstructions = fulfillmentInstructions;
        makeAutoObservable(this);
    }
    get minOrderValue() {
        if (this.fulfillmentInstructions.isDelivery) {
            return this.delivery.minOrderValue;
        }
        if (this.fulfillmentInstructions.fulfillmentMethodType === "Takeaway") {
            return this.restaurant.minOrderValue.takeaway;
        }
        return Money.zero();
    }
    get deliveryCost() {
        if (this.fulfillmentInstructions.isDelivery) {
            return this.delivery.cost;
        }
        return Money.zero();
    }
};
PurchaseOrder = __decorate([
    perRestaurant(),
    __param(2, inject(FulfillmentInstructionsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof Restaurant !== "undefined" && Restaurant) === "function" ? _a : Object, typeof (_b = typeof Delivery !== "undefined" && Delivery) === "function" ? _b : Object, typeof (_c = typeof FulfillmentInstructions !== "undefined" && FulfillmentInstructions) === "function" ? _c : Object])
], PurchaseOrder);
export { PurchaseOrder };
