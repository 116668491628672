var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { perRestaurant } from "../di";
import { FulfillmentInstructions, FulfillmentInstructionsToken, } from "./FulfillmentInstructions";
import { InternalParameterSet, ParameterSet } from "./Menu";
let PurchaseOrderScope = class PurchaseOrderScope {
    constructor(fulfillmentInstructions) {
        this.fulfillmentInstructions = fulfillmentInstructions;
        makeAutoObservable(this);
    }
    get parameterSet() {
        return new ParameterSet({
            internal: new InternalParameterSet({
                fulfillmentMethod: this.fulfillmentInstructions.fulfillmentMethod,
                splitToHalves: null,
            }),
        });
    }
};
PurchaseOrderScope = __decorate([
    perRestaurant(),
    __param(0, inject(FulfillmentInstructionsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof FulfillmentInstructions !== "undefined" && FulfillmentInstructions) === "function" ? _a : Object])
], PurchaseOrderScope);
export { PurchaseOrderScope };
