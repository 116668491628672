var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
import { inject } from "tsyringe";
import { IdMap, RArray } from "../collections";
import { Timezone, makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { Menu, PPromotionType, PromotionParser, } from "../ordering";
import { TranslatedParser } from "./TranslatedParser";
let PPromotionParser = class PPromotionParser {
    constructor(domain, translatedParser, timezone, menu) {
        this.domain = domain;
        this.translatedParser = translatedParser;
        this.timezone = timezone;
        this.productTypeIds = menu.productTypes.ids;
        this.productCategoryIds = menu.productCategories.ids;
        this.customParameterTypes = menu.customParameterTypes;
        makeImmutable(this);
    }
    parsePromotion(promotion) {
        const domain = this.domain.parse({
            productTypeIds: this.productTypeIds,
            productCategoryIds: this.productCategoryIds,
            customParameterTypes: this.customParameterTypes,
            timezone: this.timezone,
            promotion,
        });
        if (domain === null) {
            return null;
        }
        return new PPromotionType({
            domain,
            name: this.translatedParser.parse(promotion.name),
            description: this.translatedParser.parse(promotion.description),
            isCoupon: promotion.promotionType === "RequiresCoupon",
        });
    }
    parsePromotions(promotions) {
        return IdMap.fromIterable(new RArray(promotions).mapOptional((promotion) => this.parsePromotion(promotion)));
    }
};
PPromotionParser = __decorate([
    perRestaurant(),
    __param(2, inject(Timezone)),
    __metadata("design:paramtypes", [typeof (_a = typeof PromotionParser !== "undefined" && PromotionParser) === "function" ? _a : Object, typeof (_b = typeof TranslatedParser !== "undefined" && TranslatedParser) === "function" ? _b : Object, typeof (_c = typeof Timezone !== "undefined" && Timezone) === "function" ? _c : Object, typeof (_d = typeof Menu !== "undefined" && Menu) === "function" ? _d : Object])
], PPromotionParser);
export { PPromotionParser };
