import { makeImmutable } from "./makeImmutable";
/**
 * Percentage
 */
export class Percentage {
    constructor(value) {
        this.value = value;
        makeImmutable(this);
    }
    static fromNumber(value) {
        if (value <= 0) {
            throw new Error("Percentage has to be positive value");
        }
        return new Percentage(value);
    }
    of(value) {
        return (value * this.value) / 100;
    }
    toString() {
        return `${this.value}%`;
    }
}
