var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
import { inject } from "tsyringe";
import { makeImmutable } from "../../../core";
import { perRestaurant } from "../../../di";
import { FulfillmentInstructionsAvailabilityService, Restaurant, } from "../../../ordering";
import { ApartmentInfoController } from "../ApartmentInfoController";
import { DeliveryAddressFormController } from "../DeliveryAddressFormController";
import { FulfillmentMethodController } from "../FulfillmentMethodController";
import { UserNoteController } from "../UserNoteController";
import { OnlineFulfillmentFormController } from "./OnlineFulfillmentFormController";
let OnlineFulfillmentFormControllerFactory = class OnlineFulfillmentFormControllerFactory {
    constructor(restaurant, apartmentInfoController, deliveryAddressController, fulfillmentInstructionsAvailabilityService, userNoteController) {
        this.restaurant = restaurant;
        this.apartmentInfoController = apartmentInfoController;
        this.deliveryAddressController = deliveryAddressController;
        this.fulfillmentInstructionsAvailabilityService = fulfillmentInstructionsAvailabilityService;
        this.userNoteController = userNoteController;
        makeImmutable(this);
    }
    create(fulfillmentInstructions) {
        const fulfillmentMethodController = new FulfillmentMethodController({
            fulfillmentInstructions,
            fulfillmentMethodTypes: this.restaurant.fulfillmentMethods,
            apartmentInfoController: this.apartmentInfoController,
            deliveryAddressController: this.deliveryAddressController,
            fulfillmentInstructionsAvailabilityService: this.fulfillmentInstructionsAvailabilityService,
        });
        return new OnlineFulfillmentFormController({
            fulfillmentMethodController,
            userNoteController: this.userNoteController,
            fulfillmentInstructions,
        });
    }
};
OnlineFulfillmentFormControllerFactory = __decorate([
    perRestaurant(),
    __param(1, inject(ApartmentInfoController)),
    __metadata("design:paramtypes", [typeof (_a = typeof Restaurant !== "undefined" && Restaurant) === "function" ? _a : Object, typeof (_b = typeof ApartmentInfoController !== "undefined" && ApartmentInfoController) === "function" ? _b : Object, typeof (_c = typeof DeliveryAddressFormController !== "undefined" && DeliveryAddressFormController) === "function" ? _c : Object, typeof (_d = typeof FulfillmentInstructionsAvailabilityService !== "undefined" && FulfillmentInstructionsAvailabilityService) === "function" ? _d : Object, typeof (_e = typeof UserNoteController !== "undefined" && UserNoteController) === "function" ? _e : Object])
], OnlineFulfillmentFormControllerFactory);
export { OnlineFulfillmentFormControllerFactory };
