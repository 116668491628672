import { makeImmutable } from "../../core";
/**
 * How will the split product be priced.
 *
 * Either based on the more expensive half, or avarage price of both halves.
 */
export class HalvesPricing {
    constructor(value) {
        this.value = value;
        makeImmutable(this);
    }
    /**
     * @returns combined price of the two given halves.
     */
    price(firstHalf, secondHalf) {
        switch (this.value) {
            case "Average":
                return firstHalf.add(secondHalf).half();
            case "MostExpensive":
                return firstHalf.max(secondHalf);
        }
    }
}
HalvesPricing.Average = new HalvesPricing("Average");
HalvesPricing.MostExpensive = new HalvesPricing("MostExpensive");
