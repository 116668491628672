import { makeAutoObservable } from "mobx";
import { toPairs } from "../../collections";
import { CheckboxFieldController, ErrorBox, SelectableFieldController, } from "../../form";
import { t, translate } from "../../I18n";
import { DEFAULT_FULFILLMENT_TYPE, FulfillmentInstructions, } from "../../ordering";
export class FulfillmentMethodController {
    constructor(params) {
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.fulfillmentMethodTypes = params.fulfillmentMethodTypes;
        this.fulfillmentInstructionsAvailabilityService =
            params.fulfillmentInstructionsAvailabilityService;
        this.apartmentInfoController = params.apartmentInfoController;
        this.deliveryAddressController = params.deliveryAddressController;
        this.noContactDelivery = new CheckboxFieldController({
            initialValue: params.fulfillmentInstructions.fulfillmentMethodType ===
                "NoContactDelivery",
            onUpdate: (value) => {
                params.fulfillmentInstructions.fulfillmentMethodType = value
                    ? "NoContactDelivery"
                    : "Delivery";
            },
        });
        this.fulfillmentMethod = new SelectableFieldController({
            initialValue: params.fulfillmentInstructions.fulfillmentMethodType ===
                "NoContactDelivery" &&
                params.fulfillmentMethodTypes.includes("Delivery")
                ? "Delivery"
                : params.fulfillmentInstructions.fulfillmentMethodType,
            onUpdate: (value) => {
                if (value === "Delivery" &&
                    params.fulfillmentInstructions.fulfillmentMethodType ===
                        "NoContactDelivery") {
                    return;
                }
                params.fulfillmentInstructions.fulfillmentMethodType = value;
                // NOTICE: update no contact delivery checbox state to reflect the current value
                this.noContactDelivery.changeWithoutUpdate(value === "NoContactDelivery");
            },
            onClear: () => {
                params.fulfillmentInstructions.fulfillmentMethodType =
                    FulfillmentInstructions.getAvailableFulfillmentMethod(DEFAULT_FULFILLMENT_TYPE, this.fulfillmentMethodTypes);
            },
            options: this.fulfillmentMethodTypes.raw,
            errorBox: new ErrorBox({
                domainObjects: [this.fulfillmentInstructionsAvailabilityService],
                errorMessages: {
                    FulfillmentMethodTypeMissing: translate(t._common.form.errors.required),
                },
            }),
        });
        makeAutoObservable(this);
    }
    get fields() {
        return {
            fulfillmentMethod: this.fulfillmentMethod,
            noContactDelivery: this.noContactDelivery,
            ...this.deliveryAddressController.fields,
            ...this.apartmentInfoController.fields,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const { fulfillmentMethodTypeAvailability, deliveryAvailability } = this.fulfillmentInstructionsAvailabilityService;
        return (fulfillmentMethodTypeAvailability.isAvailable.isDefinitelyFalse ||
            deliveryAvailability.isAvailable.isDefinitelyFalse);
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.fulfillmentInstructions;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
}
