var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
import { inject } from "tsyringe";
import { RArray } from "../collections";
import { Now, RDate } from "../core";
import { perRestaurant } from "../di";
import { Asap } from "./Asap";
import { OnlineOrderingSettingsToken, } from "./OnlineOrderingSettings";
import { IOrderingHours } from "./OrderingHours";
import { ProductInstancePreorderSettingsService } from "./PreorderSettingsService";
import { PurchaseOrderPayload } from "./PurchaseOrderPayload";
import { RDay } from "./RDay";
export const IPProductInstanceName = "IPProductInstanceName";
let OrderingHoursWidget = class OrderingHoursWidget {
    constructor(orderingHours, asap, now, onlineOrderingSettings, purchaseOrderPayload, productInstanceName, productInstancePreorderSettingsService) {
        this.orderingHours = orderingHours;
        this.asap = asap;
        this.now = now;
        this.purchaseOrderPayload = purchaseOrderPayload;
        this.productInstanceName = productInstanceName;
        this.productInstancePreorderSettingsService = productInstancePreorderSettingsService;
        this.enableDelayedDelivery = onlineOrderingSettings.enableDelayedDelivery;
        this.allowOrderingInClosedRestaurant =
            onlineOrderingSettings.allowOrderingInClosedRestaurant;
    }
    getDates(params) {
        let from = params.from;
        if (from.isBefore(RDay.fromRDate(this.now))) {
            from = RDay.fromRDate(this.now);
        }
        const days = [...RDay.range(from, params.to)];
        const dayItems = days.map((day) => {
            return this.createFulfillmentDateItem({
                day,
                schedule: params.schedule,
                preorderSettings: params.preorderSettings,
            });
        });
        return dayItems;
    }
    createFulfillmentDateItem(params) {
        var _a, _b;
        const times = this.isPlacingOrderPossibleForTimes()
            ? this.orderingHours.fulfillmentTimes({
                now: this.now,
                date: params.day.toRDate(),
            })
            : RArray.empty();
        let asap = [];
        if (this.asap.asapOptionAvailable(params.preorderSettings) &&
            params.day.toRDate().isToday) {
            const asapConflicts = this.findFulfillmentTimeConflicts(this.now);
            const isAsapScheduleAvailable = (_b = (_a = params.schedule) === null || _a === void 0 ? void 0 : _a.includes(this.now)) !== null && _b !== void 0 ? _b : true;
            if (!isAsapScheduleAvailable) {
                asap = [
                    {
                        type: "Disabled",
                        value: { type: "ASAP" },
                    },
                ];
            }
            else if (asapConflicts.length > 0) {
                asap = [
                    {
                        type: "Conflicting",
                        value: { type: "ASAP" },
                        conflictingProducts: asapConflicts.map((orderLine) => this.productInstanceName.productInstanceName(orderLine.productInstance)),
                    },
                ];
            }
            else {
                asap = [
                    {
                        type: "Available",
                        value: { type: "ASAP" },
                    },
                ];
            }
        }
        const todayEndOfDay = this.orderingHours.endOfDay(this.now);
        const hourItems = asap.concat(times.raw
            .map((time) => {
            return this.createFulfillmentDateTimeItem({
                time,
                todayEndOfDay,
                preorderSettings: params.preorderSettings,
                schedule: params.schedule,
            });
        })
            .filter((item) => item !== null));
        const items = hourItems.filter((item) => item.type !== "Disabled");
        if (items.length === 0) {
            return {
                type: "Disabled",
                date: params.day,
            };
        }
        else if (items.every((item) => item.type === "Conflicting")) {
            return {
                type: "Conflicting",
                date: params.day,
                items: hourItems,
            };
        }
        else {
            return {
                type: "Available",
                date: params.day,
                items: hourItems,
            };
        }
    }
    createFulfillmentDateTimeItem(params) {
        var _a, _b;
        const conflicts = this.findFulfillmentTimeConflicts(params.time);
        const preorderAvailable = params.preorderSettings.includes(params.time);
        const isScheduleAvailable = (_b = (_a = params.schedule) === null || _a === void 0 ? void 0 : _a.includes(params.time)) !== null && _b !== void 0 ? _b : true;
        const shouldNotShowHoursToday = !this.enableDelayedDelivery &&
            (params.todayEndOfDay !== null
                ? params.time.isBefore(params.todayEndOfDay)
                : params.time.isToday);
        if (shouldNotShowHoursToday) {
            return null;
        }
        if (preorderAvailable) {
            if (!isScheduleAvailable) {
                return {
                    type: "Disabled",
                    value: { type: "OnTime", date: params.time },
                };
            }
            else if (conflicts.length > 0) {
                return {
                    type: "Conflicting",
                    value: { type: "OnTime", date: params.time },
                    conflictingProducts: conflicts.map((orderLine) => this.productInstanceName.productInstanceName(orderLine.productInstance)),
                };
            }
            else {
                return {
                    type: "Available",
                    value: { type: "OnTime", date: params.time },
                };
            }
        }
        else {
            return null;
        }
    }
    isAvailableAt(props) {
        var _a, _b;
        const isPreorderOk = props.preorderSettings.includes(props.date);
        const isScheduleOk = (_b = (_a = props.schedule) === null || _a === void 0 ? void 0 : _a.includes(props.date)) !== null && _b !== void 0 ? _b : true;
        return isPreorderOk && isScheduleOk;
    }
    removeConflictsFromCart(selectedDate) {
        const conflicts = this.findFulfillmentTimeConflicts(selectedDate);
        conflicts.forEach((orderLine) => {
            orderLine.remove();
        });
    }
    findFulfillmentTimeConflicts(date) {
        return this.purchaseOrderPayload.orderLines.raw.filter((orderLine) => !this.isAvailableAt({
            preorderSettings: this.productInstancePreorderSettingsService.preorderSettings(orderLine.productInstance),
            schedule: orderLine.productInstance.productWeeklySchedule,
            date,
        }));
    }
    isOnlyAsap(params) {
        return (this.asap.asapOptionAvailable(params.preorderSettings) &&
            !this.isAnyFutureDate(params));
    }
    *dateItemsIterator(params) {
        var _a, _b;
        const { minDay, maxDay } = params.preorderSettings;
        console.log("maxDay", (_a = minDay === null || minDay === void 0 ? void 0 : minDay.toString()) !== null && _a !== void 0 ? _a : "null", (_b = maxDay === null || maxDay === void 0 ? void 0 : maxDay.toString()) !== null && _b !== void 0 ? _b : "null");
        if (minDay === null || maxDay === null) {
            return false;
        }
        const nowDay = RDay.fromRDate(this.now);
        const min = nowDay.isAfter(minDay) ? nowDay : minDay;
        const performanceLimit = minDay.addDays(70);
        const max = maxDay.isAfter(performanceLimit) ? performanceLimit : maxDay;
        if (min.isAfter(max))
            return false;
        for (const day of RDay.range(min, max)) {
            const dateItems = this.getDates({
                preorderSettings: params.preorderSettings,
                schedule: params.schedule,
                from: day,
                to: day,
            });
            for (const dateItem of dateItems) {
                yield dateItem;
            }
        }
    }
    hasAnyAvailableTime(params) {
        for (const dateItem of this.dateItemsIterator(params)) {
            if (dateItem.type === "Available" || dateItem.type === "Conflicting")
                return true;
        }
        return false;
    }
    isAnyFutureDate(params) {
        for (const dateItem of this.dateItemsIterator(params)) {
            if (dateItem.type === "Available" || dateItem.type === "Conflicting") {
                if (dateItem.items.filter((item) => (item.type === "Available" || item.type === "Conflicting") &&
                    item.value.type === "OnTime").length > 0) {
                    return true;
                }
            }
        }
        return false;
    }
    isPlacingOrderPossibleForTimes() {
        const event = this.orderingHours.nextEventAtDate(this.now);
        switch (event.type) {
            case "OrderingBegins":
                return this.allowOrderingInClosedRestaurant;
            case "OrderingEnds":
                return true;
        }
    }
};
OrderingHoursWidget = __decorate([
    perRestaurant(),
    __param(0, inject(IOrderingHours)),
    __param(2, inject(Now)),
    __param(3, inject(OnlineOrderingSettingsToken)),
    __param(5, inject(IPProductInstanceName)),
    __metadata("design:paramtypes", [typeof (_a = typeof IOrderingHours !== "undefined" && IOrderingHours) === "function" ? _a : Object, typeof (_b = typeof Asap !== "undefined" && Asap) === "function" ? _b : Object, typeof (_c = typeof RDate !== "undefined" && RDate) === "function" ? _c : Object, Object, typeof (_d = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _d : Object, Object, typeof (_e = typeof ProductInstancePreorderSettingsService !== "undefined" && ProductInstancePreorderSettingsService) === "function" ? _e : Object])
], OrderingHoursWidget);
export { OrderingHoursWidget };
