import { makeImmutable, randomUUID } from "../../core";
import { ProductInstanceTrackingId, } from "../Identifiers";
import { ProductConfig } from "./ProductConfig";
import { ProductInstanceHalves } from "./ProductInstanceHalves";
import { ProductInstancePart } from "./ProductInstancePart";
import { ProductInstanceParts, } from "./ProductInstanceParts";
import { SingleProductInstancePart } from "./SingleProductInstancePart";
/**
 * ProductInstance is collection of common config and config for parts
 * Common pittfall: Each product instance part can be of different product type, so we cannot say that ProductInstance is an instance of a ProductType
 * Can be split into parts.
 * Example: Large Margherita on thick dough with onion rings
 */
export class ProductInstance {
    constructor(params) {
        this.trackingId = params.trackingId;
        this.commonConfig = params.commonConfig;
        this.parts = params.parts;
        makeImmutable(this);
    }
    static fromSingleProductType(productType) {
        return new ProductInstance({
            trackingId: new ProductInstanceTrackingId(randomUUID()),
            commonConfig: ProductConfig.empty(),
            parts: new SingleProductInstancePart(ProductInstancePart.empty(productType)),
        });
    }
    static fromHalvesProductTypes(params) {
        const parts = ProductInstanceHalves.empty({
            productCategory: params.productCategory,
            first: ProductInstancePart.empty(params.firstProductType),
            second: ProductInstancePart.empty(params.secondProductType),
        });
        if (parts === null) {
            return null;
        }
        return new ProductInstance({
            trackingId: new ProductInstanceTrackingId(randomUUID()),
            commonConfig: ProductConfig.empty(),
            parts,
        });
    }
    eq(other) {
        return (this.commonConfig.eq(other.commonConfig) &&
            ProductInstanceParts.eq(this.parts, other.parts));
    }
    /*
     * NOTICE This equivalence is not symmetrical
     * burger with size = large is cart equivalent to a burger with size = large and doneness = rare
     * but not vice versa
     */
    isCartEquivalentTo(other) {
        return (this.commonConfig.isCartEquivalentTo(other.commonConfig) &&
            ProductInstanceParts.isCartEquivalentTo(this.parts, other.parts));
    }
    get productCategoryId() {
        return this.parts.productCategoryId;
    }
    get productTypeIds() {
        return this.parts.productTypeIds;
    }
    get productWeeklySchedule() {
        return this.parts.productWeeklySchedule;
    }
    get customParameterChoiceIds() {
        const choiceIds = this.parts.customParameterChoiceIds;
        const common = this.commonConfig.customParameters.choiceIds;
        return choiceIds.union(common);
    }
    matchesCustomParameterValue(customParameterTypeId, customParameterChoiceId) {
        return (this.commonConfig.matchesCustomParameterValue(customParameterTypeId, customParameterChoiceId) ||
            this.parts.parts.some((part) => part.productConfig.matchesCustomParameterValue(customParameterTypeId, customParameterChoiceId)));
    }
    get storageData() {
        return {
            trackingId: this.trackingId,
            commonConfig: this.commonConfig.storageData,
            parts: this.parts.storageData,
        };
    }
}
