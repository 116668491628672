import { makeAutoObservable } from "mobx";
import { RArray, RSet } from "../collections";
import { Quantity } from "../core";
/*
 * Collection of OrderLines added by user
 * It's price is a nominal order price (without delivery and promotions)
 * It's availability depends on availability of all ordered product instances
 */
export class PurchaseOrderPayload {
    constructor(params) {
        this.menu = params.menu;
        this._orderLines = params.orderLines;
        makeAutoObservable(this);
    }
    get orderLines() {
        return new RArray(this._orderLines).filtered((orderLine) => !orderLine.isEmpty);
    }
    get numberOfItems() {
        return this.orderLines.reduce((acc, { quantity }) => acc.add(quantity), Quantity.Zero);
    }
    get isEmpty() {
        return this.numberOfItems.isZero;
    }
    addOrderLine(orderLine) {
        if (orderLine.isEmpty) {
            throw new Error(`Unable to add an empty OrderLine to PurchaseOrder`);
        }
        if (!this.menu.includes(orderLine.productTypeIds)) {
            throw new Error(`Unable to add an OrderLine, because some of the product types are offered by restaurant`);
        }
        const existingOrderLineIndex = this._orderLines.findIndex((existingOrderLine) => existingOrderLine.productInstance.eq(orderLine.productInstance));
        if (existingOrderLineIndex === -1) {
            this._orderLines.push(orderLine);
            return "added_new_item";
        }
        const existingOrderLine = this._orderLines[existingOrderLineIndex];
        if (existingOrderLine.isEmpty) {
            this._orderLines[existingOrderLineIndex] = orderLine;
            return "added_new_item";
        }
        existingOrderLine.addQuantity(orderLine.quantity);
        return "increased_existing_item";
    }
    cartCount(productInstance) {
        return this._orderLines
            .filter((orderLine) => productInstance.isCartEquivalentTo(orderLine.productInstance))
            .map((orderLine) => orderLine.quantity)
            .reduce((prev, curr) => prev.add(curr), Quantity.Zero);
    }
    clear() {
        this._orderLines = [];
    }
    get productTypeIds() {
        return this.orderLines
            .map((orderLine) => orderLine.productTypeIds)
            .reduce((acc, ids) => acc.union(ids), RSet.empty());
    }
    get storageData() {
        return this.orderLines.map((orderLine) => orderLine.storageData).raw;
    }
}
