import { makeImmutable } from "../core";
export class MenuSection {
    constructor(params) {
        this.id = params.id;
        this.variants = params.variants;
        this.columns = params.columns;
        this.creators = params.creators;
        makeImmutable(this);
    }
}
