import { RArray } from "../../collections";
import { makeImmutable } from "../../core";
export class PromotionSolverVariableAssignment {
    constructor(promotionAssociations) {
        this.promotionAssociations = promotionAssociations;
        makeImmutable(this);
    }
    eq(other) {
        return RArray.eq(this.promotionAssociations, other.promotionAssociations);
    }
    get variableCount() {
        return this.promotionAssociations.reduce((acc, promotionAssociation) => acc + promotionAssociation.variableCount, 0);
    }
    get byPromotion() {
        let variableAccumulator = 0;
        return this.promotionAssociations.map((promotionAssociation) => {
            const variables = RArray.range(promotionAssociation.variableCount).map((v) => variableAccumulator + v);
            variableAccumulator += variables.size;
            return [promotionAssociation.promotionType, variables];
        });
    }
    get byVariable() {
        return this.promotionAssociations.flatMap((promotionAssociation) => promotionAssociation.variables);
    }
}
