export class RelativeDatePreorderSettingsType {
    constructor(params) {
        this.type = "RelativeDate";
        this.buffer = params.buffer;
        this.maxDays = params.maxDays;
    }
    max(other) {
        return new RelativeDatePreorderSettingsType({
            buffer: this.buffer.min(other.buffer),
            maxDays: Math.max(this.maxDays, other.maxDays),
        });
    }
    toString() {
        return `${this.type}[minDays=${this.buffer.minDays},maxDays=${this.maxDays}]`;
    }
}
