import { makeAutoObservable } from "mobx";
import { IdMap } from "../../collections";
import { ParameterScope } from "../ParameterScope";
export class WholeOrderPromotionInstance {
    constructor(params) {
        this.kind = "WholeOrder";
        this.id = params.id;
        this.promotionType = params.promotionType;
        this._active = params.active;
        this._freebie = params.freebie;
        this._scope = ParameterScope.Uninitialized;
        this.excludedInstances = IdMap.empty();
        makeAutoObservable(this);
    }
    static fromStorageData(params) {
        const { promotionType, id, storageData } = params;
        if (promotionType.scope.kind !== "WholeOrder") {
            return null;
        }
        return new WholeOrderPromotionInstance({
            id,
            promotionType,
            active: storageData.active,
            freebie: params.freebie,
        });
    }
    get scope() {
        return this._scope;
    }
    setScope(scope) {
        this._scope = scope;
    }
    setExcludedInstances(excluded) {
        this.excludedInstances = excluded;
    }
    get active() {
        return this._active;
    }
    setActive(active) {
        this._active = active;
        if (active) {
            this.excludedInstances.objects.forEach((excludedInstance) => excludedInstance.setActive(false));
        }
    }
    get freebie() {
        return this._freebie;
    }
    setFreebie(freebie) {
        this._freebie = freebie;
    }
    get storageData() {
        return {
            kind: "WholeOrderPromotionInstance",
            promotionTypeId: this.promotionType.id.value,
            active: this.active,
            freebie: this.freebie === null ? null : this.freebie.storageData,
        };
    }
}
