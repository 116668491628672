var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { makeAutoObservable } from "mobx";
import { perRestaurant } from "../../di";
import { Availability } from "../Availability";
import { PurchaseOrderPayload } from "../PurchaseOrderPayload";
import { OrderLineAvailabilityService } from "./OrderLineAvailabilityService";
let PurchaseOrderPayloadAvailabilityService = class PurchaseOrderPayloadAvailabilityService {
    constructor(payload, orderLineAvailabilityService) {
        this.payload = payload;
        this.orderLineAvailabilityService = orderLineAvailabilityService;
        makeAutoObservable(this);
    }
    get availability() {
        if (this.payload.isEmpty) {
            return Availability.unavailable("EmptyOrder");
        }
        const orderLinesAvailabilities = this.payload.orderLines.map((orderLine) => this.orderLineAvailabilityService.availability(orderLine)).raw;
        return Availability.dependent("OrderLine", "", Availability.composite(orderLinesAvailabilities));
    }
};
PurchaseOrderPayloadAvailabilityService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _a : Object, typeof (_b = typeof OrderLineAvailabilityService !== "undefined" && OrderLineAvailabilityService) === "function" ? _b : Object])
], PurchaseOrderPayloadAvailabilityService);
export { PurchaseOrderPayloadAvailabilityService };
