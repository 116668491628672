var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { inject } from "tsyringe";
import { MenuConsumer } from "../config";
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { automaticCrossSellingCategoryFilter, CrossSellingListProvider, Menu, OnlineOrderingSettingsToken, } from "../ordering";
import { AutomaticCrossSellingWeightsParser } from "./AutomaticCrossSellingWeightsParser";
import { CrossSellingListParser } from "./CrossSellingListParser";
let CrossSellingListProviderParser = class CrossSellingListProviderParser {
    constructor(menuConsumer, menu, onlineOrderingSettings, crossSellingListParser) {
        this.menuConsumer = menuConsumer;
        this.menu = menu;
        this.onlineOrderingSettings = onlineOrderingSettings;
        this.crossSellingListParser = crossSellingListParser;
        makeImmutable(this);
    }
    parse(params) {
        const automaticCrossSellingWeightsParser = new AutomaticCrossSellingWeightsParser({
            weights: params.automaticCrossSellingWeights,
        });
        const allowedAutomaticCrossSellingProductCategories = this.menu.productCategories.ids.filter((productCategoryId) => automaticCrossSellingCategoryFilter(params.catalogue.categories[productCategoryId.value].name));
        return CrossSellingListProvider.create({
            crossSellingConsumer: this.menuConsumer,
            crossSellingListRepository: this.crossSellingListParser.parse(params.catalogue),
            menuProductTypes: this.menu.productTypes,
            allowedAutomaticProductCategories: allowedAutomaticCrossSellingProductCategories,
            automaticWeights: automaticCrossSellingWeightsParser.parse(),
            onlineOrderingSettings: this.onlineOrderingSettings,
        });
    }
};
CrossSellingListProviderParser = __decorate([
    perRestaurant(),
    __param(0, inject(MenuConsumer)),
    __param(2, inject(OnlineOrderingSettingsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof MenuConsumer !== "undefined" && MenuConsumer) === "function" ? _a : Object, typeof (_b = typeof Menu !== "undefined" && Menu) === "function" ? _b : Object, Object, typeof (_c = typeof CrossSellingListParser !== "undefined" && CrossSellingListParser) === "function" ? _c : Object])
], CrossSellingListProviderParser);
export { CrossSellingListProviderParser };
