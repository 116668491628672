var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { Availability } from "../Availability";
import { OrderLinePriceService, PurchaseOrderPayloadPriceService, } from "../PriceService";
import { PurchaseOrderPayload } from "../PurchaseOrderPayload";
import { OrderLineAvailabilityService } from "./OrderLineAvailabilityService";
import { PromotionTypeAvailabilityService } from "./PromotionTypeAvailabilityService";
import { PurchaseOrderPayloadAvailabilityService } from "./PurchaseOrderPayloadAvailabilityService";
let PromotionInstanceAvailabilityService = class PromotionInstanceAvailabilityService {
    constructor(purchaseOrderPayload, orderLineAvailabilityService, purchaseOrderPayloadAvailabilityService, promotionTypeAvailabilityService, orderLinePriceService, purchaseOrderPayloadPriceService) {
        this.purchaseOrderPayload = purchaseOrderPayload;
        this.orderLineAvailabilityService = orderLineAvailabilityService;
        this.purchaseOrderPayloadAvailabilityService = purchaseOrderPayloadAvailabilityService;
        this.promotionTypeAvailabilityService = promotionTypeAvailabilityService;
        this.orderLinePriceService = orderLinePriceService;
        this.purchaseOrderPayloadPriceService = purchaseOrderPayloadPriceService;
        makeImmutable(this);
    }
    orderLineAvailability(orderLine) {
        return orderLine === null
            ? null
            : this.orderLineAvailabilityService.availability(orderLine);
    }
    availability(promotionInstance) {
        switch (promotionInstance.kind) {
            case "Product":
                return this.productPromotionInstanceAvailability(promotionInstance);
            case "WholeOrder":
                return this.wholeOrderPromotionInstanceAvailability(promotionInstance);
        }
    }
    wholeOrderPromotionInstanceAvailability(promotionInstance) {
        const selector = promotionInstance.promotionType.scope
            .selector;
        return Availability.composite([
            this.promotionTypeAvailabilityService.availability(promotionInstance.promotionType, promotionInstance.scope),
            Availability.dependent("PurchaseOrderPayload", `${promotionInstance.id.toString()}`, this.purchaseOrderPayloadAvailabilityService.availability),
            Availability.boolean({
                UnmetSelector: !selector.test(this.purchaseOrderPayloadPriceService),
            }),
        ]);
    }
    productPromotionInstanceAvailability(promotionInstance) {
        var _a, _b;
        const { orderLines } = this.purchaseOrderPayload;
        const sourceOrderLines = promotionInstance.discountSource.raw.map((orderLineReference) => orderLines.find(orderLineReference.orderLineIndex));
        const targetOrderLine = orderLines.find(promotionInstance.discountTarget.orderLineIndex);
        const productInstances = [
            ...sourceOrderLines.map((orderLine) => { var _a; return (_a = orderLine === null || orderLine === void 0 ? void 0 : orderLine.productInstance) !== null && _a !== void 0 ? _a : null; }),
            (_a = targetOrderLine === null || targetOrderLine === void 0 ? void 0 : targetOrderLine.productInstance) !== null && _a !== void 0 ? _a : null,
        ];
        const selector = promotionInstance.promotionType.scope
            .selector;
        return Availability.composite([
            this.promotionTypeAvailabilityService.availability(promotionInstance.promotionType, promotionInstance.scope),
            Availability.dependent("DiscountSource", `PromotionInstance${promotionInstance.id.toString()}`, Availability.composite(sourceOrderLines.map((orderLine) => {
                var _a;
                return (_a = this.orderLineAvailability(orderLine)) !== null && _a !== void 0 ? _a : Availability.unavailable("InvalidSourceReference");
            }))),
            Availability.dependent("DiscountTarget", `PromotionInstance${promotionInstance.id.toString()}`, (_b = this.orderLineAvailability(targetOrderLine)) !== null && _b !== void 0 ? _b : Availability.unavailable("InvalidTargetReference")),
            Availability.boolean({
                // NOTICE Assignment is correct only when discountTarget is cheaper than all of discountSource elements
                InvalidAssignment: sourceOrderLines.some((sourceOrderLine) => sourceOrderLine === null ||
                    targetOrderLine === null ||
                    this.orderLinePriceService
                        .unitPrice(sourceOrderLine)
                        .compare(this.orderLinePriceService.unitPrice(targetOrderLine)) <
                        0),
                UnmetSelector: productInstances.some((productInstance) => productInstance === null || !selector.test(productInstance)),
            }),
        ]);
    }
};
PromotionInstanceAvailabilityService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _a : Object, typeof (_b = typeof OrderLineAvailabilityService !== "undefined" && OrderLineAvailabilityService) === "function" ? _b : Object, typeof (_c = typeof PurchaseOrderPayloadAvailabilityService !== "undefined" && PurchaseOrderPayloadAvailabilityService) === "function" ? _c : Object, typeof (_d = typeof PromotionTypeAvailabilityService !== "undefined" && PromotionTypeAvailabilityService) === "function" ? _d : Object, typeof (_e = typeof OrderLinePriceService !== "undefined" && OrderLinePriceService) === "function" ? _e : Object, typeof (_f = typeof PurchaseOrderPayloadPriceService !== "undefined" && PurchaseOrderPayloadPriceService) === "function" ? _f : Object])
], PromotionInstanceAvailabilityService);
export { PromotionInstanceAvailabilityService };
