import { translate, t } from "../I18n";
export const NotAvailableFulfillmentMethod = {
    createLabel() {
        return (labelParams) => {
            if (labelParams.fulfillmentTime === null) {
                return null;
            }
            if (labelParams.view === "Cart") {
                return null;
            }
            const reasons = labelParams.reasons;
            if (reasons.includes("Delivery")) {
                return {
                    message: translate(t.client.labels.not_available_fulfillment_method.delivery),
                    enabledOnClick: false,
                    type: "error",
                };
            }
            else if (reasons.includes("Takeaway")) {
                return {
                    message: translate(t.client.labels.not_available_fulfillment_method.takeway),
                    enabledOnClick: false,
                    type: "error",
                };
            }
            else if (reasons.includes("DineIn")) {
                return {
                    message: translate(t.client.labels.not_available_fulfillment_method.dinein),
                    enabledOnClick: false,
                    type: "error",
                };
            }
            return null;
        };
    },
};
