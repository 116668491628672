var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { ProductConfigCreatorFactory } from "./ProductConfigCreatorFactory";
import { ProductInstanceCreatorPart } from "./ProductInstanceCreatorPart";
let ProductInstanceCreatorPartFactory = class ProductInstanceCreatorPartFactory {
    constructor(productConfigCreatorFactory) {
        this.productConfigCreatorFactory = productConfigCreatorFactory;
        makeImmutable(this);
    }
    create(part) {
        const { customParameterInstances: initialCustomParameterInstances, modifierInstances: initialModifierInstances, } = part.productConfig;
        const productConfigCreator = this.productConfigCreatorFactory.create({
            modifierTypes: part.productType.divisibleModifierTypes,
            customParameterTypes: part.productType.divisibleCustomParameterTypes,
            initialCustomParameterInstances,
            initialModifierInstances,
            modifierSettings: (modifier) => part.productType.modifierSettings(modifier),
        });
        return new ProductInstanceCreatorPart({
            productType: part.productType,
            productConfigCreator,
        });
    }
};
ProductInstanceCreatorPartFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ProductConfigCreatorFactory !== "undefined" && ProductConfigCreatorFactory) === "function" ? _a : Object])
], ProductInstanceCreatorPartFactory);
export { ProductInstanceCreatorPartFactory };
