export function getMobileAppType() {
    if (!Skubacz.Device.isMobileApp()) {
        return null;
    }
    const appType = Skubacz.Device.getMobileAppType();
    switch (appType) {
        case "IosWebPage":
            return null;
        case "IosMobileApp":
            return "IosApp";
        default:
            return "AndroidApp";
    }
}
