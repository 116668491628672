var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { makeImmutable, randomUUID } from "../../core";
import { perRestaurant } from "../../di";
import { ProductInstanceTrackingId } from "../Identifiers";
import { ProductConfigFactory } from "./ProductConfigFactory";
import { ProductInstance } from "./ProductInstance";
import { ProductInstancePartsFactory } from "./ProductInstancePartsFactory";
let ProductInstanceFactory = class ProductInstanceFactory {
    constructor(productConfigFactory, productInstancePartsFactory) {
        this.productConfigFactory = productConfigFactory;
        this.productInstancePartsFactory = productInstancePartsFactory;
        makeImmutable(this);
    }
    fromStorageData(storageData) {
        var _a;
        const commonConfig = this.productConfigFactory.fromStorageData({
            modifierSettingsProvider: (modifierType) => modifierType.defaultSettings,
            storageData: storageData.commonConfig,
        });
        if (commonConfig === null) {
            return null;
        }
        const parts = this.productInstancePartsFactory.fromStorageData(storageData.parts);
        if (parts === null) {
            return null;
        }
        const trackingId = (_a = storageData.trackingId) !== null && _a !== void 0 ? _a : new ProductInstanceTrackingId(randomUUID());
        return new ProductInstance({
            trackingId,
            commonConfig,
            parts,
        });
    }
};
ProductInstanceFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ProductConfigFactory !== "undefined" && ProductConfigFactory) === "function" ? _a : Object, typeof (_b = typeof ProductInstancePartsFactory !== "undefined" && ProductInstancePartsFactory) === "function" ? _b : Object])
], ProductInstanceFactory);
export { ProductInstanceFactory };
