var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { IdentifiableMap, RMap, toPairs } from "../../collections";
import { makeImmutable, Quantity } from "../../core";
import { perRestaurant } from "../../di";
import { ModifierItemTypeId } from "../Identifiers";
import { Menu, } from "../Menu";
import { ParameterScope } from "../ParameterScope";
import { ModifierInstance, } from "./ModifierInstance";
import { ModifierItemInstance } from "./ModifierItemInstance";
let ModifierInstanceFactory = class ModifierInstanceFactory {
    constructor(menu) {
        this.menu = menu;
        makeImmutable(this);
    }
    create(params) {
        const { modifierType, modifierSettings, itemQuantities } = params;
        const items = IdentifiableMap.fromIterable("typeId", itemQuantities.entries.mapOptional(([modifierItemTypeId, quantity]) => {
            const modifierItemType = modifierType.itemTypes.find(modifierItemTypeId);
            return modifierItemType === null
                ? null
                : new ModifierItemInstance({
                    scope: ParameterScope.Uninitialized,
                    modifierType,
                    modifierSettings,
                    modifierItemType,
                    quantity,
                });
        }));
        return new ModifierInstance({
            scope: ParameterScope.Uninitialized,
            modifierType,
            modifierSettings,
            items,
        });
    }
    fromStorageData(params) {
        const modifierType = this.menu.modifierTypes.rawFind(params.modifierTypeId);
        if (modifierType === null) {
            return null;
        }
        return this.create({
            modifierType,
            modifierSettings: params.modifierSettingsProvider(modifierType),
            itemQuantities: RMap.fromIterable(toPairs(params.storageData.items).map(([storedModifierItemTypeId, itemStorageData]) => [
                new ModifierItemTypeId(storedModifierItemTypeId),
                Quantity.fromNumber(itemStorageData.quantity),
            ])),
        });
    }
    createWithDefaultItems({ modifierType, modifierSettings, }) {
        return this.create({
            modifierType,
            modifierSettings,
            itemQuantities: modifierSettings.defaultItems,
        });
    }
};
ModifierInstanceFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof Menu !== "undefined" && Menu) === "function" ? _a : Object])
], ModifierInstanceFactory);
export { ModifierInstanceFactory };
