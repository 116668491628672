import { ApplePayToken, makeImmutable, randomUUID, } from "../core";
import { Sentry } from "../Sentry";
export class ApplePayService {
    constructor(params) {
        this.currency = params.currency;
        makeImmutable(this);
    }
    static create(params) {
        if (params.isApplePaySupported && params.mobileAppType === "IosApp") {
            return new ApplePayService({
                currency: params.currency,
            });
        }
        return null;
    }
    async getToken(price) {
        var _a;
        try {
            const res = await fetch(`/applePay/authorization?price=${price.cents}&currency=${this.currency}&id=${randomUUID()}`);
            const json = (await res.json());
            const token = (_a = json === null || json === void 0 ? void 0 : json.token) !== null && _a !== void 0 ? _a : null;
            if (token === null) {
                return null;
            }
            return new ApplePayToken(token);
        }
        catch (err) {
            console.log("Apple pay authorization failed: ", err);
            Sentry.logError(err);
            return null;
        }
    }
    async registerResponseToken(responseToken) {
        try {
            await fetch(`/applePay/register?p24token=${responseToken}`);
        }
        catch (err) {
            console.log("Apple pay P24 registration failed: ", err);
            Sentry.logError(err);
        }
    }
}
