var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject } from "tsyringe";
import { Now, RDate, makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { IOrderingHours } from "./OrderingHours";
let NextOrderingTime = class NextOrderingTime {
    constructor(now, orderingHours) {
        this.now = now;
        this.orderingHours = orderingHours;
        makeImmutable(this);
    }
    get nextOrderAvailableAt() {
        const date = this.findNextOrderingHour();
        if (date === null) {
            return null;
        }
        else if (date.isToday) {
            return {
                type: "today",
                hour: date.formatHHmm(),
            };
        }
        else if (date.isTomorrow) {
            return {
                type: "tomorrow",
                hour: date.formatHHmm(),
            };
        }
        else {
            return {
                type: "later",
                date: date.formatDDMM(),
            };
        }
    }
    findNextOrderingHour() {
        for (let day = 0; day < 70; day++) {
            const date = day == 0 ? this.now : this.now.add(day, "days").startOfDay();
            const nextEvent = this.orderingHours.nextEventAtDate(date);
            if (nextEvent.type === "OrderingEnds") {
                return null;
            }
            else {
                if (nextEvent.at !== null) {
                    return nextEvent.at;
                }
            }
        }
        return null;
    }
};
NextOrderingTime = __decorate([
    perRestaurant(),
    __param(0, inject(Now)),
    __param(1, inject(IOrderingHours)),
    __metadata("design:paramtypes", [typeof (_a = typeof RDate !== "undefined" && RDate) === "function" ? _a : Object, typeof (_b = typeof IOrderingHours !== "undefined" && IOrderingHours) === "function" ? _b : Object])
], NextOrderingTime);
export { NextOrderingTime };
