const name = "MenuStickyTabs";
const variants = ["OldTabs", "NewStickyTabs"];
export const siteIds = [
    190917, 207944, 38085, 22390, 11088, 253352, 5240, 204722, 106370, 166826,
    25502, 29248, 30478, 91949, 39802, 312786, 25993, 317870, 265299, 30192,
    30161, 286187, 126665, 30576, 1066, 28161, 308528, 139667, 204692, 11322,
    246191,
];
export const menuStickyTabs = {
    name,
    variants: variants.map((variant) => [variant, 1]),
    controlVariant: variants[0],
    apply: (assignVariant, clearVariant, getVariant) => {
        const menuElement = document.querySelector(".restaurant-menu");
        const menuGroupElement = document.querySelector(".js-group");
        const menuGroupNavTopElement = document.querySelector(".m-flex-layout--column");
        // FIXME: this is a hack to allow to simply test field-test
        const isTesting = localStorage.getItem("fieldTestExperimentsTest");
        const validSiteId = siteIds.includes(Skubacz.configuration.site_id);
        const validSource = !Skubacz.Device.isMobileApp();
        const validDOM = menuElement !== null &&
            menuGroupElement !== null &&
            menuGroupNavTopElement !== null;
        // e2e & testing purposes
        if (isTesting && validDOM) {
            const testingVariant = getVariant();
            applyExperiment(testingVariant, menuElement, menuGroupElement);
            return;
        }
        if (validSiteId && validSource) {
            if (validDOM) {
                const variant = assignVariant();
                applyExperiment(variant, menuElement, menuGroupElement);
            }
        }
        else {
            clearVariant();
        }
    },
};
function applyExperiment(variant, menuElement, menuGroupElement) {
    switch (variant) {
        case "NewStickyTabs": {
            // Activate feature flag and reflect it's changes in the DOM from the liquid side
            Skubacz.configuration.feature_flags.sticky_nav = true;
            menuGroupElement.setAttribute("data-mode", "list-all");
            menuGroupElement.setAttribute("data-mode-mobile", "list-all");
            menuElement.classList.add("restaurant-menu--sticky-tabs-mobile", "restaurant-menu--sticky-tabs-desktop");
            break;
        }
        default: {
            // Control variant doesn't need any change
        }
    }
}
