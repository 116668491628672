import { RSet } from "../../collections";
import { makeImmutable, Tristate } from "../../core";
import { ParameterMultiSet } from "../Menu";
/*
 * Availability strategy based on Parametrization and parameters in scope
 */
export class ParametrizedAvailability {
    constructor(params) {
        const availability = params.parametrization.unify(params.scope.parameterSet);
        const { isAvailable, unavailabilityCausingParameters } = ParametrizedAvailability.availability(availability);
        this.isAvailable = isAvailable;
        this.unavailabilityCausingParameters = unavailabilityCausingParameters;
        this.unavailabilityReasons = isAvailable.isDefinitelyTrue
            ? RSet.empty()
            : RSet.singleton(params.unavailabilityReason);
        makeImmutable(this);
    }
    static availability(parametrization) {
        if (parametrization.hasPossibleValues &&
            parametrization.values.every((a) => a)) {
            return {
                isAvailable: Tristate.True,
                unavailabilityCausingParameters: ParameterMultiSet.empty(),
            };
        }
        if (parametrization.isValuePossible(true)) {
            return {
                isAvailable: Tristate.Maybe,
                unavailabilityCausingParameters: ParameterMultiSet.empty(),
            };
        }
        if (parametrization.hasPossibleValues) {
            return {
                isAvailable: Tristate.False,
                unavailabilityCausingParameters: parametrization.matchingParameters,
            };
        }
        return {
            isAvailable: Tristate.False,
            unavailabilityCausingParameters: parametrization.incompatibleParameters,
        };
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitParametrized(this);
    }
}
