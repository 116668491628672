import { makeImmutable } from "../core";
export class PreorderInfo {
    constructor(params) {
        this.from = params.from;
        this.to = params.to;
        makeImmutable(this);
    }
    toString() {
        return `${this.from.toString()} - ${this.to.toString()}`;
    }
}
