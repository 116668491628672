import { RArray } from "../collections";
import { makeImmutable } from "../core";
import { t, translate } from "../I18n";
import { P24, PAY_PO_CHANNEL_ID, equalPaymentChannelIds, isAppleChannelId, } from "../ordering";
const PAYMENT_MEDIUM_SEPARATOR = ".";
const P24_CHANNELS_TRANSLATION = {
    [PAY_PO_CHANNEL_ID]: t.orders.checkout.payment_channel.name.paypo,
};
export class PSinglePaymentChannel {
    constructor(params) {
        this.kind = "SingleChannel";
        this._name = params.name;
        this.channelId = params.channelId;
        this.logoUrl = params.logoUrl;
        this.enabled = params.enabled;
        this.groupName = params.groupName;
        makeImmutable(this);
    }
    get name() {
        if (this.channelId.tag === "P24Channel") {
            const translationOverride = P24_CHANNELS_TRANSLATION[this.channelId.contents];
            if (translationOverride !== undefined) {
                return translate(P24_CHANNELS_TRANSLATION[this.channelId.contents], {
                    defaultValue: this._name,
                });
            }
        }
        return this._name;
    }
    get domainValue() {
        return {
            type: "channel",
            method: "Online",
            channelId: this.channelId,
        };
    }
    get stringValue() {
        return [
            this.kind,
            this.channelId.tag,
            "contents" in this.channelId ? this.channelId.contents : "",
        ].join(PAYMENT_MEDIUM_SEPARATOR);
    }
    isSelected(selected) {
        return equalMediumValues(this.domainValue, selected);
    }
}
export class PSinglePaymentMethod {
    constructor(params) {
        this.kind = "SingleMethod";
        this.name = params.name;
        this.method = params.method;
        this.enabled = true;
        makeImmutable(this);
    }
    get domainValue() {
        return { type: "method", method: this.method };
    }
    get stringValue() {
        return [this.kind, this.method].join(PAYMENT_MEDIUM_SEPARATOR);
    }
    isSelected(selected) {
        return equalMediumValues(this.domainValue, selected);
    }
}
export class POnlineChannelGroup {
    constructor(params) {
        this.kind = "OnlineGroup";
        this.name = params.name;
        this.enabled = params.enabled;
        this._paymentChannels = params.paymentChannels;
        makeImmutable(this);
    }
    get domainValue() {
        // NOTICE: user needs to select any value within this group, selecting the group itself doesn't set payment medium
        return null;
    }
    get stringValue() {
        return this.kind;
    }
    get paymentChannels() {
        return this._paymentChannels
            .sorted((a) => (a.enabled ? -1 : 1))
            .map((channel) => new PSinglePaymentChannel({
            enabled: channel.enabled,
            channelId: channel.id,
            logoUrl: channel.logo,
            name: channel.name,
            groupName: this.name,
        }));
    }
    isSelected(selected) {
        return this.paymentChannels.some((channel) => channel.isSelected(selected));
    }
    getChannel(domainValue) {
        return (this.paymentChannels.findWhere((channel) => channel.isSelected(domainValue)) || null);
    }
}
function equalMediumValues(a, b) {
    if (a.type === "channel" && b.type === "channel") {
        return equalPaymentChannelIds(a.channelId, b.channelId);
    }
    if (a.type === "method" && b.type === "method") {
        return a.method === b.method;
    }
    return false;
}
export class PPaymentMediumGenerator {
    static group({ paymentChannels, paymentMethods, includeApplePay, }) {
        const remainingChannels = includeApplePay
            ? [...paymentChannels]
            : paymentChannels.filtered(({ id }) => !isAppleChannelId(id)).raw;
        const result = [];
        for (const featuredChannelId of PPaymentMediumGenerator.FeaturedChannels) {
            const featuredChannelIdx = remainingChannels.findIndex(({ id }) => equalPaymentChannelIds(id, featuredChannelId));
            if (featuredChannelIdx === -1) {
                continue;
            }
            const featuredChannel = remainingChannels.splice(featuredChannelIdx, 1)[0];
            result.push(new PSinglePaymentChannel({
                name: featuredChannel.name,
                channelId: featuredChannel.id,
                logoUrl: featuredChannel.logo,
                enabled: featuredChannel.enabled,
                groupName: null,
            }));
        }
        if (remainingChannels.length > 0 && paymentMethods.includes("Online")) {
            result.push(new POnlineChannelGroup({
                name: translate(t.payment_method.online.label),
                enabled: remainingChannels.some((channel) => channel.enabled),
                paymentChannels: new RArray(remainingChannels),
            }));
        }
        if (paymentMethods.includes("Cash")) {
            result.push(new PSinglePaymentMethod({
                name: translate(t.payment_method.cash.label),
                method: "Cash",
            }));
        }
        if (paymentMethods.includes("Card")) {
            result.push(new PSinglePaymentMethod({
                name: translate(t.payment_method.card.label),
                method: "Card",
            }));
        }
        return new RArray(result);
    }
}
PPaymentMediumGenerator.FeaturedChannels = [
    { tag: "StripeChannel" },
    { tag: "P24Channel", contents: P24.BLIK },
    { tag: "P24Channel", contents: P24.GOOGLE_PAY },
    { tag: "P24Channel", contents: P24.APPLE_PAY_PL },
    { tag: "P24Channel", contents: P24.APPLE_PAY },
    { tag: "P24Channel", contents: P24.CARDS },
    { tag: "P24Channel", contents: P24.PAYPO },
];
