var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
import { inject } from "tsyringe";
import { IdMap, RArray, toPairs } from "../../collections";
import { GlobalConfig } from "../../config";
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { Restaurant } from "../../ordering";
import { TranslatedParser } from "../../parsing";
import { PMenu } from "../PMenu";
import { PRestaurant } from "../PRestaurant";
import { PCustomParameterTypeParser } from "./PCustomParameterTypeParser";
import { PMenuSectionParser } from "./PMenuSectionParser";
import { PModifierTypeParser } from "./PModifierTypeParser";
import { PProductTypeParser } from "./PProductTypeParser";
let PRestaurantParser = class PRestaurantParser {
    constructor(globalConfig, translatedParser, domainRestaurant, menuSectionParser) {
        this.translatedParser = translatedParser;
        this.domainRestaurant = domainRestaurant;
        this.menuSectionParser = menuSectionParser;
        this.isApplePaySupported = globalConfig.isApplePaySupported;
        makeImmutable(this);
    }
    parse(params) {
        const domainMenu = this.domainRestaurant.menu;
        const customParameterTypeParser = new PCustomParameterTypeParser({
            domainCustomParameterTypes: domainMenu.customParameterTypes,
            translatedParser: this.translatedParser,
        });
        const customParameterTypes = this.parseCustomParameterTypes(params.input.catalogue, customParameterTypeParser);
        const modifierTypeParser = new PModifierTypeParser({
            domainModifiers: domainMenu.modifierTypes,
            translatedParser: this.translatedParser,
        });
        const modifierTypes = this.parseModifierTypes(params.input.catalogue, modifierTypeParser);
        const productTypeParser = new PProductTypeParser({
            domainProductTypes: domainMenu.productTypes,
            domainSchedules: domainMenu.schedules,
            translatedParser: this.translatedParser,
            modifierTypes,
        });
        const productTypes = this.parseProductTypes(params.input.catalogue, productTypeParser);
        const sections = this.parseSections({
            catalogue: params.input.catalogue,
            domainMenuSections: params.menuSections,
            customParameterTypes,
            productTypes,
        });
        return new PRestaurant({
            domain: this.domainRestaurant,
            name: params.input.name,
            slug: params.input.slug,
            address: params.input.address,
            menu: new PMenu({
                domain: domainMenu,
                sections,
            }),
            productTypes,
            modifierTypes,
            customParameterTypes,
            promotionTypes: params.promotionTypes,
            isApplePaySupported: this.isApplePaySupported,
            franchiseName: params.franchiseName,
            orderPhoneNumbers: params.input.order_phone_numbers,
            contactPhoneNumbers: params.input.contact_phone_numbers,
            contactEmail: params.input.contact_email,
            logoUrl: params.input.logo_url,
        });
    }
    parseProductTypes(catalogue, parser) {
        return IdMap.fromIterable(toPairs(catalogue.products).mapOptional(([productId, product]) => {
            const productCategory = catalogue.categories[product.category];
            return parser.parse(productId, productCategory.name, product);
        }));
    }
    parseModifierTypes(catalogue, parser) {
        return IdMap.fromIterable(toPairs(catalogue.modifiers).mapOptional(([modifierId, modifier]) => parser.parse(modifierId, modifier)));
    }
    parseCustomParameterTypes(catalogue, parser) {
        return IdMap.fromIterable(toPairs(catalogue.customParameters).mapOptional(([customParameterId, customParameter]) => parser.parse(customParameterId, customParameter)));
    }
    parseSections(params) {
        const sections = new RArray(toPairs(params.catalogue.menus).raw.flatMap(([_, menu]) => menu.sections)).mapOptional((sectionId) => {
            const section = params.catalogue.sections[sectionId];
            return this.menuSectionParser.parse({
                domainMenuSections: params.domainMenuSections,
                productTypes: params.productTypes,
                customParameterTypes: params.customParameterTypes,
                id: sectionId,
                section,
            });
        });
        return IdMap.fromIterable(sections);
    }
};
PRestaurantParser = __decorate([
    perRestaurant(),
    __param(0, inject(GlobalConfig)),
    __metadata("design:paramtypes", [typeof (_a = typeof GlobalConfig !== "undefined" && GlobalConfig) === "function" ? _a : Object, typeof (_b = typeof TranslatedParser !== "undefined" && TranslatedParser) === "function" ? _b : Object, typeof (_c = typeof Restaurant !== "undefined" && Restaurant) === "function" ? _c : Object, typeof (_d = typeof PMenuSectionParser !== "undefined" && PMenuSectionParser) === "function" ? _d : Object])
], PRestaurantParser);
export { PRestaurantParser };
