import { makeImmutable } from "../../../core";
export class AbsoluteDatePreorderSettingsType {
    constructor(params) {
        this.type = "AbsoluteDate";
        this.buffer = params.buffer;
        this.range = params.range;
        makeImmutable(this);
    }
    eq(other) {
        return this.range.eq(other.range) && this.buffer.eq(other.buffer);
    }
    toString() {
        return `${this.type}[minDays=${this.buffer.minDays},range={${this.range.toString()}}]`;
    }
}
