var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { Menu } from "../Menu";
import { ParameterScope } from "../ParameterScope";
import { ProductInstanceHalves, } from "./ProductInstanceHalves";
import { ProductInstancePartFactory } from "./ProductInstancePartFactory";
import { SingleProductInstancePart, } from "./SingleProductInstancePart";
let ProductInstancePartsFactory = class ProductInstancePartsFactory {
    constructor(menu, productInstancePartFactory) {
        this.menu = menu;
        this.productInstancePartFactory = productInstancePartFactory;
        makeImmutable(this);
    }
    fromStorageData(storageData) {
        switch (storageData.type) {
            case "SinglePart":
                return this.fromStorageDataSingle(storageData);
            case "Halves":
                return this.fromStorageDataHalves(storageData);
        }
    }
    fromStorageDataSingle(storageData) {
        const part = this.productInstancePartFactory.fromStorageData(storageData.part);
        if (part === null) {
            return null;
        }
        return new SingleProductInstancePart(part);
    }
    fromStorageDataHalves(storageData) {
        const first = this.productInstancePartFactory.fromStorageData(storageData.first);
        if (first === null) {
            return null;
        }
        const second = this.productInstancePartFactory.fromStorageData(storageData.second);
        if (second === null) {
            return null;
        }
        if (!first.productCategoryId.eq(second.productCategoryId)) {
            return null;
        }
        const productCategory = this.menu.productCategories.find(first.productCategoryId);
        if (productCategory === null) {
            return null;
        }
        return new ProductInstanceHalves({
            scope: ParameterScope.Uninitialized,
            productCategory,
            first,
            second,
        });
    }
};
ProductInstancePartsFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof Menu !== "undefined" && Menu) === "function" ? _a : Object, typeof (_b = typeof ProductInstancePartFactory !== "undefined" && ProductInstancePartFactory) === "function" ? _b : Object])
], ProductInstancePartsFactory);
export { ProductInstancePartsFactory };
