var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
import { inject } from "tsyringe";
import { makeImmutable, Quantity } from "../core";
import { perRestaurant } from "../di";
import { ICrossSellingListProvider, ProductCardCreator, ProductCardCrossSellingCreatorFactory, ProductInstanceCreatorFactory, ProductInstanceParser, PurchaseOrderScope, } from "../ordering";
import { PCart, PCrossSellingCreatorFactory, PFulfillmentTimeFactory, PMenuIntegrationContext, POrderingAvailability, PProductCardCreatorFactory, PProductInstanceCreatorFactory, } from "../presentation";
import { MenuResponse } from "../services";
import { NewFulfillmentTimeFactory } from "./NewFulfillmentTimeFactory";
import { OrderingHoursAvailabilityFactory } from "./OrderingHoursAvailabilityFactory";
let PCartIntegration = class PCartIntegration {
    constructor(cart, orderingAvailability, purchaseOrderScope, menuIntegrationContext, productInstanceParser, menuResponse, pProductInstanceCreatorFactory, productCardCreatorFactory, newFulfillmentTimeFactory, pCrossSellingCreatorFactory, productInstanceCreatorFactory, productCardCrossSellingCreatorFactory, orderingHoursAvailabilityFactory, pFulfillmentTimeFactory) {
        this.cart = cart;
        this.orderingAvailability = orderingAvailability;
        this.purchaseOrderScope = purchaseOrderScope;
        this.menuIntegrationContext = menuIntegrationContext;
        this.productInstanceParser = productInstanceParser;
        this.menuResponse = menuResponse;
        this.pProductInstanceCreatorFactory = pProductInstanceCreatorFactory;
        this.productCardCreatorFactory = productCardCreatorFactory;
        this.newFulfillmentTimeFactory = newFulfillmentTimeFactory;
        this.pCrossSellingCreatorFactory = pCrossSellingCreatorFactory;
        this.productInstanceCreatorFactory = productInstanceCreatorFactory;
        this.productCardCrossSellingCreatorFactory = productCardCrossSellingCreatorFactory;
        this.orderingHoursAvailabilityFactory = orderingHoursAvailabilityFactory;
        this.pFulfillmentTimeFactory = pFulfillmentTimeFactory;
        makeImmutable(this);
    }
    addToCart(creatorResult) {
        creatorResult.initWithScope(this.purchaseOrderScope);
        this.cart.addOrderLines(creatorResult.orderLines);
    }
    createProductInstanceCreator(productInstance) {
        return this.productInstanceCreatorFactory.create(productInstance);
    }
    createProductCard(productInstanceCreator) {
        const domainCrossSellingCreator = this.productCardCrossSellingCreatorFactory.create();
        const pProductInstanceCreator = this.pProductInstanceCreatorFactory.create(productInstanceCreator);
        const newFulfillmentTime = this.newFulfillmentTimeFactory.forProductCard(pProductInstanceCreator);
        const pFulfillmentTime = this.pFulfillmentTimeFactory.create({
            newFulfillmentTime,
            restoreValueOnInit: false,
        });
        const orderingHoursAvailability = this.orderingHoursAvailabilityFactory.create(newFulfillmentTime);
        const domainProductCardCreator = new ProductCardCreator({
            productInstanceCreator,
            quantity: Quantity.One,
            crossSellingCreator: domainCrossSellingCreator,
            orderingHoursAvailability,
        });
        domainProductCardCreator.initWithScope(this.purchaseOrderScope);
        const pCrossSellingCreator = this.pCrossSellingCreatorFactory.create(domainCrossSellingCreator);
        return this.productCardCreatorFactory.create({
            domain: domainProductCardCreator,
            productInstanceCreator: pProductInstanceCreator,
            crossSellingCreator: pCrossSellingCreator,
            fulfillmentTime: pFulfillmentTime,
        });
    }
    parseCreatorUrl(params) {
        const productInstance = this.productInstanceParser.parseUrl(params);
        if (productInstance === null) {
            return null;
        }
        return this.productInstanceCreatorFactory.create(productInstance);
    }
};
PCartIntegration = __decorate([
    perRestaurant(),
    __param(5, inject(ICrossSellingListProvider)),
    __param(5, inject(MenuResponse)),
    __metadata("design:paramtypes", [typeof (_a = typeof PCart !== "undefined" && PCart) === "function" ? _a : Object, typeof (_b = typeof POrderingAvailability !== "undefined" && POrderingAvailability) === "function" ? _b : Object, typeof (_c = typeof PurchaseOrderScope !== "undefined" && PurchaseOrderScope) === "function" ? _c : Object, typeof (_d = typeof PMenuIntegrationContext !== "undefined" && PMenuIntegrationContext) === "function" ? _d : Object, typeof (_e = typeof ProductInstanceParser !== "undefined" && ProductInstanceParser) === "function" ? _e : Object, typeof (_f = typeof MenuResponse !== "undefined" && MenuResponse) === "function" ? _f : Object, typeof (_g = typeof PProductInstanceCreatorFactory !== "undefined" && PProductInstanceCreatorFactory) === "function" ? _g : Object, typeof (_h = typeof PProductCardCreatorFactory !== "undefined" && PProductCardCreatorFactory) === "function" ? _h : Object, typeof (_j = typeof NewFulfillmentTimeFactory !== "undefined" && NewFulfillmentTimeFactory) === "function" ? _j : Object, typeof (_k = typeof PCrossSellingCreatorFactory !== "undefined" && PCrossSellingCreatorFactory) === "function" ? _k : Object, typeof (_l = typeof ProductInstanceCreatorFactory !== "undefined" && ProductInstanceCreatorFactory) === "function" ? _l : Object, typeof (_m = typeof ProductCardCrossSellingCreatorFactory !== "undefined" && ProductCardCrossSellingCreatorFactory) === "function" ? _m : Object, typeof (_o = typeof OrderingHoursAvailabilityFactory !== "undefined" && OrderingHoursAvailabilityFactory) === "function" ? _o : Object, typeof (_p = typeof PFulfillmentTimeFactory !== "undefined" && PFulfillmentTimeFactory) === "function" ? _p : Object])
], PCartIntegration);
export { PCartIntegration };
