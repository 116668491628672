import { makeAutoObservable } from "mobx";
import { ParameterScope } from "../ParameterScope";
/*
  Parametrized modifier instance editability class to optimize unification - calculated once for all ModifierItemInstanceCreators
*/
export class ModifierInstanceCreatorEditability {
    constructor(editabilityParametrization) {
        this.scope = ParameterScope.Uninitialized;
        this.editabilityParametrization = editabilityParametrization;
        makeAutoObservable(this);
    }
    setScope(scope) {
        this.scope = scope;
    }
    get isEditable() {
        return this.editabilityParametrization.unify(this.scope.parameterSet);
    }
    get isPossiblyEditable() {
        return this.isEditable.isValuePossible(true);
    }
    clone() {
        return new ModifierInstanceCreatorEditability(this.editabilityParametrization);
    }
}
