import StickyHeader from "./StickyHeader";
const stickyNavSelector = ".js-sticky-nav";
const stickyNavs = document.querySelectorAll(stickyNavSelector);
/**
 * Set top value to sticky navigation dynamically if sticky header is in affixed state.
 */
function setOffsetToStickyNav() {
    if (!stickyNavs.length)
        return;
    document.addEventListener("affixed.restaumatic.sticky-header", () => {
        if (!StickyHeader.utils.isHeaderSticky())
            return;
        stickyNavs.forEach((nav) => {
            nav.style.top = `${StickyHeader.utils.getStickyHeaderHeight()}px`;
        });
    });
}
/**
 * Sticky Navigation.
 */
export default {
    init: () => {
        setOffsetToStickyNav();
    },
};
