import { makeAutoObservable } from "mobx";
import { PTemporarilyUnavailableIngredients } from "./PTemporarilyUnavailableIngredients";
export class PProductConfigCreator {
    constructor(params) {
        this.domain = params.domain;
        this.productConfigAvailabilityService =
            params.productConfigAvailabilityService;
        this._modifierInstanceCreators = params.modifierInstanceCreators;
        this.customParameterInstanceCreators =
            params.customParameterInstanceCreators;
        makeAutoObservable(this);
    }
    get availability() {
        return this.productConfigAvailabilityService.availability(this.domain.productConfig);
    }
    get modifierInstanceCreators() {
        return this._modifierInstanceCreators.filter((modifierInstanceCreator) => modifierInstanceCreator.isVisible);
    }
    touch() {
        this.customParameterInstanceCreators.forEach((customParameterInstanceCreator) => customParameterInstanceCreator.touch());
        this.modifierInstanceCreators.forEach((modifierInstanceCreator) => modifierInstanceCreator.touch());
    }
    get temporarilyUnavailableIngredients() {
        return this._modifierInstanceCreators
            .map((modifierInstanceCreator) => modifierInstanceCreator.temporarilyUnavailableIngredients)
            .reduce((prev, curr) => prev.union(curr), PTemporarilyUnavailableIngredients.empty());
    }
}
