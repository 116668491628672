import { makeAutoObservable } from "mobx";
export class ProductCardCreator {
    constructor(params) {
        this.productInstanceCreator = params.productInstanceCreator;
        this._quantity = params.quantity;
        this.crossSellingCreator = params.crossSellingCreator;
        this.orderingHoursAvailability = params.orderingHoursAvailability;
        makeAutoObservable(this);
    }
    initWithScope(parentScope) {
        this.productInstanceCreator.initWithScope(parentScope);
        this.crossSellingCreator.initWithScope(parentScope);
        this.updateCrossSellingProductTypes();
    }
    get productTypeIds() {
        return this.productInstanceCreator.productTypeIds;
    }
    get quantity() {
        return this._quantity;
    }
    increment() {
        this._quantity = this.quantity.incremented();
    }
    decrement() {
        this._quantity = this.quantity.decremented();
    }
    updateCrossSellingProductTypes() {
        this.crossSellingCreator.setInputProductTypeIds(this.productTypeIds);
    }
    split() {
        this.productInstanceCreator.split();
        this.updateCrossSellingProductTypes();
    }
    removeFirstHalf() {
        this.productInstanceCreator.removeFirstHalf();
        this.updateCrossSellingProductTypes();
    }
    removeSecondHalf() {
        this.productInstanceCreator.removeSecondHalf();
        this.updateCrossSellingProductTypes();
    }
    setFirstHalfProductType(productType) {
        this.productInstanceCreator.setFirstHalfProductType(productType);
        this.updateCrossSellingProductTypes();
    }
    setSecondHalfProductType(productType) {
        this.productInstanceCreator.setSecondHalfProductType(productType);
        this.updateCrossSellingProductTypes();
    }
}
