import { RArray, RSet } from "../../collections";
import { makeImmutable } from "../../core";
/**
 * A product instance that is not split into halves.
 */
export class SingleProductInstancePart {
    constructor(part) {
        this.type = "SinglePart";
        this.part = part;
        makeImmutable(this);
    }
    get productCategoryId() {
        return this.part.productCategoryId;
    }
    get productTypeIds() {
        return RSet.singleton(this.part.productTypeId);
    }
    get productWeeklySchedule() {
        return this.part.weeklySchedule;
    }
    get customParameterChoiceIds() {
        return this.part.customParameterChoiceIds;
    }
    get parts() {
        return RArray.singleton(this.part);
    }
    get productInstanceParts() {
        return [this.part];
    }
    get storageData() {
        return {
            type: "SinglePart",
            part: this.part.storageData,
        };
    }
}
