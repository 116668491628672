var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { IdentifiableMap, RArray, RMutableMap, toPairs, } from "../../collections";
import { makeImmutable, Quantity, randomUUID } from "../../core";
import { perRestaurant } from "../../di";
import { ProductInstanceTrackingId } from "../Identifiers";
import { Menu, } from "../Menu";
import { ParameterScope } from "../ParameterScope";
import { CustomParameterInstanceFactory } from "./CustomParameterInstanceFactory";
import { ProductConfig } from "./ProductConfig";
import { ProductInstance } from "./ProductInstance";
import { ProductInstanceHalves } from "./ProductInstanceHalves";
import { ProductInstancePart } from "./ProductInstancePart";
import { SingleProductInstancePart } from "./SingleProductInstancePart";
import { ModifierInstanceFactory } from "./ModifierInstanceFactory";
let ProductInstanceParser = class ProductInstanceParser {
    constructor(menu, modifierInstanceFactory, customParameterInstanceFactory) {
        this.modifierInstanceFactory = modifierInstanceFactory;
        this.customParameterInstanceFactory = customParameterInstanceFactory;
        this.productCategories = menu.productCategories;
        this.productTypes = menu.productTypes;
        this.modifierTypes = menu.modifierTypes;
        this.customParameterTypes = menu.customParameterTypes;
        makeImmutable(this);
    }
    // NOTICE WARNING This instance is only DTO for initializing creator - it has uninitialized parameter scopes everywhere
    parseUrl(url) {
        const productTypes = new RArray(url.split("/")).mapOptional((productTypeId) => this.productTypes.rawFind(productTypeId));
        switch (productTypes.size) {
            case 1:
                return ProductInstance.fromSingleProductType(productTypes.get(0));
            case 2: {
                const firstProductType = productTypes.get(0);
                const secondProductType = productTypes.get(1);
                return ProductInstance.fromHalvesProductTypes({
                    productCategory: this.productCategories.get(firstProductType.productCategoryId),
                    firstProductType,
                    secondProductType,
                });
            }
            default:
                return null;
        }
    }
    // NOTICE WARNING This instance is only DTO for initializing creator - it has uninitialized parameter scopes everywhere
    parseCartProductKey(cartProductKey) {
        const result = this.parseInstanceParts(cartProductKey.parts);
        if (result === null) {
            return null;
        }
        const commonConfig = this.parseCommonConfig(cartProductKey);
        return new ProductInstance({
            commonConfig,
            parts: result.state,
            trackingId: new ProductInstanceTrackingId(randomUUID()),
        });
    }
    parseCommonConfig(input) {
        const modifierSettings = (modifier) => modifier.defaultSettings;
        return new ProductConfig({
            customParameterInstances: this.parseCustomParameterInstances(input.customParameters),
            modifierInstances: this.parseModifierInstances(modifierSettings, input.modifiers),
        });
    }
    parseInstanceParts(parts) {
        switch (parts.length) {
            case 1:
                return this.parseSinglePart(parts[0]);
            case 2:
                return this.parseHalves(parts[0], parts[1]);
            default:
                throw new Error(`Unsupported number of product instance parts: ${parts.length}`);
        }
    }
    parseSinglePart(inputPart) {
        const part = this.parsePart(inputPart);
        if (part === null) {
            return null;
        }
        const productCategory = this.productCategories.get(part.productType.productCategoryId);
        const state = new SingleProductInstancePart(part);
        return { state, productCategory };
    }
    parseHalves(inputFirst, inputSecond) {
        const first = this.parsePart(inputFirst);
        if (first === null) {
            return null;
        }
        const second = this.parsePart(inputSecond);
        if (second === null) {
            return null;
        }
        const productCategory = this.productCategories.find(first.productType.productCategoryId);
        if (productCategory === null) {
            return null;
        }
        const state = ProductInstanceHalves.empty({
            productCategory,
            first,
            second,
        });
        if (state === null) {
            return null;
        }
        return { state, productCategory };
    }
    parsePart(input) {
        const productType = this.productTypes.rawFind(input.productId);
        if (productType === null) {
            return null;
        }
        return new ProductInstancePart({
            scope: ParameterScope.Uninitialized,
            productType,
            productConfig: this.parsePartConfig(productType, input),
        });
    }
    parsePartConfig(productType, input) {
        return new ProductConfig({
            modifierInstances: this.parseModifierInstances((m) => productType.modifierSettings(m), input.modifiers),
            customParameterInstances: this.parseCustomParameterInstances(input.customParameters),
        });
    }
    parseModifierInstances(modifierSettingsProvider, modifiers) {
        return IdentifiableMap.fromIterable("typeId", toPairs(modifiers).map(([modifierTypeId, choices]) => {
            const modifierType = this.modifierTypes.rawGet(modifierTypeId);
            const counts = RMutableMap.empty({
                makeAutoObservable: false,
            });
            for (const choice of choices) {
                const modifierItemTypeId = modifierType.itemTypeIds.rawFind(choice);
                if (modifierItemTypeId !== null) {
                    const count = counts.getOrCreate(modifierItemTypeId, () => Quantity.Zero);
                    counts.update(modifierItemTypeId, count.incremented());
                }
            }
            const modifierSettings = modifierSettingsProvider(modifierType);
            return this.modifierInstanceFactory.create({
                modifierType,
                modifierSettings,
                itemQuantities: modifierSettings.defaultItems.union(counts.freeze()),
            });
        }));
    }
    parseCustomParameterInstances(customParameters) {
        return IdentifiableMap.fromIterable("typeId", toPairs(customParameters).mapOptional(([customParameterTypeId, choiceId]) => {
            const customParameterType = this.customParameterTypes.rawGet(customParameterTypeId);
            const customParameterChoiceId = customParameterType.choiceIds.rawFind(choiceId);
            if (customParameterChoiceId === null) {
                return null;
            }
            return this.customParameterInstanceFactory.create(customParameterType, customParameterChoiceId);
        }));
    }
};
ProductInstanceParser = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof Menu !== "undefined" && Menu) === "function" ? _a : Object, typeof (_b = typeof ModifierInstanceFactory !== "undefined" && ModifierInstanceFactory) === "function" ? _b : Object, typeof (_c = typeof CustomParameterInstanceFactory !== "undefined" && CustomParameterInstanceFactory) === "function" ? _c : Object])
], ProductInstanceParser);
export { ProductInstanceParser };
