var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a;
import { inject } from "tsyringe";
import { RSet } from "../../collections";
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { SchedulesAvailableNow } from "./Schedule";
let ScheduleAvailabilityService = class ScheduleAvailabilityService {
    constructor(schedulesAvailableNow) {
        this.schedulesAvailableNow = schedulesAvailableNow;
        makeImmutable(this);
    }
    isAvailable(schedule, fulfillmentTime) {
        switch (fulfillmentTime.type) {
            case "ASAP":
                return this.schedulesAvailableNow.includes(schedule.id);
            case "OnTime":
                return schedule.includes(fulfillmentTime.date);
        }
    }
};
ScheduleAvailabilityService = __decorate([
    perRestaurant(),
    __param(0, inject(SchedulesAvailableNow)),
    __metadata("design:paramtypes", [typeof (_a = typeof RSet !== "undefined" && RSet) === "function" ? _a : Object])
], ScheduleAvailabilityService);
export { ScheduleAvailabilityService };
