import { makeAutoObservable } from "mobx";
import { IdentifiableMap, RMutableMap, } from "../../collections";
import { Quantity } from "../../core";
import { ParameterScope } from "../ParameterScope";
import { ModifierInstance } from "../ProductInstance";
export class ModifierInstanceCreator {
    constructor(params) {
        this.scope = ParameterScope.Uninitialized;
        this.behaviour = params.behaviour;
        if (params.modifierType.behaviour !== this.behaviour) {
            throw new Error("Modifier behaviour mismatch");
        }
        this.modifierType = params.modifierType;
        this.modifierSettings = params.modifierSettings;
        this.itemQuantities = params.itemQuantities;
        this.itemCreators = params.itemCreators;
        this.editability = params.editability;
        makeAutoObservable(this);
    }
    get typeId() {
        return this.modifierType.id;
    }
    setScope(scope) {
        this.scope = scope;
        this.editability.setScope(this.scope);
        this.itemCreators.objects.forEach((modifierItemInstanceCreator) => {
            modifierItemInstanceCreator.setScope(this.scope);
        });
    }
    /** Item entries, only items which are selected */
    get selectedItems() {
        return this.itemCreators.filter((itemInstance) => itemInstance.isSelected);
    }
    get modifierInstance() {
        const items = IdentifiableMap.fromIterable("typeId", this.itemCreators.objects.map((modifierItemInstanceCreator) => modifierItemInstanceCreator.modifierItemInstance));
        return new ModifierInstance({
            scope: this.scope,
            modifierType: this.modifierType,
            modifierSettings: this.modifierSettings,
            items,
        });
    }
    clone() {
        const itemInstanceCreators = this.itemCreators.objects;
        const itemQuantities = this.itemQuantities.clone();
        const editability = this.editability.clone();
        return new ModifierInstanceCreator({
            behaviour: this.behaviour,
            modifierType: this.modifierType,
            modifierSettings: this.modifierSettings,
            itemQuantities: itemQuantities,
            itemCreators: IdentifiableMap.fromIterable("typeId", itemInstanceCreators.map((itemInstanceCreator) => itemInstanceCreator.clone({
                itemQuantities,
                editability,
            }))),
            editability,
        });
    }
    selectInferredDefaults() {
        if (this.selectedItems.isEmpty && this.modifierType.minItems.isOne) {
            const availableItems = this.itemCreators.filter((itemInstanceCreator) => itemInstanceCreator.isEditable);
            if (availableItems.size === 1) {
                availableItems.sample.select();
            }
        }
    }
    setSelectedItemIds(selectedItemTypeIds) {
        var _a;
        const counts = RMutableMap.empty({
            makeAutoObservable: false,
        });
        for (const itemId of selectedItemTypeIds) {
            const count = counts.getOrCreate(itemId, () => Quantity.Zero);
            counts.update(itemId, count.incremented());
        }
        for (const itemInstanceCreator of this.itemCreators.objects) {
            const count = (_a = counts.find(itemInstanceCreator.typeId)) !== null && _a !== void 0 ? _a : Quantity.Zero;
            this.itemQuantities.setQuantity(itemInstanceCreator.typeId, count);
        }
    }
}
