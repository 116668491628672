var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { CrossSellingItemInstancePriceService, } from "./CrossSelling";
import { Price } from "./Price";
import { ProductInstancePriceService } from "./PriceService";
let ProductCardCreatorPriceService = class ProductCardCreatorPriceService {
    constructor(productInstancePriceService, crossSellingItemInstancePriceService) {
        this.productInstancePriceService = productInstancePriceService;
        this.crossSellingItemInstancePriceService = crossSellingItemInstancePriceService;
        makeImmutable(this);
    }
    productCardCrossSellingCreatorPrice(creator) {
        return Price.total(creator.items.map((crossSellingItemInstance) => this.crossSellingItemInstancePriceService.totalPrice(crossSellingItemInstance)));
    }
    price(productCartCreator) {
        const mainProductPrice = this.productInstancePriceService
            .price(productCartCreator.productInstanceCreator.instance)
            .multiply(productCartCreator.quantity);
        return mainProductPrice.add(this.productCardCrossSellingCreatorPrice(productCartCreator.crossSellingCreator));
    }
};
ProductCardCreatorPriceService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ProductInstancePriceService !== "undefined" && ProductInstancePriceService) === "function" ? _a : Object, typeof (_b = typeof CrossSellingItemInstancePriceService !== "undefined" && CrossSellingItemInstancePriceService) === "function" ? _b : Object])
], ProductCardCreatorPriceService);
export { ProductCardCreatorPriceService };
