var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
import { inject, singleton } from "tsyringe";
import { RArray, WeeklySchedule } from "../collections";
import { RestaurantId, makeImmutable } from "../core";
import { CheckoutInfoService, HolidayService, IQrWaiter, OrderingAvailabilityService, RestaumaticRPCService, } from "../services";
import { OnlineOrderingHours, QrWaiterOrderingHours, } from "./OrderingHours";
let BackendDataService = class BackendDataService {
    constructor(rpcService, checkoutInfoService, holidayService, orderingAvailabilityService, qrWaiter) {
        this.rpcService = rpcService;
        this.checkoutInfoService = checkoutInfoService;
        this.holidayService = holidayService;
        this.orderingAvailabilityService = orderingAvailabilityService;
        this.qrWaiter = qrWaiter;
        makeImmutable(this);
    }
    async getBackendData(params) {
        const restaurantId = new RestaurantId(params.restaurantId);
        const requests = Promise.all([
            // menuResponse
            params.menuResponse,
            // dineInLocation
            this.qrWaiter.getDineInLocation(restaurantId),
            // orderingAvailability
            this.orderingAvailabilityService.getOrderingAvailability(restaurantId),
            // checkoutInfo
            this.checkoutInfoService.getCheckoutInfo(restaurantId),
            // payment channels
            params.fetchPaymentChannels
                ? this.checkoutInfoService.getPaymentChannels(restaurantId)
                : Promise.resolve(RArray.empty()),
            // automaticCrossSellingWeights
            this.rpcService.rpc("GetAcsWeights", {
                restaurantId: restaurantId.value,
            }),
        ]);
        const [menuResponse, dineInLocation, backendOrderingAvailability, checkoutInfo, paymentChannels, automaticCrossSellingWeights,] = await requests;
        const orderingHours = await this.orderingHours({
            weeklyAvailability: menuResponse.ordering_hours,
            holidays: this.holidayService.getHolidays(restaurantId, menuResponse.time_zone),
        });
        return {
            restaurantId,
            menuResponse,
            dineInLocation,
            backendOrderingAvailability,
            checkoutInfo,
            orderingHours,
            paymentChannels,
            automaticCrossSellingWeights,
        };
    }
    async getBackendPaymentChannels(restaurantId) {
        return this.checkoutInfoService.getPaymentChannels(restaurantId);
    }
    async orderingHours(params) {
        if (this.qrWaiter.isQrWaiterOrder()) {
            return new QrWaiterOrderingHours();
        }
        return new OnlineOrderingHours({
            weeklySchedule: WeeklySchedule.fromWeeklyAvailability(params.weeklyAvailability),
            holidays: await params.holidays,
        });
    }
};
BackendDataService = __decorate([
    singleton(),
    __param(4, inject(IQrWaiter)),
    __metadata("design:paramtypes", [typeof (_a = typeof RestaumaticRPCService !== "undefined" && RestaumaticRPCService) === "function" ? _a : Object, typeof (_b = typeof CheckoutInfoService !== "undefined" && CheckoutInfoService) === "function" ? _b : Object, typeof (_c = typeof HolidayService !== "undefined" && HolidayService) === "function" ? _c : Object, typeof (_d = typeof OrderingAvailabilityService !== "undefined" && OrderingAvailabilityService) === "function" ? _d : Object, typeof (_e = typeof IQrWaiter !== "undefined" && IQrWaiter) === "function" ? _e : Object])
], BackendDataService);
export { BackendDataService };
