import { makeImmutable } from "../../core";
import { RArray } from "../../collections";
import { PModifierItemSection } from "../PModifierItemSection";
import { PModifierItemType } from "../PModifierItemType";
import { PModifierType } from "../PModifierType";
export class PModifierTypeParser {
    constructor(params) {
        this.domainModifiers = params.domainModifiers;
        this.translatedParser = params.translatedParser;
        makeImmutable(this);
    }
    parse(id, modifier) {
        const domainModifier = this.domainModifiers.rawFind(id);
        if (domainModifier === null) {
            return null;
        }
        return new PModifierType({
            domain: domainModifier,
            name: this.translatedParser.parse(modifier.name),
            description: modifier.description
                ? this.translatedParser.parse(modifier.description)
                : null,
            displayMode: this.parseDisplayMode(modifier.displayMode),
            sections: this.parseModifierSections(domainModifier.itemTypes, modifier),
        });
    }
    parseDisplayMode(displayMode) {
        switch (displayMode) {
            case "ModifierList":
                return "StepperList";
            case "ModifierCheckboxList":
                return "CheckBoxList";
            case "ModifierRadioButtonList":
                return "RadioList";
            case "ModifierPizzaGrid":
                return "PizzaGrid";
            // FIXME: handle Hidden modifiers
            case "ModifierHidden":
                return "StepperList";
        }
    }
    parseModifierSections(domainModifierItemTypes, modifier) {
        return modifier.sections.map((section) => {
            return new PModifierItemSection({
                name: section.name ? this.translatedParser.parse(section.name) : null,
                items: new RArray(section.items).mapOptional(([modifierItemTypeId, modifierItemType]) => {
                    const domainModifierItemType = domainModifierItemTypes.rawFind(modifierItemTypeId);
                    if (domainModifierItemType === null) {
                        return null;
                    }
                    return new PModifierItemType({
                        domain: domainModifierItemType,
                        name: this.translatedParser.parse(modifierItemType.name),
                        photo: modifierItemType.photo,
                    });
                }).raw,
            });
        });
    }
}
