import { makeAutoObservable } from "mobx";
import { PCustomParameterInstanceId } from "./PCustomParameterInstanceId";
import { PProductError } from "./PProductError";
export class PCustomParameterInstanceCreator {
    constructor(params) {
        this.instanceId = new PCustomParameterInstanceId();
        this.domain = params.domain;
        this.customParameterInstanceAvailabilityService =
            params.customParameterInstanceAvailabilityService;
        this.customParameterType = params.customParameterType;
        this.touched = false;
        makeAutoObservable(this);
    }
    get isVisible() {
        return this.choices.length > 0;
    }
    get typeId() {
        return this.domain.typeId;
    }
    get name() {
        return this.customParameterType.name;
    }
    get availability() {
        return this.customParameterInstanceAvailabilityService.availability(this.domain.customParameterInstance);
    }
    // NOTICE: Custom parameter is always required
    get isRequired() {
        return true;
    }
    get error() {
        if (this.touched &&
            this.availability.unavailabilityReasons.includes("Required")) {
            return PProductError.required();
        }
        return null;
    }
    get choices() {
        return this.customParameterType.choices.objects.raw;
    }
    choiceAvailability(customParameterChoiceId) {
        return this.customParameterInstanceAvailabilityService.choiceAvailability(this.domain.customParameterType, customParameterChoiceId);
    }
    isSelected(customParameterChoiceId) {
        const { selectedChoice } = this.domain;
        return ((selectedChoice && selectedChoice.eq(customParameterChoiceId)) || false);
    }
    choose(customParameterChoiceId) {
        this.touch();
        this.domain.choose(customParameterChoiceId);
    }
    touch() {
        this.touched = true;
    }
}
