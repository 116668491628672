var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { flow, makeAutoObservable } from "mobx";
import { singleton } from "tsyringe";
import { DeliveryAddress, DeliveryAddressStatus, } from "../ordering";
let PDeliveryAddress = class PDeliveryAddress {
    constructor(domain, status) {
        this.domain = domain;
        this.status = status;
        this.streetAddress = domain.streetAddress.clone();
        this.apartmentInfo = domain.apartmentInfo.clone();
        makeAutoObservable(this, {
            validateAndSave: flow,
            validateAndForceSave: flow,
        });
    }
    /**
     * Saves a new value only if the address is valid (for modal form). Returns the status of saving.
     */
    *validateAndSave(restaurantId) {
        const requestedStreetAddress = this.streetAddress.clone();
        const isValid = (yield this.status.validate(restaurantId, requestedStreetAddress));
        if (isValid) {
            this.saveInDomain(requestedStreetAddress);
        }
        return isValid;
    }
    /**
     * Save the address with proper status, even if not valid
     * TODO: (B2C-256) remove it when address will be saved only in AddressModal
     */
    *validateAndForceSave(restaurantId) {
        const requestedStreetAddress = this.streetAddress.clone();
        const isValid = (yield this.status.validate(restaurantId, requestedStreetAddress));
        this.saveInDomain(requestedStreetAddress);
        return isValid;
    }
    /**
     * Saves even invalid value instantly without validation (for Cart & Checkout inline forms)
     * NOTICE: You should validate by `forceSave()` on the input blur event
     * TODO: (B2C-256) remove it when address will be saved only in AddressModal
     */
    saveUnvalidated() {
        this.domain.set({
            streetAddress: this.streetAddress.clone(),
            apartmentInfo: this.apartmentInfo.clone(),
            status: { type: "ChangedAndNotValidatedYet" },
        });
    }
    saveInDomain(streetAddress) {
        this.domain.set({
            streetAddress,
            apartmentInfo: this.apartmentInfo.clone(),
            status: this.status.state,
        });
    }
    saveApartmentInfo() {
        this.domain.setApartmentInfoOnly(this.apartmentInfo.clone());
    }
};
PDeliveryAddress = __decorate([
    singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof DeliveryAddress !== "undefined" && DeliveryAddress) === "function" ? _a : Object, typeof (_b = typeof DeliveryAddressStatus !== "undefined" && DeliveryAddressStatus) === "function" ? _b : Object])
], PDeliveryAddress);
export { PDeliveryAddress };
