var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { makeAutoObservable } from "mobx";
import { RArray } from "../../collections";
import { InCartPromotionTypes } from "../InCartPromotionTypes";
import { OrderLineReference } from "../OrderLineReference";
import { PurchaseOrderPayload } from "../PurchaseOrderPayload";
import { ProductPromotionAssociation, WholeOrderPromotionAssociation, } from "./PromotionAssociation";
import { PromotionSolverVariableAssignment } from "./PromotionSolverVariableAssignment";
import { PurchaseOrderPayloadPriceService } from "../PriceService";
import { perRestaurant } from "../../di";
let PromotionSolverVariableAssignmentFactory = class PromotionSolverVariableAssignmentFactory {
    constructor(inCartPromotionTypes, purchaseOrderPayload, purchaseOrderPayloadPriceService) {
        this.inCartPromotionTypes = inCartPromotionTypes;
        this.purchaseOrderPayload = purchaseOrderPayload;
        this.purchaseOrderPayloadPriceService = purchaseOrderPayloadPriceService;
        makeAutoObservable(this);
    }
    createProductAssociation(promotionType, promotionScope) {
        const matchingOrderLines = this.purchaseOrderPayload.orderLines.mapOptional(({ productInstance, quantity }, orderLineIndex) => {
            if (promotionScope.selector.test(productInstance)) {
                return { productInstance, quantity: quantity.value, orderLineIndex };
            }
            return null;
        });
        const matchingProducts = matchingOrderLines.flatMap(({ quantity, productInstance, orderLineIndex }) => RArray.range(quantity).map((productInstanceIndex) => new OrderLineReference({
            productInstance,
            orderLineIndex,
            productInstanceIndex,
        })));
        if (matchingProducts.size >= promotionScope.requiredItems.value) {
            return new ProductPromotionAssociation({
                promotionType,
                matchingProducts,
            });
        }
        return null;
    }
    createWholeOrderAssociation(promotionType, promotionScope) {
        if (promotionScope.selector.test(this.purchaseOrderPayloadPriceService)) {
            return new WholeOrderPromotionAssociation({ promotionType });
        }
        return null;
    }
    get assignment() {
        const promotionAssociations = this.inCartPromotionTypes.promotionTypes.objects.mapOptional((promotionType) => {
            const { scope: promotionScope } = promotionType;
            switch (promotionScope.kind) {
                case "Product":
                    return this.createProductAssociation(promotionType, promotionScope);
                case "WholeOrder":
                    return this.createWholeOrderAssociation(promotionType, promotionScope);
            }
        });
        return new PromotionSolverVariableAssignment(promotionAssociations);
    }
};
PromotionSolverVariableAssignmentFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof InCartPromotionTypes !== "undefined" && InCartPromotionTypes) === "function" ? _a : Object, typeof (_b = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _b : Object, typeof (_c = typeof PurchaseOrderPayloadPriceService !== "undefined" && PurchaseOrderPayloadPriceService) === "function" ? _c : Object])
], PromotionSolverVariableAssignmentFactory);
export { PromotionSolverVariableAssignmentFactory };
