var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { IdentifiableMap } from "../../collections";
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { Menu } from "../Menu";
import { CustomParameterInstance, } from "./CustomParameterInstance";
let CustomParameterInstanceFactory = class CustomParameterInstanceFactory {
    constructor(menu) {
        this.menu = menu;
        makeImmutable(this);
    }
    create(customParameterType, selectedChoice) {
        return new CustomParameterInstance({
            customParameterType,
            selectedChoice,
        });
    }
    createWithDefaultChoice(customParameterType) {
        return this.create(customParameterType, customParameterType.defaultChoice);
    }
    fromStorageData({ customParameterTypeId, storageData, }) {
        const customParameterType = this.menu.customParameterTypes.rawFind(customParameterTypeId);
        if (customParameterType === null) {
            return null;
        }
        const selectedChoice = customParameterType.choiceIds.rawFind(storageData.selectedChoice);
        return new CustomParameterInstance({
            customParameterType,
            selectedChoice,
        });
    }
    fromCustomParameterSet(customParameterSet) {
        return IdentifiableMap.fromIterable("typeId", [...customParameterSet.parameterValues].map(([parameterType, parameterChoiceId]) => this.create(parameterType, parameterChoiceId)));
    }
};
CustomParameterInstanceFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof Menu !== "undefined" && Menu) === "function" ? _a : Object])
], CustomParameterInstanceFactory);
export { CustomParameterInstanceFactory };
