import { IdentifiableMap, fromPairs } from "../../collections";
import { makeImmutable } from "../../core";
import { CustomParameterInstances } from "./CustomParameterInstances";
/**
 * ProductConfig is a collection of `ModifierInstance`s and `CustomParameterInstance`s
 * It is used in a context of a common config (undivisible modifiers and parameter types) and part config (divisible modifier and parameter types)
 */
export class ProductConfig {
    constructor(params) {
        this.modifierInstances = params.modifierInstances;
        this.customParameterInstances = params.customParameterInstances;
        makeImmutable(this);
    }
    static empty() {
        return new ProductConfig({
            modifierInstances: IdentifiableMap.empty(),
            customParameterInstances: IdentifiableMap.empty(),
        });
    }
    eq(other) {
        return (IdentifiableMap.eq("typeId", this.modifierInstances, other.modifierInstances) &&
            IdentifiableMap.eq("typeId", this.customParameterInstances, other.customParameterInstances));
    }
    isCartEquivalentTo(other) {
        for (const [thisParameterType, thisParameterChoice] of this
            .customParameterInstances) {
            const otherParameterChoice = other.customParameterInstances.find(thisParameterType);
            if (otherParameterChoice === null ||
                !otherParameterChoice.eq(thisParameterChoice)) {
                return false;
            }
        }
        return true;
    }
    get customParameters() {
        return CustomParameterInstances.toCustomParameterSet(this.customParameterInstances);
    }
    matchesCustomParameterValue(k, v) {
        var _a, _b, _c;
        return ((_c = (_b = (_a = this.customParameterInstances.find(k)) === null || _a === void 0 ? void 0 : _a.selectedChoice) === null || _b === void 0 ? void 0 : _b.eq(v)) !== null && _c !== void 0 ? _c : false);
    }
    get storageData() {
        return {
            modifierInstances: fromPairs(this.modifierInstances.objects.map((modifierInstance) => [
                modifierInstance.typeId.value,
                modifierInstance.storageData,
            ])),
            customParameterInstances: fromPairs(this.customParameterInstances.objects.mapOptional((customParameterInstance) => customParameterInstance.storageData === null
                ? null
                : [
                    customParameterInstance.typeId.value,
                    customParameterInstance.storageData,
                ])),
        };
    }
}
