var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { makeAutoObservable } from "mobx";
import { perRestaurant } from "../../di";
import { Delivery } from "../Delivery";
import { Availability } from "../Availability";
let DeliveryAvailabilityService = class DeliveryAvailabilityService {
    constructor(delivery) {
        this.delivery = delivery;
        makeAutoObservable(this);
    }
    availability(orderValidationPrice) {
        if (this.delivery.lastDeliveryPolicy === null) {
            return Availability.unavailable("LoadingDeliveryPolicy");
        }
        const deliveryValidationResult = this.delivery.lastDeliveryPolicy.validate(orderValidationPrice);
        switch (deliveryValidationResult) {
            case "DeliveryImpossible":
                return Availability.unavailable(deliveryValidationResult);
            case "FreeDeliveryPossible":
            case "PaidDeliveryPossible":
                return Availability.available();
        }
    }
};
DeliveryAvailabilityService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof Delivery !== "undefined" && Delivery) === "function" ? _a : Object])
], DeliveryAvailabilityService);
export { DeliveryAvailabilityService };
