import { makeAutoObservable } from "mobx";
import { ParameterScope } from "../ParameterScope";
import { ProductInstance, ProductInstancePart } from "../ProductInstance";
import { HalvesCreatorState } from "./HalvesCreatorState";
import { ProductInstanceCreatorState } from "./ProductInstanceCreatorState";
/*
 * Utility class for building correct `ProductInstance` - it deals with changing productTypes in halves / splitting / removal of halves
 */
export class ProductInstanceCreator {
    constructor(params) {
        this.productInstanceCreatorStateFactory =
            params.productInstanceCreatorStateFactory;
        this.parentScope = ParameterScope.Uninitialized;
        this.possibleProductTypeIds = params.possibleProductTypeIds;
        this._state = params.state;
        this.trackingId = params.trackingId;
        makeAutoObservable(this);
    }
    initWithScope(parentScope) {
        this.parentScope = parentScope;
        this.state.initWithScope(this.parentScope);
        this.selectInferredDefaults();
    }
    get scope() {
        return this.parentScope;
    }
    get state() {
        return this._state;
    }
    get productTypeIds() {
        return this._state.productTypeIds;
    }
    setState(newState) {
        this._state = newState;
        this.state.initWithScope(this.parentScope);
        this.state.selectInferredDefaults();
    }
    selectInferredDefaults() {
        this.state.selectInferredDefaults();
    }
    get instance() {
        return new ProductInstance({
            commonConfig: this.state.commonProductConfig,
            parts: this.state.productInstanceParts,
            trackingId: this.trackingId,
        });
    }
    split() {
        const { partCreator: firstHalf, productCategory } = this
            .state;
        const secondHalf = firstHalf.clone();
        this.setState(new HalvesCreatorState({
            productCategory,
            commonConfigCreator: this.state.commonConfigCreator,
            firstHalf,
            secondHalf,
        }));
    }
    removeHalf(half) {
        if (!ProductInstanceCreatorState.isSplit(this.state)) {
            throw new Error("Removing part is not possible at this state");
        }
        const partCreator = this.state[invertHalf(half)];
        this.setState(this.productInstanceCreatorStateFactory.createSingle({
            productCategory: this.state.productCategory,
            initialCommonConfig: this.state.commonProductConfig,
            part: partCreator.productInstancePart,
        }));
    }
    removeFirstHalf() {
        this.removeHalf("firstHalf");
    }
    removeSecondHalf() {
        this.removeHalf("secondHalf");
    }
    setPartProductType(productType) {
        if (ProductInstanceCreatorState.isSplit(this.state)) {
            throw new Error("Use setFirstHalfProductType/setSecondHalfProductType in split state");
        }
        this.setState(this.productInstanceCreatorStateFactory.createSingle({
            productCategory: this.state.productCategory,
            initialCommonConfig: this.state.commonProductConfig,
            part: ProductInstancePart.empty(productType),
        }));
    }
    setFirstHalfProductType(productType) {
        if (!ProductInstanceCreatorState.isSplit(this.state)) {
            throw new Error("Use setPartProductType in non-split state");
        }
        this.setState(this.productInstanceCreatorStateFactory.createHalves({
            productCategory: this.state.productCategory,
            initialCommonConfig: this.state.commonProductConfig,
            firstHalf: ProductInstancePart.empty(productType),
            secondHalf: this.state.secondHalf.productInstancePart,
        }));
    }
    setSecondHalfProductType(productType) {
        if (!ProductInstanceCreatorState.isSplit(this.state)) {
            throw new Error("Use setPartProductType in non-split state");
        }
        this.setState(this.productInstanceCreatorStateFactory.createHalves({
            productCategory: this.state.productCategory,
            initialCommonConfig: this.state.commonProductConfig,
            firstHalf: this.state.firstHalf.productInstancePart,
            secondHalf: ProductInstancePart.empty(productType),
        }));
    }
}
function invertHalf(half) {
    switch (half) {
        case "firstHalf":
            return "secondHalf";
        case "secondHalf":
            return "firstHalf";
    }
}
