import { makeImmutable } from "../core";
export class SalesChannel {
    constructor(value) {
        this.value = value;
        makeImmutable(this);
    }
    static create(isQrWaiterOrder, mobileAppType) {
        if (isQrWaiterOrder) {
            return SalesChannel.QRWaiter;
        }
        switch (mobileAppType) {
            case "IosApp":
                return SalesChannel.IosApp;
            case "AndroidApp":
                return SalesChannel.AndroidApp;
        }
        return SalesChannel.Sites;
    }
    get menuOrderOrigin() {
        switch (this.value) {
            case "Sites":
            case "AndroidApp":
            case "IosApp":
                return "Online";
            case "QRWaiter":
                return "Bar";
        }
    }
}
SalesChannel.Sites = new SalesChannel("Sites");
SalesChannel.QRWaiter = new SalesChannel("QRWaiter");
SalesChannel.IosApp = new SalesChannel("IosApp");
SalesChannel.AndroidApp = new SalesChannel("AndroidApp");
