import { makeImmutable } from "../../core";
// NOTICE ProductTypeGroup will gradually replace ProductCategory and MenuSection, and is used for grouping settings across multiple ProductTypes
export class ProductTypeGroup {
    constructor(params) {
        this.id = params.id;
        this.visibilityScheduleId = params.visibilityScheduleId;
        this.preorderSettings = params.preorderSettings;
        makeImmutable(this);
    }
}
