var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject, singleton } from "tsyringe";
import { makeImmutable } from "../core";
import { ApartmentInfoStorageSerializer } from "./ApartmentInfoStorageSerializer";
import { CartStorageSerializer } from "./CartStorageSerializer";
import { ConsentsStorageSerializer } from "./ConsentsStorageSerializer";
import { ContactDetailsStorageSerializer } from "./ContactDetailsStorageSerializer";
import { FieldTestsStorageSerializer } from "./FieldTestsStorageSerializer";
import { FulfillmentMethodTypeStorageSerializer } from "./FulfillmentMethodTypeStorageSerializer";
import { FulfillmentTimeStorageSerializer } from "./FulfillmentTimeStorageSerializer";
import { InvoiceDetailsStorageSerializer } from "./InvoiceDetailsStorageSerializer";
import { IStorage } from "./IStorage";
import { NumberStorageSerializer } from "./NumberStorageSerializer";
import { OrderItemListStorageSerializer, } from "./OrderItemListStorageSerializer";
import { PaymentStorageSerializer } from "./PaymentStorageSerializer";
import { StorageManager } from "./StorageManager";
import { StreetAddressStorageSerializer } from "./StreetAddressStorageSerializer";
import { StringStorageSerializer } from "./StringStorageSerializer";
import { RMutableMap } from "../collections";
import { StorageVersionManager } from "./StorageVersionManager";
let StorageManagerFactory = class StorageManagerFactory {
    constructor(storage, storageVersionManager) {
        this.storage = storage;
        this.storageVersionManager = storageVersionManager;
        this.storageManagers = RMutableMap.empty({ makeAutoObservable: false });
        makeImmutable(this);
    }
    create(storageKey, storageSerializer, checkStorageFormatVersion = true) {
        return this.storageManagers.getOrCreate(storageKey, () => {
            const storageManager = new StorageManager({
                storage: this.storage,
                storageKey,
                storageSerializer,
            });
            if (checkStorageFormatVersion) {
                this.storageVersionManager.checkStorageFormatVersion(storageManager);
            }
            return storageManager;
        });
    }
    forApartmentInfo() {
        return this.create("client_apartment_info", new ApartmentInfoStorageSerializer());
    }
    forStreetAddress() {
        return this.create("client_street_address", new StreetAddressStorageSerializer());
    }
    // TODO: fixes
    forCart(restaurantSlug) {
        return this.create(`client_cart_${restaurantSlug}`, new CartStorageSerializer());
    }
    forPreviousCart(restaurantSlug) {
        return this.create(`client_previousOrder_${restaurantSlug}`, new CartStorageSerializer());
    }
    forConsents() {
        return this.create("client_consents", new ConsentsStorageSerializer());
    }
    forFulfillmentMethodType() {
        return this.create("client_fulfillmentMethod", new FulfillmentMethodTypeStorageSerializer());
    }
    forFulfillmentTime() {
        return this.create("client_fulfillmentTime", new FulfillmentTimeStorageSerializer());
    }
    forInvoiceDetails() {
        return this.create("client_invoiceDetails", new InvoiceDetailsStorageSerializer());
    }
    forOnlineContactDetails() {
        return this.create("client_contactDetails", new ContactDetailsStorageSerializer());
    }
    forQrWaiterContactDetails() {
        return this.create("client_contactDetails_qr_waiter", new ContactDetailsStorageSerializer());
    }
    forPayment() {
        return this.create("client_paymentMedium", new PaymentStorageSerializer());
    }
    forOrderItemList() {
        return this.create("client_orderItemListTracking", new OrderItemListStorageSerializer());
    }
    forFieldTests() {
        return this.create("fieldTestExperiments", new FieldTestsStorageSerializer(), false);
    }
    forCurrentRestaurantSlug() {
        return this.create("currentRestaurant", new StringStorageSerializer());
    }
    // Used in mobile-app only
    forCurrentRestaurantId() {
        return this.create("currentRestaurantId", new NumberStorageSerializer());
    }
    // Used in mobile-app only
    forActiveMenuSectionId() {
        return this.create("openToggleGroup", new StringStorageSerializer());
    }
};
StorageManagerFactory = __decorate([
    singleton(),
    __param(0, inject(IStorage)),
    __metadata("design:paramtypes", [typeof (_a = typeof IStorage !== "undefined" && IStorage) === "function" ? _a : Object, typeof (_b = typeof StorageVersionManager !== "undefined" && StorageVersionManager) === "function" ? _b : Object])
], StorageManagerFactory);
export { StorageManagerFactory };
