var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { flow, makeAutoObservable } from "mobx";
import { RestaumaticRPCService } from "../services";
import { result } from "../core";
import { Tracking } from "../tracking";
import { perRestaurant } from "../di";
import { PRestaurant } from "./PRestaurant";
let PAiWaiter = class PAiWaiter {
    constructor(rpcService, restaurant) {
        this.rpcService = rpcService;
        this.restaurantId = restaurant.id;
        this.menu = restaurant.menu;
        this._state = { type: "NotRequested" };
        makeAutoObservable(this, { submit: flow });
    }
    get state() {
        return this._state;
    }
    *submit(query) {
        this._state = { type: "Loading" };
        const startTime = performance.now();
        try {
            Tracking.trackEvent({
                tag: "AiWaiterMessageSent",
                contents: { query },
            });
            const response = yield* result(this.rpcService.rpc("AiWaiterRequest", {
                restaurantId: this.restaurantId.value,
                query,
            }));
            if (response === null) {
                throw new Error("RPC Error");
            }
            const { message, recommendations } = response;
            this._state = {
                type: "Success",
                message,
                items: recommendations
                    .map((productTypeId) => findItem(productTypeId, this.menu))
                    .filter((item) => item !== null),
            };
            Tracking.trackEvent({
                tag: "AiWaiterMessageReceived",
                contents: {
                    message,
                    responseTimeMs: Math.round(performance.now() - startTime),
                },
            });
        }
        catch (error) {
            this._state = {
                type: "Error",
                error: "Wystąpił nieoczekiwany błąd",
            };
            Tracking.trackEvent({
                tag: "AiWaiterMessageError",
                contents: { responseTimeMs: Math.round(performance.now() - startTime) },
            });
        }
    }
    reset() {
        this._state = { type: "NotRequested" };
    }
};
PAiWaiter = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof RestaumaticRPCService !== "undefined" && RestaumaticRPCService) === "function" ? _a : Object, typeof (_b = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _b : Object])
], PAiWaiter);
export { PAiWaiter };
function findItem(productTypeId, menu) {
    for (const section of menu.sections.objects.raw) {
        const item = section.items.objects.findWhere((item) => item.content.type === "SingleProduct" &&
            item.content.id.value === productTypeId);
        if (item !== null) {
            return {
                sectionId: section.id,
                sectionItem: item,
            };
        }
    }
    return null;
}
