var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { IdMap, RMap, RMutableMap } from "../collections";
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { ProductTypeGroup, ProductTypeGroupId, ProductTypeId, } from "../ordering";
import { PreorderSettingsTypeParser } from "./PreorderSettingsTypeParser";
let ProductTypeGroupParser = class ProductTypeGroupParser {
    constructor(preorderSettingsTypeParser) {
        this.preorderSettingsTypeParser = preorderSettingsTypeParser;
        makeImmutable(this);
    }
    parse(params) {
        const productTypeGroups = RMutableMap.empty({ makeAutoObservable: false });
        for (const [sectionId, section] of params.activeSections) {
            if (section.content.tag !== "Products") {
                continue;
            }
            const visibilityScheduleId = section.visibilitySchedule === null
                ? null
                : params.domainScheduleIds.rawFind(section.visibilitySchedule);
            const productTypeGroup = new ProductTypeGroup({
                id: new ProductTypeGroupId(sectionId),
                preorderSettings: this.preorderSettingsTypeParser.parse({
                    preorderSettings: section.preorderSettings,
                    visibilityScheduleId,
                }),
                visibilityScheduleId,
            });
            for (const [, item] of section.content.items) {
                if (item.tag === "SingleProduct") {
                    const productTypeId = new ProductTypeId(item.itemProduct);
                    productTypeGroups
                        .getOrCreate(productTypeId, () => [])
                        .push(productTypeGroup);
                }
            }
        }
        return RMap.fromIterable(productTypeGroups.entries.map(([productTypeId, groupsForType]) => [productTypeId, IdMap.fromIterable(groupsForType)]));
    }
};
ProductTypeGroupParser = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof PreorderSettingsTypeParser !== "undefined" && PreorderSettingsTypeParser) === "function" ? _a : Object])
], ProductTypeGroupParser);
export { ProductTypeGroupParser };
