var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import I18n from "i18n-js";
import { singleton } from "tsyringe";
import { makeImmutable } from "../core";
/**
 * Allows translating values to a localization
 */
let LocalizationProvider = class LocalizationProvider {
    constructor(params) {
        this.locale = params.locale;
        this.fallbackLocale = params.fallbackLocale;
        this.currency = params.currency;
        makeImmutable(this);
    }
    localize(text) {
        const hasAnyText = Object.keys(text).length > 0;
        if (!hasAnyText) {
            return "";
        }
        const localizedText = text[this.locale];
        if (localizedText !== undefined) {
            return localizedText;
        }
        const fallbackLocalizedText = text[this.fallbackLocale];
        if (fallbackLocalizedText !== undefined) {
            return fallbackLocalizedText;
        }
        for (const anyLocalizedText of Object.values(text)) {
            if (anyLocalizedText !== undefined) {
                return anyLocalizedText;
            }
        }
        return "???";
    }
    formatPrice(money) {
        return this.formatCurrencySimple(money.cents, this.currency);
    }
    formatCurrencySimple(cents, currency) {
        const options = CURRENCY_OPTIONS[currency];
        return I18n.toCurrency(cents / 100, {
            format: options.format,
            precision: options.precision,
            separator: options.separator,
            delimiter: options.delimiter,
            unit: options.unit,
            strip_insignificant_zeros: false,
        });
    }
    get currencySymbol() {
        return CURRENCY_OPTIONS[this.currency].unit;
    }
    get currencySeparator() {
        return CURRENCY_OPTIONS[this.currency].separator;
    }
    get currencyPrecision() {
        return CURRENCY_OPTIONS[this.currency].precision;
    }
};
LocalizationProvider = __decorate([
    singleton(),
    __metadata("design:paramtypes", [Object])
], LocalizationProvider);
export { LocalizationProvider };
const CURRENCY_OPTIONS = {
    PLN: {
        format: "%n %u",
        precision: 2,
        separator: ",",
        delimiter: " ",
        unit: "zł",
    },
    CZK: {
        format: "%n %u",
        precision: 2,
        separator: ",",
        delimiter: "",
        unit: "Kč",
    },
    GBP: {
        format: "%u%n",
        precision: 2,
        separator: ".",
        delimiter: ",",
        unit: "£",
    },
    USD: {
        format: "%u%n",
        precision: 2,
        separator: ".",
        delimiter: "",
        unit: "$",
    },
    EUR: {
        format: "%n %u",
        precision: 2,
        separator: ",",
        delimiter: "",
        unit: "EUR",
    },
    RUB: {
        format: "%n %u",
        precision: 2,
        separator: ",",
        delimiter: " ",
        unit: "руб.",
    },
    RON: {
        format: "%n %u",
        precision: 2,
        separator: ",",
        delimiter: " ",
        unit: "RON",
    },
    HRK: {
        format: "%n %u",
        precision: 2,
        separator: ",",
        delimiter: " ",
        unit: "kn",
    },
    BRL: {
        format: "%u%n",
        precision: 2,
        separator: ".",
        delimiter: "",
        unit: "R$",
    },
};
export function localizePath(path) {
    if (I18n.locale === I18n.defaultLocale) {
        return path;
    }
    else {
        return `/${I18n.locale}${path}`;
    }
}
