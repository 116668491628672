import { makeImmutable } from "./makeImmutable";
/**
 * Represents an amount of money in a given currency.
 */
export class Money {
    constructor(cents) {
        this.cents = cents;
        makeImmutable(this);
    }
    static fromCents(cents) {
        return new Money(cents);
    }
    static roundCents(value) {
        const sign = Math.sign(value);
        const abs = Math.abs(value);
        const integer = Math.floor(abs);
        const fraction = abs - integer;
        return (sign * (fraction === 0.5 ? integer + (integer % 2) : Math.round(abs)));
    }
    static fromBackend(value) {
        // NOTICE No need to use cent rounding when parsing from compatible backend
        return Money.fromNumber(value, "simple");
    }
    static fromNumber(value, rounding) {
        switch (rounding) {
            case "simple":
                return new Money(Math.round(value * 100));
            case "monetary":
                return new Money(Money.roundCents(value * 100));
        }
    }
    static fromString(value) {
        return Money.fromNumber(parseFloat(value), "simple");
    }
    static zero() {
        return new Money(0);
    }
    static total(centss) {
        return [...centss].reduce((a, b) => a.add(b), Money.zero());
    }
    get asNumber() {
        return this.cents / 100;
    }
    get isZero() {
        return this.cents === 0;
    }
    /**
     * Add another Money
     */
    add(other) {
        return new Money(this.cents + other.cents);
    }
    sub(other) {
        return new Money(this.cents - other.cents);
    }
    eq(other) {
        return this.cents === other.cents;
    }
    /**
     * Multiply by a Quantity
     */
    multiply(quantity) {
        return new Money(this.cents * quantity.value);
    }
    /**
     * Get the smallest of two Money objects
     */
    half() {
        return new Money(Money.roundCents(this.cents / 2));
    }
    /**
     * Negate
     */
    get negated() {
        return new Money(-this.cents);
    }
    /**
     * Percentage of money
     */
    percentage(percentage) {
        return Money.fromNumber(percentage.of(this.asNumber), "monetary");
    }
    /**
     * Get the smallest of two Money objects
     */
    min(other) {
        return new Money(Math.min(this.cents, other.cents));
    }
    /**
     * Get the largest of two Money objects
     */
    max(other) {
        return new Money(Math.max(this.cents, other.cents));
    }
    gt(other) {
        return this.cents > other.cents;
    }
    geq(other) {
        return this.cents >= other.cents;
    }
    leq(other) {
        return this.cents <= other.cents;
    }
    lt(other) {
        return this.cents < other.cents;
    }
    /**
     * Format the cents as a string with currency symbol.
     *
     * NOTE: Use {@link LocalizationProvider} for proper human-readable formatting.
     */
    toString() {
        return `${this.cents / 100} [MONEY]`;
    }
    get associationKey() {
        return this.toString();
    }
}
