import { makeAutoObservable } from "mobx";
import { makeImmutable, Money } from "../core";
export class DisabledTipInstance {
    constructor() {
        this.kind = "Disabled";
        makeImmutable(this);
    }
    get value() {
        return Money.zero();
    }
    get storageData() {
        return null;
    }
}
export class EnabledTipInstance {
    constructor(value) {
        this.kind = "Enabled";
        this._value = value;
        makeAutoObservable(this);
    }
    static fromCents(cents) {
        if (cents === null || cents <= 0) {
            return new EnabledTipInstance(null);
        }
        return new EnabledTipInstance(Money.fromCents(cents));
    }
    get value() {
        var _a;
        return (_a = this._value) !== null && _a !== void 0 ? _a : Money.zero();
    }
    get numericValue() {
        var _a, _b;
        return (_b = (_a = this._value) === null || _a === void 0 ? void 0 : _a.asNumber) !== null && _b !== void 0 ? _b : null;
    }
    setValue(value) {
        if (value.leq(Money.zero())) {
            this.resetValue();
        }
        else {
            this._value = value;
        }
    }
    resetValue() {
        this._value = null;
    }
    get storageData() {
        var _a, _b;
        return (_b = (_a = this._value) === null || _a === void 0 ? void 0 : _a.cents) !== null && _b !== void 0 ? _b : null;
    }
}
export const TipInstance = {
    fromStorageData(params) {
        if (params.areTipsEnabled) {
            return EnabledTipInstance.fromCents(params.value);
        }
        return new DisabledTipInstance();
    },
};
export const TipInstanceToken = "TipInstance";
