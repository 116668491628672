export const ProductInstanceParts = {
    isCartEquivalentTo(a, b) {
        if (a.type === "SinglePart" && b.type === "SinglePart") {
            return a.part.isCartEquivalentTo(b.part);
        }
        if (a.type === "Halves" && b.type === "Halves") {
            return (a.first.isCartEquivalentTo(b.first) &&
                a.second.isCartEquivalentTo(b.second));
        }
        return false;
    },
    eq(a, b) {
        if (a.type === "SinglePart" && b.type === "SinglePart") {
            return a.part.eq(b.part);
        }
        if (a.type === "Halves" && b.type === "Halves") {
            return a.first.eq(b.first) && a.second.eq(b.second);
        }
        return false;
    },
};
