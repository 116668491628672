import { RSet } from "../../collections";
import { makeImmutable, Tristate } from "../../core";
/*
 * Maybe availability strategy
 */
export class Maybe {
    constructor(reason) {
        this.reason = reason;
        makeImmutable(this);
    }
    get isAvailable() {
        return Tristate.Maybe;
    }
    get unavailabilityReasons() {
        return RSet.singleton(this.reason);
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitMaybe(this);
    }
}
