import { RArray } from "../../collections";
import { DateRange } from "../../core";
export class QrWaiterOrderingHours {
    fulfillmentTimes(_date) {
        return RArray.empty();
    }
    endOfDay(date) {
        return date.add(1, "days");
    }
    nextEventAtDate(_after) {
        return { type: "OrderingEnds", at: null };
    }
    canOrderAt(date) {
        return this.nextEventAtDate(date).type === "OrderingEnds";
    }
    addWorkdays(date, workdays) {
        const result = date.add(workdays, "days");
        return DateRange.fromDates({
            begin: result.startOfDay(),
            end: result.endOfDay(),
        });
    }
}
