import { Temporal } from "@js-temporal/polyfill";
export class RTimeZone {
    static get browserTimezone() {
        return Temporal.Now.timeZoneId();
    }
    static get restaurantTimezone() {
        return Skubacz.configuration.time_zone;
    }
    static get isRestarantDifferentFromBrowserTimezone() {
        return this.browserTimezone !== this.restaurantTimezone;
    }
}
