import { RArray } from "../../collections";
export class DebugAvailablityVistor {
    constructor() {
        this.errors = [];
    }
    visitAvailable(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [
                `Available[${availability.unavailabilityReasons.toString()}]`,
            ];
        }
    }
    visitUnavailable(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [
                `Unavailable[${availability.unavailabilityReasons.toString()}]`,
            ];
        }
    }
    visitMaybe(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [`Maybe[${availability.unavailabilityReasons.toString()}]`];
        }
    }
    visitBoolean(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [
                `Boolean[${availability.unavailabilityReasons.toString()}]`,
            ];
        }
    }
    visitComposite(availability) {
        const nodes = new RArray(availability.availabilities)
            .filtered((availability) => availability.isAvailable.isDefinitelyFalse)
            .mapOptional((availability) => {
            const vistor = new DebugAvailablityVistor();
            availability.accept(vistor);
            return vistor.errors;
        }).raw;
        this.errors = nodes.flat(); //.map(error => `Composite[${availability.unavailabilityReasons.toString()}] -> ${error}`);
    }
    visitDependent(availability) {
        const vistitor = new DebugAvailablityVistor();
        availability.dependency.accept(vistitor);
        //this.errors = vistitor.errors.map(error => `Dependency[${availability.unavailabilityReasons.toString()}] -> ${error}`)
        this.errors = vistitor.errors.map((error) => `${availability.unavailabilityReasons.toString()}(${availability.description}) -> ${error}`);
    }
    visitParametrized(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [
                `Parametrized[${availability.unavailabilityCausingParameters.toString()}]`,
            ];
        }
    }
    visitSchedule(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [`Schedule[${availability.toString()}]`];
        }
    }
    visitPreorder(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [`Preorder(${availability.reasons.join(", ")})`];
        }
    }
    visitOrderingHours(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [`OrderingHours`];
        }
    }
}
