var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
import { inject } from "tsyringe";
import { makeImmutable, Now, RDate } from "../core";
import { perRestaurant } from "../di";
import { ProductInstancePartAvailabilityService, IOrderingHours, } from "../ordering";
import { PProductConfigCreatorFactory } from "./PProductConfigCreatorFactory";
import { PProductInstanceCreatorPart } from "./PProductInstanceCreatorPart";
import { PRestaurant } from "./PRestaurant";
let PProductInstanceCreatorPartFactory = class PProductInstanceCreatorPartFactory {
    constructor(now, pProductConfigCreatorFactory, productInstancePartAvailabilityService, orderingHours, restaurant) {
        this.now = now;
        this.pProductConfigCreatorFactory = pProductConfigCreatorFactory;
        this.productInstancePartAvailabilityService = productInstancePartAvailabilityService;
        this.orderingHours = orderingHours;
        this.restaurant = restaurant;
        makeImmutable(this);
    }
    create(params) {
        const productType = this.restaurant.productTypes.get(params.productTypeId);
        const partConfigCreator = this.pProductConfigCreatorFactory.create({
            domain: params.domain.productConfigCreator,
            modifierOrder: productType.divisibleModifiersOrder,
        });
        return new PProductInstanceCreatorPart({
            now: this.now,
            domain: params.domain,
            productInstancePartAvailabilityService: this.productInstancePartAvailabilityService,
            orderingHours: this.orderingHours,
            productType,
            partConfigCreator,
            possibleProductTypes: params.possibleProductTypes,
            restaurant: this.restaurant,
        });
    }
};
PProductInstanceCreatorPartFactory = __decorate([
    perRestaurant(),
    __param(0, inject(Now)),
    __param(3, inject(IOrderingHours)),
    __metadata("design:paramtypes", [typeof (_a = typeof RDate !== "undefined" && RDate) === "function" ? _a : Object, typeof (_b = typeof PProductConfigCreatorFactory !== "undefined" && PProductConfigCreatorFactory) === "function" ? _b : Object, typeof (_c = typeof ProductInstancePartAvailabilityService !== "undefined" && ProductInstancePartAvailabilityService) === "function" ? _c : Object, typeof (_d = typeof IOrderingHours !== "undefined" && IOrderingHours) === "function" ? _d : Object, typeof (_e = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _e : Object])
], PProductInstanceCreatorPartFactory);
export { PProductInstanceCreatorPartFactory };
