import { Price } from "../Price";
export const ModifierItemInstancePrice = {
    priceSelector,
    unitPrice,
    defaultPrice,
};
function priceSelector(modifierItemInstance) {
    return {
        id: modifierItemInstance.modifierItemType.id.value,
        pricing: modifierItemInstance.modifierItemType.pricing,
        defaultQuantity: modifierItemInstance.defaultQuantity,
        parameterSet: modifierItemInstance.scope.parameterSet,
        quantity: modifierItemInstance.quantity,
    };
}
function unitPrice(params) {
    return Price.scoped(params.pricing, params.parameterSet);
}
function defaultPrice(params) {
    return unitPrice(params).multiply(params.defaultQuantity);
}
