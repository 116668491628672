import { RMap } from "../../../collections";
import { makeImmutable } from "../../../core";
export class CustomParameterMultiSet {
    constructor(values) {
        this.values = values;
        makeImmutable(this);
    }
    static empty() {
        return new CustomParameterMultiSet(RMap.empty());
    }
    get isEmpty() {
        return this.values.isEmpty;
    }
    union(other) {
        return new CustomParameterMultiSet(this.values.unionWithKey(other.values, (_, a, b) => a.union(b)));
    }
    intersection(other) {
        const intersection = this.values
            .intersectionWithKey(other.values, (_, a, b) => a.intersection(b))
            .filterByValues((v) => !v.isEmpty);
        return new CustomParameterMultiSet(intersection);
    }
    toString() {
        return `${this.values.toString()}`;
    }
}
