var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
import { inject, singleton } from "tsyringe";
import { IdMap, RArray, toPairs } from "../collections";
import { MenuConsumer } from "../config";
import { makeImmutable } from "../core";
import { Menu, } from "../ordering";
import { CustomParameterTypeParser } from "./CustomParameterTypeParser";
import { ModifierTypeParser } from "./ModifierTypeParser";
import { ProductCategoryParser } from "./ProductCategoryParser";
import { ProductTypeParser } from "./ProductTypeParser";
/**
 * Transforms MenuV2 from backend to restaumatic-client objects.
 */
let MenuV2Parser = class MenuV2Parser {
    constructor(appOrigin, customParameterTypeParser, modifierTypeParser, productCategoryParser, productTypeParser) {
        this.appOrigin = appOrigin;
        this.customParameterTypeParser = customParameterTypeParser;
        this.modifierTypeParser = modifierTypeParser;
        this.productCategoryParser = productCategoryParser;
        this.productTypeParser = productTypeParser;
        makeImmutable(this);
    }
    parseMenu(params) {
        const customParameterTypes = this.parseCustomParameterTypes(params.catalogue.customParameters);
        const modifierTypes = this.parseModifierTypes(customParameterTypes, params.catalogue.modifiers);
        const productCategories = this.parseProductCategories(customParameterTypes, modifierTypes, params.catalogue.categories);
        const productTypes = this.parseProductTypes(params.schedules, modifierTypes, customParameterTypes, productCategories, params.productTypeGroups, params.catalogue.products);
        return new Menu({
            productCategories,
            productTypes,
            modifierTypes,
            customParameterTypes,
            schedules: params.schedules,
        });
    }
    parseProductTypes(schedules, modifierTypes, customParameterTypes, productCategories, productTypeGroups, products) {
        return IdMap.fromIterable(new RArray(toPairs(products)).mapOptional(([id, productType]) => {
            if (!productType.visibleIn.some((visbleIn) => visbleIn === this.appOrigin)) {
                return null;
            }
            return this.productTypeParser.parse(schedules, modifierTypes, customParameterTypes, productCategories, productTypeGroups, id, productType);
        }));
    }
    parseModifierTypes(customParameterTypes, modifiers) {
        return IdMap.fromIterable(toPairs(modifiers).map(([id, modifier]) => this.modifierTypeParser.parse(customParameterTypes, id, modifier)));
    }
    parseProductCategories(customParameterTypes, modifierTypes, categories) {
        return IdMap.fromIterable(toPairs(categories).map(([id, category]) => this.productCategoryParser.parse(customParameterTypes, modifierTypes, id, category)));
    }
    parseCustomParameterTypes(customParameters) {
        return IdMap.fromIterable(toPairs(customParameters).map(([id, customParameter]) => this.customParameterTypeParser.parse(id, customParameter)));
    }
};
MenuV2Parser = __decorate([
    singleton(),
    __param(0, inject(MenuConsumer)),
    __metadata("design:paramtypes", [typeof (_a = typeof MenuConsumer !== "undefined" && MenuConsumer) === "function" ? _a : Object, typeof (_b = typeof CustomParameterTypeParser !== "undefined" && CustomParameterTypeParser) === "function" ? _b : Object, typeof (_c = typeof ModifierTypeParser !== "undefined" && ModifierTypeParser) === "function" ? _c : Object, typeof (_d = typeof ProductCategoryParser !== "undefined" && ProductCategoryParser) === "function" ? _d : Object, typeof (_e = typeof ProductTypeParser !== "undefined" && ProductTypeParser) === "function" ? _e : Object])
], MenuV2Parser);
export { MenuV2Parser };
