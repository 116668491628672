var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { RArray } from "../../collections";
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
let PackingCheckoutDataService = class PackingCheckoutDataService {
    constructor() {
        makeImmutable(this);
    }
    checkoutData(packing) {
        return packing.containers.map((containerInstance) => this.packagingContainerInstanceCheckoutData(containerInstance));
    }
    packagingContainerInstanceCheckoutData(containerInstance) {
        return [
            containerInstance.packagingContainerType.id.value,
            containerInstance.items.flatMap((packingItem) => this.packingItemCheckoutData(packingItem)),
        ];
    }
    packingItemCheckoutData(packingItem) {
        return RArray.repeat(this.packingSubjectCheckoutData(packingItem.subject), packingItem.quantity).raw;
    }
    packingSubjectCheckoutData(packingSubject) {
        switch (packingSubject.type) {
            case "ProductInstance":
                return {
                    tag: "ProductId",
                    contents: [...packingSubject.productTypeIds].map((productTypeId) => productTypeId.value),
                };
            case "ModifierItem":
                return {
                    tag: "ModifierItemId",
                    contents: [
                        packingSubject.modifierTypeId.value,
                        packingSubject.modifierItemTypeId.value,
                    ],
                };
        }
    }
};
PackingCheckoutDataService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [])
], PackingCheckoutDataService);
export { PackingCheckoutDataService };
