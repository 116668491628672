var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject } from "tsyringe";
import { makeImmutable } from "../core";
import { perRestaurant } from "../di";
import { Consents, ContactDetails, ContactDetailsToken, InvoiceRequest, OrderReceiver, } from "../ordering";
import { PurchaseOrderStorageManager } from "../storage";
let OrderReceiverFactory = class OrderReceiverFactory {
    constructor(purchaseOrderStorageManager, contactDetails) {
        this.purchaseOrderStorageManager = purchaseOrderStorageManager;
        this.contactDetails = contactDetails;
        makeImmutable(this);
    }
    create() {
        const invoiceRequestStorageData = this.purchaseOrderStorageManager.invoiceDetailsStorageManager.restore();
        const consentsStorageData = this.purchaseOrderStorageManager.consentsStoreManager.restore();
        const invoiceRequest = invoiceRequestStorageData === null
            ? null
            : InvoiceRequest.fromStorageData(invoiceRequestStorageData);
        const consents = Consents.fromStorageData(consentsStorageData);
        return new OrderReceiver({
            invoiceRequest,
            contactDetails: this.contactDetails,
            consents,
        });
    }
};
OrderReceiverFactory = __decorate([
    perRestaurant(),
    __param(1, inject(ContactDetailsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof PurchaseOrderStorageManager !== "undefined" && PurchaseOrderStorageManager) === "function" ? _a : Object, typeof (_b = typeof ContactDetails !== "undefined" && ContactDetails) === "function" ? _b : Object])
], OrderReceiverFactory);
export { OrderReceiverFactory };
