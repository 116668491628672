var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { makeImmutable } from "../core";
import { ConditionallyAvailableSpec, TemporarilyUnavailableSpec, } from "../ordering";
import { ParametrizationParser } from "./ParametrizationParser";
import { singleton } from "tsyringe";
let AvailabilitySpecParser = class AvailabilitySpecParser {
    constructor(parametrizationParser) {
        this.parametrizationParser = parametrizationParser;
        makeImmutable(this);
    }
    parse(customParameterTypes, availability, availabilityParametric, availabilitySchedule = null) {
        switch (availability) {
            case "Hidden":
                throw new Error("Hidden elements should be filtered beforehand");
            case "TemporarilyUnavailable":
                return new TemporarilyUnavailableSpec();
            case "Available": {
                const availabilityParametrization = this.parametrizationParser.parse(customParameterTypes, availabilityParametric);
                return new ConditionallyAvailableSpec({
                    availabilityParametrization,
                    availabilitySchedule,
                });
            }
        }
    }
};
AvailabilitySpecParser = __decorate([
    singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof ParametrizationParser !== "undefined" && ParametrizationParser) === "function" ? _a : Object])
], AvailabilitySpecParser);
export { AvailabilitySpecParser };
