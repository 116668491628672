import { RArray, RSet } from "../../collections";
import { makeImmutable } from "../../core";
import { ParameterScope } from "../ParameterScope";
/**
 * A product instance split into halves, such as a pizza, split into half Margherita and half Capriciosa
 */
export class ProductInstanceHalves {
    constructor(params) {
        this.type = "Halves";
        this.scope = params.scope;
        this.productCategory = params.productCategory;
        this.first = params.first;
        this.second = params.second;
        makeImmutable(this);
    }
    static create(params) {
        if (!params.first.productCategoryId.eq(params.productCategory.id)) {
            return null;
        }
        if (!params.second.productCategoryId.eq(params.productCategory.id)) {
            return null;
        }
        return new ProductInstanceHalves(params);
    }
    static empty(params) {
        return ProductInstanceHalves.create({
            ...params,
            scope: ParameterScope.Uninitialized,
        });
    }
    get productTypeIds() {
        return RSet.fromIterable([this.first.productTypeId, this.second.productTypeId], {
            ignoreDuplicates: true,
        });
    }
    get productWeeklySchedule() {
        var _a;
        if (this.first.weeklySchedule !== null &&
            this.second.weeklySchedule !== null) {
            return this.first.weeklySchedule.intersection(this.second.weeklySchedule);
        }
        return (_a = this.first.weeklySchedule) !== null && _a !== void 0 ? _a : this.second.weeklySchedule;
    }
    get productCategoryId() {
        return this.first.productCategoryId;
    }
    get customParameterChoiceIds() {
        const first = this.first.customParameterChoiceIds;
        const second = this.second.customParameterChoiceIds;
        return first.union(second);
    }
    get parts() {
        return new RArray([this.first, this.second]);
    }
    get productInstanceParts() {
        return [this.first, this.second];
    }
    get storageData() {
        return {
            type: "Halves",
            first: this.first.storageData,
            second: this.second.storageData,
        };
    }
}
