import { makeAutoObservable } from "mobx";
import { PCrossSellingItem } from "./PCrossSellingItem";
import { PCrossSellingItemBehaviour } from "./PCrossSellingItemBehaviour";
export class PCrossSellingCreator {
    constructor(domain, restaurant, localizationProvider, orderItemListTracking, pProductInstanceCreatorFactory, crossSellingItemInstancePriceService) {
        this.domain = domain;
        this.restaurant = restaurant;
        this.localizationProvider = localizationProvider;
        this.orderItemListTracking = orderItemListTracking;
        this.pProductInstanceCreatorFactory = pProductInstanceCreatorFactory;
        this.crossSellingItemInstancePriceService = crossSellingItemInstancePriceService;
        makeAutoObservable(this);
    }
    get items() {
        return this.domain.items.map((crossSellingItemInstance) => this.createItem(crossSellingItemInstance));
    }
    createItem(crossSellingItemInstance) {
        const behaviour = PCrossSellingItemBehaviour.create({
            domain: crossSellingItemInstance.behaviour,
            kind: crossSellingItemInstance.kind,
            orderItemListTracking: this.orderItemListTracking,
        });
        const { product } = this.pProductInstanceCreatorFactory.create(crossSellingItemInstance.itemCreator);
        return new PCrossSellingItem({
            domain: crossSellingItemInstance,
            behaviour,
            localizationProvider: this.localizationProvider,
            crossSellingItemInstancePriceService: this.crossSellingItemInstancePriceService,
            restaurant: this.restaurant,
            product,
        });
    }
}
