var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
import { inject } from "tsyringe";
import { makeImmutable, Now, RDate } from "../core";
import { perRestaurant } from "../di";
import { DEFAULT_FULFILLMENT_TYPE, DeliveryAddress, FulfillmentInstructions, FulfillmentTime, FulfillmentTimeValidator, OnlineFulfillmentInstructions, QrWaiterFulfillmentInstructions, Restaurant, } from "../ordering";
import { PurchaseOrderStorageManager } from "../storage";
let FulfillmentInstructionsFactory = class FulfillmentInstructionsFactory {
    constructor(restaurant, now, fulfillmentTimeValidator, purchaseOrderStorageManager, deliveryAddress) {
        this.restaurant = restaurant;
        this.now = now;
        this.fulfillmentTimeValidator = fulfillmentTimeValidator;
        this.purchaseOrderStorageManager = purchaseOrderStorageManager;
        this.deliveryAddress = deliveryAddress;
        makeImmutable(this);
    }
    create(dineInLocation) {
        var _a;
        const fulfillmentTimeStorageData = this.purchaseOrderStorageManager.fulfillmentTimeStorageManager.restore();
        const fulfillmentTimeFromStorage = fulfillmentTimeStorageData === null
            ? null
            : FulfillmentTime.fromStorageData({
                now: this.now,
                storageData: fulfillmentTimeStorageData,
            });
        const fulfillmentTime = this.fulfillmentTimeValidator.validate(fulfillmentTimeFromStorage);
        const rawFulfillmentMethodType = (_a = this.purchaseOrderStorageManager.fulfillmentMethodTypeStorageManager.restore()) !== null && _a !== void 0 ? _a : DEFAULT_FULFILLMENT_TYPE;
        const fulfillmentMethodType = FulfillmentInstructions.getAvailableFulfillmentMethod(rawFulfillmentMethodType, this.restaurant.fulfillmentMethods);
        if (dineInLocation === null) {
            return new OnlineFulfillmentInstructions({
                fulfillmentMethodType,
                fulfillmentTime,
                deliveryAddress: this.deliveryAddress,
            });
        }
        else {
            return new QrWaiterFulfillmentInstructions({
                dineInLocation,
            });
        }
    }
};
FulfillmentInstructionsFactory = __decorate([
    perRestaurant(),
    __param(1, inject(Now)),
    __metadata("design:paramtypes", [typeof (_a = typeof Restaurant !== "undefined" && Restaurant) === "function" ? _a : Object, typeof (_b = typeof RDate !== "undefined" && RDate) === "function" ? _b : Object, typeof (_c = typeof FulfillmentTimeValidator !== "undefined" && FulfillmentTimeValidator) === "function" ? _c : Object, typeof (_d = typeof PurchaseOrderStorageManager !== "undefined" && PurchaseOrderStorageManager) === "function" ? _d : Object, typeof (_e = typeof DeliveryAddress !== "undefined" && DeliveryAddress) === "function" ? _e : Object])
], FulfillmentInstructionsFactory);
export { FulfillmentInstructionsFactory };
