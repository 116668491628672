var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { HalvesCreatorState } from "./HalvesCreatorState";
import { ProductConfigCreatorFactory } from "./ProductConfigCreatorFactory";
import { ProductInstanceCreatorPartFactory } from "./ProductInstanceCreatorPartFactory";
import { SinglePartCreatorState } from "./SinglePartCreatorState";
let ProductInstanceCreatorStateFactory = class ProductInstanceCreatorStateFactory {
    constructor(productConfigCreatorFactory, productInstanceCreatorPartFactory) {
        this.productConfigCreatorFactory = productConfigCreatorFactory;
        this.productInstanceCreatorPartFactory = productInstanceCreatorPartFactory;
        makeImmutable(this);
    }
    fromProductInstanceParts(params) {
        switch (params.parts.type) {
            case "SinglePart":
                return this.createSingle({
                    productCategory: params.productCategory,
                    initialCommonConfig: params.commonConfig,
                    part: params.parts.part,
                });
            case "Halves":
                return this.createHalves({
                    productCategory: params.productCategory,
                    initialCommonConfig: params.commonConfig,
                    firstHalf: params.parts.first,
                    secondHalf: params.parts.second,
                });
        }
    }
    createSingle(params) {
        const commonConfigCreator = this.productConfigCreatorFactory.create({
            modifierTypes: params.part.productType.nonDivisibleModifierTypes,
            customParameterTypes: params.part.productType.nonDivisibleCustomParameterTypes,
            initialModifierInstances: params.initialCommonConfig.modifierInstances,
            initialCustomParameterInstances: params.initialCommonConfig.customParameterInstances,
            modifierSettings: (m) => m.defaultSettings,
        });
        const partCreator = this.productInstanceCreatorPartFactory.create(params.part);
        return new SinglePartCreatorState({
            productCategory: params.productCategory,
            commonConfigCreator,
            partCreator,
        });
    }
    createHalves(params) {
        const commonConfigCreator = this.productConfigCreatorFactory.create({
            modifierTypes: params.firstHalf.productType.nonDivisibleModifierTypes.union(params.secondHalf.productType.nonDivisibleModifierTypes),
            customParameterTypes: params.firstHalf.productType.nonDivisibleCustomParameterTypes.union(params.secondHalf.productType.nonDivisibleCustomParameterTypes),
            initialModifierInstances: params.initialCommonConfig.modifierInstances,
            initialCustomParameterInstances: params.initialCommonConfig.customParameterInstances,
            modifierSettings: (m) => m.defaultSettings,
        });
        return new HalvesCreatorState({
            productCategory: params.productCategory,
            commonConfigCreator,
            firstHalf: this.productInstanceCreatorPartFactory.create(params.firstHalf),
            secondHalf: this.productInstanceCreatorPartFactory.create(params.secondHalf),
        });
    }
};
ProductInstanceCreatorStateFactory = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [typeof (_a = typeof ProductConfigCreatorFactory !== "undefined" && ProductConfigCreatorFactory) === "function" ? _a : Object, typeof (_b = typeof ProductInstanceCreatorPartFactory !== "undefined" && ProductInstanceCreatorPartFactory) === "function" ? _b : Object])
], ProductInstanceCreatorStateFactory);
export { ProductInstanceCreatorStateFactory };
