import { OnlineContactDetails, } from "./OnlineContactDetails";
import { QrWaiterContactDetails, } from "./QRWaiterContactDetails";
export const ContactDetails = {
    empty(params) {
        return params.isQrWaiterOrder
            ? QrWaiterContactDetails.empty(params.contactDetailsLock)
            : OnlineContactDetails.empty(params.validatePhoneService, params.contactDetailsLock);
    },
    fromStorageData({ validatePhoneService, contactDetailsLock, storageData, }) {
        switch (storageData.type) {
            case "Online":
                return OnlineContactDetails.fromStorageData({
                    validatePhoneService,
                    contactDetailsLock,
                    storageData,
                });
            case "QRWaiter":
                return QrWaiterContactDetails.fromStorageData(contactDetailsLock, storageData);
        }
    },
};
export const ContactDetailsToken = "ContactDetails";
