var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { perRestaurant } from "../di";
import { LocalizationProvider } from "../locale";
import { ContactDetails, ContactDetailsToken, OrderReceiver, Payment, PromotionScheme, PurchaseOrder, PurchaseOrderAvailabilityService, PurchaseOrderCheckoutDataService, PurchaseOrderPackingService, PurchaseOrderPayload, PurchaseOrderPriceService, } from "../ordering";
import { formatFulfillmentMethodType, formatFulfillmentMethodTypeShort, } from "./formatFulfillmentMethodType";
import { POrderLineFactory } from "./POrderLineFactory";
import { PPrice } from "./PPrice";
import { PPurchaseOrderUnavailabilityDetails } from "./PPurchaseOrderUnavailabilityDetails";
import { PRestaurant } from "./PRestaurant";
let PPurchaseOrder = class PPurchaseOrder {
    constructor(domain, payload, promotionScheme, payment, orderReceiver, purchaseOrderCheckoutDataService, purchaseOrderPackingService, restaurant, localizationProvider, purchaseOrderAvailabilityService, purchaseOrderPriceService, pOrderLineFactory, pPurchaseOrderUnavailabilityDetails, contactDetails) {
        this.domain = domain;
        this.payload = payload;
        this.promotionScheme = promotionScheme;
        this.payment = payment;
        this.orderReceiver = orderReceiver;
        this.purchaseOrderCheckoutDataService = purchaseOrderCheckoutDataService;
        this.purchaseOrderPackingService = purchaseOrderPackingService;
        this.restaurant = restaurant;
        this.localizationProvider = localizationProvider;
        this.purchaseOrderAvailabilityService = purchaseOrderAvailabilityService;
        this.purchaseOrderPriceService = purchaseOrderPriceService;
        this.pOrderLineFactory = pOrderLineFactory;
        this.pPurchaseOrderUnavailabilityDetails = pPurchaseOrderUnavailabilityDetails;
        this.contactDetails = contactDetails;
        makeAutoObservable(this);
    }
    get availability() {
        return this.purchaseOrderAvailabilityService.availability;
    }
    get productTypeIds() {
        return this.payload.productTypeIds;
    }
    get error() {
        return this.pPurchaseOrderUnavailabilityDetails.error;
    }
    get pPrice() {
        return new PPrice(this.localizationProvider, this.purchaseOrderPriceService.price.spectrum);
    }
    get price() {
        return this.pPrice.priceValue;
    }
    get grandTotal() {
        return this.pPrice.grandPriceValue;
    }
    get finalPrice() {
        return this.purchaseOrderPriceService.price.finalValue;
    }
    get validationPrice() {
        return this.purchaseOrderPriceService.validationPrice;
    }
    get packagingPrice() {
        const packagingPrice = this.purchaseOrderPackingService.packaging.price;
        if (packagingPrice.isZero) {
            return null;
        }
        return this.localizationProvider.formatPrice(packagingPrice);
    }
    get deliveryPrice() {
        const deliveryPrice = this.purchaseOrderPriceService.deliveryPrice;
        if (deliveryPrice.isZero) {
            return null;
        }
        return this.localizationProvider.formatPrice(deliveryPrice);
    }
    get orderLines() {
        return this.payload.orderLines.map((orderLine) => this.pOrderLineFactory.create(orderLine)).raw;
    }
    get canPlaceOrder() {
        return this.purchaseOrderAvailabilityService.canPlaceOrder;
    }
    get isReadyForCheckout() {
        return this.purchaseOrderAvailabilityService.isReadyForCheckout;
    }
    get isCheckoutFormValid() {
        return this.purchaseOrderAvailabilityService.isCheckoutFormValid;
    }
    get isAgreedToMarketingConsents() {
        return (this.orderReceiver.consents.subscribeEmail ||
            this.orderReceiver.consents.subscribePhone);
    }
    addOrderLine(orderLine) {
        return this.payload.addOrderLine(orderLine);
    }
    get numItems() {
        return this.payload.numberOfItems.value;
    }
    clear() {
        this.payload.clear();
        this.promotionScheme.clear();
    }
    get contactDetailsLocked() {
        return this.contactDetails.locked;
    }
    unlockContactDetails() {
        this.contactDetails.unlock();
    }
    // NOTICE: NoContactDelivery method is not served as an option but as a checkbox inside "Delivery" form
    get fulfillmentMethods() {
        if (!this.restaurant.fulfillmentMethods.includes("Delivery") &&
            this.restaurant.fulfillmentMethods.includes("NoContactDelivery")) {
            return this.restaurant.fulfillmentMethods.raw;
        }
        return this.restaurant.fulfillmentMethods.raw.filter((method) => method !== "NoContactDelivery");
    }
    get fulfillmentMethodOptions() {
        return this.fulfillmentMethods.map((method) => ({
            value: method,
            label: formatFulfillmentMethodType(method),
        }));
    }
    get fulfillmentMethodOptionsShort() {
        return this.fulfillmentMethods.map((method) => ({
            value: method,
            label: formatFulfillmentMethodTypeShort(method),
        }));
    }
    checkoutData(params) {
        return this.purchaseOrderCheckoutDataService.checkoutData(params);
    }
    get checkoutLoggingData() {
        return this.purchaseOrderCheckoutDataService.checkoutLoggingData;
    }
    get instanceCounts() {
        return this.payload.orderLines.map((line) => ({
            instance: line.productInstance,
            quantity: line.quantity.value,
        }));
    }
    get isPaymentRestored() {
        return this.payment.isRestored;
    }
};
PPurchaseOrder = __decorate([
    perRestaurant(),
    __param(13, inject(ContactDetailsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof PurchaseOrder !== "undefined" && PurchaseOrder) === "function" ? _a : Object, typeof (_b = typeof PurchaseOrderPayload !== "undefined" && PurchaseOrderPayload) === "function" ? _b : Object, typeof (_c = typeof PromotionScheme !== "undefined" && PromotionScheme) === "function" ? _c : Object, typeof (_d = typeof Payment !== "undefined" && Payment) === "function" ? _d : Object, typeof (_e = typeof OrderReceiver !== "undefined" && OrderReceiver) === "function" ? _e : Object, typeof (_f = typeof PurchaseOrderCheckoutDataService !== "undefined" && PurchaseOrderCheckoutDataService) === "function" ? _f : Object, typeof (_g = typeof PurchaseOrderPackingService !== "undefined" && PurchaseOrderPackingService) === "function" ? _g : Object, typeof (_h = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _h : Object, typeof (_j = typeof LocalizationProvider !== "undefined" && LocalizationProvider) === "function" ? _j : Object, typeof (_k = typeof PurchaseOrderAvailabilityService !== "undefined" && PurchaseOrderAvailabilityService) === "function" ? _k : Object, typeof (_l = typeof PurchaseOrderPriceService !== "undefined" && PurchaseOrderPriceService) === "function" ? _l : Object, typeof (_m = typeof POrderLineFactory !== "undefined" && POrderLineFactory) === "function" ? _m : Object, typeof (_o = typeof PPurchaseOrderUnavailabilityDetails !== "undefined" && PPurchaseOrderUnavailabilityDetails) === "function" ? _o : Object, typeof (_p = typeof ContactDetails !== "undefined" && ContactDetails) === "function" ? _p : Object])
], PPurchaseOrder);
export { PPurchaseOrder };
