import I18n from "i18n-js";
import { makeImmutable } from "../core";
export class PPrice {
    constructor(localizationProvider, value) {
        this.localizationProvider = localizationProvider;
        this.spectrum = value;
        makeImmutable(this);
    }
    get type() {
        return this.spectrum.type;
    }
    /*
     * Null is returned when money equals zero - this is convenient for UI
     */
    get priceValue() {
        switch (this.spectrum.type) {
            case "null":
                return null;
            case "value":
                return this.localizationProvider.formatPrice(this.spectrum.value);
            case "range":
                return `${I18n.t("theme_defaults.menu.price_from")} ${this.localizationProvider.formatPrice(this.spectrum.min)}`;
        }
    }
    /**
     * Min price value is minimal price in range, null when not range
     */
    get minPriceValue() {
        switch (this.spectrum.type) {
            case "null":
            case "value":
                return null;
            case "range":
                return this.localizationProvider.formatPrice(this.spectrum.min);
        }
    }
    /*
     * Grand price is a singular price, null when range
     */
    get grandPriceValue() {
        switch (this.spectrum.type) {
            case "null":
                return null;
            case "value":
                return this.localizationProvider.formatPrice(this.spectrum.value);
            case "range":
                return null;
        }
    }
}
