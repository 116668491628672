import { makeImmutable } from "../../core";
import { ParameterScope } from "../ParameterScope";
import { ProductConfig } from "./ProductConfig";
/**
 * Part of a product instance
 * Has ProductType and ProductConfig
 */
export class ProductInstancePart {
    constructor(params) {
        this.scope = params.scope;
        this.productType = params.productType;
        this.productConfig = params.productConfig;
        makeImmutable(this);
    }
    static empty(productType) {
        return new ProductInstancePart({
            scope: ParameterScope.Uninitialized,
            productType,
            productConfig: ProductConfig.empty(),
        });
    }
    eq(other) {
        return (this.productTypeId.eq(other.productTypeId) &&
            this.productConfig.eq(other.productConfig));
    }
    isCartEquivalentTo(other) {
        return (this.productTypeId.eq(other.productTypeId) &&
            this.productConfig.isCartEquivalentTo(other.productConfig));
    }
    get productTypeId() {
        return this.productType.id;
    }
    get productCategoryId() {
        return this.productType.productCategoryId;
    }
    get customParameterChoiceIds() {
        return this.productConfig.customParameters.choiceIds;
    }
    get weeklySchedule() {
        var _a, _b;
        return (_b = (_a = this.productType.schedule) === null || _a === void 0 ? void 0 : _a.schedule) !== null && _b !== void 0 ? _b : null;
    }
    get storageData() {
        return {
            productConfig: this.productConfig.storageData,
            productTypeId: this.productType.id.value,
        };
    }
}
