import { makeImmutable } from "../core";
import { OrderLine } from "./OrderLine";
export class ProductCardCreatorResult {
    constructor(orderLines) {
        this._orderLines = orderLines;
        makeImmutable(this);
    }
    initWithScope(parentScope) {
        this._orderLines.forEach(([productInstanceCreator]) => {
            productInstanceCreator.initWithScope(parentScope);
        });
    }
    get orderLines() {
        return this._orderLines.map(([productInstanceCreator, quantity]) => {
            return new OrderLine({
                productInstanceCreator,
                quantity,
            });
        });
    }
}
