var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { makeImmutable } from "../../core";
import { perRestaurant } from "../../di";
import { DEFAULT_APARTMENT_INFO, } from "../DeliveryAddress";
let ApartmentInfoCheckoutDataService = class ApartmentInfoCheckoutDataService {
    constructor() {
        makeImmutable(this);
    }
    checkoutData(apartmentInfo) {
        switch (apartmentInfo.type) {
            case "Romanian":
                return apartmentInfo.isBlock
                    ? { ...apartmentInfo.data }
                    : {
                        ...DEFAULT_APARTMENT_INFO,
                        sector: apartmentInfo.data.sector,
                        houseOrBlock: apartmentInfo.data.houseOrBlock,
                        interphone: apartmentInfo.data.interphone,
                        additionalInfo: apartmentInfo.data.additionalInfo,
                    };
            case "Default":
                return {
                    ...DEFAULT_APARTMENT_INFO,
                    ...apartmentInfo.data,
                };
        }
    }
};
ApartmentInfoCheckoutDataService = __decorate([
    perRestaurant(),
    __metadata("design:paramtypes", [])
], ApartmentInfoCheckoutDataService);
export { ApartmentInfoCheckoutDataService };
